import React from "react";
import { connect } from "react-redux";
import SelectBox from "form-controls/SelectBox";
import { Formik, Field, Form } from "formik";
import Button from "Button";
import {
  optionList as trailerStatusOptions,
  trailerStatuses
} from "trailerStatuses";
import { updateTrailerStatus } from "warehouse/pages/warehouseQueries";
import { addMessage } from "redux/app";

class EditTrailerStatusForm extends React.Component {
  get trailerStatusOptions() {
    if (
      [trailerStatuses.readyToMove, trailerStatuses.actionComplete].includes(
        this.props.selectedTrailer.status
      ) &&
      this.props.isSelectedDockSafe
    ) {
      return trailerStatusOptions();
    }

    return trailerStatusOptions().filter(
      t => t.value !== trailerStatuses.readyToMove
    );
  }

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          trailerStatus: this.props.selectedTrailer.status
        }}
        onSubmit={values => {
          updateTrailerStatus(
            this.props.selectedTrailer.id,
            values.trailerStatus
          )
            .then(() => {
              this.props.dispatch(
                addMessage("success", "Successfully updated the trailer status")
              );
              return this.props.afterUpdate();
            })
            .catch(errors => {
              if (errors instanceof Error) {
                this.props.dispatch(
                  addMessage("error", "An unknown error occurred")
                );
              } else {
                errors.forEach(({ message }) =>
                  this.props.dispatch(addMessage("error", message))
                );
              }
            });
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Field
              name="trailerStatus"
              label="Trailer status"
              component={SelectBox}
              options={this.trailerStatusOptions}
              noDefault
            />
            <div className="button-col">
              <Button
                type="submit"
                variant="primary"
                size="small"
                title="Update status"
                disabled={
                  Object.keys(touched).length < 1 || Object.keys(errors).length
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect()(EditTrailerStatusForm);
