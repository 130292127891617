import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { startOfDay } from "date-fns";
import { selectDate } from "redux/app";
import { isEqual, format } from "date-fns";
import BookingRightPanel from "./calendar-view-page/BookingRightPanel";
import Loader from "Loader";
import BookingPageTemplate from "./BookingPageTemplate";
import { selectSite } from "redux/app";
import CalendarView from "./calendar-view-page/CalendarView";
import {
  getSiteBookingsOverview as getSiteBookings,
  bookingFromJourneyReference
} from "./calendar-view-page/calendar-view-queries";
import * as Booking from "./calendar-view-page/Booking";

class CalendarViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingLoading: false,
      selectedBooking: null,
      selectedBookings: [],
      bookings: [],
      bookingsLoading: true
    };
  }

  componentDidMount() {
    if (parseInt(this.props.match.params.siteId) !== this.props.selectedSite) {
      this.props.dispatch(selectSite(this.props.match.params.siteId));
    } else {
      this.fetchSiteBookings();
    }

    if (this.props.match.params.journeyRef) {
      this.fetchBooking();
    }
  }

  updateRoute(id) {
    if (id !== null) {
      this.props.history.push({
        pathname: `/bookings/site/${id}/bookings`
      });
    } else {
      this.props.history.push({ pathname: "/bookings/" });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedSite !== this.props.selectedSite ||
      !isEqual(prevProps.selectedDate, this.props.selectedDate)
    ) {
      if (
        this.props.selectedSite &&
        parseInt(this.props.match.params.siteId) !== this.props.selectedSite
      ) {
        this.updateRoute(this.props.selectedSite);
      }
      this.fetchSiteBookings();
    }

    if (
      prevProps.match.params.journeyRef !== this.props.match.params.journeyRef
    ) {
      if (this.props.match.params.journeyRef) {
        this.fetchBooking();
        Booking.selectBooking(this.props.match.params.journeyRef);
      } else {
        this.setState({
          selectedBooking: null,
          selectedBookings: [],
          bookingLoading: false,
        });
        Booking.deselectAllBookings();
      }
    }
  }

  handleCreateBooking() {
    this.fetchSiteBookings();
  }

  handleDeleteBooking() {
    const { id } = this.state.selectedBooking;
    this.updateRoute(this.props.selectedSite);
    this.setState({
      selectedBooking: null,
      selectedBookings: [],
      bookings: this.state.bookings.filter((b) => b.id !== id),
    });
  }

  handleUpdateBooking() {
    this.fetchBooking().then(data => {
      if (data.bookings.length > 0) {
        const [selectedBooking] = data.bookings;
        this.setState({
          bookings: this.state.bookings.map(b =>
            b.id === selectedBooking.id ? selectedBooking : b
          )
        });
      }
    });
  }

  handleShowCreateInternal() {
    this.props.history.push(
      `/bookings/site/${this.props.selectedSite}/createInternalBooking`
    );
  }

  handleShowCreateExternal() {
    this.props.history.push(
      `/bookings/site/${this.props.selectedSite}/createBooking`
    );
  }

  handleSelectBooking(journeyReference) {
    const isNewJourneyReference =
      journeyReference !== this.props.match.params.journeyRef;
    if (isNewJourneyReference) {
      this.props.history.push(
        `/bookings/site/${this.props.selectedSite}/booking/${journeyReference}`
      );

      Booking.selectBooking(journeyReference);
    } else {
      this.props.history.push(
        `/bookings/site/${this.props.selectedSite}/bookings`
      );

      Booking.deselectAllBookings();
    }
  }

  handleSelectAlternateBooking(bookingId) {
    const alternateBooking = this.state.selectedBookings.find(
      ({ id }) => id === bookingId
    );
    const bookingDate = startOfDay(alternateBooking.slotStart);
    if (!isEqual(bookingDate, this.props.selectedDate)) {
      this.props.dispatch(selectDate(bookingDate));
    }

    this.setState({ selectedBooking: alternateBooking });
  }

  fetchBooking() {
    this.setState({
      selectedBooking: null,
      selectedBookings: [],
      bookingLoading: true,
    });

    return bookingFromJourneyReference(this.props.match.params.journeyRef).then(
      data => {
        if (data.bookings.length > 0) {
          let selectedBooking = data.bookings.find((b) => {
            const bookingDate = startOfDay(b.slotStart);

            return (
              b.status !== "completed" &&
              isEqual(bookingDate, this.props.selectedDate)
            );
          });

          if (!selectedBooking) {
            [selectedBooking] = data.bookings;
          }

          const bookingDate = startOfDay(selectedBooking.slotStart);
          if (!isEqual(bookingDate, this.props.selectedDate)) {
            this.props.dispatch(selectDate(bookingDate));
          }
          this.setState({
            selectedBooking,
            selectedBookings: data.bookings,
            bookingLoading: false,
          });
        }

        return data;
      }
    );
  }

  fetchSiteBookings() {
    this.setState({ bookings: [], bookingsLoading: true });
    return getSiteBookings(
      this.props.selectedSite,
      format(this.props.selectedDate, "YYYY-MM-DD")
    ).then(data => {
      this.setState({ bookings: data.bookings, bookingsLoading: false });
    });
  }

  render() {
    const journeyReference = this.props.match.params.journeyRef;

    return (
      <BookingPageTemplate className="calendar-view-page">
        {this.state.bookingsLoading && (
          <div className="calendar-view__loader">
            <Loader />
          </div>
        )}

        {!this.state.bookingsLoading && (
          <CalendarView
            bookings={this.state.bookings}
            selectedDate={this.props.selectedDate}
            journeyReference={journeyReference}
            selectedSite={this.props.selectedSite}
            onShowCreateInternal={this.handleShowCreateInternal.bind(this)}
            onShowCreateExternal={this.handleShowCreateExternal.bind(this)}
            onSelectBooking={this.handleSelectBooking.bind(this)}
          />
        )}

        <BookingRightPanel
          journeyReference={journeyReference}
          match={this.props.match}
          selectedSite={
            this.props.selectedSite !== null ? this.props.selectedSite : ""
          }
          bookings={this.state.bookings}
          loadingBooking={this.state.bookingLoading}
          selectedBooking={this.state.selectedBooking}
          selectedBookings={this.state.selectedBookings}
          selectedDate={this.props.selectedDate}
          updateRoute={this.updateRoute.bind(this)}
          onSelectBooking={this.handleSelectAlternateBooking.bind(this)}
          onCreateBooking={this.handleCreateBooking.bind(this)}
          onUpdateBooking={this.handleUpdateBooking.bind(this)}
          onDeleteBooking={this.handleDeleteBooking.bind(this)}
        />
      </BookingPageTemplate>
    );
  }
}

function mapStateToProps({ app: { selectedDate, selectedSite } }) {
  return { selectedDate, selectedSite };
}

export default withRouter(connect(mapStateToProps)(CalendarViewPage));
