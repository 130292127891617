import React from "react";
import TableView from "TableView";
import TrailerUnavailableIcon from "TrailerUnavailableIcon";
import RebookingIcon from "RebookingIcon";

export default function VehiclesOffRoadTable({
  selectedSiteId,
  onSelectBooking
}) {
  const unavailabilityReasons = {
    vor: "Vehicle Off Road",
    service: "Service",
    mot: "MOT",
    tracker_issues: "Tracker Issues",
    returns_rebooks: "Returns/Rebooks"
  };

  return (
    <TableView
      root="trailers"
      args={{ site: selectedSiteId, isUnavailable: true }}
      noDataMessage="No vehicles currently off road."
      fields={[
        [
          "",
          [
            "isUnavailable",
            "unavailabilityReason",
            "unavailabilityNotes",
            "returnDate"
          ],
          (d, t) => <TrailerUnavailableIcon trailer={t} />
        ],
        [
          "Type",
          "unavailabilityReason",
          d => unavailabilityReasons[d] || "Unknown"
        ],
        ["Owner", "haulier.shortCode"],
        ["Number", "trailerNo"],
        [
          "Last Move",
          ["movedAt", "insertedAt"],
          (d, t) => t.movedAt || t.insertedAt
        ],
        [
          "Booking",
          [
            "id",
            "booking.id",
            "booking.journeyReference",
            "booking.direction",
            "booking.requiresTranship",
            "booking.isRebooking"
          ],
          (d, t) =>
            t.booking && (
              <div
                className="link"
                onClick={() => onSelectBooking(t.booking.id)}
              >
                <RebookingIcon booking={t.booking} />
                {t.booking.journeyReference} ({t.booking.direction})
              </div>
            )
        ]
      ]}
    />
  );
}
