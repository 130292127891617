export const bookingStatuses = {
  new: "new",
  arrived: "arrived",
  completed: "completed",
  cancelled: "cancelled",
  inboundInProgress: "inbound_in_progress",
  outboundInProgress: "outbound_in_progress",
  readyForDriver: "ready_for_driver",
  trailerAttached: "trailer_attached"
};

export function getBookingStatusLabel(booking) {
  switch (booking.status) {
    case bookingStatuses.new:
      if (booking.direction == "inbound") {
        return "Awaiting arrival";
      } else {
        return "Awaiting trailer";
      }
    case bookingStatuses.arrived:
      return "Arrived on site";
    case bookingStatuses.completed:
      return "Completed";
    case bookingStatuses.cancelled:
      return "Cancelled";
    case bookingStatuses.inboundInProgress:
      return "Unloading";
    case bookingStatuses.outboundInProgress:
      return "Loading";
    case bookingStatuses.readyForDriver:
      return "Ready for driver";
    case bookingStatuses.trailerAttached:
      return "Trailer assigned";
    default:
      return "";
  }
}
