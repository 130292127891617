import React from "react";
import Button from "Button";
import CheckInForm from "./CheckInForm";
import Alert from "Alert";
import { formatLocalDate } from "date-helper";
import CheckBoxNoFormik from "components/form-controls/CheckBoxNoFormik";
import config from "../../../../config";

export default class CheckOutPanel extends React.Component {
  constructor(props) {
    super(props);
    this.video = React.createRef();
    this.photo = React.createRef();
    this.canvas = React.createRef();
    this.width = 1280;
    this.height = 960;
    this.state = {
      showClear: false,
      vetCheckPerformed: false,
      showVetError: false,
      showImgError: false
    };
  }

  componentDidUpdate() {
    if (this.video.current) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then(stream => {
          this.video.current.srcObject = stream;
          this.video.current.play();
        })
        .catch(function(err) {
          err;
        });
    }
  }

  takePicture() {
    const context = this.canvas.current.getContext("2d");
    this.clearPhoto();
    this.canvas.current.width = this.width;
    this.canvas.current.height = this.height;
    context.drawImage(this.video.current, 0, 0, this.width, this.height);

    const data = this.canvas.current.toDataURL("image/png");
    this.photo.current.setAttribute("src", data);
    this.setState({ showClear: true });
  }

  clearPhoto() {
    const context = this.canvas.current.getContext("2d");
    context.fillStyle = "#FFF";
    context.fillRect(
      0,
      0,
      this.canvas.current.width,
      this.canvas.current.height
    );

    const data = this.canvas.current.toDataURL("image/png");
    this.photo.current.setAttribute("src", data);
    this.setState({ showClear: false });
  }

  stopVideo() {
    if (this.video.current && this.video.current.srcObject) {
      this.video.current.srcObject.getTracks().forEach(function(track) {
        track.stop();
      });
    }
  }

  checkPhoto(photo) {
    return !photo || photo === "" || !this.state.showClear ? false : true;
  }

  checkForRequired(isExport, vetCheckRequired, booking) {
    let errors = false;

    if (config.REACT_APP_CLIENT === "york") {
      this.props.onConfirm(booking.id, null, false, false);
      return;
    }

    if (vetCheckRequired && !this.state.vetCheckPerformed) {
      this.setState({ showVetError: true });
      errors = true;
    }
    if (isExport && !this.checkPhoto(this.photo.current.src)) {
      this.setState({ showImgError: true });
      errors = true;
    }
    if (isExport && !errors) {
      const vetCheckPerformed = !vetCheckRequired
        ? false
        : this.state.vetCheckPerformed;
      this.props.onConfirm(
        booking.id,
        this.photo.current.src,
        vetCheckRequired,
        vetCheckPerformed
      );
    } else if (!isExport) {
      this.props.onConfirm(booking.id, null, false, false);
    }
  }

  render() {
    const requiresImageCapture = config.FEATURE_CHECKOUT_REQUIRE_IMAGE_CAPTURE;

    const { booking, action } = this.props;

    const isExport =
      booking &&
      booking.appointment &&
      booking.appointment.data.export_ind === "Y";

    const vetCheckRequired =
      config.FEATURE_CHECKOUT_REQUIRE_VET_CHECK &&
      booking &&
      booking.appointment &&
      booking.appointment.data.vet_check_required === "Y";

    // turn off camera when panel is closed
    if (!booking && this.video.current !== null) {
      this.stopVideo();
    }
    return (
      <div>
        {booking && action === "Check In" && (
          <CheckInForm
            booking={booking}
            closePanel={this.props.onClose}
            showDriverLocation={this.props.showDriverLocation}
          />
        )}
        {booking && action === "Driver Off Site" && (
          <div>
            {booking.direction === "outbound" && (
              <h5>
                Please ensure that driver is leaving site without a trailer, and
                will return for booking at a later time.
              </h5>
            )}
            {booking.direction === "inbound" && (
              <h5>
                Please ensure that the driver is leaving site without a trailer,
                and will NOT return using the same journey reference.
              </h5>
            )}
            <div className="button-col">
              <Button
                variant="primary"
                size="small"
                title="Confirm driver off site"
                onClick={() => {
                  this.props.confirmDriverOffSite(
                    booking.id,
                    booking.direction
                  );
                }}
              />
            </div>
          </div>
        )}
        {booking && action === "Staged Load" && (
          <div>
            <h5>
              Please ensure that driver is leaving site without a trailer, and
              has parked his trailer in the appropriate spot (or let the traffic
              office know where the trailer is).
            </h5>
            <div className="button-col">
              <Button
                variant="primary"
                size="small"
                title="Confirm driver check out"
                onClick={() => {
                  this.props.confirmStagedLoad(booking.id);
                }}
              />
            </div>
          </div>
        )}
        {booking && action === "Check Out" && (
          <>
            <h5>
              Make sure the following information <br /> matches the truck and
              trailer leaving <br /> the site, and then confirm check out.
            </h5>
            {vetCheckRequired && (
              <h5>
                Please contact the transport office <br /> if VET inspector is
                not present. <br />
                Ensure the load has not left until <br /> VET inspector has
                witnessed sealing <br />
                of load and vehicle departing site.
              </h5>
            )}
            <table className="booking-list-table">
              <tbody>
                <tr>
                  <td>Vehicle Reg</td>
                  <td>{booking.vehicleReg}</td>
                </tr>
                <tr>
                  <td>Trailer Number</td>
                  <td>
                    {booking.trailer ? booking.trailer.trailerNo : "No trailer"}
                  </td>
                </tr>
                <tr>
                  <td>Trailer Owner</td>
                  <td>
                    {booking.trailer
                      ? booking.trailer.haulier.name
                      : "No haulier"}
                  </td>
                </tr>
                <tr>
                  <td>Journey Ref</td>
                  <td>{booking.journeyReference}</td>
                </tr>
                <tr>
                  <td>Weighbridge</td>
                  <td>
                    {booking.weighbridgeTransactions.length === 0 &&
                      "No transactions recorded."}
                    {booking.weighbridgeTransactions.length > 0 &&
                      booking.weighbridgeTransactions.map(t => (
                        <p>
                          {formatLocalDate(t.startTime)} -{" "}
                          {t.error ? (
                            <span className="error-message">{t.error}</span>
                          ) : (
                            `${t.vehicleWeight}kg`
                          )}
                        </p>
                      ))}
                  </td>
                </tr>
                <tr>
                  <td>Is Trailer Empty</td>
                  <td>
                    {booking.direction === "inbound" ||
                    booking.requiresEmptyTrailer
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                {vetCheckRequired && (
                  <tr>
                    <td>Vet Inspection Performed</td>
                    <td>
                      <CheckBoxNoFormik
                        checked={this.state.vetCheckPerformed}
                        onChange={() => {
                          this.setState({
                            vetCheckPerformed: !this.state.vetCheckPerformed,
                            showVetError:
                              vetCheckRequired && this.state.vetCheckPerformed
                                ? true
                                : false
                          });
                        }}
                      />
                      {vetCheckRequired && this.state.showVetError && (
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          Vet Inspection is required.
                        </span>
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Seal Number</td>
                  <td>
                    {booking.appointment
                      ? booking.appointment.data["seal_no"]
                      : "Unknown"}
                  </td>
                </tr>
                {requiresImageCapture && (
                  <tr>
                    <td>Seal (Photo)</td>
                    <td style={{ position: "relative" }}>
                      <img
                        style={{
                          border: "1px solid #ccc",
                          backgroundColor: "#fff"
                        }}
                        width={320}
                        height={240}
                        ref={this.photo}
                      />
                      {!this.state.showClear && (
                        <div
                          style={{ position: "absolute", top: 120, left: 40 }}
                        >
                          Photo of seal will show here once captured
                          {isExport && this.state.showImgError && (
                            <div style={{ color: "red", fontWeight: "bold" }}>
                              Photo of seal is required.
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {requiresImageCapture && isExport && (
              <div className="camera">
                <canvas ref={this.canvas} style={{ display: "none" }}></canvas>
                <div className="button-col">
                  <video
                    style={{
                      border: "1px solid #ccc",
                      backgroundColor: "#eee",
                      margin: "5px",
                      width: "320px",
                      height: "240px"
                    }}
                    ref={this.video}
                  />
                  <div className="button-row">
                    <Button
                      variant="primary"
                      size="small"
                      title="Capture Photo"
                      onClick={() => {
                        this.setState({ showImgError: false });
                        this.takePicture();
                      }}
                    />
                    {this.state.showClear && (
                      <Button
                        variant="secondary"
                        size="small"
                        title="Clear Photo"
                        onClick={() => {
                          this.clearPhoto();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {booking.trailer && booking.trailer.isUnavailable && (
              <div style={{ marginBottom: "1rem" }}>
                <Alert
                  type="warning"
                  message="The attached trailer is VOR'd and cannot be checked out."
                />
                <h5>
                  The trailer may not be road legal and should not be
                  <br />
                  permitted to leave the site.
                  <br />
                  Contact the traffic office for further information.
                </h5>
              </div>
            )}
            <div className="button-col">
              <Button
                variant="primary"
                size="small"
                title="Confirm check out"
                onClick={() => {
                  this.checkForRequired(isExport, vetCheckRequired, booking);
                }}
                disabled={booking.trailer && booking.trailer.isUnavailable}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}
