import React from "react";
import Button from "Button";
import Modal from "Modal";

export default function ViewMessageModal({
  data,
  onResume,
  onCancel,
  onClose
}) {
  const { shortCode } = data.movement.trailer.haulier;
  const { trailerNo } = data.movement.trailer;

  return (
    <Modal>
      <div className="modal">
        <div className="modal__content">
          <div className="modal__header">
            <span className="modal__title">Incomplete Movement Actions</span>
            <i className="material-icons modal__close" onClick={onClose}>
              close
            </i>
          </div>
          <div className="modal__body">
            <div className="modal__description">
              <p>
                Select an action for trailer {shortCode} {trailerNo}
              </p>
              <p>{data.message}</p>
            </div>
            <div className="modal__buttons">
              <Button
                variant="primary"
                size="tiny"
                title="Resume Movement"
                onClick={onResume}
              />
              <Button
                variant="secondary"
                size="tiny"
                title="Cancel Movement"
                onClick={onCancel}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
