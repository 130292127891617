import React from "react";

const types = {
  loading: "Loading",
  parking: "Parking",
  numbered_parking: "Parking",
  virtual: "Virtual Parking",
};

export default function ZoneOverview(props) {
  const { type, capacity, isMaintained, site } = props.zone;
  return (
    <table>
      <tbody>
        <tr>
          <td>Site</td>
          <td>
            {site.parentSite ? site.parentSite.name + " - " : ""}
            {site.name}
          </td>
        </tr>
        <tr>
          <td>Type:</td>
          <td>{types[type] || "Unknown"}</td>
        </tr>
        <tr>
          <td>Capacity:</td>
          <td>{capacity}</td>
        </tr>
        <tr>
          <td>Occupied:</td>
          <td>---</td>
        </tr>
        <tr>
          <td>In Maintenance:</td>
          <td>{isMaintained ? "Yes" : "No"}</td>
        </tr>
      </tbody>
    </table>
  );
}
