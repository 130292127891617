import React, { useState, useEffect } from "react";
import Switch from "form-controls/Switch";
import AppTemplate from "templates/AppTemplate";
import Content from "Content";
import Loader from "Loader";
import { fetch, toArgList } from "graphql-helper";

export default function TrailerTypesAdminPage() {
  const [trailerTypes, setTrailerTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadTrailerTypes = () => {
    return fetch(`
      query {
        trailerTypes {
          id
          name
          baseType
          type
          allowCheckIn
          isRigid
          permittedTypes {
            type
          }
        }
      }
    `).then(({ trailerTypes }) => {
      setTrailerTypes(trailerTypes);
    });
  };

  const updateTrailerType = args => {
    fetch(`
      mutation {
        updateTrailerType(${toArgList(args)}) {
          id
        }
      }
    `).then(() => {
      loadTrailerTypes();
    });
  };

  const toggleFlag = (id, key) => {
    setTrailerTypes(
      trailerTypes.map(t => {
        if (t.id === id) {
          return { ...t, [key]: !t[key] };
        }

        return t;
      })
    );

    const type = trailerTypes.find(t => t.id === id);
    updateTrailerType({ id, [key]: !type[key] });
  };

  useEffect(() => {
    setIsLoading(true);
    loadTrailerTypes().then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <AppTemplate>
        <Content>
          <div className="content-block">
            <h2>Trailer Types</h2>
            <Loader />
          </div>
        </Content>
      </AppTemplate>
    );
  }

  return (
    <AppTemplate>
      <Content>
        <div className="content-block">
          <h2>Trailer Types</h2>
          {trailerTypes.length === 0 && (
            <p>
              No trailer types found. Please speak to your system administrator
            </p>
          )}
          {trailerTypes.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Base</th>
                  <th>Type</th>
                  <th>Allow Check In</th>
                  <th>Is Rigid</th>
                  <th>Permitted Types</th>
                </tr>
              </thead>
              <tbody>
                {trailerTypes.map(t => (
                  <tr key={t.id}>
                    <td>{t.name}</td>
                    <td>{t.baseType}</td>
                    <td>{t.type}</td>
                    <td>
                      <Switch
                        field={{
                          value: t.allowCheckIn,
                          onChange: () => toggleFlag(t.id, "allowCheckIn")
                        }}
                      />
                    </td>
                    <td>
                      <Switch
                        field={{
                          value: t.isRigid,
                          onChange: () => toggleFlag(t.id, "isRigid")
                        }}
                      />
                    </td>
                    <td>
                      {t.permittedTypes.map(({ type }) => type).join(", ")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Content>
    </AppTemplate>
  );
}
