import React from "react";
import classnames from "classnames";
import "./SelectBox.css";

export default function SelectBox(props) {
  const { label, placeholder, options, noDefault } = props;
  const selectClass = classnames("select-box__select", {
    "select-box__select--has-errors": props.error
  });
  const theme = props.theme || "light";
  const containerClass = classnames("select-box", `select-box--${theme}`);

  return (
    <div
      className="form-control"
      style={{ paddingBottom: props.warning ? "3rem" : "0" }}
    >
      {label}
      <div className={containerClass}>
        <select
          className={selectClass}
          {...props.field}
          onChange={event => {
            props.field.onChange(event);
            if (props.onChange) {
              props.onChange(event);
            }
          }}
          disabled={props.disabled}
        >
          {!noDefault && <option value="">{placeholder}</option>}
          {options.map(({ value, label, isDisabled }) => (
            <option
              key={value}
              value={value}
              disabled={isDisabled || isDisabled === ""}
            >
              {label}
            </option>
          ))}
        </select>
        <span className="select-box__icon material-icons">arrow_drop_down</span>
      </div>
      {props.error && <div className="form-field-error">{props.error}</div>}
      {props.warning && (
        <div className="form-field-warning" style={{ position: "absolute" }}>
          {props.warning}
        </div>
      )}
    </div>
  );
}
