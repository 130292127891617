import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Content from "Content";
import Confirm from "Confirm";
import WarehousePageTemplate from "./WarehousePageTemplate";
import RequestTrailerPanel from "./RequestTrailerPanel";
import RightPanel from "RightPanel";
import NumberedDockTable from "./warehouse-zones/NumberedDockTable";
import ParkingTrailersTable from "./warehouse-zones/ParkingTrailersTable";
import NumberedParkingTrailersTable from "./warehouse-zones/NumberedParkingTrailersTable";
import SelectSitePanel from "SelectSitePanel";
import { cancelMovement } from "./warehouseQueries";
import MoveTrailerPanel from "./MoveTrailerPanel";
import {
  zoneSubscription,
  releaseTrailer,
  getZones
} from "./warehouse-zones/warehouse-zone-queries";
import NotificationsTable from "./warehouse-zones/NotificationsTable";
import LiveDataView from "LiveDataView";
import BookingPreviewModal from "BookingPreviewModal";
import { selectSite } from "redux/app";

class WarehouseZones extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zones: [],
      zone: "",
      selectedDockId: null,
      showPanel: false,
      moveTrailer: false,
      trailerId: "",
      selectedSite: "",
      showingBookingPreviewModal: false,
      selectedBookingId: null
    };
  }

  componentDidMount() {
    let siteId = this.props.match.params.siteId;

    if (parseInt(this.props.match.params.siteId) !== this.props.selectedSite) {
      if (
        this.props.selectedSite === null &&
        siteId !== null &&
        siteId !== undefined
      ) {
        this.props.dispatch(selectSite(siteId));
      } else {
        siteId = this.props.selectedSite;
        this.updateRoute(this.props.selectedSite);
      }
    }

    if (siteId) {
      this.getZones(siteId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedSite !== this.props.selectedSite &&
      this.props.selectedSite !== null
    ) {
      let siteId = parseInt(this.props.match.params.siteId);
      if (
        this.props.match.params.siteId === null ||
        this.props.match.params.siteId === undefined ||
        prevProps.selectedSite !== this.props.selectedSite
      ) {
        siteId = this.props.selectedSite;
      }

      this.getZones(siteId);

      this.updateRoute(this.props.selectedSite);
    }
  }

  getZones(siteId) {
    getZones(siteId).then(({ zones }) => {
      this.setState({
        zones: zones,
        selectedSite: this.props.match.params.siteId
      });
    });
  }

  updateZone(zone) {
    const zones = this.state.zones;
    const zoneIndex = zones.findIndex(
      z => parseInt(z.id) === parseInt(zone.id)
    );
    zones[zoneIndex] = zone;
    this.setState({
      zones
    });
  }

  updateRoute(id) {
    if (id === null) {
      this.props.history.push({ pathname: "/warehouse/zones" });
    } else {
      this.props.history.push({
        pathname: `/warehouse/zones/site/${id}`
      });
    }
  }

  closePanel() {
    this.setState({ showPanel: false, selectedDockId: null });
  }

  openPanel(dockId, zone) {
    this.setState({
      showPanel: true,
      selectedDockId: dockId,
      moveTrailer: false,
      zone: zone
    });
  }

  cancelMovement(movementId) {
    cancelMovement(movementId);
  }

  async releaseTrailer(trailerId, returnToSender) {
    return await releaseTrailer(trailerId, returnToSender);
  }

  moveTrailer(trailerId, zone) {
    this.setState({ moveTrailer: true, showPanel: true, trailerId, zone });
  }

  pluraliseSpace(quantity) {
    if (quantity === 1) {
      return `${quantity} space`;
    }

    return `${quantity} spaces`;
  }

  calculateAvailability(zone) {
    if (zone) {
      const takenSpaces = zone.trailers.length;
      const availableSpaces = zone.capacity - takenSpaces;

      return `${this.pluraliseSpace(takenSpaces)} taken. ${this.pluraliseSpace(
        availableSpaces
      )} available.`;
    }

    return "";
  }

  zoneMovements(zoneId) {
    const zone = this.state.zones.find(z => z.id === zoneId);

    if (!zone) {
      return [];
    }

    const movements = zone.movements;
    const filteredTrailers = zone.trailers.filter(t => t.movements.length > 0);

    filteredTrailers.forEach(t =>
      t.movements.forEach(m => {
        m.trailer = t;
        movements.push(m);
      })
    );

    return movements;
  }

  showBookingPreviewModal(selectedBookingId) {
    this.setState({ showingBookingPreviewModal: true, selectedBookingId });
  }

  closeBookingPreviewModal() {
    this.setState({
      showingBookingPreviewModal: false,
      selectedBookingId: null
    });
  }

  get allowNonShunterMovement() {
    if (!this.state.zone || !this.state.selectedDockId) {
      return false;
    }

    const movements = this.zoneMovements(this.state.zone.id);
    const res = movements.find(
      m =>
        m.trailer.dock &&
        parseInt(m.trailer.dock.id) == this.state.selectedDockId
    );

    return !res;
  }

  render() {
    return (
      <WarehousePageTemplate>
        {this.state.showingBookingPreviewModal && (
          <BookingPreviewModal
            bookingId={this.state.selectedBookingId}
            onClose={this.closeBookingPreviewModal.bind(this)}
          />
        )}
        {!this.state.selectedSite && <SelectSitePanel />}
        {this.state.selectedSite && (
          <Content>
            <div className="dash-row" style={{ marginBottom: "1rem" }}>
              <div className="content-block">
                <h2>Notifications</h2>
                <NotificationsTable siteId={this.state.selectedSite} />
              </div>
            </div>
            <Confirm title="Are you sure you want to cancel the movement?">
              {confirm => {
                return this.state.zones.map(zone => {
                  if (zone.type === "loading") {
                    return (
                      <div className="dash-row" key={zone.id}>
                        <div className="content-block">
                          <h2>{zone.name}</h2>
                          {this.calculateAvailability(zone)}
                          <LiveDataView
                            subscription={zoneSubscription(zone.id)}
                            onResult={({ zone }) => {
                              this.updateZone(zone);
                            }}
                          >
                            {() => (
                              <NumberedDockTable
                                zone={zone}
                                docks={zone.docks}
                                onRequestTrailer={this.openPanel.bind(this)}
                                onMoveTrailer={this.moveTrailer.bind(this)}
                                releaseTrailer={this.releaseTrailer.bind(this)}
                                onCancelMovement={confirm(
                                  this.cancelMovement.bind(this)
                                )}
                                onSelectBooking={this.showBookingPreviewModal.bind(
                                  this
                                )}
                                movements={this.zoneMovements(zone.id)}
                                selectedDockId={this.state.selectedDockId}
                              />
                            )}
                          </LiveDataView>
                        </div>
                      </div>
                    );
                  } else if (zone.type === "numbered_parking") {
                    return (
                      <div className="dash-row" key={zone.id}>
                        <div className="content-block">
                          <h2>{zone.name}</h2>
                          {this.calculateAvailability(zone)}
                          <LiveDataView
                            subscription={zoneSubscription(zone.id)}
                            onResult={({ zone }) => {
                              this.updateZone(zone);
                            }}
                          >
                            {() => (
                              <NumberedParkingTrailersTable
                                zone={zone}
                                trailers={zone.trailers}
                                onRequestTrailer={this.openPanel.bind(this)}
                                onMoveTrailer={this.moveTrailer.bind(this)}
                                movements={this.zoneMovements(zone.id)}
                              />
                            )}
                          </LiveDataView>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className="dash-row" key={zone.id}>
                      <div className="content-block">
                        <h2>{zone.name}</h2>
                        {this.calculateAvailability(zone)}
                        <LiveDataView
                          subscription={zoneSubscription(zone.id)}
                          onResult={({ zone }) => {
                            this.updateZone(zone);
                          }}
                        >
                          {() => (
                            <ParkingTrailersTable
                              zone={zone}
                              trailers={zone.trailers}
                              onSelectBooking={this.showBookingPreviewModal.bind(
                                this
                              )}
                              movements={this.zoneMovements(zone.id)}
                            />
                          )}
                        </LiveDataView>
                      </div>
                    </div>
                  );
                });
              }}
            </Confirm>
          </Content>
        )}
        <RightPanel
          title={this.state.moveTrailer ? "Move Trailer" : "Request a trailer"}
          isExpanded={this.state.showPanel}
          theme="light"
          onClose={this.closePanel.bind(this)}
        >
          {this.state.showPanel && !this.state.moveTrailer && (
            <RequestTrailerPanel
              zone={this.state.zone}
              selectedDockId={this.state.selectedDockId}
              selectedSite={this.props.selectedSite}
              onClose={this.closePanel.bind(this)}
              allowNonShunterMovement={this.allowNonShunterMovement}
            />
          )}
          {this.state.showPanel && this.state.moveTrailer && (
            <MoveTrailerPanel
              zone={this.state.zone}
              selectedSite={this.props.selectedSite}
              trailerId={this.state.trailerId}
              onClose={this.closePanel.bind(this)}
            />
          )}
        </RightPanel>
      </WarehousePageTemplate>
    );
  }
}

function mapStateToProps({ app: { selectedDate, selectedSite }, auth }) {
  return { selectedDate, selectedSite, auth };
}

export default withRouter(connect(mapStateToProps)(WarehouseZones));
