import React from "react";
import { Provider } from "react-redux";
import Toast from "Toast";
import AppsRouter from "AppsRouter";
import { clearAuth } from "redux/auth";
import axios from "axios";
import "./Root.css";

export default class Root extends React.Component {
  constructor(props) {
    super(props);

    axios.interceptors.response.use(
      res => res,
      error => {
        if (!error.response) {
          throw error;
        }

        if (
          error.response.status === 401 &&
          window.location.pathname === "/auth/login"
        ) {
          props.store.dispatch(clearAuth());
          throw error;
        }

        if (error.response.status === 403 || error.response.status === 401) {
          props.store.dispatch(clearAuth());
          window.location.href = "/auth/login";
        }

        throw error;
      }
    );
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <AppsRouter storeAppData={this.props.storeAppData} />
        <Toast />
      </Provider>
    );
  }
}
