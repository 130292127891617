import React from "react";
import AppTemplate from "templates/AppTemplate";
import config from "../../../config";

export default function WarehousePageTemplate(props) {
  let innerNavItems;
  if (config.FEATURE_LITE_VERSION) {
    innerNavItems = [
      {
        title: "Zones",
        href: "/warehouse/zones",
        permission: "warehouse.zones.view"
      }
    ];
  } else {
    innerNavItems = [
      {
        title: "Dashboard",
        href: "/warehouse",
        permission: "warehouse.dashboard.view"
      },
      {
        title: "Bookings",
        href: "/warehouse/bookings",
        permission: "warehouse.bookings.view"
      },
      {
        title: "Delivery Failures/Refusals",
        href: "/warehouse/rebookings",
        permission: "warehouse.rebookings.view"
      },
      {
        title: "Zones",
        href: "/warehouse/zones",
        permission: "warehouse.zones.view"
      }
    ];
  }

  return (
    <AppTemplate filters={props.filters} innerNavItems={innerNavItems}>
      {props.children}
    </AppTemplate>
  );
}
