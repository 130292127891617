import { distanceInWords } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";

export function timeOnDock(trailer) {
  const format = "YYYY-MM-DDTHH:mm:ssZ";
  if (trailer.movedAt) {
    return distanceInWords(
      trailer.movedAt,
      formatToTimeZone(Date.now(), format, {
        timeZone: "Etc/UTC"
      })
    );
  } else if (trailer.insertedAt) {
    return distanceInWords(
      trailer.insertedAt,
      formatToTimeZone(Date.now(), format, {
        timeZone: "Etc/UTC"
      })
    );
  }

  return "";
}
