import React from "react";
import classnames from "classnames";
import "./AdminItem.css";

export default function AdminItem(props) {
  const className = classnames("admin-item", {
    "admin-item--selected": props.selected
  });

  return (
    <div
      className={className}
      onClick={() => {
        props.showItemDetails(props.id);
      }}
    >
      <div className="admin-item-text-row">
        <div className="admin-item-title">{props.title}</div>
        <div className="admin-item-right-text">{props.rightTextTitle}</div>
      </div>
      <div className="admin-item-text-row">
        <div className="admin-item-text">{props.text}</div>
        <div className="admin-item-right-text">{props.rightTextContent}</div>
      </div>
      {props.icon && <div className="admin-item-icon">{props.icon}</div>}
    </div>
  );
}
