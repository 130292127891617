import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { distanceInWordsStrict } from "date-fns";
import RoundButton from "RoundButton";
import AdminItem from "AdminItem";
import AppNav from "AppNav";
import Can from "Can";
import "./AdminPanel.css";
// TODO: These shouldn't be imported here!!
import "./Sidebar.css";
import "settings/pages/user-admin-page/UserRolesForm.css";

class AdminPanel extends React.Component {
  render() {
    const showFooterText =
      this.props.footerText && this.props.footerText !== "";

    return (
      <div className="admin-panel">
        <AppNav />
        <div className="admin-panel-sidebar">
          <div className="admin-sidebar-title">
            {this.props.title}
            {this.props.addNew && (
              <Can when={this.props.addPermission}>
                <RoundButton
                  variant="absolute"
                  onClick={() => {
                    this.props.addNew();
                  }}
                />
              </Can>
            )}
          </div>
          <div className="admin-panel__items">
            {this.props.items.map((item, idx) => (
              <AdminItem
                key={idx}
                id={item.id}
                title={item.name}
                text={
                  item.text ||
                  (item.roles
                    ? item.roles[0]
                    : item.parentSite
                    ? item.parentSite.name
                    : "")
                }
                rightTextTitle={item.lastSeen ? "Last seen:" : ""}
                rightTextContent={
                  item.lastSeen
                    ? distanceInWordsStrict(new Date(), item.lastSeen, {
                        addSuffix: true
                      })
                    : ""
                }
                selected={this.props.selectedId === item.id}
                showItemDetails={() => {
                  this.props.showItem(item.id);
                }}
              />
            ))}
          </div>
          {showFooterText && (
            <div
              className="admin-sidebar-footer"
              onClick={() => this.props.footerLink()}
            >
              {this.props.footerText}
            </div>
          )}
        </div>

        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps({
  app: { selectedDate, showAppSwitcher, showFilters },
  sites
}) {
  return { selectedDate, sites, showAppSwitcher, showFilters };
}

export default withRouter(connect(mapStateToProps)(AdminPanel));
