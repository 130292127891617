export function sortDropdownOptions(options, promotedOptions) {
  return options
    .map(option => {
      const promoted = promotedOptions.includes(option.label);
      const rank = promoted
        ? promotedOptions.indexOf(option.label)
        : options.length;

      return { ...option, rank };
    })
    .sort((a, b) => a.rank - b.rank);
}
