import React from "react";
import SplashPage from "./SplashPage";
import "./RegisterPage.css";

export default function InvalidTokenPage() {
  return (
    <SplashPage>
      <p className="splash-page__heading">Expired</p>
      <p className="thin">Sorry, this invite has expired</p>
      <p className="small-type center">
        Invite links are only valid for 24 hours.
      </p>
      <p className="small-type center">
        Please contact your site administrator to request a new invitation.
      </p>
    </SplashPage>
  );
}
