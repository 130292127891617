import React from "react";

export default class VerticalBar extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.canvasRef = React.createRef();
    this.toolRef = React.createRef();
    this.state = {
      barStatus: []
    };
  }

  componentDidMount() {
    this.createGraph();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.createGraph();
    }
  }

  createGraph() {
    const data = this.props.data;
    const canvas = this.canvasRef.current;
    const containerDimensions = this.containerRef.current.getBoundingClientRect();
    const context = canvas.getContext("2d");
    const width = containerDimensions.width;
    const height = 300;
    canvas.width = width;
    canvas.height = height;
    const barCount = data[0].length;
    const tools = this.toolRef.current;
    const tc = tools.getContext("2d");
    tools.width = width;
    tools.height = height;
    const xLabelMargin = 30;
    const yLabelMargin = 20;
    const maxY =
      data[0].reduce((acc, datum) => Math.max(acc, datum.value), 0) +
      Math.floor(
        0.2 * data[0].reduce((acc, datum) => Math.max(acc, datum.value), 0)
      );
    const barWidth = (canvas.width - yLabelMargin) / (barCount + 1);
    const rowCount = 6;
    const rowWidth =
      Math.floor(maxY / rowCount) * ((canvas.height - xLabelMargin) / maxY);
    const margin = 3;
    const gridHeight = (canvas.height - xLabelMargin) / maxY;

    if (barCount === 0) {
      context.fillStyle = "black";
      context.font = "30px Arial";
      context.fillText("No Data", width * 0.4, height * 0.4);
    } else {
      const max = this.buildBars(data[0], {
        yLabelMargin,
        xLabelMargin,
        barWidth,
        margin,
        gridHeight,
        height
      });
      let actual = [];
      let series = 1;
      if (data[1]) {
        series = 2;
        actual = this.buildBars(data[1], {
          yLabelMargin,
          xLabelMargin,
          barWidth,
          margin,
          gridHeight,
          height
        });
      }
      this.setState({
        barStatus: [...Array(data.length * series).keys()].fill(0)
      });

      this.buildGrid(context, {
        xLabelMargin,
        yLabelMargin,
        width,
        height,
        rowWidth,
        rowCount,
        barCount,
        barWidth
      });

      this.addYlabel(context, maxY, yLabelMargin, height, xLabelMargin);

      const labels = [];

      max.forEach((bar, index) => {
        const labelOffset = max.length > 5 ? 0.85 : 0.5;
        const label = max.length > 5 ? bar.label.substr(0, 3) : bar.label;

        context.fillStyle = data[1] ? `rgba(0, 145, 147, 0.5)` : bar.color;
        context.fillRect(bar.x, bar.y, bar.w, -1 * bar.h);
        context.fillStyle = "black";
        context.font = "10px Arial";
        context.fillText(label, (index + labelOffset) * barWidth, height - 17);
        labels.push({
          label: bar.label,
          x: (index + labelOffset) * barWidth,
          y: height - 15
        });
      });
      this.setState({ labels: labels });
      if (data[1]) {
        actual.forEach((bar, index) => {
          const capacity = bar.h / max[index].h;
          let barColor = "rgba(143, 201, 58, 1)";
          if (capacity > 0.6 && capacity < 0.75) {
            barColor = "rgba(254, 153, 32, 1)";
          } else if (capacity > 0.74 && capacity < 0.9) {
            barColor = "rgba(254, 153, 32, 1)";
          } else if (capacity > 0.89) {
            barColor = "rgba(209, 73, 91, 1)";
          }

          if (bar.h < max[index].h) {
            context.fillStyle = barColor;
            context.fillRect(bar.x, bar.y, bar.w, -1 * bar.h);
          } else {
            context.fillStyle = barColor;
            context.fillRect(
              max[index].x,
              bar.y - max[index].h,
              bar.w,
              -1 * (bar.h - max[index].h)
            );
            context.strokeStyle = barColor;
            context.beginPath();
            context.rect(bar.x, bar.y, bar.w, -1 * bar.h);
            context.stroke();
            context.closePath();
            this.addHatch(context, bar, max[index]);
          }
        });
      }

      tools.onmousemove = e => {
        let merged = [];
        if (data[1]) {
          for (let i = 0; i < max.length; i++) {
            merged.push(max[i]);
            merged.push(actual[i]);
          }
        } else {
          merged = max;
        }

        const ob = [...Array(merged.length).keys()].fill(0);
        const r = tools.getBoundingClientRect();
        const x = e.clientX - r.left;
        const y = e.clientY - r.top;

        for (let i = merged.length - 1, b; (b = merged[i]); i--) {
          if (this.overBar(x, y, b)) {
            ob[i] = 1;
            this.showLabel(
              context,
              this.state.labels.find(
                label => label.x > b.x && label.x < b.x + barWidth
              ),
              width,
              height
            );
            break;
          } else {
            this.hideLabel(context, width, height);
            ob[i] = 0;
          }
        }

        this.updateToolTip(tc, ob, merged, width, height, series);
      };
    }
  }

  addYlabel(context, maxY, yLabelMargin, height, xLabelMargin) {
    for (let i = 1; i < maxY; i++) {
      if (i % 5 === 0) {
        context.fillStyle = "black";
        context.font = "10px Arial";
        context.fillText(
          i,
          yLabelMargin * 0.2,
          height - xLabelMargin - i * ((height - xLabelMargin) / maxY) + 4
        );
      }
    }
  }

  addHatch(context, bar, max) {
    const lines = (max.h - 10) / 10;
    for (let i = 0; i < lines; i++) {
      context.beginPath();
      context.moveTo(bar.x, bar.y - i * 10);
      context.lineTo(bar.x + bar.w, bar.y - i * 10 - 10);
      context.stroke();
      context.closePath();
    }
  }

  buildBars(data, values) {
    return data.map((d, index) => {
      return {
        x: index * values.barWidth + values.margin + values.yLabelMargin,
        y: values.height - values.xLabelMargin,
        w: values.barWidth - 2 * values.margin,
        h: values.gridHeight * d.value,
        label: d.label,
        color: d.color,
        value: d.value
      };
    });
  }

  buildGrid(context, values) {
    context.strokeStyle = "black";
    context.beginPath();
    context.moveTo(values.yLabelMargin, 0);
    context.lineTo(
      values.yLabelMargin,
      values.height - values.xLabelMargin + 0.5
    );
    context.lineTo(values.width, values.height - values.xLabelMargin + 0.5);
    context.stroke();
    context.closePath();

    for (let i = 1; i <= values.barCount; i++) {
      context.beginPath();
      context.strokeStyle = "#ccc";
      context.moveTo(values.yLabelMargin + i * values.barWidth, 0);
      context.lineTo(
        values.yLabelMargin + i * values.barWidth + 0.5,
        values.height - values.xLabelMargin
      );
      context.stroke();
      context.closePath();
    }

    for (let i = 1; i < values.rowCount; i++) {
      context.beginPath();
      context.strokeStyle = "#ccc";
      context.moveTo(
        values.yLabelMargin,
        ((values.height - values.xLabelMargin) / values.rowCount) * i
      );
      context.lineTo(
        values.width,
        ((values.height - values.xLabelMargin) / values.rowCount) * i
      );
      context.stroke();
      context.closePath();
    }
  }

  buildToolTip(context, x, y, w, h, r, color) {
    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    context.fillStyle = color;
    context.beginPath();
    context.moveTo(x + r, y);
    context.arcTo(x + w, y, x + w, y + h, r);
    context.arcTo(x + w, y + h, x, y + h, r);
    context.arcTo(x, y + h, x, y, r);
    context.arcTo(x, y, x + w, y, r);
    context.fill();
    context.closePath();
    return context;
  }

  hideLabel(context, width, height) {
    context.fillStyle = "white";
    context.fillRect(0, height - 15, width, 20);
  }

  overBar(x, y, b) {
    return x >= b.x && x <= b.x + b.w && y >= b.y - b.h && y <= b.y;
  }

  showLabel(context, label, width, height) {
    this.hideLabel(context, width, height);
    context.fillStyle = "black";
    context.font = "bold 10px Arial";
    const x = width - label.x < 100 ? width - 100 : label.x;
    context.fillText(label.label, x, label.y + 12);
  }

  updateToolTip(context, ob, bars, width, height, seriesCount) {
    const currentOb = this.state.barStatus.findIndex(k => k === 1);
    const newOb = ob.findIndex(k => k === 1);
    if (newOb === -1 && currentOb !== newOb) {
      context.clearRect(0, 0, width, height);
      this.setState({ barStatus: ob });
    } else if (currentOb !== newOb) {
      let text = "";
      let color = "#eee";
      let fill = "black";
      if (newOb % 2 && seriesCount == 2) {
        text = `${bars[newOb].value} / ${bars[newOb - 1].value} Max`;
        if (bars[newOb - 1].value < bars[newOb].value) {
          color = "rgba(255, 73, 91, 1)";
          fill = "white";
        }
      } else {
        text = `Max: ${bars[newOb].value}`;
      }

      const backgroundOffset = newOb % 2 && seriesCount == 2 ? 15 : 5;
      context.clearRect(0, 0, width, height);
      this.buildToolTip(
        context,
        bars[newOb].x - backgroundOffset,
        bars[newOb].y - bars[newOb].h - 30,
        newOb % 2 && seriesCount == 2 ? 75 : 55,
        25,
        25,
        color
      );
      const textOffset = newOb % 2 && seriesCount == 2 ? -5 : 5;
      context.fillStyle = fill;
      context.font = "10px Arial";
      context.fillText(
        text,
        bars[newOb].x + textOffset,
        bars[newOb].y - bars[newOb].h - 14
      );
      this.setState({ barStatus: ob });
    }
  }

  render() {
    const height = { height: "300px" };
    return (
      <div className="barGraph" style={height} ref={this.containerRef}>
        <canvas className="bars" ref={this.canvasRef} />
        <canvas className="tools" ref={this.toolRef} />
      </div>
    );
  }
}
