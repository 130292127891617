import React from "react";
import FlightboardIcon from "assets/flightboard-icon-dark.png";
import { getLanguageFlagCode, getBookingDisplayStatus } from "languages";
export default function FlightboardTable(props) {
  return (
    <>
      <div className="flightboard-top-bar"></div>
      <table className="flightboard-table">
        <thead>
          <tr>
            <th>
              <img
                src={FlightboardIcon}
                className="flightboard-icon"
                alt="Mercurius flightboard app icon"
              />
            </th>
            <th>Vehicle Reg</th>
            <th>Driver Name</th>
            <th>Location</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {props.bookings.map(
            ({
              id,
              vehicleReg,
              driverName,
              driverLanguage,
              trailer,
              status
            }) => (
              <tr key={id}>
                <td>
                  <div className="flightboard-flag">
                    {driverLanguage && (
                      <img
                        src={`assets/flags-iso/${getLanguageFlagCode(
                          driverLanguage
                        )}.png`}
                      ></img>
                    )}
                  </div>
                </td>
                <td>{vehicleReg}</td>
                <td>{driverName}</td>
                <td>
                  {trailer && trailer.zone && trailer.zone.name}
                  {trailer && trailer.dock && ` - Dock ${trailer.dock.number}`}
                </td>
                <td className={`flightboard-status__${status}`}>
                  {getBookingDisplayStatus(driverLanguage, status)}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </>
  );
}
