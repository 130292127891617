import React from "react";
import { Formik, Field, Form } from "formik";
import Button from "Button";
import { generateDeviceRegistrationLink, registerDevice } from "api/devices";
import TextInput from "form-controls/TextInput";

export default function RegisterDeviceForm(props) {
  return (
    <Formik
      initialValues={{ deviceId: "" }}
      onSubmit={({ deviceId }) => {
        generateDeviceRegistrationLink()
          .then(({ token }) => {
            return registerDevice(deviceId, token);
          })
          .then(() => {
            props.onRegister(deviceId);
          });
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <Field name="deviceId" label="Device ID" component={TextInput} />
          <div className="button-col">
            <Button
              type="submit"
              variant="primary"
              size="small"
              title="Register Device"
              disabled={
                Object.keys(touched).length < 1 || Object.keys(errors).length
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
