import React from "react";
import { Formik, Field, Form } from "formik";
import { dockStatus, zonesBySite } from "./move-trailer-queries";
import { formatAsOptions } from "siteList";
import SelectBox from "form-controls/SelectBox";
import TextInput from "form-controls/TextInput";
import CheckBox from "form-controls/CheckBox";
import Button from "Button";
import config from "../../../../config";
import "../../Warehouse.css";

export default class RequestMovementForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sites: props.sites,
      zones: [],
      docks: []
    };
  }

  siteChange(siteId) {
    zonesBySite(siteId).then(data => {
      this.setState({
        docks: [],
        zones: data.zones
      });
    });
  }

  get zoneOptions() {
    const opts = this.state.zones.map(z => ({
      value: z.id,
      label:
        z.trailers.length >= z.capacity || !z.isActive
          ? `${z.name} (Full)`
          : z.name,
      isDisabled: z.trailers.length >= z.capacity || !z.isActive ? true : false
    }));

    return opts;
  }

  get siteOptions() {
    return formatAsOptions(this.state.sites);
  }

  zoneChange(zoneId) {
    dockStatus(zoneId).then(data => {
      const dockOptions = data.dockList.docks.map(d => {
        const occupied =
          data.trailers.find(t => (t.dock ? t.dock.id === d.id : false)) ||
          false;
        const pending =
          data.movements.find(m => m.location.dock.id === d.id) || false;

        let label = d.number;

        let isMovingSoon = false;
        if (occupied) {
          if (
            config.ALLOW_TRAILERS_EARLY_QUEUING &&
            occupied.movements.find(m =>
              ["pending", "assigned", "picked_up"].includes(m.status)
            )
          ) {
            isMovingSoon = true;
            label = `${d.number} - ${occupied.trailerNo} (moving soon)`;
          } else {
            label = `${d.number} - ${occupied.trailerNo} (on dock)`;
          }
        } else if (pending) {
          label = `${d.number} - ${pending.trailer.trailerNo} (pending) `;
        }

        return {
          value: d.id,
          label: label,
          isDisabled: !d.isActive || (occupied && !isMovingSoon) || pending
        };
      });

      this.setState({
        docks: dockOptions
      });
    });
  }

  zoneById(zoneId) {
    return this.state.zones.find(z => z.id === zoneId);
  }

  isParkingZone(zoneId) {
    const zone = this.zoneById(zoneId);
    if (typeof zone === "undefined") {
      return false;
    }

    return ["parking", "virtual"].includes(zone.type);
  }

  canSubmit({ dockId, zoneId, isEmpty }) {
    return (
      (dockId || this.isParkingZone(zoneId)) &&
      ((isEmpty !== undefined && isEmpty !== "") ||
        !this.props.trailer.booking ||
        this.props.trailer.booking.status === "staged" ||
        this.props.trailer.booking.direction !== "inbound")
    );
  }

  render() {
    const theme = this.props.theme || "light";

    const isEmptyOptions = [
      { value: true, label: "Yes" },
      { value: false, label: "No" }
    ];

    const trailerCanBeEmptied =
      this.props.trailer.booking &&
      this.props.trailer.booking.status == "inbound_in_progress";

    const numberOfPallets =
      this.props.trailer.booking && this.props.trailer.booking.numberOfPallets;

    return (
      <Formik
        onSubmit={this.props.onSubmit}
        initialValues={{ numberOfPallets }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => (
          <Form>
            <Field
              name="hasIssuesWithTrailer"
              label="Issue with trailer"
              component={CheckBox}
            />
            <Field
              name="trailerIssueNotes"
              label="Describe the issue (optional)"
              component={TextInput}
              disabled={!values.hasIssuesWithTrailer}
            />
            {trailerCanBeEmptied && (
              <>
                <label>Trailer info:</label>
                <Field
                  name="isEmpty"
                  label="Has trailer been completely emptied?"
                  options={isEmptyOptions}
                  placeholder="Select"
                  component={SelectBox}
                  error={touched.isEmpty && errors.isEmpty}
                  theme={theme}
                />
                <div className="form-hint">
                  * Selecting <b>"yes"</b> will <b>remove Journey Reference</b>
                  <br />
                  from the booking and set its <b>status to "complete"</b>
                </div>
                {values.isEmpty === "false" && (
                  <>
                    <label>Pallets remaining</label>
                    <Field
                      name="numberOfPallets"
                      label="Number of Pallets"
                      component={TextInput}
                    />
                  </>
                )}
              </>
            )}
            <label>Destination:</label>
            <Field
              name="siteId"
              label="Site"
              options={this.siteOptions}
              placeholder="Select Site"
              component={SelectBox}
              error={touched.siteId && errors.siteId}
              onChange={e => {
                setFieldValue("zoneId", "");
                setFieldValue("dockId", "");
                this.siteChange(e.target.value);
              }}
              theme={theme}
            />
            <Field
              name="zoneId"
              label="Zone"
              options={this.zoneOptions}
              placeholder="Select Zone"
              component={SelectBox}
              disabled={!values.siteId}
              error={touched.zoneId && errors.zoneId}
              onChange={e => {
                setFieldValue("dockId", "");
                this.zoneChange(e.target.value);
              }}
              theme={theme}
            />
            <Field
              name="dockId"
              label="Dock"
              options={this.state.docks}
              placeholder="Select Dock"
              component={SelectBox}
              disabled={!values.zoneId || this.isParkingZone(values.zoneId)}
              error={touched.dockId && errors.dockId}
              theme={theme}
            />
            <div className="button-col">
              <Button
                type="submit"
                variant="primary"
                size="small"
                title="Request movement"
                disabled={
                  isSubmitting ||
                  !this.canSubmit(values) ||
                  Object.keys(errors).length
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
