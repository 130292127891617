import React from "react";
import AppTemplate from "templates/AppTemplate";
import LandingPanel from "LandingPanel";

export default function NotFoundPage() {
  return (
    <AppTemplate>
      <LandingPanel
        title="404 Not Found"
        text="Whoops! We can't find what you are looking for."
      />
    </AppTemplate>
  );
}
