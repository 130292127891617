import React from "react";
import ConfirmationModal from "ConfirmationModal";

export default class Confirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      callback: null
    };
  }

  show = callback => event => {
    this.setState({
      isOpen: true,
      callback: () => callback(event)
    });
  };

  handleConfirm() {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    } else {
      this.state.callback();
    }

    this.handleClose();
  }

  handleClose() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }

    this.setState({
      isOpen: false,
      callback: null
    });
  }

  render() {
    return (
      <>
        {this.props.children && this.props.children(this.show)}
        {(this.props.isOpen || this.state.isOpen) && (
          <ConfirmationModal
            title={this.props.title}
            onConfirm={this.handleConfirm.bind(this)}
            onCancel={this.handleClose.bind(this)}
          />
        )}
      </>
    );
  }
}
