import React from "react";
import { addMonths } from "date-fns";
import PropTypes from "prop-types";
import "./MonthPicker.css";

function generateListOfMonths(selectedDate) {
  let currentMonth = addMonths(selectedDate, -6);
  const lastMonth = addMonths(selectedDate, 6);

  const months = [];

  while (currentMonth < lastMonth) {
    months.push({
      date: currentMonth,
      title: currentMonth.toLocaleString("en-gb", {
        month: "long",
        year: "numeric"
      })
    });
    currentMonth = addMonths(currentMonth, 1);
  }

  return months;
}

export default function MonthPicker({ selectedDate, onSelectMonth }) {
  const selectedOption = selectedDate.toLocaleString("en-gb", {
    month: "long",
    year: "numeric"
  });

  const months = generateListOfMonths(selectedDate);

  const handleSelectMonth = ({ target }) => {
    const month = months.find(m => m.title === target.value);

    onSelectMonth(month.date);
  };

  const monthName = selectedDate.toLocaleString("en-gb", {
    month: "long"
  });

  const year = selectedDate.getFullYear();

  return (
    <div className="month-picker">
      <select
        className="month-picker__hidden-select"
        value={selectedOption}
        onChange={handleSelectMonth}
      >
        {months.map(month => (
          <option key={month.title} value={month.title}>
            {month.title}
          </option>
        ))}
      </select>
      <span className="month-picker__month">{monthName}</span>
      <span className="month-picker__year">{year}</span>
      <i className="material-icons">unfold_more</i>
    </div>
  );
}

MonthPicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired
};
