import React from "react";
import { Formik, Field, Form } from "formik";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as Yup from "yup";
import { attemptLogin } from "api/auth";
import { updateAuth } from "redux/auth";
import Alert from "Alert";
import Button from "Button";
import Loader from "Loader";
import TextInput from "form-controls/TextInput";
import PasswordInput from "form-controls/PasswordInput";
import "./LoginForm.css";

const registerSchema = Yup.object().shape({
  email: Yup.string()
    .max(100, "")
    .required("Please enter your email"),
  password: Yup.string().required("Please enter your password")
});

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { submitError: false, errorMessage: "" };
  }

  render() {
    const initialValues = {
      email: "",
      password: ""
    };

    const defaultPathFromPermission = p => `/${p.replace(".access", "")}`;

    return (
      <Formik
        validationSchema={registerSchema}
        onSubmit={(values, actions) => {
          values["email"] = values["email"].toLowerCase();
          attemptLogin(values)
            .then(res => {
              const defaultPath = defaultPathFromPermission(
                res.data.permissions.filter(p => p.endsWith(".access"))[0]
              );

              let pushTo =
                this.props.location.state !== undefined &&
                this.props.location.state.from.pathname !== "/"
                  ? this.props.location.state.from.pathname +
                    this.props.location.state.from.search
                  : defaultPath;

              if (res.data.password_expired) {
                pushTo = `/auth/update-password?reason=expired&redirect=${pushTo}`;
              }

              this.props.dispatch(
                updateAuth(
                  res.data.token,
                  true,
                  res.data.permissions,
                  res.data.allowedSites,
                  pushTo
                )
              );
            })
            .catch(errorMessage => {
              actions.setSubmitting(false);
              this.setState({ submitError: true, errorMessage });
            });
        }}
        initialValues={initialValues}
      >
        {({ errors, touched, isSubmitting }) => (
          <div className="login-form">
            {this.state.submitError && (
              <Alert
                type="error"
                message={this.state.errorMessage}
                onDismiss={() =>
                  this.setState({ submitError: false, errorMessage: "" })
                }
              />
            )}
            {isSubmitting && <Loader />}
            <Form>
              <Field
                name="email"
                label="Email"
                component={TextInput}
                error={touched.email && errors.email}
              />
              <Field
                name="password"
                label="Password"
                component={PasswordInput}
                error={touched.password && errors.password}
              />
              <div className="login-form__buttons center">
                <Button
                  type="submit"
                  variant="primary"
                  title="Login"
                  disabled={
                    Object.keys(touched).length < 1 ||
                    Object.keys(errors).length
                  }
                />
                <Button
                  type="submit"
                  variant="white"
                  size="small"
                  title="Forgot password"
                  onClick={() => this.props.history.push("/auth/login/forgot")}
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    );
  }
}

export default connect()(withRouter(LoginForm));
