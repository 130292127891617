import React from "react";
import SplashPage from "./SplashPage";
import ForgotPasswordForm from "./forgot-password-page/ForgotPasswordForm";

export default function ForgotPasswordPage() {
  return (
    <SplashPage>
      <p className="splash-page__heading">Forgot</p>
      <p className="thin">Oh dear, don't worry</p>
      <p className="small-type center">
        Enter your account email and we'll send you a password link to get you
        logged in again.
      </p>
      <ForgotPasswordForm />
    </SplashPage>
  );
}
