import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getSite, updateSiteHauliers } from "./site-admin-queries";
import SiteHaulierTable from "./SiteHaulierTable";
import Loader from "Loader";
import { withErrorHandler } from "graphql-helper";
import "./SiteAdminForm.css";
import "./SiteEditForm.css";

class SiteHaulierForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: [],
      loading: true,
      hauliers: []
    };
  }

  componentDidMount() {
    getSite(this.props.selectedSite.id).then(data => {
      this.setState({
        site: data.site,
        hauliers: data.hauliers,
        loading: false
      });
    });
  }

  updateHauliers(siteHauliers) {
    this.props.handleErrors(
      updateSiteHauliers(parseInt(this.state.site.id), siteHauliers).then(
        data => {
          this.setState({ site: data.site });
        }
      ),
      "The hauliers have been added."
    );
  }

  render() {
    if (this.state.siteLoading || this.state.siteListLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    return (
      <div className="admin-main">
        {!this.state.loading && (
          <SiteHaulierTable
            hauliers={this.state.hauliers}
            siteHauliers={this.state.site.hauliers}
            onSubmit={this.updateHauliers.bind(this)}
            returnHome={() => this.props.returnHome()}
          />
        )}
      </div>
    );
  }
}

export default withErrorHandler(connect()(withRouter(SiteHaulierForm)));
