import React from "react";

export default function HaulierOverview(props) {
  const {
    name,
    shortCode,
    standLevel,
    trailers,
    manuallyCreated
  } = props.haulier;

  const receipts = props.receipts;

  return (
    <table>
      <tbody>
        <tr>
          <td>Name</td>
          <td>{name}</td>
        </tr>
        <tr>
          <td>Short Code:</td>
          <td>{shortCode}</td>
        </tr>
        <tr>
          <td>Stand Level:</td>
          <td>{standLevel}</td>
        </tr>
        <tr>
          <td>Current Trailer Level:</td>
          <td>{trailers.length}</td>
        </tr>
        <tr>
          <td>Manually Created:</td>
          <td>{manuallyCreated ? "Yes" : "No"}</td>
        </tr>
        {receipts.map((receipt, index) => {
          return (
            <tr key={receipt.id}>
              <td>{index == 0 ? "Email:" : null}</td>
              <td>{receipt.email}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
