import axios from "axios";

const rootURL = "/api/user_sites";

export function updateUserSites(id, allowedSites) {
  return axios
    .put(`${rootURL}/${id}`, {
      allowedSites: allowedSites
    })
    .then(result => {
      return result.data;
    });
}

export function getUserSites(id) {
  return axios.get(`${rootURL}/${id}`).then(result => {
    return result.data;
  });
}
