import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  const routeRender = props => {
    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: "/auth/login",
            state: { from: props.location }
          }}
        />
      );
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={routeRender} />;
}

function mapStateToProps({ auth: { isAuthenticated } }) {
  return {
    isAuthenticated
  };
}

export default connect(mapStateToProps)(PrivateRoute);
