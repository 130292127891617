import React, { useState } from "react";
import YesNoCancelModal from "YesNoCancelModal";

export default function YesNoCancel({ children, ...rest }) {
  const [isOpen, setIsOpen] = useState(false);
  const [yesCallback, setYesCallback] = useState(null);
  const [noCallback, setNoCallback] = useState(null);

  function handleClose() {
    setIsOpen(false);
    if (rest.onClose) {
      rest.onClose();
    }
    setYesCallback(null);
    setNoCallback(null);
  }

  function handleYes() {
    if (rest.onYes) {
      rest.onYes();
    } else {
      yesCallback();
    }
    handleClose();
  }

  function handleNo() {
    if (rest.onNo) {
      rest.onNo();
    } else {
      noCallback();
    }
    handleClose();
  }

  const show = (onYes, onNo) => event => {
    setIsOpen(true);
    setYesCallback(() => () => onYes(event));
    setNoCallback(() => () => onNo(event));
  };

  return (
    <>
      {children && children(show)}
      {(isOpen || rest.isOpen) && (
        <YesNoCancelModal
          {...rest}
          onYes={handleYes}
          onNo={handleNo}
          onCancel={handleClose}
        />
      )}
    </>
  );
}
