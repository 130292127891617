import React from "react";
import { connect } from "react-redux";
import { fetch } from "graphql-helper";
import { addMessage } from "redux/app";
import { withRouter } from "react-router";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { createInvite } from "api/invites";
import { getSites } from "queries/sites";
import { formatAsOptions } from "siteList";
import Loader from "Loader";
import MultiSelect from "form-controls/MultiSelect";
import SelectBox from "form-controls/SelectBox";
import TextInput from "form-controls/TextInput";
import Button from "Button";
import Can from "Can";
import "./UserRolesForm.css";
import config from "../../../../config";

const inviteValidationSchema = Yup.object().shape({
  email: Yup.string().required("Please enter a valid email address"),
  roles: Yup.string().required("Please choose at least one role"),
  sites: Yup.string().required("Please choose at least one site")
});

const haulierQuery = `
query {
  hauliers {
    id
    name
  }
}
`;

class InviteUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      hauliers: [],
      loading: true
    };
  }

  componentDidMount() {
    getSites().then(({ sites }) => {
      this.setState({ sites, loading: false });
    });

    fetch(haulierQuery).then(({ hauliers }) => {
      this.setState({ hauliers });
    });
  }

  handleSubmit(values) {
    const invite = {
      email: values.email.toLowerCase(),
      roles: values.roles.map(({ value }) => value),
      sites: values.sites.map(({ value }) => value),
      haulierId: values.haulierId
    };
    createInvite(invite).then(
      res => {
        this.props.showInvites(res);
      },
      error => {
        this.props.dispatch(
          addMessage("error", "Email " + error.response.data.errors.email)
        );
      }
    );
  }

  get siteList() {
    return formatAsOptions(this.state.sites);
  }

  get haulierList() {
    return this.state.hauliers.map(({ id, name }) => ({
      label: name,
      value: id
    }));
  }

  get initialValues() {
    return {
      email: "",
      roles: [],
      sites: [],
      haulierId: ""
    };
  }

  // This exists becaues Yup isn't playing nicely
  isHaulierIdValid(selectedRoles, haulierId) {
    if (!selectedRoles) return false;
    if (
      selectedRoles.length === 1 &&
      selectedRoles.find(role => role.value === "Haulier") &&
      !haulierId
    ) {
      return false;
    }
    return true;
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    let roleOptions = [
      { value: "Security", label: "Security" },
      { value: "Hamshall_security", label: "Hamshall Security" },
      { value: "Shunter", label: "Shunter" },
      { value: "Haulier", label: "Haulier" },
      { value: "Traffic_office", label: "Traffic office" },
      { value: "Warehouse_manager", label: "Warehouse Manager" },
      { value: "Warehouse_operator", label: "Warehouse Operator" },
      { value: "Logistics_management", label: "Logistics Management" },
      { value: "Workshop", label: "Workshop" },
      { value: "Flightboard", label: "Flightboard" },
      { value: "Admin", label: "Admin" }
    ];

    if (config.REACT_APP_CLIENT !== "york") {
      roleOptions = [
        { value: "Digital_enabler", label: "Digital Enabler" }
      ].concat(roleOptions);
    }

    return (
      <div>
        <div>
          <Formik
            validationSchema={inviteValidationSchema}
            initialValues={this.initialValues}
            enableReinitialize
            onSubmit={this.handleSubmit.bind(this)}
          >
            {({ setFieldValue, errors, touched, values }) => (
              <Form className="admin-form">
                <div>
                  <Field
                    name="email"
                    label="Email"
                    component={TextInput}
                    error={touched.email && errors.email}
                  />
                  <Field
                    name="roles"
                    label="Roles"
                    component={MultiSelect}
                    options={roleOptions}
                    selected={[]}
                    error={errors.roles}
                    onBlur={selected => {
                      setFieldValue("roles", selected);
                    }}
                  />
                  <Field
                    name="sites"
                    label="Sites"
                    component={MultiSelect}
                    options={this.siteList}
                    selected={[]}
                    onBlur={selected => {
                      setFieldValue("sites", selected);
                    }}
                    error={errors.sites}
                  />
                  {values.roles.find(role => role.value === "Haulier") && (
                    <>
                      <Field
                        name="haulierId"
                        label="Haulier"
                        component={SelectBox}
                        options={this.haulierList}
                        error={touched.haulierId && errors.haulierId}
                        onBlur={selected => {
                          setFieldValue("haulierId", selected);
                        }}
                      />
                      {!this.isHaulierIdValid(
                        values.roles,
                        values.haulierId
                      ) && (
                        <div
                          className="form-control"
                          style={{ marginTop: "-1.8rem" }}
                        >
                          <div className="form-field-error">
                            Please select haulier
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <br />
                  <div className="button-col">
                    <Can when="settings.users.invite">
                      <Button
                        type="submit"
                        variant="primary"
                        size="small"
                        title="Save"
                        disabled={
                          Object.keys(touched).length < 1 ||
                          Object.keys(errors).length ||
                          !this.isHaulierIdValid(values.roles, values.haulierId)
                        }
                      />
                    </Can>
                    <Button
                      variant="secondary"
                      size="small"
                      title="Cancel"
                      onClick={() => this.props.cancel()}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(InviteUserForm));
