import React from "react";
import bwipjs from "bwip-js";
import "./Barcode.css";

export default class Barcode extends React.Component {
  constructor(props) {
    super(props);
    this.barcodeRef = React.createRef();
  }

  generateBarcode() {
    bwipjs(
      this.barcodeRef.current,
      {
        bcid: "qrcode",
        text: this.props.text
      },
      err => {
        if (err) {
          if (this.props.onError) {
            this.props.onError(err);
          } else {
            throw err;
          }
        }
      }
    );

    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(
      this.props.onExpiry,
      this.props.expiresAfter * 1000
    );
  }

  componentDidMount() {
    this.generateBarcode();
  }

  componentDidUpdate() {
    this.generateBarcode();
  }

  render() {
    return <canvas className="barcode__canvas" ref={this.barcodeRef} />;
  }
}
