import React from "react";

export default function TabMenuItem(props) {
  const className =
    props.index === props.selectedIndex
      ? "admin-menu-item-selected"
      : "admin-menu-item";
  return (
    <div
      className={className}
      onClick={() => {
        props.select(props.index);
      }}
    >
      <div className="admin-menu-item-title">{props.title}</div>
    </div>
  );
}
