import React, { useState } from "react";
import { withRouter } from "react-router";
import Button from "Button";
import LoginForm from "./login-page/LoginForm";
import SplashPage from "./SplashPage";
import config from "../../../config";

function LoginPage(props) {
  const allowSingleSignOn = config.FEATURE_SSO_ENABLED;
  const [showLoginForm, setShowLoginForm] = useState(!allowSingleSignOn);

  return (
    <SplashPage>
      <p className="splash-page__heading">Welcome</p>
      <p className="thin">You're almost there</p>
      {!showLoginForm && (
        <div className="login-form__buttons center">
          <a
            href="/sso/auth/signin/nestle?target_url=%2Fauth%2Fsso%2Fhandle"
            style={{ margin: "1rem 0" }}
          >
            <Button
              variant="primary"
              size="small"
              icon="login"
              title="Log in with Nestlé Account"
            />
          </a>
          <Button
            variant="primary"
            size="small"
            title="Log in with your email address"
            onClick={() => setShowLoginForm(true)}
          />
        </div>
      )}
      {showLoginForm && (
        <>
          <p className="small-type center">
            Please enter your account details below to access Mercurius.
          </p>
          <LoginForm {...props} />
        </>
      )}
    </SplashPage>
  );
}

export default withRouter(LoginPage);
