import React, { Component } from "react";
import { withRouter } from "react-router";
import { format, isBefore, isAfter } from "date-fns";
import Loader from "Loader";
import Button from "Button";
import RebookingIcon from "RebookingIcon";
import Can from "Can";
import { urlFromBooking } from "route-helper";
import {
  sendingSiteDescription,
  receivingSiteDescription,
  formatTrailer
} from "booking-helper";
import { formatWithDefaultTz } from "date-helper";

class ListView extends Component {
  goToBooking(booking) {
    const url = urlFromBooking(booking);
    this.props.history.push({
      pathname: url
    });
  }

  isItOverdue(slotStart, slotEnd, updated_at, status) {
    const now = new Date();
    return (
      (isBefore(updated_at, slotEnd) && isAfter(updated_at, slotStart)) ||
      isBefore(now, slotStart) ||
      status != null
    );
  }

  driverActionButton(booking) {
    if (booking.haulier === null) {
      return "";
    } else if (
      !["new", "staged", "ready_for_driver"].includes(booking.status) &&
      booking.direction === "inbound" &&
      !booking.driverName
    ) {
      return booking.isRebooking ? "" : "Driver Departed";
    } else if (booking.mission && booking.mission.operationStatus === 2) {
      return (
        <Can when="bookings.list.checkIn">
          <Button
            variant="primary"
            size="tiny"
            title="Validate"
            onClick={() => this.props.driverActions(booking.id, "Check In")}
          />
        </Can>
      );
    } else if (booking.status === "staged_arrived") {
      return (
        <Can when="bookings.list.checkIn">
          <Button
            variant="red"
            size="tiny"
            title="Check In 2nd"
            onClick={() => this.props.driverActions(booking.id, "Check In")}
          />
        </Can>
      );
    } else if (
      (booking.status === "new" && booking.direction === "inbound") ||
      (booking.status === "ready_for_driver" &&
        booking.direction === "inbound" &&
        !booking.driverName &&
        !booking.vehicleReg) ||
      booking.status === "staged" ||
      (!booking.driverName &&
        !booking.vehicleReg &&
        booking.direction === "outbound")
    ) {
      return (
        <Can when="bookings.list.checkIn">
          <Button
            variant="primary"
            size="tiny"
            title="Check In"
            onClick={() => this.props.driverActions(booking.id, "Check In")}
          />
        </Can>
      );
    } else if (
      booking.direction !== "internal" &&
      (!["ready_for_driver", "completed", "staged_arrived"].includes(
        booking.status
      ) ||
        booking.status === "completed")
    ) {
      const title =
        booking.direction === "outbound" ? "Off Site" : "Leave Solo";
      return (
        <Can when="bookings.list.offSite">
          <Button
            variant="secondary-30"
            size="tiny"
            title={title}
            onClick={() =>
              this.props.driverActions(booking.id, "Driver Off Site")
            }
          />
        </Can>
      );
    } else if (booking.status === "staged_arrived") {
      return (
        <Button
          variant="secondary"
          size="tiny"
          title="Off Site"
          disabled={booking.trailer.zone ? "" : "disabled"}
          onClick={() => this.props.driverActions(booking.id, "Staged Load")}
        />
      );
    } else if (booking.status === "ready_for_driver") {
      const title =
        booking.direction === "outbound" ? "Off Site" : "Leave Solo";
      return (
        <>
          <Can when="bookings.list.offSite">
            <Button
              variant="secondary-30"
              size="tiny"
              title={title}
              style={{ marginBottom: "0.5rem" }}
              onClick={() =>
                this.props.driverActions(booking.id, "Driver Off Site")
              }
            />
          </Can>
          <Can when="bookings.list.checkOut">
            <Button
              variant="secondary"
              size="tiny"
              title="Check Out"
              onClick={() => this.props.driverActions(booking.id, "Check Out")}
            />
          </Can>
        </>
      );
    }

    return "";
  }

  render() {
    if (this.props.bookingsLoading || this.props.loading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }
    return (
      <>
        {!this.props.bookings.length && (
          <p className="booking-list--empty">
            No bookings for the selected day.
          </p>
        )}
        {!!this.props.bookings.length && (
          <>
            {this.props.hideHeading == false && (
              <h2>
                Bookings - {format(this.props.selectedDate, "dddd Do MMM YYYY")}
              </h2>
            )}
            <table className="booking-list-table">
              <thead>
                <tr>
                  <th />
                  <th>Date</th>
                  <th>Slot Start</th>
                  <th>ETA</th>
                  <th>Direction</th>
                  <th>Trailer</th>
                  <th>Pallets</th>
                  <th>Load Type</th>
                  <th>Journey Ref</th>
                  <th>Tag</th>
                  <th>Load/Receipt</th>
                  <th>Sending Site</th>
                  <th>Receiving Site</th>
                  <th>Reg</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.props.bookings.map(booking => (
                  <tr
                    key={booking.journeyReference}
                    className={
                      (this.isItOverdue(
                        booking.slotStart,
                        booking.slotEnd,
                        booking.updatedAt,
                        booking.status
                      )
                        ? ""
                        : " booking-list--overdue") +
                        this.props.selectedBooking ===
                      booking.id
                        ? "table-row--selected"
                        : ""
                    }
                  >
                    <td style={{ width: "20px", paddingRight: "0" }}>
                      <RebookingIcon booking={booking} />
                    </td>
                    <td>{formatWithDefaultTz(booking.slotStart, "DD-MM-YYYY")}</td>
                    <td>{formatWithDefaultTz(booking.slotStart, "HH:mm:ss")}</td>
                    <td>
                      {booking.appointment ? booking.appointment.data.eta : ""}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {booking.direction}
                    </td>
                    {this.isItOverdue(
                      booking.slotStart,
                      booking.slotEnd,
                      booking.updatedAt,
                      booking.status
                    ) ? (
                      <td>{formatTrailer(booking)}</td>
                    ) : (
                      <td>OVERDUE</td>
                    )}
                    <td>{booking.numberOfPallets}</td>
                    <td>{booking.loadType}</td>
                    <td
                      className="booking-list-link"
                      onClick={() => this.goToBooking(booking)}
                    >
                      {booking.journeyReference}
                      {booking.appointment &&
                      booking.appointment.data.column_number
                        ? ` (${booking.appointment.data.column_number})`
                        : ""}
                    </td>
                    <td>
                      {booking.appointment &&
                        booking.appointment.data["journey_tag"]}
                    </td>
                    <td>
                      {booking.appointment
                        ? booking.appointment.loadNo
                        : booking.poNumber}
                    </td>
                    <td>{sendingSiteDescription(booking)}</td>
                    <td>{receivingSiteDescription(booking)}</td>
                    <td>{booking.vehicleReg}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {booking.haulier === null
                        ? "PROPOSED"
                        : booking.status.replace(/_/g, " ")}
                    </td>
                    <td>{this.driverActionButton(booking)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </>
    );
  }
}

export default withRouter(ListView);
