import React from "react";
import SelectBox from "form-controls/SelectBox";
import { Formik, Field, Form } from "formik";
import Button from "Button";
import Loader from "Loader";
import { transferBooking, allBookings } from "./trailerQueries";
import { formatDestinations } from "booking-helper";
import { formatLocalDate } from "date-helper";
import { connect } from "react-redux";

class LinkTrailerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      bookings: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    allBookings(this.props.selectedSite).then(({ bookings }) => {
      this.setState({ bookings, loading: false });
    });
  }

  updateBooking(bookingId) {
    transferBooking(bookingId, this.props.trailerId).then(() => {
      this.props.afterUpdate();
    });
  }

  handleSubmit(values) {
    this.updateBooking(values.bookingId);
  }

  get availableBookings() {
    if (this.props.isInternal) {
      return this.state.bookings.filter(
        b => b.direction !== "outbound" && b.direction !== "inbound"
      );
    }

    return this.state.bookings;
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const bookingOptions = this.availableBookings.map(b => ({
      value: b.id,
      label: `${formatLocalDate(b.slotStart)} ${b.journeyReference} (${
        b.direction
      }) ${formatDestinations(b)}`,
      isDisabled: b.trailer !== null
    }));

    return (
      <Formik
        enableReinitialize
        initialValues={{ bookingId: "" }}
        onSubmit={this.handleSubmit.bind(this)}
      >
        {({ errors, values }) => (
          <Form>
            <Field
              name="bookingId"
              label="Booking"
              options={bookingOptions}
              placeholder="Add Booking"
              component={SelectBox}
            />

            <div className="button-col">
              <Button
                type="submit"
                variant="primary"
                size="small"
                title="Link journey reference"
                disabled={values.bookingId === "" || Object.keys(errors).length}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

function mapStateToProps({ app: { selectedSite } }) {
  return { selectedSite };
}

export default connect(mapStateToProps)(LinkTrailerForm);
