import React from "react";
import Content from "Content";
import { connect } from "react-redux";
import WarehousePageTemplate from "./WarehousePageTemplate";
import { bookingsBySiteId } from "./warehouse-dashboard-page/warehouse-dashboard-queries";
import "./Dashboard.css";
import Loader from "Loader";
import DashboardBookingTable from "./warehouse-dashboard-page/DashboardBookingTable";
import DashboardInternalBooking from "./warehouse-dashboard-page/DashboardInternalBooking";
import SelectSitePanel from "SelectSitePanel";
import { selectSite } from "redux/app";
import { format } from "date-fns";
import ListView from "./warehouse-dashboard-page/ListView";

class WarehouseDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      allBookings: [],
      bookings: [],
      priorityBookings: [],
      filteredBookings: []
    };
  }
  componentDidMount() {
    if (parseInt(this.props.match.params.siteId) !== this.props.selectedSite) {
      if (this.props.selectedSite === null) {
        this.props.dispatch(selectSite(this.props.match.params.siteId));
      } else {
        this.updateRoute(this.props.selectedSite);
        this.getWarehouseData(this.props.selectedSite);
      }
    } else {
      this.getWarehouseData(this.props.selectedSite);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedSite !== this.props.selectedSite ||
      prevProps.selectedDate !== this.props.selectedDate
    ) {
      this.getWarehouseData(this.props.selectedSite);
      if (
        parseInt(this.props.match.params.siteId) !== this.props.selectedSite
      ) {
        this.updateRoute(this.props.selectedSite);
      }
    }
  }

  getBookingInfo(id, bookings) {
    return bookings.find(b => parseInt(b.id) === parseInt(id));
  }

  getWarehouseData(id) {
    const date = format(this.props.selectedDate, "YYYY-MM-DD");
    bookingsBySiteId(id, date).then(data => {
      let filteredBookings = this.filterStagedBookings(data.bookings);
      const orderedBookings = [];
      if (
        data.priorityBookings &&
        data.priorityBookings.booking_priority.length > 0
      ) {
        data.priorityBookings.booking_priority.forEach(id => {
          const bookingData = this.getBookingInfo(id, filteredBookings);
          if (bookingData !== undefined) {
            orderedBookings.push(bookingData);
          }
          filteredBookings = filteredBookings.filter(
            b => parseInt(b.id) !== parseInt(id)
          );
        });
      }

      this.setState({
        allBookings: data.allBookings,
        bookings: data.bookings,
        loading: false,
        sites: data.site.children
          ? [data.site].concat(data.site.children)
          : [data.site],
        priorityBookings: orderedBookings,
        filteredBookings: filteredBookings
      });
    });
  }

  filterStagedBookings(bookings) {
    return bookings.filter(
      booking =>
        booking.status !== "staged" &&
        booking.status !== "staged_arrived" &&
        booking.status !== "ready_for_driver" &&
        booking.haulier !== null
    );
  }

  get inboundBookings() {
    return this.state.bookings.filter(b => b.direction === "inbound");
  }

  get internalBookings() {
    return this.state.bookings.filter(b => b.direction === "internal");
  }

  get outboundBookings() {
    return this.state.bookings.filter(b => b.direction === "outbound");
  }

  get rebookings() {
    return this.state.allBookings.filter(b => b.isRebooking);
  }

  get rebookingBreakdown() {
    return Object.entries(
      this.rebookings.reduce((acc, b) => {
        acc[b.loadType] = 1 + (acc[b.loadType] || 0);

        return acc;
      }, {})
    );
  }

  updateRoute(id) {
    if (id === null) {
      this.props.history.push({ pathname: "/warehouse" });
    } else {
      this.props.history.push({
        pathname: `/warehouse/site/${id}`
      });
    }
  }

  render() {
    if (this.state.loading && this.props.selectedSite) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }
    return (
      <WarehousePageTemplate>
        {!this.props.selectedSite && <SelectSitePanel />}

        {this.props.selectedSite && (
          <Content>
            <div className="dash-row">
              <div className="content-block">
                <h2>{this.rebookings.length} Rebookings</h2>
                {this.rebookingBreakdown.map(([loadType, count]) => (
                  <p>
                    {count} {loadType}
                  </p>
                ))}
              </div>
            </div>
            <div className="dash-row">
              <div className="content-block">
                <h2>{this.state.sites[0].name} - Inbound Bookings</h2>
                {this.inboundBookings.length > 0 && (
                  <DashboardBookingTable bookings={this.inboundBookings} />
                )}
                {this.inboundBookings.length === 0 && (
                  <h4>No bookings to display</h4>
                )}
              </div>
            </div>
            <div className="dash-row">
              <div className="content-block">
                <h2>{this.state.sites[0].name} - Outbound Bookings</h2>
                {this.outboundBookings.length > 0 && (
                  <DashboardBookingTable bookings={this.outboundBookings} />
                )}
                {this.outboundBookings.length === 0 && (
                  <h4>No bookings to display</h4>
                )}
              </div>
            </div>
            <div className="dash-row">
              <div className="content-block">
                <h2>{this.state.sites[0].name} - Internal Bookings</h2>
                {this.internalBookings.length > 0 && (
                  <DashboardInternalBooking
                    bookings={this.internalBookings}
                    direction="internal"
                  />
                )}
                {this.internalBookings.length === 0 && (
                  <h4>No bookings to display</h4>
                )}
              </div>
            </div>
            <div className="dash-row">
              <div className="content-block">
                <ListView
                  bookings={this.state.filteredBookings}
                  orderedBookings={this.state.priorityBookings}
                  siteId={this.props.selectedSite}
                />
              </div>
            </div>
          </Content>
        )}
      </WarehousePageTemplate>
    );
  }
}

function mapStateToProps({ app: { selectedSite, selectedDate } }) {
  return { selectedSite, selectedDate };
}

export default connect(mapStateToProps)(WarehouseDashboardPage);
