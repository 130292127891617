import React, { useEffect, useState } from "react";
import Table from "Table";
import LiveDataView from "LiveDataView";
import Loader from "Loader";
import { buildSubscription, buildQuery, fetch } from "graphql-helper";

export default function TableView({ root, args, fields, noDataMessage }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const fieldPaths = fields.flatMap(([, v]) => v);
    fetch(buildQuery(root, args, fieldPaths)).then(res => {
      setData(res[root]);
      setIsLoading(false);
    });
  }, [root, args, fields]);

  const headers = fields.map(([k]) => k);

  const accessByPath = (obj, path) =>
    path.split(".").reduce((acc, p) => (acc ? acc[p] : null), obj);

  const rows = data.map(d => {
    return fields.map(([, v, callback]) => {
      let result = null;
      if (Array.isArray(v)) {
        result = v.map(iv => accessByPath(d, iv)).join(" ");
      } else {
        result = accessByPath(d, v);
      }

      if (typeof result !== "undefined" && callback) {
        return callback(result, d);
      }

      return result;
    });
  });

  if (isLoading) {
    return (
      <div className="calendar-view__loader">
        <Loader />
      </div>
    );
  }

  return (
    <LiveDataView
      subscription={buildSubscription(
        root,
        args,
        fields.flatMap(([, v]) => v)
      )}
      onResult={res => {
        setData(res[root]);
      }}
    >
      {() => (
        <Table headers={headers} rows={rows} noDataMessage={noDataMessage} />
      )}
    </LiveDataView>
  );
}
