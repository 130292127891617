import React, { useEffect, useState } from "react";
import WarehousePageTemplate from "./WarehousePageTemplate";
import SelectBox from "form-controls/SelectBox";
import SelectSitePanel from "SelectSitePanel";
import Button from "Button";
import Content from "Content";
import TableView from "TableView";
import Loader from "Loader";
import Confirm from "Confirm";
import Can from "Can";
import BookingPreviewModal from "BookingPreviewModal";
import { connect } from "react-redux";
import { fetch, toArgList, withErrorHandler } from "graphql-helper";
import { formatStatus } from "booking-helper";
import { formatLocalDate } from "date-helper";
import Modal from "Modal";

function LinkJourneyModal(props) {
  const [selectedJourney, setSelectedJourney] = useState("");
  const outboundJourneys = props.outboundBookings.map(b => {
    const destination =
      (b.appointment &&
        b.appointment.data &&
        b.appointment.data.receiving_site_description) ||
      b.origin;
    return {
      value: b.id,
      label: `${formatLocalDate(b.slotStart)} ${
        b.journeyReference
      } - ${destination}`
    };
  });
  return (
    <Modal>
      <div className="modal">
        <div className="modal__content">
          <div className="modal__title">{props.title}</div>
          <p>{props.rebooking.journeyReference}</p>
          <p>{props.rebooking.origin}</p>
          <SelectBox
            label="Outbound journey"
            options={outboundJourneys}
            field={{
              value: selectedJourney,
              onChange: e => {
                setSelectedJourney(e.target.value);
              }
            }}
          />
          <div className="modal__buttons">
            <Button
              variant="primary"
              size="small"
              title="Confirm"
              onClick={() => props.onConfirm(selectedJourney)}
            />
            <Button
              variant="secondary"
              size="small"
              title="Cancel"
              onClick={props.onCancel}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

function WarehouseRebookings({ selectedSite, handleErrors }) {
  const [outboundBookings, setOutboundBookings] = useState([]);
  const [showingModal, setShowingModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showingBookingPreviewModal, setShowingBookingPreviewModal] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedSite === null) {
      return;
    }

    setIsLoading(true);

    loadOutboundBookings().then(() => setIsLoading(false));
  }, [selectedSite]);

  const loadOutboundBookings = () => {
    return fetch(`
      query {
        outboundBookings: bookings(isRebooking: false, direction: "outbound", siteId: ${selectedSite}) {
          id
          journeyReference
          slotStart
          origin
          status
          trailer {
            trailerNo
          }
          appointment {
            data
          }
        }
      }
    `).then(({ outboundBookings }) => {
      setOutboundBookings(outboundBookings);
    });
  };

  const handleBackToStock = id => {
    handleErrors(
      fetch(`
      mutation {
        backToStock(rebookingId: ${id}) {
          id
        }
      }
    `).then(() => {
        loadOutboundBookings();
      })
    );
  };

  const handleStageLoad = (id, status) => {
    const newStatus = status === "new" ? "staged" : "staged_arrived";
    handleErrors(
      fetch(`
      mutation {
        updateBooking(id: ${id}, changeset: {status: "${newStatus}"}) {
          id
        }
      }
    `).then(() => {
        loadOutboundBookings();
      })
    );
  };

  const handleRequiresTranship = (id, requiresTranship, status) => {
    handleErrors(
      fetch(`
      mutation {
        updateBooking(id: ${id}, changeset: {requiresTranship: ${
        requiresTranship ? "true" : "false"
      }}) {
          id
        }
      }
    `).then(() => {
        if (requiresTranship) {
          return handleBackToStock(id);
        }
        return handleStageLoad(id, status);
      })
    );
  };

  const handleShowBookingModal = b => {
    setSelectedBooking(b);
    setShowingBookingPreviewModal(true);
  };

  const handleCloseShowBookingModal = () => {
    setSelectedBooking(null);
    setShowingBookingPreviewModal(false);
  };

  if (selectedSite === null) {
    return (
      <WarehousePageTemplate>
        <SelectSitePanel />
      </WarehousePageTemplate>
    );
  }

  if (isLoading) {
    return (
      <WarehousePageTemplate>
        <Loader />
      </WarehousePageTemplate>
    );
  }

  return (
    <WarehousePageTemplate>
      <Content>
        {showingModal && (
          <LinkJourneyModal
            rebooking={selectedBooking}
            title="Link outbound journey"
            onConfirm={outboundBookingId => {
              const args = {
                rebookingId: selectedBooking.id,
                outboundBookingId
              };
              handleErrors(
                fetch(`
                mutation {
                  switchRebooking(${toArgList(args)}) {
                    id
                  }
                }
              `).then(() => {
                  setShowingModal(false);
                })
              );
            }}
            onCancel={() => {
              setShowingModal(false);
              setSelectedBooking(null);
            }}
            outboundBookings={outboundBookings}
          />
        )}
        {showingBookingPreviewModal && (
          <BookingPreviewModal
            bookingId={selectedBooking.id}
            onClose={handleCloseShowBookingModal}
          />
        )}
        <div className="dash-row">
          <div className="content-block">
            <h2>Delivery Failures/Refusals</h2>

            <TableView
              root="bookings"
              args={{
                isRebooking: true,
                filterOldNoCheckin: true,
                siteId: selectedSite,
              }}
              fields={[
                [
                  "Journey Ref",
                  [
                    "journeyReference",
                    "direction",
                    "sendingSite.id",
                    "receivingSite.id"
                  ],
                  (d, b) => (
                    <div onClick={() => handleShowBookingModal(b)}>
                      {b.journeyReference}
                    </div>
                  )
                ],
                ["Trailer", ["haulier.shortCode", "trailer.trailerNo"]],
                ["Pallets", "numberOfPallets"],
                [
                  "Origin",
                  ["origin", "appointment.data"],
                  (d, b) =>
                    b.appointment
                      ? b.appointment.data.sending_site_description
                      : b.origin
                ],
                ["Planned Arrival Time", "slotStart"],
                ["Load Type", "loadType"],
                ["Status", "status", (status, b) => formatStatus(b)],
                [
                  "",
                  "id",
                  (id, b) =>
                    b.status === "ready_for_driver" || b.requiresTranship ? (
                      ""
                    ) : ["staged", "staged_arrived"].includes(b.status) ? (
                      <Can when="warehouse.rebookings.backToStock">
                        <Confirm title="This will allow the warehouse to unload the trailer once it has arrived.">
                          {confirm => (
                            <Button
                              size="tiny"
                              variant="secondary"
                              title="Back to Stock"
                              onClick={confirm(() => handleBackToStock(id))}
                            />
                          )}
                        </Confirm>
                      </Can>
                    ) : ["new", "arrived"].includes(b.status) ? (
                      <Can when="warehouse.rebookings.backToStock">
                        <Confirm title="The rebooking will be staged and the warehouse will not be able to unload the trailer.">
                          {confirm => (
                            <Button
                              size="tiny"
                              variant="cancel"
                              title="Undo Back to Stock"
                              onClick={confirm(() =>
                                handleStageLoad(id, b.status)
                              )}
                            />
                          )}
                        </Confirm>
                      </Can>
                    ) : (
                      ""
                    )
                ],
                [
                  "",
                  ["id", "requiresTranship"],
                  (d, b) =>
                    ["staged", "staged_arrived"].includes(b.status) ||
                    b.requiresTranship ? (
                      <Can when="warehouse.rebookings.requiresTranship">
                        <Confirm
                          title={
                            b.requiresTranship
                              ? "The rebooking will be staged and the warehouse will not be able to unload the trailer."
                              : "This will allow the warehouse to unload the trailer once it has arrived."
                          }
                        >
                          {confirm => (
                            <Button
                              size="tiny"
                              variant={
                                b.requiresTranship ? "cancel" : "secondary"
                              }
                              title={
                                b.requiresTranship
                                  ? "Undo Requires Tranship"
                                  : "Requires Tranship"
                              }
                              onClick={confirm(() =>
                                handleRequiresTranship(
                                  b.id,
                                  !b.requiresTranship,
                                  b.status
                                )
                              )}
                            />
                          )}
                        </Confirm>
                      </Can>
                    ) : (
                      ""
                    )
                ],
                [
                  "",
                  "id",
                  (id, b) =>
                    b.trailer ? (
                      <Can when="warehouse.rebookings.linkOutboundJourney">
                        <Button
                          size="tiny"
                          variant="primary"
                          title="Link outbound journey"
                          onClick={() => {
                            setShowingModal(true);
                            setSelectedBooking(b);
                          }}
                        />
                      </Can>
                    ) : (
                      ""
                    )
                ]
              ]}
            />
          </div>
        </div>
      </Content>
    </WarehousePageTemplate>
  );
}

function mapStateToProps({ app: { selectedSite } }) {
  return { selectedSite };
}

export default withErrorHandler(connect(mapStateToProps)(WarehouseRebookings));
