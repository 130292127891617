import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withErrorHandler } from "graphql-helper";
import isEmail from "isemail";
import { Formik, Form } from "formik";
import {
  activateBookingReceipt,
  deactivateBookingReceipt,
  removeBookingReceipt,
  createBookingReceipt
} from "./hauliers-admin-queries";
import Switch from "form-controls/Switch";
import TextInput from "form-controls/TextInput";
import "./HaulierCreateForm.css";

class HaulierForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newReceipt: "",
      emailFormError: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedHaulierId !== this.props.selectedHaulierId) {
      this.setState({
        emailFormError: "",
        newReceipt: ""
      });
    }
  }

  toggleFlag(id) {
    const receipt = this.props.receipts.find(rec => rec.id == id);
    if (receipt.isActive) {
      deactivateBookingReceipt(id).then(() => {
        this.props.getBookingReceipts();
      });
    } else {
      activateBookingReceipt(id).then(() => {
        this.props.getBookingReceipts();
      });
    }
  }

  deleteReceipt(id) {
    removeBookingReceipt(id).then(() => {
      this.props.getBookingReceipts();
    });
  }

  createNewBookingReceipt() {
    if (this.validateEmail()) {
      this.setState({
        emailFormError: ""
      });
      this.props.handleErrors(
        createBookingReceipt(
          this.props.selectedHaulierId,
          this.state.newReceipt
        ).then(() => {
          this.setState({
            newReceipt: ""
          });
          this.props.getBookingReceipts();
        }),
        "Email added to haulier"
      );
    }
  }

  validateEmail() {
    if (!isEmail.validate(this.state.newReceipt)) {
      this.setState({
        emailFormError: "Please enter a valid email address"
      });
      return false;
    } else if (
      this.props.receipts.some(r => r["email"] == this.state.newReceipt)
    ) {
      this.setState({
        emailFormError: "Email address already exists"
      });
      return false;
    }
    return true;
  }

  get initialValues() {
    return {
      receipts: this.props.receipts
    };
  }

  render() {
    return (
      <Formik initialValues={this.initialValues} enableReinitialize>
        {() => (
          <Form>
            <table className="stand-level-table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Status</th>
                  <th className="booking-receipt-table-header-end">Delete</th>
                </tr>
              </thead>
              <tbody>
                {this.props.receipts.map(receipt => (
                  <tr key={receipt.id}>
                    <td className="site-label">{receipt.email}</td>
                    <td className="booking-receipt-table-row-switch">
                      <Switch
                        field={{
                          value: receipt.isActive,
                          onChange: () => this.toggleFlag(receipt.id)
                        }}
                      />
                    </td>
                    <td className="booking-receipt-table-row-end">
                      <span
                        className="material-icons link"
                        onClick={() => this.deleteReceipt(receipt.id)}
                      >
                        delete
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="booking-receipt-table-input-row">
              <div className="booking-receipt-table-input">
                <TextInput
                  field={{
                    value: this.state.newReceipt,
                    onChange: e => {
                      this.setState({
                        newReceipt: e.target.value
                      });
                    }
                  }}
                  placeholder="+ Add email address"
                  error={this.state.emailFormError}
                  onFocus={() => this.setState({ emailFormError: "" })}
                ></TextInput>
              </div>
              <div className="booking-receipt-add-receipt">
                <span
                  className="material-icons link"
                  onClick={() => this.createNewBookingReceipt()}
                >
                  add_circle_outline
                </span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withErrorHandler(connect()(withRouter(HaulierForm)));
