import React from "react";
import "./CheckBox.css";

export default function BaseCheckBox(props) {
  const checked = props.field.value;

  return (
    <label className="check-box">
      <input
        {...props.field}
        type="checkbox"
        checked={checked}
        onChange={event => {
          props.field.onChange(event);
          if (props.onChange) {
            props.onChange(event);
          }
        }}
        disabled={props.disabled}
      />
      {checked ? (
        <i className="check-box__icon check-box__icon--checked material-icons">
          check
        </i>
      ) : (
        <i className="check-box__icon" />
      )}
      <span className="check-box-label">{props.label}</span>
    </label>
  );
}
