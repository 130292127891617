import React from "react";
import classnames from "classnames";
import "./SelectBox.css";
import "./TimePicker.css";

export default function TimePicker(props) {
  const className = classnames("select-box__select", {
    "select-box__select--has-errors": props.error
  });
  return (
    <div className="form-control">
      {props.label}
      <div className="time-picker">
        <select
          className={className}
          {...props.field}
          disabled={props.disabled}
        >
          <option value="">{props.placeholder}</option>
          {props.options.map(({ value, label, isAvailable }, index) => (
            <option key={index} value={value} disabled={!isAvailable}>
              {label}
            </option>
          ))}
        </select>
        <i className="material-icons time-picker__icon">access_time</i>
      </div>
      {props.error && <div className="form-field-error">{props.error}</div>}
    </div>
  );
}
