import React from "react";
import { formatTrailer, formatSite } from "booking-helper";
import { formatWithDefaultTz } from "date-helper";

export default function DashboardInternalBooking({ bookings }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Start</th>
          <th>Trailer</th>
          <th>Load Type</th>
          <th>Pallets</th>
          <th>Journey Ref</th>
          <th>Sending Site</th>
          <th>Receiving Site</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {bookings.map((b) => {
          let zone = "";
          if (
            b.trailer !== null &&
            b.trailer.zone !== null &&
            ["parking", "virtual"].includes(b.trailer.zone.type)
          ) {
            zone = b.trailer.zone.name;
          } else if (
            b.trailer !== null &&
            b.trailer.zone !== null &&
            b.trailer.zone.dock
          ) {
            zone = `${b.trailer.zone.name} - ${b.trailer.zone.dock.number}`;
          }

          return (
            <tr key={b.id}>
              <td>{formatWithDefaultTz(b.slotStart, "HH:mm")}</td>
              <td>{formatTrailer(b)}</td>
              <td>{b.loadType}</td>
              <td>{b.numberOfPallets}</td>
              <td>{b.journeyReference}</td>
              <td>{formatSite(b.sendingSite)}</td>
              <td>{formatSite(b.receivingSite)}</td>
              <td>{zone}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
