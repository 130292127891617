import React from "react";
import { formatLocalDate } from "date-helper";
import {
  sendingSiteDescription,
  receivingSiteDescription
} from "booking-helper";
import RebookingIcon from "RebookingIcon";
import LoadIndicator from "LoadIndicators";
import TrailerUnavailableIcon from "TrailerUnavailableIcon";

export default function NumberedParkingTrailersTable({
  zone,
  trailers,
  movements
}) {
  function findValidMovement(trailerId) {
    return (
      movements &&
      movements.find(m => parseInt(m.trailer.id) === parseInt(trailerId))
    );
  }

  function moveTo(t) {
    const validMovement = findValidMovement(t.id);
    if (validMovement) {
      return (
        validMovement.location.zone.name +
        (validMovement.location.dock
          ? ` - ${validMovement.location.dock.number}`
          : "")
      );
    } else {
      return "";
    }
  }

  const trailerOnDock = id =>
    trailers.find(t => (t.dock ? t.dock.id === id : false));

  return (
    <table className="zone-table" style={{ whiteSpace: "nowrap" }}>
      <thead>
        <tr>
          <th></th>
          {zone.type === "numbered_parking" && <th>Bay</th>}
          <th>Trailer</th>
          <th>Pallets</th>
          <th></th>
          <th>Journey Ref</th>
          <th>PO/ASN</th>
          <th>Direction</th>
          <th>Last Moved</th>
          <th>Sending Site</th>
          <th>Receiving Site</th>
          <th>Pending Movement</th>
        </tr>
      </thead>
      <tbody>
        {zone.docks.map(d => {
          const t = trailerOnDock(d.id);

          if (!t) {
            return (
              <tr key={d.id}>
                <td></td>
                <td>{d.number}</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td style={{ textTransform: "capitalize" }} />
                <td />
                <td />
                <td />
                <td />
              </tr>
            );
          }
          return (
            <tr key={d.id}>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <TrailerUnavailableIcon trailer={t} />
                  {t.booking && t.booking.appointment && (
                    <LoadIndicator appointment={t.booking.appointment} />
                  )}
                </div>
              </td>
              <td>{d.number}</td>
              <td>
                {t.haulier.shortCode} - {t.trailerNo} ({t.trailerType.type})
              </td>
              <td>{t.booking && t.booking.numberOfPallets}</td>
              <td>{t.booking && <RebookingIcon booking={t.booking} />}</td>
              <td>{t.booking && t.booking.journeyReference}</td>
              <td>{t.booking && t.booking.poNumber}</td>
              <td style={{ textTransform: "capitalize" }}>
                {t.booking && t.booking.direction}
              </td>
              <td>
                {t.movedAt
                  ? formatLocalDate(t.movedAt)
                  : formatLocalDate(t.insertedAt)}
              </td>
              <td>{t.booking && sendingSiteDescription(t.booking)}</td>
              <td>{t.booking && receivingSiteDescription(t.booking)}</td>
              <td>{moveTo(t)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
