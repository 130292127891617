import React from "react";
import { connect } from "react-redux";
import SelectBox from "form-controls/SelectBox";
import { updateTrailer } from "./traffic-live-queries";
import { requestMovement } from "warehouse/pages/move-trailer-page/move-trailer-queries";
import { getZonesBySite } from "api/zones";
import { getSites } from "queries/sites";
import { formatAsOptions } from "siteList";
import { Formik, Field, Form } from "formik";
import Button from "Button";
import { withErrorHandler } from "graphql-helper";
import YesNoCancel from "YesNoCancel";
import Confirm from "Confirm";
import Can from "Can";
import Cannot from "Cannot";

class MoveTrailerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingConfirmation: false,
      zones: [],
      sites: [],
      loading: true,
      zoneLoading: true,
      selectedZone: props.selectedTrailer ? props.selectedTrailer.zone.id : ""
    };
  }

  componentDidMount() {
    getSites().then(({ sites }) => {
      this.setState({ sites, loading: false });
    });
    this.getZones(this.props.selectedSite);
    this.updateDocks(this.props.selectedTrailer.zone.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getZones(this.props.selectedSite);

      if (
        this.props.selectedTrailer &&
        prevProps.selectedTrailer != this.props.selectedTrailer
      )
        this.updateDocks(this.props.selectedTrailer.zone.id);
    }
  }

  get siteList() {
    return formatAsOptions(this.state.sites);
  }

  getZones(siteId) {
    if (siteId !== "") {
      getZonesBySite(siteId).then(zones => {
        this.setState({
          zones: zones,
          zoneLoading: false
        });
      });
    } else {
      this.setState({
        zones: [],
        zoneLoading: false
      });
    }
  }

  isParkingDock() {
    const zone = this.state.zones.find(
      zone => zone.id === this.state.selectedZone
    );
    return zone ? ["parking", "virtual"].includes(zone.type) : false;
  }

  getDockOptions(zoneId) {
    const zone = this.state.zones.find(zone => zone.id === zoneId);
    const docks = !zone ? [] : zone.docks;

    docks.sort((a, b) => (a.number > b.number ? 1 : -1));

    return docks.length > 0
      ? docks.map(d => ({
          value: d.id,
          label: d.number,
          isDisabled: !(d.isActive && !d.isTaken)
        }))
      : [];
  }

  updateSite(siteId) {
    this.getZones(siteId);
  }

  updateDocks(zoneId) {
    this.setState({ selectedZone: parseInt(zoneId, 10) });
  }

  get initialValues() {
    const { selectedTrailer } = this.props;

    if (!selectedTrailer) {
      return {
        siteId: "",
        zoneId: "",
        dockId: ""
      };
    }

    return {
      siteId: this.props.selectedSite,
      zoneId: selectedTrailer.zone.id,
      dockId: selectedTrailer.dock ? selectedTrailer.dock.id : ""
    };
  }

  render() {
    const { isSelectedDockSafe } = this.props;

    const zoneOptions = this.state.zones.map(z => ({
      value: z.id,
      label: z.name
    }));

    const dockOptions = this.getDockOptions(this.state.selectedZone);

    const locationValid = value => {
      if (!this.isParkingDock() && !value) {
        return "You must select a dock";
      } else {
        return null;
      }
    };
    return (
      <Formik
        enableReinitialize
        validateOnChange={true}
        initialValues={this.initialValues}
        onSubmit={() => {
          this.setState({
            showingConfirmation: true
          });
        }}
      >
        {({ errors, touched, values, setSubmitting }) => (
          <Form>
            <Can when="traffic.trailers.createMovement">
              <YesNoCancel
                title="Create a shunter movement?"
                isOpen={this.state.showingConfirmation}
                onClose={() => this.setState({ showingConfirmation: false })}
                onYes={() => {
                  const { id } = this.props.selectedTrailer;
                  this.props
                    .handleErrors(
                      requestMovement(
                        id,
                        values.zoneId,
                        values.dockId === undefined ? null : values.dockId,
                        false
                      ),
                      "Movement requested"
                    )
                    .then(() => {
                      setSubmitting(false);
                    });
                }}
                onNo={() => {
                  const { id } = this.props.selectedTrailer;
                  this.props.handleErrors(
                    updateTrailer(id, values).then(() => {
                      setSubmitting(false);
                      this.props.afterUpdate();
                    }),
                    "The trailer location has been updated."
                  );
                }}
              />
            </Can>
            <Cannot when="traffic.trailers.createMovement">
              <Confirm
                title="Are you sure you want to manually update the trailer location?"
                isOpen={this.state.showingConfirmation}
                onConfirm={() => {
                  const { id } = this.props.selectedTrailer;
                  this.props.handleErrors(
                    updateTrailer(id, values).then(() => {
                      setSubmitting(false);
                      this.props.afterUpdate();
                    }),
                    "The trailer location has been updated."
                  );
                }}
                onCancel={() => this.setState({ showingConfirmation: false })}
              ></Confirm>
            </Cannot>
            <Field
              name="siteId"
              label="Site"
              options={this.siteList}
              disabled={this.state.loading || !isSelectedDockSafe}
              placeholder="Select Site"
              component={SelectBox}
              onChange={e => this.updateSite(e.target.value)}
              error={touched.siteId && errors.siteId}
            />
            <Field
              name="zoneId"
              label="Zone"
              component={SelectBox}
              validate={locationValid}
              options={zoneOptions}
              disabled={
                this.state.loading ||
                this.state.zoneLoading ||
                !isSelectedDockSafe
              }
              onChange={e => this.updateDocks(e.target.value)}
              error={
                touched.zoneId && errors.zoneId ? (
                  <div>{errors.zoneId}</div>
                ) : null
              }
            />
            <Field
              name="dockId"
              label="Dock"
              validate={locationValid}
              options={dockOptions}
              placeholder="Select Dock"
              component={SelectBox}
              disabled={
                this.state.loading ||
                this.isParkingDock() ||
                !isSelectedDockSafe
              }
              error={
                touched.dockId && errors.dockId ? (
                  <div>{errors.dockId}</div>
                ) : null
              }
            />
            <div className="button-col">
              <Button
                type="submit"
                variant="primary"
                size="small"
                title="Update location"
                disabled={
                  Object.keys(touched).length < 1 ||
                  Object.keys(errors).length ||
                  !isSelectedDockSafe
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withErrorHandler(connect()(MoveTrailerForm));
