import { fetch } from "graphql-helper";

export function zones() {
  return fetch(`
  query {
    zones(isActive: null) {
    capacity
    id
    isActive
    isMaintained
    name
    startingBay
    site {
      id
      name
      parentSite {
        id
        name
      }
    }
  docks {
    id
    number
    isActive
    isUnmanned
    dockSafetyDevice {
      deviceId
      currentPower
      lastMessageReceivedAt
      trafficLightStatus
      errorState
    }
  }
  type
  parkingRules {
    id
    site {
      name
    }
  }
  }}
  `);
}

export function zonesBySite(siteID) {
  return fetch(`
  query {
    zones(site: ${siteID}) {
    capacity
    id
    isActive
    isMaintained
    name
    startingBay
    site {
      id
      name
      parentSite {
        id
        name
      }
    }
  docks {
    id
    number
    isActive
    isUnmanned
    dockSafetyDevice {
      deviceId
    }
  }
  }}
  `);
}

export function zone(zoneID) {
  return fetch(`
  query {
    zone(id: ${zoneID}) {
    capacity
    id
    isActive
    isMaintained
    name
    startingBay
    site {
      id
      name
      parentSite {
        id
        name
      }
    }
  docks {
    id
    number
    isActive
    isUnmanned
    dockSafetyDevice {
      deviceId
      currentPower
      lastMessageReceivedAt
      trafficLightStatus
      errorState
    }
  }
  type
  }}
  `);
}

export function availableDockSafetyDevices(siteId) {
  return fetch(`
  query {
    availableDockSafetyDevicesForSite(siteId: ${siteId}) {
      deviceId
      isActive
      errorState
      messageData
      lastMessageReceivedAt
      currentPower
    trafficLightStatus
  }}
  `);
}

export function assignDockSafetyDevice(deviceId, dockId) {
  return fetch(`
  mutation 
  {assignDockSafetyDevice(deviceId: "${deviceId}", dockId:"${dockId}") {
    deviceId
  }
}
`);
}

export function unassignDockSafetyDevice(deviceId) {
  return fetch(`
  mutation 
  {unassignDockSafetyDevice(device_id: "${deviceId}") {
    deviceId
  }
}
`);
}
