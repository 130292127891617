import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import Modal from "Modal";
import Button from "Button";
import Loader from "Loader";
import { Formik, Form } from "formik";
import { availableDockSafetyDevices } from "../zones-page/zones-queries";
import { format } from "date-fns";
import low from "assets/low.svg";
import medium from "assets/medium.svg";
import full from "assets/full.svg";

function AssignDockSafetyDeviceModal({
  siteId,
  selectedZoneName,
  selectedDock,
  onClose,
  onAssignDevice
}) {
  const [devices, setDevices] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    availableDockSafetyDevices(siteId).then(data => {
      setDevices(data.availableDockSafetyDevicesForSite);
      setIsLoading(false);
    });
  }, []);

  return (
    <Modal>
      <div className="modal">
        <div className="modal__content">
          <div className="modal__header">
            <span className="modal__title">
              Assign a dock safety device to | {selectedZoneName} | Dock #{" "}
              {!isLoading && selectedDock.number}
            </span>
            <i className="material-icons modal__close" onClick={onClose}>
              close
            </i>
          </div>
          <div className="modal__body">
            {isLoading && <Loader />}

            {!isLoading && (
              <Formik enableReinitialize>
                {() => (
                  <Form className="dock-safety-device-table">
                    <table className="zones-dock-table">
                      <thead>
                        <tr className="zones-dock-table">
                          <th className="zones-dock-table">Device ID</th>
                          <th className="zones-dock-table">Last update sent</th>
                          <th className="zones-dock-table">Status</th>
                          <th className="zones-dock-table">Battery Level</th>
                          <th className="zones-dock-table">Errors</th>
                          <th className="zones-dock-table">Assign</th>
                        </tr>
                      </thead>
                      <tbody className="zones-dock-table-body">
                        {devices.map((dockSafetyDevice, index) => (
                          <tr key={index}>
                            <td>{dockSafetyDevice.deviceId}</td>
                            <td>
                              {format(
                                dockSafetyDevice.lastMessageReceivedAt,
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </td>
                            <td>
                              <TrafficLight
                                status={dockSafetyDevice.trafficLightStatus}
                              />
                            </td>
                            <td>
                              <BatteryIcon
                                level={dockSafetyDevice.currentPower}
                              />
                            </td>
                            <td
                              style={
                                dockSafetyDevice.errorState != ""
                                  ? { color: "rgba(209, 73, 91, 1)" }
                                  : {}
                              }
                            >
                              {dockSafetyDevice.errorState != ""
                                ? dockSafetyDevice.errorState
                                : "-"}
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                size="tiny"
                                title="Assign"
                                onClick={() => {
                                  onAssignDevice(
                                    dockSafetyDevice.deviceId,
                                    selectedDock.id
                                  );
                                  onClose();
                                }}
                              />{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <br />
                  </Form>
                )}
              </Formik>
            )}
            <div className="modal__buttons">
              <Button
                variant="secondary"
                size="tiny"
                title="Close"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function TrafficLight({ status }) {
  let color = "rgba(209, 73, 91, 1)";
  if (status == "green") {
    color = "rgba(143, 201, 58, 1)";
  }
  return (
    <div style={{ background: color }}>
      {status == "green" ? "GREEN" : "RED"}
    </div>
  );
}

function BatteryIcon({ level }) {
  if (level > 60) {
    return (
      <div style={{ color: "rgba(143, 201, 58, 1)" }}>
        <img src={full} style={{ height: "23px" }} /> {level}%
      </div>
    );
  } else if (level > 15 && level <= 60) {
    return (
      <div style={{ color: "rgba(254, 153, 32, 1)" }}>
        <img src={medium} style={{ height: "23px" }} /> {level}%
      </div>
    );
  } else
    return (
      <div style={{ color: "rgba(209, 73, 91, 1)" }}>
        <img src={low} style={{ height: "23px" }} /> {level}%
      </div>
    );
}

export default withRouter(AssignDockSafetyDeviceModal);
