import React from "react";
import { Formik, Field, Form } from "formik";
import { withRouter } from "react-router";
import * as Yup from "yup";
import { createAccount } from "api/accounts";
import Button from "components/Button";
import Loader from "components/Loader";
import TextInput from "components/form-controls/TextInput";

function equalTo(ref, msg) {
  return this.test({
    name: "equalTo",
    exclusive: false,
    message: msg || "${path} must be the same as ${reference}",
    params: {
      reference: ref.path
    },
    test: function(value) {
      return value === this.resolve(ref);
    }
  });
}

Yup.addMethod(Yup.string, "equalTo", equalTo);

const registerSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, "")
    .required("Please enter your name"),
  contactNumber: Yup.string().required("Please enter a contact number"),
  password: Yup.string()
    .min(16, "Your password must be at least 16 characters")
    .matches(
      /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{16,})/,
      "Your password must contain: at least one upper case character, at least on lower case character and at least one number."
    )
    .required("Please enter a password"),
  confirmPassword: Yup.string().equalTo(
    Yup.ref("password"),
    "Passwords do not match"
  )
});

function RegisterForm({ inviteToken, history }) {
  const initialValues = {
    name: "",
    contactNumber: "",
    password: "",
    confirmPassword: ""
  };

  return (
    <Formik
      validationSchema={registerSchema}
      onSubmit={values => {
        createAccount(values, inviteToken)
          .then(() => {
            history.push("/auth/register/success");
          })
          .catch(() => {
            history.push("/auth/register/invalid");
          });
      }}
      initialValues={initialValues}
    >
      {({ errors, touched, isSubmitting }) => (
        <div>
          {isSubmitting && <Loader />}
          <Form>
            <Field
              name="name"
              label="Name"
              component={TextInput}
              error={touched.name && errors.name}
            />
            <Field
              name="contactNumber"
              label="Contact number"
              component={TextInput}
              error={touched.contactNumber && errors.contactNumber}
            />
            <Field
              name="password"
              label="Password"
              component={TextInput}
              inputType="password"
              error={touched.password && errors.password}
            />
            <Field
              name="confirmPassword"
              label="Confirm Password"
              component={TextInput}
              inputType="password"
              error={touched.confirmPassword && errors.confirmPassword}
            />
            <div className="center">
              <Button
                type="submit"
                variant="primary"
                size="small"
                title="Register"
                disabled={
                  Object.keys(touched).length < 2 || Object.keys(errors).length
                }
              />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}

export default withRouter(RegisterForm);
