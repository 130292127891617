import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SelectSitePanel from "SelectSitePanel";
import Loader from "Loader";
import ListView from "./traffic-bookings-page/ListView";
import PreAllocateRightPanel from "./traffic-bookings-page/PreAllocateRightPanel";
import { getSiteBookings } from "./traffic-bookings-page/traffic-bookings-queries";
import { format } from "date-fns";
import TrafficPageTemplate from "./TrafficPageTemplate";
import BookingPreviewModal from "BookingPreviewModal";
import { selectSite } from "redux/app";
import Content from "../../../components/Content";

class TrafficBookingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      bookingsLoading: true,
      panelOpen: false,
      selectedBookingId: null,
      showingBookingPreviewModal: false
    };
  }

  componentDidMount() {
    if (parseInt(this.props.match.params.siteId) !== this.props.selectedSite) {
      if (this.props.selectedSite === null && this.props.match.params.siteId) {
        this.props.dispatch(selectSite(this.props.match.params.siteId));
      } else if (this.props.selectedSite !== null) {
        this.updateRoute(this.props.selectedSite);
        this.fetchSiteBookings();
      } else {
        this.updateRoute();
        this.fetchSiteBookings();
      }
    } else {
      this.fetchSiteBookings();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedSite !== this.props.selectedSite ||
      prevProps.selectedDate !== this.props.selectedDate
    ) {
      if (this.props.selectedSite !== null) {
        this.updateRoute(this.props.selectedSite);
        this.fetchSiteBookings();
      } else if (this.props.match.params.siteId !== undefined) {
        this.props.dispatch(selectSite(this.props.match.params.siteId));
      }
    }
  }

  updateRoute(id) {
    if (id !== undefined && id !== "") {
      this.props.history.push({
        pathname: `/traffic/bookings/site/${id}`
      });
    }
  }

  fetchSiteBookings() {
    getSiteBookings(
      this.props.selectedSite,
      format(this.props.selectedDate, "YYYY-MM-DD")
    ).then(data => {
      this.setState({
        bookings: data.bookings,
        bookingsLoading: false
      });
    });
  }

  selectBooking(id) {
    this.setState({ panelOpen: true, selectedBookingId: id });
  }

  closePanel() {
    this.setState({ panelOpen: false });
    this.fetchSiteBookings();
  }

  closeBookingPreviewModal() {
    this.setState({
      selectedBookingId: null,
      showingBookingPreviewModal: false
    });
  }

  showBookingPreviewModal(selectedBookingId) {
    this.setState({ selectedBookingId, showingBookingPreviewModal: true });
  }

  get selectedBooking() {
    return this.state.bookings.find(b => b.id === this.state.selectedBookingId);
  }

  render() {
    if (this.state.bookingsLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    return (
      <TrafficPageTemplate>
        {this.state.showingBookingPreviewModal && (
          <BookingPreviewModal
            bookingId={this.state.selectedBookingId}
            onClose={this.closeBookingPreviewModal.bind(this)}
          />
        )}
        {!this.props.selectedSite && <SelectSitePanel />}
        {this.props.selectedSite && (
          <Content>
            <div className="dash-row">
              <div className="content-block">
                <ListView
                  {...this.state}
                  selectedSite={this.props.selectedSite}
                  selectedDate={this.props.selectedDate}
                  onAllocate={this.selectBooking.bind(this)}
                  onSelectBooking={this.showBookingPreviewModal.bind(this)}
                />
                {this.state.panelOpen && (
                  <PreAllocateRightPanel
                    selectedBooking={this.selectedBooking}
                    isExpanded={this.state.panelOpen}
                    onClose={this.closePanel.bind(this)}
                  />
                )}
              </div>
            </div>
          </Content>
        )}
      </TrafficPageTemplate>
    );
  }
}

function mapStateToProps({ app: { selectedDate, selectedSite }, auth }) {
  return { selectedDate, selectedSite, auth };
}

export default withRouter(connect(mapStateToProps)(TrafficBookingsPage));
