import React from "react";
import { Formik, Field, Form } from "formik";
import MultiSelect from "form-controls/MultiSelect";
import Switch from "form-controls/Switch";
import Button from "Button";
import { options as goodsOptions } from "trailer-goods";
import "./TrailerFilterForm.css";
import config from "../../../../config";

const uniqueValues = arr =>
  arr.filter(
    ({ value }, idx) =>
      arr.findIndex(({ value: innerValue }) => innerValue === value) === idx
  );

const alphaCompare = (a, b) => {
  if (a.label === b.label) {
    return 0;
  }

  return a.label > b.label ? 1 : -1;
};

const trailerOwnerOptions = trailers =>
  uniqueValues(
    trailers.map(t => ({
      value: t.haulier.id,
      label: t.haulier.name
    }))
  ).sort(alphaCompare);

const trailerTypeOptions = trailers =>
  uniqueValues(
    trailers.map(t => ({
      value: t.trailerType.id,
      label: t.trailerType.name
    }))
  ).sort(alphaCompare);

const zoneOptions = trailers =>
  uniqueValues(
    trailers.map(t => ({
      value: t.zone.id,
      label: t.zone.name
    }))
  ).sort(alphaCompare);

const loadTypeOptions = trailers =>
  uniqueValues(
    trailers
      .filter(t => t.booking)
      .map(t => ({
        value: t.booking.loadType,
        label: t.booking.loadType
      }))
  ).sort(alphaCompare);

const siteOptions = trailers =>
  uniqueValues(
    trailers
      .filter(t => t.booking)
      .flatMap(t => {
        if (t.booking.sendingSite && t.booking.receivingSite) {
          return [
            {
              label: t.booking.sendingSite.name,
              value: t.booking.sendingSite.id
            },
            {
              label: t.booking.receivingSite.name,
              value: t.booking.receivingSite.id
            }
          ];
        } else if (t.booking.origin) {
          return { label: t.booking.origin, value: t.booking.origin };
        }

        const site = t.booking.sendingSite || t.booking.receivingSite;

        return { label: site.name, value: site.id };
      })
  );

const statusOptions = [
  "Empty",
  "Awaiting Loading",
  "Awaiting Unloading",
  "Loading in Progress",
  "Unloading in Progress",
  "Loading Complete",
  "Unloading Complete",
  "Ready to Move",
  "Awaiting Driver"
].map(s => ({ label: s, value: s }));

const unavailabilityOptions = [
  { value: "service", label: "Service" },
  { value: "mot", label: "MOT" },
  { value: "vor", label: "Vehicle Off Road" },
  { value: "tracker_issues", label: "Tracker Issues" },
  ...(config.FEATURE_RETURNS_REBOOKS_UNAVAILABILITY_REASON
    ? [{ value: "returns_rebooks", label: "Returns/Rebooks" }]
    : [])
];

export default function TrailerFilterForm({
  appliedFilters,
  trailers,
  onSubmit,
  onReset
}) {
  const initialValues = {
    trailerOwner: [],
    trailerType: [],
    status: [],
    location: [],
    loadType: [],
    goods: [],
    sendingSite: [],
    receivingSite: [],
    unavailabilityReason: [],
    internalOnly: false,
    ...appliedFilters
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ setFieldValue }) => (
        <Form className="trailer-filter-form">
          <Field
            name="trailerOwner"
            label="Trailer Owner"
            placeholder="Any"
            options={trailerOwnerOptions(trailers)}
            component={MultiSelect}
            onBlur={selectedItems => {
              setFieldValue("trailerOwner", selectedItems);
            }}
          />
          <Field
            name="trailerType"
            label="Trailer Type"
            placeholder="Any"
            options={trailerTypeOptions(trailers)}
            component={MultiSelect}
            onBlur={selectedItems => {
              setFieldValue("trailerType", selectedItems);
            }}
          />
          <Field name="internalOnly" label="Internal only" component={Switch} />
          <Field
            name="status"
            label="Status"
            placeholder="Any"
            options={statusOptions}
            component={MultiSelect}
            onBlur={selectedItems => {
              setFieldValue("status", selectedItems);
            }}
          />
          <Field
            name="unavailabilityReason"
            label="Unavailability Reason"
            placeholder="None"
            options={unavailabilityOptions}
            component={MultiSelect}
            onBlur={selectedItems => {
              setFieldValue("unavailabilityReason", selectedItems);
            }}
          />
          <Field
            name="loadType"
            label="Load Type"
            placeholder="Any"
            options={loadTypeOptions(trailers)}
            component={MultiSelect}
            onBlur={selectedItems => {
              setFieldValue("loadType", selectedItems);
            }}
          />
          <Field
            name="goods"
            label="Goods"
            placeholder="Any"
            options={goodsOptions}
            component={MultiSelect}
            onBlur={selectedItems => {
              setFieldValue("goods", selectedItems);
            }}
          />
          <Field
            name="location"
            label="Location"
            placeholder="Any"
            options={zoneOptions(trailers)}
            component={MultiSelect}
            onBlur={selectedItems => {
              setFieldValue("location", selectedItems);
            }}
          />
          <Field
            name="sendingSite"
            label="Sending Site"
            placeholder="Any"
            options={siteOptions(trailers)}
            component={MultiSelect}
            onBlur={selectedItems => {
              setFieldValue("sendingSite", selectedItems);
            }}
          />
          <Field
            name="receivingSite"
            label="Receiving Site"
            placeholder="Any"
            options={siteOptions(trailers)}
            component={MultiSelect}
            onBlur={selectedItems => {
              setFieldValue("receivingSite", selectedItems);
            }}
          />
          <div className="horizontal-container">
            <Button
              type="submit"
              variant="primary"
              size="small"
              title="Apply filters"
            />
            <Button
              type="reset"
              variant="secondary"
              size="small"
              title="Reset"
              onClick={onReset}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
