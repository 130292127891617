import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  selectSite,
  searchBookingsQueryInput,
  searchAllDatesChange
} from "redux/app";
import Loader from "Loader";
import Notice from "Notice";
import BaseCheckBox from "form-controls/BaseCheckBox";
import ListView from "./list-view-page/ListView";
import "./ListViewPage.css";
import {
  confirmStagedLoad,
  getSiteBookings,
  driverOffSite,
  checkOut,
  checkOutExportLoad,
  getDailyPalletInfo
} from "./list-view-page/list-view-queries";
import { format } from "date-fns";
import BookingPageTemplate from "./BookingPageTemplate";
import RightPanel from "RightPanel";
import { withErrorHandler } from "graphql-helper";
import CheckOutPanel from "./list-view-page/CheckOutPanel";
import Content from "../../../components/Content";
import Can from "Can";

class ListViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      bookingsLoading: true,
      sitesLoading: true,
      siteList: [],
      panelOpen: false,
      selectedBookingId: null,
      driverActions: "",
      includeInternal: false
    };
  }

  componentDidMount() {
    if (parseInt(this.props.match.params.siteId) !== this.props.selectedSite) {
      let siteId = this.props.match.params.siteId;

      if (this.props.selectedSite === null && siteId !== null) {
        this.props.dispatch(selectSite(siteId));
      } else {
        siteId = this.props.selectedSite;
        this.updateRoute(this.props.selectedSite);
        this.fetchBookings();
        this.fetchDailyPalletInfo();
      }
    } else {
      this.fetchBookings();
      this.fetchDailyPalletInfo();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.match.params.siteId !== undefined &&
        prevProps.selectedSite !== parseInt(this.props.match.params.siteId)) ||
      prevProps.selectedDate !== this.props.selectedDate ||
      prevState.includeInternal !== this.state.includeInternal ||
      prevProps.searchBookingsQuery !== this.props.searchBookingsQuery ||
      prevProps.searchAllDates !== this.props.searchAllDates
    ) {
      this.fetchBookings();
      this.fetchDailyPalletInfo();
    } else if (prevProps.selectedSite !== this.props.selectedSite) {
      if (
        this.props.selectedSite &&
        parseInt(this.props.match.params.siteId) !== this.props.selectedSite
      ) {
        this.updateRoute(this.props.selectedSite);
        this.fetchBookings();
        this.fetchDailyPalletInfo();
      }
    }
  }

  updateRoute(id) {
    if (id === undefined || id === "") {
      this.props.history.push({ pathname: "/bookings/daily-bookings" });
    } else {
      this.props.history.push({
        pathname: `/bookings/site/${id}/daily-bookings`
      });
    }
  }

  fetchBookings() {
    const selectedSite = this.props.selectedSite || null;
    getSiteBookings(
      selectedSite,
      this.props.searchAllDates
        ? null
        : format(this.props.selectedDate, "YYYY-MM-DD"),
      this.state.includeInternal,
      this.props.searchBookingsQuery
    ).then(data => {
      this.setState({ bookings: data.bookings, bookingsLoading: false });
    });
  }

  fetchDailyPalletInfo() {
    const selectedSite = this.props.selectedSite || null;
    getDailyPalletInfo(
      selectedSite,
      format(this.props.selectedDate, "YYYY-MM-DD")
    ).then(data => {
      this.setState({
        dailyPalletLimit: data.dailyPalletInfo.dailyPalletLimit,
        palletsForDate: data.dailyPalletInfo.palletsForDate
      });
    });
  }

  confirmDriverOffSite(id, direction) {
    driverOffSite(id, direction).then(() => {
      this.fetchBookings();
      this.setState({
        panelOpen: false,
        selectedBookingId: "",
        driverActions: ""
      });
    });
  }

  confirmStagedLoad(id) {
    confirmStagedLoad(id).then(() => {
      this.closePanel();
    });
  }

  confirmCheckOut(id, photo, vetCheckRequired, vetCheckPerformed) {
    if (!photo) {
      this.props.handleErrors(
        checkOut(id).then(() => {
          this.closePanel();
        })
      );
    } else {
      this.props.handleErrors(
        checkOutExportLoad(id, photo, vetCheckRequired, vetCheckPerformed).then(
          () => {
            this.closePanel();
          }
        )
      );
    }
  }

  driverActions(id, action) {
    this.setState({
      panelOpen: true,
      selectedBookingId: id,
      driverActions: action
    });
  }

  showDriverLocation(trailer) {
    this.setState({
      showMessage: true,
      message: `The driver is now checked in and should be instructed to park at: ${
        trailer.zone.name
      }${trailer.dock ? " - " + trailer.dock.number : ""}`
    });
  }

  dismissNotice() {
    this.setState({
      showMessage: false,
      message: ""
    });
  }

  closePanel() {
    this.setState({ panelOpen: false, selectedBookingId: null });
    this.fetchBookings();
  }

  get selectedBooking() {
    return this.state.bookings.find(b => b.id === this.state.selectedBookingId);
  }

  render() {
    if (this.state.isLoading || this.state.bookingsLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    return (
      <BookingPageTemplate
        searchPlaceholder="Search bookings"
        showDateToggle={true}
        searchAllDates={this.props.searchAllDates}
        onSearchAllDatesChange={val => {
          this.props.dispatch(searchAllDatesChange(val));
        }}
        searchQuery={this.props.searchBookingsQuery}
        onSearchQueryChange={val =>
          this.props.dispatch(searchBookingsQueryInput(val))
        }
      >
        <RightPanel
          title={this.state.driverActions}
          isExpanded={this.state.panelOpen}
          onClose={this.closePanel.bind(this)}
        >
          <CheckOutPanel
            booking={this.selectedBooking}
            showPanel={this.state.showCheckOutPanel}
            onClose={this.closePanel.bind(this)}
            onConfirm={this.confirmCheckOut.bind(this)}
            action={this.state.driverActions}
            confirmDriverOffSite={this.confirmDriverOffSite.bind(this)}
            confirmStagedLoad={this.confirmStagedLoad.bind(this)}
            showDriverLocation={this.showDriverLocation.bind(this)}
          />
        </RightPanel>
        <Content>
          {this.state.showMessage && (
            <Notice
              onDismiss={this.dismissNotice.bind(this)}
              message={this.state.message}
            />
          )}
          <div
            className="dash-row"
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <strong>Filter results:</strong>
              <BaseCheckBox
                field={{
                  value: this.state.includeInternal,
                  onChange: () => {
                    this.setState({
                      includeInternal: !this.state.includeInternal
                    });
                  }
                }}
                label="Include internal bookings"
              />
            </div>
            <Can when="bookings.list.dailyPalletCount">
              {this.state.dailyPalletLimit > 0 && (
                <div>
                  Pallets on this day: {this.state.palletsForDate}/
                  {this.state.dailyPalletLimit}
                </div>
              )}
            </Can>
          </div>
          <div className="dash-row">
            <div className="content-block">
              <ListView
                {...this.state}
                hideHeading={this.props.searchAllDates}
                selectedDate={this.props.selectedDate}
                selectedSite={this.props.selectedSite}
                selectedBooking={this.state.selectedBookingId}
                driverActions={this.driverActions.bind(this)}
              />
            </div>
          </div>
        </Content>
      </BookingPageTemplate>
    );
  }
}

function mapStateToProps({
  app: { selectedDate, selectedSite, searchBookingsQuery, searchAllDates },
  auth
}) {
  return {
    selectedDate,
    selectedSite,
    searchBookingsQuery,
    searchAllDates,
    auth
  };
}

export default withErrorHandler(
  withRouter(connect(mapStateToProps)(ListViewPage))
);
