import React, { useEffect, useRef, useState } from "react";

export default function PieChart({ title, data }) {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [height, setHeight] = useState(300);

  function initialiseCanvas() {
    const canvas = canvasRef.current;
    const containerDimensions = containerRef.current.getBoundingClientRect();
    const context = canvas.getContext("2d");
    const { width } = containerDimensions;
    canvas.width = width;
    canvas.height = width;

    setHeight(width);

    return [canvas, context];
  }

  function drawTitle(context, title) {
    context.fillStyle = "black";
    context.font = "16px Arial";
    context.fillText(title, 20, 20);
  }

  function drawLabel(context, label, { x, y }) {
    context.fillStyle = "black";
    context.font = "12px Arial";
    context.fillText(label, x, y);
  }

  function drawPie(context, data, centre) {
    const total = data.reduce((a, b) => a + b.value, 0);

    let startAngle = 0;

    const radius = Math.min(180, centre.x);

    data
      .filter(d => d.value)
      .forEach(({ value, fillStyle, label }) => {
        const nextAngle = startAngle + ((Math.PI * 2) / total) * value;
        context.fillStyle = fillStyle;
        context.beginPath();
        context.arc(centre.x, centre.y, radius, startAngle, nextAngle);
        context.lineTo(centre.x, centre.y);
        context.fill();

        const halfPi = Math.PI / 2;

        const halfDistance = (nextAngle - startAngle) / 2 + startAngle;

        const hyp = radius + 20;
        let opp;
        let adj;
        if (halfDistance < halfPi) {
          opp = Math.sin(halfDistance) * hyp;
          adj = Math.sqrt(hyp * hyp - opp * opp);
        } else if (halfDistance < Math.PI) {
          opp = Math.sin(halfDistance) * hyp;
          adj = -Math.sqrt(hyp * hyp - opp * opp);
        } else if (halfDistance < 3 * halfPi) {
          opp = -Math.sin(halfDistance - Math.PI) * hyp;
          adj = -Math.sqrt(hyp * hyp - opp * opp);
        } else {
          opp = -Math.sin(halfDistance - Math.PI) * hyp;
          adj = Math.sqrt(hyp * hyp - opp * opp);
        }

        startAngle = nextAngle;

        context.strokeStyle = fillStyle;
        context.beginPath();
        context.moveTo(centre.x, centre.y);
        context.lineTo(centre.x + adj, centre.y + opp);
        context.stroke();

        const metrics = context.measureText(label);
        drawLabel(context, label, {
          x: centre.x + adj + (adj < 0 ? -metrics.width : 0),
          y: centre.y + opp + (opp === 0 ? 0 : opp > 0 ? 6 : -6)
        });
        drawLabel(context, value, {
          x: centre.x + adj + (adj < 0 ? -metrics.width : 0),
          y: centre.y + opp + (opp === 0 ? 0 : opp > 0 ? 6 : -6) + 16
        });
      });
  }

  function drawLegend(context, data) {
    data
      .filter(d => d.value)
      .forEach(({ value, fillStyle, label }, idx) => {
        if (value !== 0) {
          context.fillStyle = fillStyle;
          context.fillRect(20, 40 + 20 * idx, 16, 16);

          drawLabel(context, label, { x: 40, y: 52 + 20 * idx });
        }
      });
  }

  useEffect(() => {
    const [canvas, context] = initialiseCanvas();

    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);

    drawTitle(context, title);

    drawPie(context, data, { x: canvas.width / 2, y: canvas.height / 2 });

    drawLegend(context, data);
  });

  return (
    <div className="pie-chart" style={{ height: height }} ref={containerRef}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
}
