import { fetch } from "graphql-helper";

export function getZones(siteId) {
  return fetch(`query {
      zones (site: ${siteId}) {
        id
        name
        type
        docks {
          id
          number
          isActive
        }
      }
    }`);
}

export function getSites() {
  return fetch(`
    query {
      sites {
        id
        name
        parentSite{
          id
          name
        }
      }
    }
  `);
}

export function updateAllocations(
  {
    primaryZoneId,
    primaryDockId,
    secondaryZoneId,
    secondaryDockId,
    primaryId,
    secondaryId
  },
  bookingId
) {
  return fetch(`mutation UpdateAllocations {
        updateAllocations(changeset: {
          bookingId: ${bookingId}
          primaryId: ${primaryId}
          secondaryId: ${secondaryId}
          primaryZoneId: ${primaryZoneId || null}
          primaryDockId: ${primaryDockId || null}
          secondaryZoneId: ${secondaryZoneId || null}
          secondaryDockId: ${secondaryDockId || null}
        }) {
          id
       }
    }`);
}

export function getSiteBookings(siteId, date) {
  const filter =
    siteId && date
      ? `(siteId: ${siteId}, date: "${date}")`
      : `(date: "${date}")`;
  return fetch(`query {
      bookings${filter} {
        id
        journeyReference
        numberOfPallets
        origin
        direction
        slotStart
        slotEnd
        status
        insertedAt
        driverName
        appointment {
          loadNo
          data
        }
        sendingSite {
          id
          name
          parentSite {
            id
            name
          }
        }
        receivingSite {
          id
          name
          parentSite {
            id
            name
          }
        }
        reasonDeleted
        updatedAt
        vehicleReg
        poNumber
        notes
        allocations {
          id
          order
          zone {
            id
            name
            type
            site {
              id
              name
            }
          }
          dock {
            id
            number
          }
        }
        haulier {
          id
          name
          shortCode
        }
        trailerOwner {
          id
          name
          shortCode
        }
        trailerType {
          id
          baseType
          type
          name
        }
        trailer {
          id
          trailerNo
          haulier {
            id
            name
            shortCode
          }
          zone {
            id
            name
            type
          }
          dock {
            id
            number
          }
        }
      }
    }`);
}
