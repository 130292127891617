import React from "react";
import TabMenuItem from "form-controls/TabMenuItem";

class TabControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0
    };
  }

  selectTab(index) {
    this.setState({ selectedTabIndex: index });
  }

  render() {
    const selectedItem = this.props.children[this.state.selectedTabIndex];

    return (
      <div>
        <div className="admin-details">
          <div className="admin-details-title">{this.props.title}</div>
          <div className="admin-details-location">
            {selectedItem.props.title}
          </div>

          <div className="admin-details-main">
            <div className="admin-details-menu">
              {this.props.children.map((item, idx) => (
                <TabMenuItem
                  key={idx}
                  index={idx}
                  title={item.props.title}
                  select={this.selectTab.bind(this)}
                  selectedIndex={this.state.selectedTabIndex}
                />
              ))}
            </div>
            {selectedItem}
          </div>
        </div>
      </div>
    );
  }
}

export default TabControl;
