import React from "react";
import classnames from "classnames";
import "./TextInput.css";

export default function TextInput(props) {
  const inputType = props.inputType || "text";
  const autoFocus = props.autoFocus ? "autoFocus" : "";

  const theme = props.theme || "light";
  const className = classnames("text-input", `text-input--${theme}`, {
    "text-input--has-errors": props.error
  });

  return (
    <div className="form-control">
      {props.label}
      <input
        {...props.field}
        className={className}
        type={inputType}
        placeholder={props.placeholder}
        disabled={props.disabled}
        autoFocus={autoFocus}
        onFocus={props.onFocus}
        onChange={event => {
          props.field.onChange(event);
          if (props.onChange) {
            props.onChange(event);
          }
        }}
        onClick={event => {
          if (props.onClick) {
            props.onClick(event);
          }
        }}
      />
      {props.error && <div className="form-field-error">{props.error}</div>}
    </div>
  );
}
