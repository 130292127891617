import React, { Component } from "react";
import { withRouter } from "react-router";
import { isBefore, isAfter } from "date-fns";
import {
  sendingSiteDescription,
  receivingSiteDescription,
  formatTrailer
} from "booking-helper";
import Button from "Button";
import Can from "Can";
import Loader from "Loader";
import { formatWithDefaultTz } from "date-helper";

class ListView extends Component {
  constructor(props) {
    super(props);
    this.isItOverdue = this.isItOverdue.bind(this);
  }

  handleSelectBooking(booking) {
    this.props.onSelectBooking(booking.id);
  }

  isItOverdue(slotStart, slotEnd, updated_at, status) {
    const now = new Date();
    return (
      (isBefore(updated_at, slotEnd) && isAfter(updated_at, slotStart)) ||
      isBefore(now, slotStart) ||
      status != null
    );
  }

  getAllocation(booking, index) {
    if (!booking.allocations[index]) {
      return "-";
    }

    const allocation = booking.allocations.find(
      al => parseInt(al.order) === index
    );

    if (allocation) {
      let string = "";
      string = allocation.zone ? allocation.zone.name : string;
      string += allocation.dock ? ` - ${allocation.dock.number}` : "";
      return string;
    }

    return "";
  }

  renderSite(site) {
    if (!site) {
      return "";
    }

    return site.parentSite
      ? `${site.parentSite.name} - ${site.name}`
      : site.name;
  }

  render() {
    if (this.props.bookingsLoading || this.props.loading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const siteCanAllocate = booking => {
      if (booking.direction === "internal") {
        return parseInt(booking.receivingSite.id) === this.props.selectedSite;
      }

      return true;
    };

    return (
      <>
        {!this.props.bookings.length && (
          <p className="booking-list--empty">
            No bookings for the selected day.
          </p>
        )}
        {!!this.props.bookings.length && (
          <>
            <h2>Trailer pre-allocations</h2>
            <table className="booking-list-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Slot Start</th>
                  <th>ETA</th>
                  <th>Direction</th>
                  <th>Trailer</th>
                  <th>Reference</th>
                  <th>Load/Receipt</th>
                  <th>Sending Site</th>
                  <th>Receiving Site</th>
                  <th>Status</th>
                  <th>Primary</th>
                  <th>Secondary</th>
                  <Can when="traffic.preallocate.edit">
                    <th />
                  </Can>
                </tr>
              </thead>
              <tbody>
                {this.props.bookings.map(booking => (
                  <tr
                    key={booking.journeyReference}
                    className={
                      this.isItOverdue(
                        booking.slotStart,
                        booking.slotEnd,
                        booking.updatedAt,
                        booking.status
                      )
                        ? ""
                        : " booking-list--overdue"
                    }
                  >
                    <td>{formatWithDefaultTz(booking.slotStart, "DD-MM-YYYY")}</td>
                    <td>{formatWithDefaultTz(booking.slotStart, "HH:mm:ss")}</td>
                    <td>
                      {booking.appointment && booking.appointment.data.eta}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {booking.direction}
                    </td>
                    {this.isItOverdue(
                      booking.slotStart,
                      booking.slotEnd,
                      booking.updatedAt,
                      booking.status
                    ) ? (
                      <td>{formatTrailer(booking)}</td>
                    ) : (
                      <td>OVERDUE</td>
                    )}
                    <td
                      className="booking-list-link"
                      onClick={() => this.handleSelectBooking(booking)}
                    >
                      {booking.journeyReference}
                    </td>
                    <td>
                      {booking.appointment
                        ? booking.appointment.loadNo
                        : booking.poNumber}
                    </td>
                    <td>{sendingSiteDescription(booking)}</td>
                    <td>{receivingSiteDescription(booking)}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {booking.status}
                    </td>
                    <td>{this.getAllocation(booking, 0)}</td>
                    <td>{this.getAllocation(booking, 1)}</td>
                    {siteCanAllocate(booking) && (
                      <Can when="traffic.preallocate.edit">
                        <td>
                          <Button
                            size="tiny"
                            variant="white"
                            title="Edit allocation"
                            onClick={() => this.props.onAllocate(booking.id)}
                          />
                        </td>
                      </Can>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </>
    );
  }
}
export default withRouter(ListView);
