import React from "react";
import SplashLogo from "assets/splash-logo.png";
import XpoSplashBackground from "assets/ddof.jpg";
import NestleSplashBackground from "assets/nestle-splash-screen.jpg";
import HamshallSplashBackground from "assets/hh-splash-screen.jpg";
import "./SplashPage.css";
import config from "../../../config";

export default function SplashPage(props) {
  let bgUrl = NestleSplashBackground;
  switch (config.REACT_APP_CLIENT) {
    case "xpo":
      bgUrl = XpoSplashBackground;
      break;
    case "hamshall":
      bgUrl = HamshallSplashBackground;
      break;
  }
  return (
    <div className="splash-page">
      <div
        className="splash-page__image"
        style={{ background: `center / cover no-repeat url(${bgUrl})` }}
      >
        <img src={SplashLogo} alt="Mercurius yard management app logo" />
      </div>
      <div className="splash-page__content">{props.children}</div>
    </div>
  );
}
