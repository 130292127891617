import { fetch } from "graphql-helper";

export function updateTrailer(trailerId, { zoneId, dockId }) {
  return fetch(`
    mutation UpdateTrailer {
      updateTrailer(
        id:${trailerId}
        zoneId:${zoneId}
        dockId:${dockId || null}
      ) {
        id
      }
    }
  `);
}

export function trailerSubscription() {
  return `
        subscription {
          trailers(site:8) {
            id
            trailerNo
            movedAt
            dock {
              id
              number
            }
            zone {
              id
              name
            }
          }
        }
      `;
}

export function getSiteBookings(siteId, date) {
  const filter =
    siteId && date
      ? `(siteId: ${siteId}, date: "${date}")`
      : `(date: "${date}")`;

  return fetch(`
    query {
      bookings${filter} {
        id
        journeyReference
        numberOfPallets
        origin
        direction
        slotStart
        slotEnd
        status
        insertedAt
        driverName
        site {
          id
          name
        }
        reasonDeleted
        updatedAt
        vehicleReg
        poNumber
        notes
        allocations {
          id
        }
        haulier {
          id
          name
        }
        trailerOwner {
          id
          name
        }
        trailerType {
          id
          baseType
          type
          name
        }
        trailer {
          id
          trailerNo
          haulier {
            id
            name
          }
          zone {
            id
            name
            type
          }
          dock {
            id
            number
          }
        }
      }
    }
  `);
}

export function getTrailerTypes() {
  return fetch(`
    query {
      trailerTypes {
        id
        name
      }
    }
  `);
}
