import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Formik, Form } from "formik";
import { format } from "date-fns";
import Button from "Button";
import Confirm from "Confirm";
import Loader from "Loader";
import AssignDockSafetyDeviceModal from "../zones-page/AssignDockSafetyDeviceModal";
import {
  assignDockSafetyDevice,
  unassignDockSafetyDevice
} from "../zones-page/zones-queries";
import low from "assets/low.svg";
import medium from "assets/medium.svg";
import full from "assets/full.svg";

class DockDevices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showAssignDockSafetyDeviceModal: false,
      selectedDock: null
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  get initialValues() {
    return {
      isActive: true,
      siteId: this.props.selectedZone ? this.props.selectedZone.site.id : "",
      ...this.props.selectedZone
    };
  }

  handleRemoveDevice(deviceId) {
    unassignDockSafetyDevice(deviceId).then(() => {
      this.props.refreshZone();
    });
  }

  handleAssignDevice(deviceId, dockId) {
    assignDockSafetyDevice(deviceId, dockId).then(() => {
      this.props.refreshZone();
    });
  }

  toggleShowAssignDockSafetyDeviceModal(dock = null) {
    if (dock !== null) {
      this.setState({ selectedDock: dock });
    }
    this.setState({
      showAssignDockSafetyDeviceModal: !this.state
        .showAssignDockSafetyDeviceModal
    });
  }

  render() {
    const { isEditMode, selectedZone } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        {this.state.showAssignDockSafetyDeviceModal && (
          <AssignDockSafetyDeviceModal
            selectedZoneName={selectedZone.name}
            selectedDock={this.state.selectedDock}
            onClose={this.toggleShowAssignDockSafetyDeviceModal.bind(this)}
            onAssignDevice={this.handleAssignDevice.bind(this)}
            siteId={selectedZone.site.id}
          />
        )}
        <Formik initialValues={this.initialValues} enableReinitialize>
          {() => (
            <Form className="dock-safety-device-table">
              {isEditMode &&
                ["loading", "numbered_parking"].includes(selectedZone.type) && (
                  <table className="zones-dock-table">
                    <thead>
                      <tr className="zones-dock-table">
                        <th className="zones-dock-table">Dock No.</th>
                        <th className="zones-dock-table">Device ID</th>
                        <th className="zones-dock-table">Last update sent</th>
                        <th className="zones-dock-table">Status</th>
                        <th className="zones-dock-table">Battery Level</th>
                        <th className="zones-dock-table">Errors</th>
                        <th className="zones-dock-table">Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedZone.docks.map((dock, index) => (
                        <tr key={index}>
                          <td>{dock.number}</td>
                          <td>
                            {dock.dockSafetyDevice
                              ? dock.dockSafetyDevice.deviceId
                              : "-"}
                          </td>
                          <td>
                            {dock.dockSafetyDevice
                              ? format(
                                  dock.dockSafetyDevice.lastMessageReceivedAt,
                                  "DD-MM-YYYY HH:mm:ss"
                                )
                              : "-"}
                          </td>
                          <td>
                            {dock.dockSafetyDevice ? (
                              <TrafficLight
                                status={
                                  dock.dockSafetyDevice.trafficLightStatus
                                }
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {dock.dockSafetyDevice ? (
                              <BatteryIcon
                                level={dock.dockSafetyDevice.currentPower}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                          <td
                            style={
                              dock.dockSafetyDevice &&
                              dock.dockSafetyDevice.errorState != ""
                                ? { color: "rgba(209, 73, 91, 1)" }
                                : {}
                            }
                          >
                            {dock.dockSafetyDevice &&
                            dock.dockSafetyDevice.errorState != ""
                              ? dock.dockSafetyDevice.errorState
                              : "-"}
                          </td>
                          <td>
                            {dock.dockSafetyDevice ? (
                              <Confirm
                                title={
                                  "The safety device will be removed from Dock #" +
                                  dock.number +
                                  " and returned to available devices for this site."
                                }
                              >
                                {confirm => (
                                  <Button
                                    size="tiny"
                                    variant="cancel"
                                    title="Unassign Device"
                                    onClick={confirm(() =>
                                      this.handleRemoveDevice(
                                        dock.dockSafetyDevice.deviceId
                                      )
                                    )}
                                  />
                                )}
                              </Confirm>
                            ) : (
                              <Button
                                variant="primary"
                                size="tiny"
                                title="Assign Device"
                                onClick={() =>
                                  this.toggleShowAssignDockSafetyDeviceModal(
                                    dock
                                  )
                                }
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              <br />
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

function TrafficLight({ status }) {
  let color = "rgba(209, 73, 91, 1)";
  if (status == "green") {
    color = "rgba(143, 201, 58, 1)";
  }
  return (
    <div style={{ background: color }}>
      {status == "green" ? "GREEN" : "RED"}
    </div>
  );
}

function BatteryIcon({ level }) {
  if (level > 60) {
    return (
      <div style={{ color: "rgba(143, 201, 58, 1)" }}>
        <img src={full} style={{ height: "23px" }} /> {level}%
      </div>
    );
  } else if (level > 15 && level <= 60) {
    return (
      <div style={{ color: "rgba(254, 153, 32, 1)" }}>
        <img src={medium} style={{ height: "23px" }} /> {level}%
      </div>
    );
  } else
    return (
      <div style={{ color: "rgba(209, 73, 91, 1)" }}>
        <img src={low} style={{ height: "23px" }} /> {level}%
      </div>
    );
}

function mapStateToProps({ sites }) {
  return { sites };
}

export default connect(mapStateToProps)(withRouter(DockDevices));
