import React, {useState} from "react";
import Barcode from "./Barcode";
import Button from "Button";
import "./ApplicationTable.css";

function ApplicationItem({x}) {
  const [showingPreviousBuild, setShowingPreviousBuild] = useState(false);

  const downloadLink = showingPreviousBuild ? x.previousBuild : x.downloadLink;
  
  return <>
           <Barcode text={downloadLink} />
           {x.previousBuild && <Button variant="white" size="tiny" title={showingPreviousBuild ? "Show latest build" : "Show previous build"} onClick={() => setShowingPreviousBuild(!showingPreviousBuild)} />}
           {showingPreviousBuild && <p className="application-table__message">Note: This is an older build and may not include the latest bug fixes and features.</p>}
           {x.message && (
             <p className="application-table__message">{x.message}</p>
           )}
         </>;
}

export default ({ applications }) => {
  return (
    <table>
      <thead>
        <tr>
          {applications.map(x => (
            <th key={JSON.stringify(x)} className="application-table__header">
              {x.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {applications.map(x => (
            <td
              key={JSON.stringify(x)}
              valign="top"
              align="center"
              className="application-table__td"
            >
              <ApplicationItem x={x} />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
