import React from "react";
import "./AdminMenuItem.css";

export default function AdminMenuItem(props) {
  const className =
    props.title === props.selected
      ? "admin-menu-item-selected"
      : "admin-menu-item";
  return (
    <div
      className={className}
      onClick={() => {
        props.select(props.title);
      }}
    >
      <div className="admin-menu-item-title">{props.title}</div>
    </div>
  );
}
