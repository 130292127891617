import React from "react";
import Button from "Button";
import Can from "Can";
import { Formik, Form, Field } from "formik";
import SelectBox from "form-controls/SelectBox";
// import Switch from "form-controls/Switch";

class SiteHaulierTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hauliers: [],
      siteHauliers: []
    };
  }

  componentDidMount() {
    if (this.props.siteHauliers) {
      this.setState({
        siteHauliers: this.props.siteHauliers,
        hauliers: this.props.hauliers
      });
    }
  }

  removeHaulier(id) {
    let currentHauliers = this.state.siteHauliers;
    currentHauliers = currentHauliers.filter(h => h.id !== id);
    this.setState({ siteHauliers: currentHauliers });
  }

  addHaulier(id) {
    const haulier = this.state.hauliers.find(h => h.id === id);
    let currentHauliers = this.state.siteHauliers;
    currentHauliers = currentHauliers.concat(haulier);
    this.setState({ siteHauliers: currentHauliers });
  }

  haulierList() {
    return this.state.hauliers.map(haulier => {
      return {
        value: haulier.id,
        label: haulier.name,
        isDisabled: this.isDisabled(haulier.id)
      };
    });
  }

  isDisabled(id) {
    const disabledList = this.state.siteHauliers.map(h => parseInt(h.id));

    return disabledList.includes(parseInt(id));
  }

  render() {
    return (
      <>
        <h2>Site Hauliers</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Short Code</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.siteHauliers
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map(h => (
                <tr key={h.id}>
                  <td>{h.name}</td>
                  <td>{h.shortCode}</td>
                  <td>
                    <Button
                      onClick={() => this.removeHaulier(h.id)}
                      size="tiny"
                      variant="primary"
                      title="Remove Haulier"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Formik
          enableReinitialize
          onSubmit={() => {
            const siteHauliers =
              this.state.siteHauliers.length > 0
                ? this.state.siteHauliers.map(h => {
                    return parseInt(h.id);
                  })
                : [];
            this.props.onSubmit(siteHauliers);
          }}
          initialValues={{}}
        >
          <Form className="site-details-form">
            <Field
              name="haulierList"
              label="Add Haulier"
              placeholder="Add Haulier"
              options={this.haulierList()}
              component={SelectBox}
              onChange={e => this.addHaulier(e.target.value)}
            />
            <div className="button-row">
              <Can when="settings.site.update">
                <Button
                  type="submit"
                  variant="primary"
                  size="small"
                  title="Save"
                />
              </Can>
              <Button
                variant="secondary"
                size="small"
                title="Cancel"
                onClick={() => this.props.returnHome()}
              />
            </div>
          </Form>
        </Formik>
      </>
    );
  }
}

export default SiteHaulierTable;
