import { createStore, applyMiddleware, combineReducers } from "redux";
import ReduxThunk from "redux-thunk";
import appReducer from "./redux/app";
import authReducer from "./redux/auth";
import sitesReducer from "./redux/sites";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  sites: sitesReducer
});

export default function configureStore(preloadedState) {
  return createStore(rootReducer, preloadedState, applyMiddleware(ReduxThunk));
}
