import { fetch } from "graphql-helper";

export function hauliers() {
  return fetch(`
    query {
      hauliers {
        id
        name
        shortCode
        standLevel
        manuallyCreated
        trailers {
          id
          trailerNo
          trailerType {
            id
            name
          }
        }
      }
      sites {
        id
        name
      }
    }
  `);
}

export function standLevels(haulierId) {
  return fetch(`query {
      standLevel(haulierId: ${haulierId}) {
        id
        site{
          id
          name
        }
        haulier{
          id
          name
        }
        standLevel
      }
    }`);
}

export function updateStandLevel(haulierId, siteId, standLevel) {
  return fetch(`
    mutation UpdateStandLevel {
      updateStandLevel(haulierId:${haulierId}, siteId: ${siteId}, standLevel:${standLevel}) {
        id
      }
    }
  `);
}

export function createHaulier({ name, shortCode }) {
  return fetch(`
    mutation CreateHaulier {
      haulier: createHaulier(name:"${name}", shortCode:"${shortCode}") {
        id
      }
    }
  `);
}

export function bookingReceipts(haulierId) {
  return fetch(`query {
    bookingReceipts(haulierId: ${haulierId}) {
    id
    email
    isActive
  }
}
`);
}

export function createBookingReceipt(haulierId, email) {
  return fetch(`
  mutation 
  {createBookingReceipt(haulierId: ${haulierId}, email:"${email}") {
    id
    email
    isActive
  }
}
`);
}

export function removeBookingReceipt(id) {
  return fetch(`
  mutation 
  {removeBookingReceipt(id: ${id}) {
    id
  }
}
`);
}

export function activateBookingReceipt(id) {
  return fetch(`
  mutation 
  {activateBookingReceipt(id: ${id}) {
    id
    email
    isActive
  }
}
`);
}

export function deactivateBookingReceipt(id) {
  return fetch(`
  mutation 
  {deactivateBookingReceipt(id: ${id}) {
    id
    email
    isActive
  }
}
`);
}
