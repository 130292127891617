import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "Button";
import Content from "Content";
import Can from "Can";
import LiveDataView from "LiveDataView";
import { distanceInWordsToNow } from "date-fns";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  movementsSubscription,
  prioritiseMovement
} from "./trafficMovementsQueries";
import "./TrafficMovementsOverview.css";
import dragIcon from "assets/drag-icon.svg";

function TrafficMovementsOverview({
  assignedMovements: inAssignedMovements,
  pendingMovements: inPendingMovements,
  handleCancel,
  handleComplete,
  permissions
}) {
  const [pendingMovements, updatePendingMovements] = useState(
    inPendingMovements
  );

  const [assignedMovements, updateAssignedMovements] = useState(
    inAssignedMovements
  );

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(
      pendingMovements,
      result.source.index,
      result.destination.index
    );

    const item = items[result.destination.index];
    if (result.destination.index === item.originalIndex) {
      items[result.destination.index].changed = false;
    } else if (!item.changed) {
      items[result.destination.index].changed = true;
      items[result.destination.index].originalIndex = result.source.index;
    }

    prioritiseMovement(item.id, result.destination.index).then(() => {
      updatePendingMovements(items);
    });
  };

  const canPrioritise = permissions.includes("traffic.movements.prioritise");

  return (
    <Content>
      <div className="dash-row">
        <div className="content-block">
          <h2>Shunter movements</h2>
          <p>
            Assigned and pending shunter movements are displayed below. Pending
            movements can be cancelled using the appropriate "Cancel" button.
          </p>
          <h3>Assigned movements</h3>

          <LiveDataView
            subscription={movementsSubscription(["assigned", "picked_up"])}
            onResult={({ movements }) => {
              updateAssignedMovements(movements);
            }}
          >
            {() => (
              <>
                {assignedMovements.length === 0 && (
                  <p>No movements currently assigned.</p>
                )}
                {assignedMovements.length !== 0 && (
                  <table>
                    <thead>
                      <tr>
                        <th>Trailer</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Device</th>
                        <th>Status</th>
                        <Can when="shunter.movements.update">
                          <th>Action</th>
                        </Can>
                      </tr>
                    </thead>
                    <tbody>
                      {assignedMovements.map(m => (
                        <tr key={m.id}>
                          <td>
                            {m.trailer.haulier.name} {m.trailer.trailerNo}
                          </td>
                          <td>
                            {m.trailer.zone && m.trailer.zone.name}{" "}
                            {m.trailer.dock && m.trailer.dock.number}
                          </td>
                          <td>
                            {m.location.zone.name}{" "}
                            {m.location.dock && m.location.dock.number}
                          </td>
                          <td>{m.device && m.device.deviceId}</td>
                          <td
                            className="status"
                            style={{ textTransform: "capitalize" }}
                          >
                            {m.status.replace(/_/g, " ")}
                          </td>
                          <Can when="shunter.movements.update">
                            <td>
                              <Button
                                title="Complete"
                                size="tiny"
                                variant="primary"
                                onClick={() => handleComplete(m.id)}
                                disabled={m.status === "cancelling"}
                              />
                            </td>
                          </Can>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </LiveDataView>

          <h3>Pending movements</h3>

          <p>
            <Can when="traffic.movements.prioritise">
              The movements below can be re-ordered to prioritise.
            </Can>{" "}
            Movements closer to the top of the list will be actioned first.
          </p>

          <LiveDataView
            subscription={movementsSubscription(["pending"])}
            onResult={({ movements }) => {
              updatePendingMovements(movements);
            }}
          >
            {() => (
              <>
                {pendingMovements.length === 0 && (
                  <p>No movements currently pending.</p>
                )}

                {pendingMovements.length > 0 && (
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                      {provided => (
                        <table
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="dnd-table"
                        >
                          <thead className="dnd-header">
                            <tr>
                              <th className="dnd-handle" />
                              <th>Trailer</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Requested</th>
                              <th>Status</th>
                              <Can when="traffic.movements.cancel">
                                <th>Action</th>
                              </Can>
                            </tr>
                          </thead>
                          <tbody>
                            {pendingMovements.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                                isDragDisabled={!canPrioritise}
                              >
                                {(provided, snapshot) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`dnd-row ${
                                      snapshot.isDragging
                                        ? "dnd-row--dragging"
                                        : item.changed
                                        ? "dnd-row--changed"
                                        : ""
                                    }`}
                                  >
                                    <td className="dnd-handle">
                                      <img
                                        src={dragIcon}
                                        style={{ height: "13px" }}
                                      />
                                    </td>
                                    <td>
                                      {item.trailer.haulier.name}{" "}
                                      {item.trailer.trailerNo}
                                    </td>
                                    <td>
                                      {item.trailer.zone &&
                                        item.trailer.zone.name}{" "}
                                      {item.trailer.dock &&
                                        item.trailer.dock.number}
                                    </td>
                                    <td>
                                      {item.location.zone.name}{" "}
                                      {item.location.dock &&
                                        item.location.dock.number}
                                    </td>
                                    <td>
                                      {distanceInWordsToNow(item.insertedAt)}
                                    </td>
                                    <td
                                      className="status"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item.status.replace(/_/g, " ")}
                                    </td>
                                    <Can when="traffic.movements.cancel">
                                      <td>
                                        <Button
                                          title="Cancel"
                                          size="tiny"
                                          variant="primary"
                                          onClick={() => handleCancel(item.id)}
                                          disabled={
                                            item.status === "cancelling"
                                          }
                                        />
                                      </td>
                                    </Can>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                          </tbody>
                          {provided.placeholder}
                        </table>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </>
            )}
          </LiveDataView>
        </div>
      </div>
    </Content>
  );
}

function mapStateToProps({ auth: { permissions } }) {
  return { permissions };
}

export default connect(mapStateToProps)(TrafficMovementsOverview);
