import React from "react";
import { connect } from "react-redux";
import AppTemplate from "templates/AppTemplate";

function BookingPageTemplate(props) {
  const innerNavItems = [{ title: "Calendar View", href: "/bookings" }];

  if (props.selectedSite) {
    innerNavItems.push({
      title: "List View",
      href: `/bookings/site/${props.selectedSite}/daily-bookings`,
      permission: "bookings.list.view"
    });
  }

  return (
    <AppTemplate
      filters={props.filters}
      innerNavItems={innerNavItems}
      className={props.className}
      showDatePicker={true}
      showDateToggle={props.showDateToggle}
      searchAllDates={props.searchAllDates}
      searchQuery={props.searchQuery}
      searchPlaceholder={props.searchPlaceholder}
      onSearchQueryChange={props.onSearchQueryChange}
      onSearchAllDatesChange={props.onSearchAllDatesChange}
    >
      {props.children}
    </AppTemplate>
  );
}

function mapStateToProps({ app: { selectedSite } }) {
  return { selectedSite };
}

export default connect(mapStateToProps)(BookingPageTemplate);
