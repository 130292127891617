import React, { useState, useEffect } from "react";
import Button from "Button";
import Confirm from "Confirm";
import Can from "Can";
import { Formik, Form, Field } from "formik";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SelectBox from "form-controls/SelectBox";
import { fetch, toArgList } from "graphql-helper";
import dragIcon from "assets/drag-icon.svg";

import { usePermission } from "hooks";

export default function DefaultParkingForm({ selectedSiteId }) {
  const [rules, setRules] = useState([]);
  const [zones, setZones] = useState([]);
  const [trailerTypes, setTrailerTypes] = useState([]);
  const canDragParkingRule = usePermission("settings.site.parkingRules.edit");

  useEffect(() => {
    loadRules();
  }, [selectedSiteId]);

  const loadRules = () => {
    fetch(`query {
      zones {
        id
        name
      }
      trailerTypes {
        id
        name
      }
      parkingRules(siteId: ${selectedSiteId}) {
        id
        loadStatus
        trailerType {
          id
          name
        }
        zone {
          id
          name
        }
      }
    }`).then(({ zones, trailerTypes, parkingRules }) => {
      setZones(zones);
      setTrailerTypes(trailerTypes);
      setRules(parkingRules);
    });
  };

  const zoneList = zones.map(({ id, name }) => ({ value: id, label: name }));
  const trailerTypeList = trailerTypes.map(({ id, name }) => ({
    value: id,
    label: name
  }));

  const deleteRule = id => {
    fetch(`
      mutation {
        deleteParkingRule(${toArgList({ id })}) {
          id
        }
      }`).then(() => loadRules());
  };

  const addRule = values => {
    fetch(
      `mutation {createParkingRule(${toArgList({
        ...values,
        siteId: selectedSiteId
      })}) {id}}`
    ).then(() => loadRules());
  };

  const prioritiseRule = (id, destination) => {
    fetch(`
      mutation {
        prioritiseParkingRule(id: ${id}, priority: ${destination}) {
          id
        }
      }`).then(() => loadRules());
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(rules, result.source.index, result.destination.index);

    items[result.destination.index].originalIndex = result.source.index;

    setRules(items);

    const item = items[result.destination.index];

    prioritiseRule(item.id, items.length - result.destination.index);
  };

  return (
    <>
      <h3>Default Parking Rules</h3>
      {rules.length === 0 && (
        <p>
          No default parking rules are currently set. Add new rules using the
          form below.
        </p>
      )}
      {rules.length > 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <table
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="dnd-table"
              >
                <thead className="dnd-header">
                  <tr>
                    <th className="dnd-handle" />
                    <th>Trailer Type</th>
                    <th>Load Status</th>
                    <th>Parking Zone</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {rules.map((r, index) => (
                    <Draggable
                      isDragDisabled={!canDragParkingRule}
                      key={r.id}
                      draggableId={r.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`dnd-row ${
                            snapshot.isDragging ? "dnd-row--dragging" : ""
                          }`}
                        >
                          <td className="dnd-handle">
                            <Can when="settings.site.parkingRules.edit">
                              <img src={dragIcon} style={{ height: "13px" }} />
                            </Can>
                          </td>
                          <td>{r.trailerType ? r.trailerType.name : "Any"}</td>
                          <td>{r.loadStatus || "Any"}</td>
                          <td>{r.zone.name}</td>
                          <td>
                            <Can when="settings.site.parkingRules.edit">
                              <Confirm title="Are you sure you want to delete the rule?">
                                {confirm => (
                                  <i
                                    className="material-icons"
                                    onClick={confirm(() => deleteRule(r.id))}
                                    style={{ cursor: "pointer" }}
                                  >
                                    cancel
                                  </i>
                                )}
                              </Confirm>
                            </Can>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                </tbody>
                {provided.placeholder}
              </table>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <Can when="settings.site.parkingRules.edit">
        <h3>Add Rule</h3>
        <Formik
          initialValues={{ loadStatus: "", trailerTypeId: "", zoneId: "" }}
          onSubmit={values => {
            addRule(values);
          }}
        >
          {({ touched, errors }) => (
            <Form>
              <Field
                name="loadStatus"
                label="Load status"
                placeholder="Any"
                options={[
                  { label: "Empty", value: "empty" },
                  { label: "Loaded", value: "loaded" }
                ]}
                component={SelectBox}
              />
              <Field
                name="trailerTypeId"
                label="Trailer Type"
                options={trailerTypeList}
                placeholder="Any"
                component={SelectBox}
                error={touched.trailerTypeId && errors.trailerTypeId}
              />
              <Field
                name="zoneId"
                label="Zone"
                options={zoneList}
                placeholder="Select Zone"
                component={SelectBox}
                error={touched.zoneId && errors.zoneId}
              />
              <Button
                type="submit"
                variant="primary"
                size="small"
                title="Add Rule"
                disabled={Object.keys(errors).length}
              />
            </Form>
          )}
        </Formik>
      </Can>
    </>
  );
}
