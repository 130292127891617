import { fetch } from "graphql-helper";

export function getAssignedMovement(deviceId) {
  return fetch(`
    mutation {
      assignedMovement(deviceId: "${deviceId}") {
        id
        status
        trailer {
          trailerNo
          haulier {
            shortCode
          }
          zone {
            id
            name
          }
          dock {
            id
            number
          }
        }
        location {
          zone {
            id
            name
          }
          dock {
            id
            number
          }
        }
        tipLocation {
          zone {
            id
            name
          }
          dock {
            id
            number
          }
        }
      }
    }
  `);
}

export function updateMovementStatus(id, status) {
  return fetch(`
    mutation {
      updateMovement(id: ${id}, status: "${status}") {
        id
      }
    }
  `);
}
