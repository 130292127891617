import React, { useState } from "react";
import SplashPage from "./SplashPage";
import AppTemplate from "templates/AppTemplate";
import Content from "Content";
import { updatePassword } from "api/accounts";
import { withRouter } from "react-router";
import UpdatePasswordForm from "./update-password-page/UpdatePasswordForm";

function UpdatePasswordPage({ history, location }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const params = new URLSearchParams(location.search);
  const handleUpdatePassword = values => {
    return updatePassword(values)
      .then(() => {
        history.push(params.get("redirect") || "/settings");
      })
      .catch(e => {
        setErrorMessage(e.response.data.errors[0]);
      });
  };

  if (params.get("reason") === "expired") {
    return (
      <SplashPage>
        <p className="splash-page__heading">Update</p>
        {params.get("reason") === "expired" && (
          <p className="thin">Your password has expired and must be updated</p>
        )}
        <p className="small-type center">
          Use the form below to update your password.
        </p>
        <UpdatePasswordForm
          errorMessage={errorMessage}
          updatePassword={handleUpdatePassword}
        />
      </SplashPage>
    );
  }

  return (
    <AppTemplate>
      <Content>
        <p className="splash-page__heading">Update Password</p>
        <p className="small-type center">
          Use the form below to update your password.
        </p>
        <UpdatePasswordForm
          errorMessage={errorMessage}
          updatePassword={handleUpdatePassword}
        />
      </Content>
    </AppTemplate>
  );
}
export default withRouter(UpdatePasswordPage);
