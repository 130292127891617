import React, { useEffect, useState } from "react";
import { fetch, toArgList } from "graphql-helper";
import { Formik, Form, Field } from "formik";
import TextInput from "form-controls/TextInput";
import Can from "Can";
import Confirm from "Confirm";
import Button from "Button";

const getLoadTypesQuery = (siteId) => `
  query {
    customLoadTypes(${toArgList({ siteId })}) {
      id
      name
      active
    }
  }
`;

const removeLoadTypeMutation = (id) => `
  mutation {
    removeLoadType(${toArgList({ id })}) {
      id
    }
  }
`;

const addLoadTypeMutation = (name, siteId) => `
  mutation {
    createLoadType(${toArgList({ name, siteId })}) {
      id
    }
  }
`;

export default function LoadTypeForm(props) {
  const [loadTypes, setLoadTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const reloadLoadTypes = (siteId) => {
    setIsLoading(true);
    fetch(getLoadTypesQuery(siteId)).then(({ customLoadTypes }) => {
      setLoadTypes(customLoadTypes);
      setIsLoading(false);
    });
  };

  const removeLoadType = (id) => {
    fetch(removeLoadTypeMutation(id)).then(() =>
      reloadLoadTypes(props.selectedSite)
    );
  };

  const addLoadType = (name) => {
    return fetch(addLoadTypeMutation(name, props.selectedSite)).then(() =>
      reloadLoadTypes(props.selectedSite)
    );
  };

  useEffect(() => {
    reloadLoadTypes(props.selectedSite);
  }, [props.selectedSite]);

  if (isLoading) {
    return "Loading";
  }

  return (
    <>
      <h2>Custom Load Types</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {loadTypes.map((lt) => (
            <tr ky={lt.id}>
              <td>{lt.name}</td>
              <td style={{ width: "112px" }}>
                <Confirm title="Are you sure?">
                  {(confirm) => (
                    <Button
                      onClick={confirm(() => removeLoadType(lt.id))}
                      size="tiny"
                      variant="primary"
                      icon="delete"
                      title="Remove"
                    />
                  )}
                </Confirm>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h2>Add Custom Load Type</h2>
      <Formik
        initialValues={{ name: "" }}
        onSubmit={(values, { setSubmitting }) => {
          addLoadType(values.name).then(() => setSubmitting(false));
        }}
      >
        <Form className="site-details-form">
          <Field
            name="name"
            label="Load Type"
            placeholder="Add Load Type"
            component={TextInput}
          />

          <Can when="settings.site.update">
            <Button type="submit" variant="primary" size="small" title="Save" />
          </Can>
        </Form>
      </Formik>
    </>
  );
}
