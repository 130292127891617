import React from "react";
import Button from "Button";
import Can from "Can";

const capitalise = str => str.charAt(0).toUpperCase() + str.slice(1);

const displayApplications = applications =>
  applications.map(x => capitalise(x.name)).join(", ");

export default function DeviceTable(props) {
  const deviceIcon = type =>
    ["smartphone", "tablet", "watch", "computer"].includes(type)
      ? type
      : "device_unknown";

  return (
    <table>
      <thead>
        <tr>
          <th>Device</th>
          <th>Applications</th>
          <th>Last accessed</th>
          <Can when="settings.device.revoke">
            <th>Revoke access</th>
          </Can>
        </tr>
      </thead>
      <tbody>
        {props.devices.map(d => (
          <tr key={d.id}>
            <td>
              <i className="material-icons">{deviceIcon(d.type)}</i>
              {d.name} {d.deviceId}
            </td>
            <td>{displayApplications(d.applications)}</td>
            <td>{d.lastAccessTime}</td>
            <Can when="settings.device.revoke">
              <td>
                <Button variant="primary" size="tiny" title="Revoke" />
              </td>
            </Can>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
