import axios from "axios";
const rootURL = "/api/accounts";

export function createAccount(details, token) {
  return axios.post(`${rootURL}`, { account: { ...details, token } });
}

export function getAccount(id) {
  axios.get(`${rootURL}/${id}`).then(result => {
    return result.data.data;
  });
}

export function fetchAllAccounts() {
  return axios.get(rootURL).then(result => {
    return result.data.data;
  });
}

export function updateUserAccount(account) {
  return axios.put(`${rootURL}/${account.id}`, { account }).then(result => {
    return result.data.data;
  });
}

export function deleteUserAccount(id) {
  return axios.delete(`${rootURL}/${id}`);
}

export function unlockAccount(accountId) {
  return axios
    .post(`/api/accounts/${accountId}/unlock`)
    .then(result => result.data.data);
}

export function resetPassword(token, password) {
  return axios
    .put(`/api/password_reset/${token}`, { password: password })
    .then(result => {
      return result.data.data;
    });
}

export function updatePassword(values) {
  return axios
    .post(`/api/accounts/update-password`, { ...values })
    .then(result => {
      return result.data.data;
    });
}

export function sendPasswordResetEmail(email) {
  return axios
    .post("/api/password_reset", { email: email.toLowerCase() })
    .then(result => {
      return result.data.data;
    });
}
