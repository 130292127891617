import React from "react";
import { addMessage } from "redux/app";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Formik, Form, Field } from "formik";
import { createTrailer, updateTrailer } from "api/trailers";
import Can from "Can";
import Confirm from "Confirm";
import Alert from "Alert";
import Button from "Button";
import { formatAsOptions } from "siteList";
import Loader from "Loader";
import { getTrailerOptionData } from "./trailer-admin-queries";
import Switch from "form-controls/Switch";

class TrailerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hauliers: [],
      sites: [],
      trailerTypes: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    getTrailerOptionData().then(({ sites, hauliers, trailerTypes }) => {
      this.setState({ sites, hauliers, trailerTypes, loading: false });
    });
  }

  get siteOptions() {
    return formatAsOptions(this.state.sites);
  }

  zoneOptions(siteId) {
    const site = this.state.sites.find(s => s.id === siteId);

    if (!site) {
      return [];
    }

    return site.zones.map(z => ({
      value: z.id,
      label: z.name,
      isDisabled: !z.isActive
    }));
  }

  dockOptions(siteId, zoneId) {
    const site = this.state.sites.find(s => s.id === siteId);

    if (!site) {
      return [];
    }

    const zone = site.zones.find(z => z.id === zoneId);

    if (!zone) {
      return [];
    }
    const { docks } = zone;

    docks.sort((a, b) => (a.number > b.number ? 1 : -1));

    return docks.map(d => ({
      value: d.id,
      label: d.number,
      isDisabled: !d.isActive
    }));
  }

  get trailerTypeOptions() {
    return this.state.trailerTypes.map(type => {
      return { value: type.id, label: type.name };
    });
  }

  get haulierOptions() {
    return this.state.hauliers.map(h => ({
      value: h.id,
      label: h.name
    }));
  }

  get title() {
    if (this.props.editing) {
      return "Edit Trailer";
    } else {
      return "Add Trailer";
    }
  }

  get subTitle() {
    if (this.props.editing) {
      return "Update an existing trailer";
    } else {
      return "Add a new trailer";
    }
  }

  get selectedTrailer() {
    return [...Object.entries(this.props.selectedTrailer)].reduce(
      (acc, [k, v]) => {
        if (v !== null) {
          acc[k] = v;
        }

        return acc;
      },
      {}
    );
  }

  get initialValues() {
    if (this.props.selectedTrailer) {
      return {
        isInternal: this.props.selectedTrailer.isInternal,
        ...this.selectedTrailer
      };
    } else {
      return {
        trailerNo: "",
        isInternal: false
      };
    }
  }

  handleSubmit(values, { setSubmitting }) {
    if (this.props.editing) {
      const { id } = this.props.selectedTrailer;
      updateTrailer(id, values).then(
        () => {
          this.props.dispatch(
            addMessage("success", "Trailer updated successfully")
          );
          setSubmitting(false);
          this.props.updateTrailers();
        },
        error => {
          if (error.response) {
            Object.values(error.response.data.errors).forEach(message => {
              this.props.dispatch(addMessage("error", message));
            });
          }
          setSubmitting(false);
        }
      );
    } else {
      createTrailer(values).then(
        () => {
          this.props.dispatch(
            addMessage("success", "Trailer created successfully")
          );
          setSubmitting(false);
          this.props.updateTrailers();
          this.props.back();
        },
        () => {
          setSubmitting(false);
        }
      );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    return (
      <div className="admin-details">
        <div className="admin-details-main">
          {this.props.editing && !this.selectedTrailer.zone && (
            <Alert
              type="error"
              message="This trailer is currently flagged as off-site"
            />
          )}
          <Formik
            enableReinitialize
            initialValues={this.initialValues}
            onSubmit={this.handleSubmit.bind(this)}
          >
            {({ handleSubmit, errors }) => (
              <Form className="admin-form">
                <div style={{ minWidth: "328px" }}>
                  <Field
                    name={`isInternal`}
                    label="Is internal trailer"
                    component={Switch}
                  />
                  <div className="button-col">
                    <Can
                      when={
                        this.props.editing
                          ? "settings.trailers.update"
                          : "settings.trailers.create"
                      }
                    >
                      <Confirm
                        title={
                          this.props.selectedTrailer.isInternal
                            ? "Is this trailer legal to be on road?"
                            : "Do you want to make this an internal trailer"
                        }
                      >
                        {confirm => (
                          <Button
                            variant="primary"
                            size="small"
                            title="Save changes"
                            onClick={confirm(() => {
                              handleSubmit();
                            })}
                            disabled={Object.keys(errors).length}
                          />
                        )}
                      </Confirm>
                    </Can>
                    <Button
                      variant="secondary"
                      size="small"
                      title="Cancel"
                      onClick={() => this.props.back()}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(TrailerForm));
