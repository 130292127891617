import { fetch } from "graphql-helper";

export function getSites() {
  return fetch(`query {
      sites {
        id
        name
        parentSite {
          id
          name
        }
      }
    }`);
}

export function zonesBySite(siteId) {
  return fetch(`query {
      zones(site: ${siteId}, includeVirtual: false, isActive: true) {
        id
        name
        type
        capacity
        isActive
        trailers {
          id
        }
      }
    }`);
}

export function cancelMovement(movementId) {
  return fetch(`mutation DeleteMovement {
      movement: deleteMovement(id: ${movementId}) {
        id
        status
      }
    }`);
}

export function completeMovement(movementId) {
  // Database constraint exists so we first need to set status to picked_up and then
  // once that transition has finished we can transition to the completed status
  return updateMovementStatus(movementId, "picked_up").then(() =>
    updateMovementStatus(movementId, "completed")
  );
}

// Utility function for above `completeMovement`, given a movement id and a new status
// it will updated the movement record with the new status if the backend rules allow.
function updateMovementStatus(id, status) {
  const query = `mutation MovementStatusTransition($id: ID!, $status: String!) {
    movement: updateMovement(id: $id, status: $status) { id, status }
  }`;

  return fetch(query, { id, status });
}

export function requestMovement(
  trailerId,
  zoneId,
  dockId,
  hasIssuesWithTrailer = false,
  trailerIssueNotes = ""
) {
  return fetch(`mutation CreateMovement {
      movement: createMovement(trailerId:${trailerId}, zoneId:${zoneId}, ${
    dockId ? `dockId: ${dockId}` : ""
  }, hasIssuesWithTrailer:${hasIssuesWithTrailer}, trailerIssueNotes:"${trailerIssueNotes}") {
        id
        status
        insertedAt
        updatedAt
        location {
          id
          zone {
            id
            name
            type
          }
          dock {
            id
            number
          }
        }
      }
    }`);
}

export function trailerMovementsById(trailerId) {
  return fetch(`query {
      sites {
        id
        name
        parentSite {
          id
          name
        }
      }
      allSites: restrictedSites {
        id
        name
        parentSite {
          id
          name
        }
      }
      trailer(id: ${trailerId}) {
          id
          haulier{
            id
            name
          }
          trailerType {
            id
            name
          }
          trailerNo
          zone {
            id
            name
            type
          }
          dock {
            id
            number
          }
        isInternal
        insertedAt
        updatedAt
        movedAt
        movements {
          id
          insertedAt
          updatedAt
          status
          location {
            zone {
              id
              name
              type
            }
            dock {
              id
              number
            }
          }
        }
        booking {
          id
          direction
          driverName
          status
          checkInCompleteAt
          numberOfPallets
        }
      }
    }`);
}

export function dockStatus(zoneId) {
  return fetch(`query
    {trailers: trailers(zone:${zoneId}) {
        id
        trailerNo
        zone {
          id
          name
          type
        }
        dock{
          id
          number
        }
        haulier {
          id
          name
        }
        movements {
          id
          status
        }
      }
      movements: movements(zone: ${zoneId}) {
          id
          trailer{
            trailerNo
          }
          status
          insertedAt
          updatedAt
          location {
            zone {
              id
              name
              type
            }
            dock {
              id
              number
            }
          }
        }
      dockList: zone(id: ${zoneId}) {
        id
        docks {
          id
          isActive
          number
        }
      }
    }`);
}

export function trailerMovementsByZone(zoneId) {
  return fetch(`query
    {trailers(zone:${zoneId}) {
        id
        trailerNo
        zone {
          id
          name
          type
        }
        dock{
          id
          number
        }
        haulier {
          id
          name
        }
        movements {
          id
          insertedAt
          updatedAt
          trailer{
            trailerNo
          }
          location {
            zone {
              id
              name
            }
            dock {
              id
              number
            }
          }
        }
      }
    }`);
}
