import axios from "axios";

export function generateDeviceRegistrationLink(siteId) {
  return axios.post("/api/devices/generate-token").then(({ data }) => {
    return {
      ...data,
      host: window.location.origin,
      siteId
    };
  });
}

export function registerDevice(deviceId, token) {
  return axios.post("/api/devices/register", { deviceId, token });
}
