import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Button.css";

export default function Button(props) {
  const className = classNames(
    "btn",
    `btn--${props.variant}`,
    props.className,
    {
      [`btn--${props.size}`]: props.size
    }
  );
  const type = props.type || "button";

  return (
    <button
      type={type}
      className={className}
      style={props.style}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.icon && <i className="material-icons">{props.icon}</i>}
      {props.title}
    </button>
  );
}

Button.propTypes = {
  title: PropTypes.string.isRequired
};
