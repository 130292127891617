import React from "react";
import AppTemplate from "templates/AppTemplate";
import Content from "Content";
import Loader from "Loader";
import { getAppointments } from "./atlas-error-page/atlas-queries";
import { format } from "date-fns";
import "./atlas-error-page/Atlas.css";

export default class DeviceManagementPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appointments: [],
      loading: true
    };
  }

  componentDidMount() {
    getAppointments().then(data => {
      this.setState({ appointments: data.appointments, loading: false });
    });
  }

  dataCheck(data) {
    for (const key in data) {
      if (!data.hasOwnProperty(key)) continue;

      data[key];
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <AppTemplate>
        <Content>
          <h3>Atlas Import Errors</h3>
          <div className="dash-row">
            <div className="content-block" style={{ flex: 1 }}>
              {this.state.appointments.length > 0 && (
                <table className="appt-table">
                  <thead>
                    <tr>
                      <th>Message Info</th>
                      <th>Errors</th>
                      <th>File</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.appointments.map(appt => (
                      <tr key={appt.id}>
                        <td>
                          <table className="appt-info">
                            <tr>
                              <td>Journey Reference: </td>
                              <td>{appt.journeyReference}</td>
                            </tr>
                            <tr>
                              <td>Nestle Data: </td>
                              <td>{appt.nestleData}</td>
                            </tr>
                            <tr>
                              <td>Inserted At: </td>
                              <td>
                                {appt.insertedAt
                                  ? format(
                                      appt.insertedAt,
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>Updated At: </td>
                              <td>
                                {appt.updatedAt
                                  ? format(
                                      appt.updatedAt,
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>Deleted At: </td>
                              <td>
                                {appt.deletedAt
                                  ? format(
                                      appt.deletedAt,
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>Status: </td>
                              <td>{appt.status}</td>
                            </tr>
                            <tr>
                              <td>Message Date: </td>
                              <td>
                                {appt.messageDatetime
                                  ? format(
                                      appt.messageDatetime,
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>Message Type: </td>
                              <td>{appt.messageType}</td>
                            </tr>
                            <tr>
                              <td>Yard Name: </td>
                              <td>{appt.yardName}</td>
                            </tr>
                            <tr>
                              <td>YMS: </td>
                              <td>{appt.yms}</td>
                            </tr>
                            <tr>
                              <td>Direction: </td>
                              <td>{appt.direction}</td>
                            </tr>
                          </table>
                        </td>

                        <td style={{ textTransform: "capitalize" }}>
                          {appt.errors
                            ? appt.errors.map(error => (
                                <div>{error.replace(/_/g, " ")}</div>
                              ))
                            : ""}
                        </td>
                        <td>{appt.file}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {this.state.appointments.length == 0 && <h3>No errors found </h3>}
            </div>
          </div>
        </Content>
      </AppTemplate>
    );
  }
}
