const UPDATE = "UPDATE";
const CLEAR_AUTH = "CLEAR_AUTH";
const CLEAR_REDIRECT = "CLEAR_REDIRECT";

export default function reducer(
  state = {
    token: "",
    isAuthenticated: false,
    permissions: [],
    allowedSites: [],
    pushTo: ""
  },
  action = {}
) {
  switch (action.type) {
    case UPDATE:
      return {
        ...action.data
      };
    case CLEAR_AUTH:
      return {
        token: "",
        isAuthenticated: false,
        permissions: [],
        allowedSites: [],
        pushTo: ""
      };
    case CLEAR_REDIRECT:
      return { ...state, pushTo: "" };
  }
  return state;
}

export function clearRedirect() {
  return { type: CLEAR_REDIRECT };
}

export function clearAuth() {
  return { type: CLEAR_AUTH };
}

export function updateAuth(
  token,
  isAuthenticated,
  permissions,
  allowedSites,
  pushTo
) {
  return {
    type: UPDATE,
    data: {
      token,
      isAuthenticated,
      permissions,
      allowedSites,
      pushTo
    }
  };
}
