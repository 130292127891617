import { fetch } from "graphql-helper";

const trailerFields = `
  id
  trailerNo
  insertedAt
  updatedAt
  movedAt
  status
  isInternal
  isUnavailable
  unavailabilityReason
  unavailabilityNotes
  returnDate
  isEmpty
  haulier {
    name
    shortCode
  }
  trailerType {
    name
  }
  zone {
    id
    name
    isActive
  }
  dock {
    id
    number
    isActive
  }
  booking {
    id
    journeyReference
    direction
    isOverweight
    status
    appointment {
      data
      loadNo
    }
    sendingSite {
      id
      name
      parentSite {
        id
        name
      }
    }
    receivingSite {
      id
      name
      parentSite {
        id
        name
      }
    }
  }
`;

export function trailerSubscription(siteId) {
  return `
        subscription {
          trailers(site:${siteId}) {
            ${trailerFields}
          }
        }
      `;
}

export function trailerOverview(args) {
  let argString = "";

  const validArgs = Object.entries(args).filter(([, v]) => v === 0 || v);
  if (validArgs.length) {
    argString = `(${validArgs.map(([k, v]) => `${k}: ${v}`).join()})`;
  }

  return fetch(`query {
      trailers ${argString} {
        ${trailerFields}
      }
      trailerTypes {
        id
        name
        baseType
      }
      hauliers {
        id
        name
      }
    }`);
}

export function allTrailers() {
  return fetch(`query {
      trailers {
        ${trailerFields}
      }
    }`);
}

export function allBookings(siteId) {
  return fetch(`query {
      bookings(siteId: ${siteId}, filterOldBookings: true) {
        id
        slotStart
        journeyReference
        direction
        origin
        status
        trailer {
          id
        }
        sendingSite {
          name
        }
        receivingSite {
          name
        }
        appointment {
          data
          loadNo
        }
      }
    }`);
}

export function transferBooking(bookingId, trailerId) {
  return fetch(`mutation {
      transferBooking(bookingId: ${bookingId}, trailerId: ${trailerId}) {
        id
      }
    }`);
}

export function updateBookingOverweightStatus(id, isOverweight) {
  return fetch(`
    mutation UpdateBooking {
      booking: updateBooking(id: ${id},
        changeset:{
          isOverweight: ${isOverweight}
        }) {
        id
      }
    }
  `);
}
