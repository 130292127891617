import axios from "axios";

export function attemptLogin({ email, password }) {
  return axios
    .post("/api/auth", {
      email,
      password,
    })
    .catch((error) => {
      if (error.response.status === 401) {
        throw error.response.data;
      }

      throw "An unknown error occurred";
    });
}

export function getPermissions() {
  return axios.post("/api/auth/permissions").catch((error) => {
    if (error.response.status === 401) {
      throw error.response.data;
    }

    throw "An unknown error occurred";
  });
}

export function logout() {
  return axios.post("/api/auth/logout");
}
