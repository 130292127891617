import { fetch, toArgList } from "graphql-helper";

export function bookingsWithTrailers(siteId) {
  return fetch(`
    query {
      bookings(skipIfTrailerIsNull: true, siteId: ${siteId}, excludeWithStatus: ["staged", "staged_arrived", "ready_for_driver"]) {
        ...bookingFields
      }
      parentBookings: bookings(skipIfTrailerIsNull: true, childSiteId: ${siteId}, excludeWithStatus: ["staged", "staged_arrived", "ready_for_driver"]) {
        ...bookingFields
      }
    }
    fragment bookingFields on Booking {
      slotStart
      journeyReference
      direction
      status
      numberOfPallets
      goods
      origin
      loadType
      checkInCompleteAt
      receivingSite {
        id
        name
      }
      sendingSite {
        id
        name
      }
      appointment {
        id
        data
        updatedAt
        messageDatetime
        yardName
        yms
        direction
        haulier
        trailerNo
        trailerType
        loadNo
        messageId
      }
      trailer(isAvailable: true) {
        id
        trailerNo
        status
        isUnavailable
        unavailabilityNotes
        unavailabilityReason
        returnDate
        movements {
          id
        }
        haulier {
          shortCode
        }
      }
      haulier {
        name
        shortCode
      }
      trailerOwner {
        name
        shortCode
      }
    }
  `);
}

export function createMovement(trailerId, zoneId, dockId) {
  return fetch(`
    mutation CreateMovement {
      createMovement(
        trailerId: ${trailerId}
        zoneId: ${zoneId}
        ${dockId ? `dockId: ${dockId}` : ""}
      ) {
        id
      }
     }
  `);
}

export function cancelMovement(movementId) {
  return fetch(`
    mutation DeleteMovement {
      deleteMovement(id:${movementId}) {
        id
      }
    }
  `);
}

export function updateBookingStatusByTrailer(trailerId, status) {
  return fetch(`
    mutation UpdateBookingStatusByTrailer {
      updateBookingStatusByTrailer(
        trailerId:${trailerId}
        status:"${status}"
      ) {
        id
      }
    }
  `);
}

export function clearTrailerFromBooking(trailerId) {
  return fetch(`
    mutation ClearTrailerFromBooking {
      clearTrailerFromBooking(
        trailerId:${trailerId}
      ) {
        id
      }
    }
  `);
}

export function updateBookingPallets(id, numberOfPallets) {
  return fetch(`
    mutation UpdateBookingPallets {
     updateBooking(id: ${id}, changeset: {numberOfPallets: ${numberOfPallets}}) {
       id
     }
   }
  `);
}

export function updateTrailerStatus(trailerId, status) {
  return fetch(`
    mutation UpdateTrailer {
      updateTrailerStatus(
        id:${trailerId}
        status:"${status}"
      ) {
        id
      }
    }
  `);
}

export function updateTrailerEmptyFlag(trailerId, isEmpty) {
  return fetch(`
    mutation {
      updateTrailerEmptyFlag(
        id:${trailerId}
        isEmpty:${isEmpty}
      ) {
        id
      }
    }
  `);
}

export function updateTrailerLocation(trailerId, zoneId, dockId) {
  const args = { id: trailerId, zoneId, dockId };

  return fetch(`
    mutation {
      updateTrailerLocation(${toArgList(args)}) {
        id
      }
    }
  `);
}

export function markTrailerOffsite(trailerId) {
  return fetch(`
    mutation {
      markTrailerOffsite(id: ${trailerId}) {
        id
      }
    }
  `);
}
