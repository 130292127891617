import React from "react";
import Alert from "Alert";

export default class FadingAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fadingOut: false
    };

    this.containerRef = React.createRef();
    this.displayDuration = 2000;
    this.fadeOutDuration = 1000;
  }

  componentDidMount() {
    window.clearTimeout(this.timer);

    this.containerRef.current.addEventListener("mouseover", () => {
      window.clearTimeout(this.timer);
    });

    this.containerRef.current.addEventListener("mouseout", () => {
      this.resetTimer();
    });

    this.resetTimer();
  }

  resetTimer() {
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.setState({ fadingOut: true });
      this.timer = window.setTimeout(() => {
        this.props.onDismiss();
      }, this.fadeOutDuration);
    }, this.displayDuration);
  }

  render() {
    return (
      <Alert
        type={this.props.type}
        message={this.props.message}
        onDismiss={this.props.onDismiss}
        fadingOut={this.state.fadingOut}
        containerRef={this.containerRef}
      />
    );
  }
}
