import React from "react";
import { addMonths } from "date-fns";
import PropTypes from "prop-types";
import Header from "./date-picker/Header";
import DayTable from "./date-picker/DayTable";
import "./DatePicker.css";

export default function DatePicker({
  selectedDate,
  onSelect,
  theme = "light"
}) {
  const handleSelectPreviousMonth = () => {
    onSelect(addMonths(selectedDate, -1));
  };

  const handleSelectNextMonth = () => {
    onSelect(addMonths(selectedDate, 1));
  };

  const handleSelectToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    onSelect(today);
  };

  return (
    <div className={`datepicker datepicker--${theme}`}>
      <Header
        selectedDate={selectedDate}
        selectPreviousMonth={handleSelectPreviousMonth}
        selectNextMonth={handleSelectNextMonth}
        onSelectToday={handleSelectToday}
        onSelectMonth={onSelect}
      />
      <DayTable selectedDate={selectedDate} onSelectDay={onSelect} />
    </div>
  );
}

DatePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired
};
