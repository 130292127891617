import React from "react";
import Booking from "./Booking";

export default function BookingLayout(props) {
  return (
    <div className="bookings">
      {props.data.bookingLayout.map((col, index) => (
        <div key={index} className="col">
          {col.map(item => (
            <Booking
              key={item.id}
              item={item}
              selectedDate={props.data.selectedDate}
              onClick={() => {
                if (item.haulier !== null || item.direction === "internal") {
                  props.onSelect(item.journeyReference);
                }
              }}
              selectedItem={props.journeyReference}
            />
          ))}
        </div>
      ))}
    </div>
  );
}
