import { connect } from "react-redux";

function Cannot({ permissions, when, children }) {
  if (!permissions) {
    return null;
  }

  const isPermitted = permissions.includes(when);

  if (!isPermitted) {
    return children;
  }

  return null;
}

function mapStateToProps({ auth: { permissions } }) {
  return { permissions };
}

export default connect(mapStateToProps)(Cannot);
