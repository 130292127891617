import React from "react";
import Modal from "Modal";
import Button from "Button";

export default function JourneyReferenceModal({ journeyReference, onClose }) {
  return (
    <Modal>
      <div className="modal">
        <div className="modal__content">
          <div className="modal__body">
            <p
              style={{
                textAlign: "center",
                fontSize: "5rem",
                lineHeight: "normal"
              }}
            >
              {journeyReference}
            </p>

            <p>
              The driver should quote the reference above when leaving the site.
            </p>

            <div className="modal__buttons">
              <Button
                variant="secondary"
                size="small"
                title="Close"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
