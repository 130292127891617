import React, { useState, useEffect } from "react";
import Can from "Can";
import Button from "Button";
import Confirm from "Confirm";
import YesNoCancel from "YesNoCancel";
import { trailerStatuses } from "trailerStatuses";
import { bookingStatuses } from "bookingStatuses";
import { isPending } from "movement-helper";
import {
  updateTrailerStatus,
  markTrailerOffsite,
  clearTrailerFromBooking
} from "../warehouseQueries";
import { fetch, withErrorHandler } from "graphql-helper";

function DockAction({
  trailer,
  dock,
  zone,
  onCancelMovement,
  onMoveTrailer,
  onRequestTrailer,
  releaseTrailer,
  incomingMovement,
  handleErrors
}) {
  const [trailerReleased, updateTrailerReleased] = useState(false);

  useEffect(() => {
    updateTrailerReleased(false);
  });

  const outgoingMovement =
    trailer && trailer.movements ? trailer.movements.find(isPending) : false;
  const booking = trailer && trailer.booking;

  if (!dock.isActive && !(incomingMovement || trailer)) {
    return "";
  } else if (dock.isUnmanned) {
    return (
      <>
        <i className="material-icons">info</i>Unmanned
      </>
    );
  } else if (!incomingMovement && !trailer) {
    return (
      <Can when="warehouse.zones.requestTrailer">
        <Button
          onClick={() => onRequestTrailer(dock.id, zone)}
          variant="secondary"
          size="tiny"
          title="Request trailer"
          disabled={
            dock.dockSafetyDevice &&
            dock.dockSafetyDevice.trafficLightStatus === "red" &&
            dock.dockSafetyDevice.errorState != "Connection Lost"
          }
        />
      </Can>
    );
  } else if (incomingMovement || outgoingMovement) {
    return (
      <Can when="warehouse.zones.cancelMovement">
        <Button
          onClick={() =>
            onCancelMovement(
              incomingMovement ? incomingMovement.id : outgoingMovement.id,
              zone
            )
          }
          variant="cancel"
          size="tiny"
          title="Cancel move"
          disabled={
            (incomingMovement && outgoingMovement) ||
            (incomingMovement && incomingMovement.status !== "pending") ||
            (outgoingMovement && outgoingMovement.status !== "pending")
          }
        />
      </Can>
    );
  } else if (trailer.status !== trailerStatuses.readyToMove) {
    const Body = ({ onClick }) => (
      <Can when="warehouse.zones.dockIsSafe">
        <Button
          onClick={onClick}
          variant="primary"
          size="tiny"
          title="Dock is safe"
          disabled={
            trailer.status !== trailerStatuses.actionComplete ||
            (dock.dockSafetyDevice &&
              dock.dockSafetyDevice.errorState != "Connection Lost")
          }
        />
      </Can>
    );

    const callback = () => {
      updateTrailerStatus(trailer.id, trailerStatuses.readyToMove, zone);
    };

    if (dock.dockSafetyDevice) {
      return (
        <Confirm title="Dock safety device disconnected. Are you sure the dock is safe?">
          {confirm => <Body onClick={confirm(callback)} />}
        </Confirm>
      );
    }

    return <Body onClick={callback} />;
  } else if (
    booking &&
    booking.direction == "internal" &&
    booking.status !== "inbound_in_progress"
  ) {
    return (
      <Can when="warehouse.zones.move">
        <YesNoCancel
          title="Return the trailer to the dock"
          description="The trailer will be tipped on an unmanned dock. Would you like to return the trailer to the current dock?"
        >
          {show => (
            <Button
              onClick={async () => {
                updateTrailerReleased(true);

                fetch(
                  `query { location: releaseTrailerLocation(trailerId: ${trailer.id}) { dock { isUnmanned } } }`
                ).then(({ location: { dock } }) => {
                  if (dock && dock.isUnmanned) {
                    show(
                      () => {
                        handleErrors(releaseTrailer(trailer.id, true));
                        updateTrailerReleased(false);
                      },
                      () => {
                        handleErrors(releaseTrailer(trailer.id, false));
                        updateTrailerReleased(false);
                      }
                    )(null);
                  } else {
                    handleErrors(releaseTrailer(trailer.id, false));
                  }
                });
              }}
              variant="primary"
              size="tiny"
              title="Release trailer"
              disabled={trailerReleased}
            />
          )}
        </YesNoCancel>
      </Can>
    );
  } else if (booking && booking.status === bookingStatuses.readyForDriver) {
    return (
      <Can when="warehouse.zones.move">
        <Confirm title="Please ensure the driver has removed the trailer from the dock.">
          {confirm => (
            <Button
              onClick={confirm(() => {
                markTrailerOffsite(trailer.id);
                if (booking.direction === "outbound" && trailer.isEmpty) {
                  clearTrailerFromBooking(trailer.id);
                }
              })}
              variant="primary"
              size="tiny"
              title="Driver off dock"
            />
          )}
        </Confirm>
      </Can>
    );
  }

  return (
    <Can when="warehouse.zones.move">
      <Button
        onClick={() => {
          onMoveTrailer(trailer.id, zone);
        }}
        variant="primary"
        size="tiny"
        title="Move trailer"
      />
    </Can>
  );
}

export default withErrorHandler(DockAction);
