import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import BookingPageTemplate from "./BookingPageTemplate";
import SelectSitePanel from "SelectSitePanel";

class BookingLandingPage extends Component {
  componentDidMount() {
    this.updateRoute(this.props.selectedSite);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedSite !== this.props.selectedSite) {
      this.updateRoute(this.props.selectedSite);
    }
  }

  updateRoute(id) {
    if (id !== null) {
      this.props.history.push({
        pathname: "/bookings/site/" + id + "/bookings"
      });
    } else {
      this.props.history.push({ pathname: "/bookings" });
    }
  }

  render() {
    return (
      <BookingPageTemplate>
        <SelectSitePanel />
      </BookingPageTemplate>
    );
  }
}

function mapStateToProps({ app: { selectedSite } }) {
  return { selectedSite };
}

export default withRouter(connect(mapStateToProps)(BookingLandingPage));
