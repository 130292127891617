import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addMessage } from "redux/app";
import * as Yup from "yup";
import { createZone, updateZone } from "api/zones";
import { Formik, Form, Field } from "formik";
import Alert from "Alert";
import Button from "Button";
import Can from "Can";
import SelectBox from "form-controls/SelectBox";
import Switch from "form-controls/Switch";
import TextInput from "form-controls/TextInput";
import Confirm from "Confirm";
import { formatAsOptions } from "siteList";
import { getSites } from "queries/sites";
import Loader from "Loader";

const zoneValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  startingBay: Yup.number()
    .integer("Starting Bay must be a round number")
    .positive("Number must be bigger than 0")
    .required("Please enter Starting Bay")
    .typeError("Starting Bay must be a number"),
  capacity: Yup.number()
    .integer("Capacity must be a round number")
    .positive("Number must be bigger than 0")
    .required("Please enter capacity")
    .typeError("Capacity must be a number"),
  type: Yup.string().required("Please select type"),
  isActive: Yup.string().required("Please set active status"),
  siteId: Yup.string().required("Please select a site")
});

const zoneTypes = [
  { value: "parking", label: "Parking Zone" },
  { value: "numbered_parking", label: "Numbered Parking Zone" },
  { value: "loading", label: "Loading Zone" },
  { value: "virtual", label: "Virtual Parking Zone" }
];

class ZoneForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      loading: true
    };
  }

  componentDidMount() {
    getSites().then(({ sites }) => {
      this.setState({ sites, loading: false });
    });
  }

  handleSubmit(values, { setSubmitting }) {
    if (this.props.isEditMode) {
      updateZone(values.id, values).then(
        response => {
          setSubmitting(false);
          this.props.dispatch(addMessage("success", "Zone updated"));
          this.props.handleResponse(response.data);
        },
        () => {
          this.props.dispatch(addMessage("error", "Failed to update zone"));
        }
      );
    } else {
      createZone(values).then(
        response => {
          setSubmitting(false);
          this.props.dispatch(addMessage("success", "Zone created"));
          this.props.handleResponse(response.data);
          this.props.returnHome();
        },
        () => {
          this.props.dispatch(addMessage("error", "Failed to create zone"));
        }
      );
    }
  }

  get initialValues() {
    return {
      name: "",
      startingBay: "",
      capacity: "",
      isActive: true,
      siteId: this.props.selectedZone ? this.props.selectedZone.site.id : "",
      ...this.props.selectedZone
    };
  }

  get siteList() {
    return formatAsOptions(this.state.sites);
  }

  render() {
    const { isEditMode, selectedZone, permissions } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={zoneValidationSchema}
        enableReinitialize
        onSubmit={this.handleSubmit.bind(this)}
      >
        {({ errors, touched, values }) => (
          <Form className="admin-form">
            <div>
              <Field
                name="name"
                label="Zone Name"
                component={TextInput}
                error={touched.name && errors.name}
                disabled={
                  !permissions.includes("settings.zones.details.updateName")
                }
              />
              <Field
                name="siteId"
                label="Site"
                options={this.siteList}
                placeholder="Select Site"
                component={SelectBox}
                error={touched.siteId && errors.siteId}
                disabled={
                  !permissions.includes("settings.zones.details.updateSite")
                }
              />
              <Field
                name="startingBay"
                label="Starting Bay Number"
                component={TextInput}
                error={touched.startingBay && errors.startingBay}
                disabled={
                  !permissions.includes(
                    "settings.zones.details.updateStartingBay"
                  )
                }
              />
              <Field
                name="capacity"
                label="Capacity"
                component={TextInput}
                error={touched.capacity && errors.capacity}
                disabled={isEditMode}
              />
              {values.type === "virtual" && (
                <Alert
                  type="warning"
                  message="Virtual zones can be used for system movements (Default parking, Off dock zone) but cannot be used for movements in the warehouse module."
                />
              )}
              <Field
                name="type"
                label="Zone Type"
                placeholder="Select Zone Type"
                options={zoneTypes}
                component={SelectBox}
                error={touched.type && errors.type}
                disabled={isEditMode}
              />
              <Field
                name="isActive"
                label="Active"
                component={Switch}
                disabled={
                  !permissions.includes("settings.zones.details.updateActive")
                }
              />
              {isEditMode &&
                ["loading", "numbered_parking"].includes(selectedZone.type) && (
                  <table className="zones-dock-table">
                    <thead>
                      <tr>
                        <th>Dock No.</th>
                        <th>Active</th>
                        <th>Unmanned</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedZone.docks.map((dock, index) => (
                        <tr key={index}>
                          <td>{dock.number}</td>
                          <td>
                            <Field
                              name={`docks[${index}].isActive`}
                              component={Switch}
                              disabled={
                                !permissions.includes(
                                  "settings.zones.details.updateDocks"
                                )
                              }
                            />
                          </td>
                          <td>
                            <Field
                              name={`docks[${index}].isUnmanned`}
                              component={Switch}
                              disabled={
                                !permissions.includes(
                                  "settings.zones.details.updateDocks"
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

              {selectedZone &&
                selectedZone.parkingRules &&
                selectedZone.parkingRules.length > 0 && (
                  <div style={{ margin: 20, marginBottom: 0 }}>
                    <Alert
                      type={"warning"}
                      message={`Warning! This zone is used for default parking rules on the following sites: ${selectedZone.parkingRules
                        .map(pr => pr.site.name)
                        .join(
                          ", "
                        )}. If you delete it, these rules will be deleted with it.`}
                    />
                  </div>
                )}
              <br />

              <div className="button-col">
                <Can
                  when={
                    isEditMode
                      ? "settings.zones.update"
                      : "settings.zones.create"
                  }
                >
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    title="Save"
                    disabled={Object.keys(errors).length}
                  />
                </Can>
                {isEditMode && (
                  <Can when="settings.zones.delete">
                    <Confirm title="Are you sure you want to delete the zone?">
                      {confirm => (
                        <Button
                          variant="white"
                          size="small"
                          title="Delete"
                          onClick={confirm(this.props.deleteZone)}
                        />
                      )}
                    </Confirm>
                  </Can>
                )}
                <Button
                  variant="secondary"
                  size="small"
                  title="Cancel"
                  onClick={() => this.props.returnHome()}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

function mapStateToProps({ sites, auth: { permissions } }) {
  return { sites, permissions };
}

export default connect(mapStateToProps)(withRouter(ZoneForm));
