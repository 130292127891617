import React from "react";
import Table from "Table";
import TrailerUnavailableIcon from "TrailerUnavailableIcon";
import Button from "Button";
import Can from "Can";
import RebookingIcon from "RebookingIcon";
import { dockStatus } from "trailerStatuses";
import {
  sendingSiteDescription,
  receivingSiteDescription,
  formatGoods
} from "booking-helper";
import { formatWithDefaultTz } from "date-helper";
import config from "../../../../config";

function status(t) {
  return t.booking ? dockStatus(t.status, t.booking.status) : "Empty";
}

export default function TrafficTrailersTable({
  trailers,
  handleEditTrailer,
  onLinkBooking,
  onShowBooking
}) {
  function LinkBookingButton({ trailerId }) {
    return (
      <Can when="traffic.trailers.linkBooking">
        <i
          className="material-icons"
          style={{
            verticalAlign: "bottom",
            cursor: "pointer"
          }}
          onClick={() => onLinkBooking(trailerId)}
        >
          add_box
        </i>
      </Can>
    );
  }

  function BookingLink({ trailer }) {
    return (
      <>
        {trailer.booking && (
          <div
            className="link"
            onClick={() => onShowBooking(trailer.booking.id)}
          >
            <RebookingIcon booking={trailer.booking} />
            {trailer.booking.journeyReference} ({trailer.booking.direction})
          </div>
        )}
        {!trailer.booking && !trailer.isUnavailable && (
          <LinkBookingButton trailerId={trailer.id} />
        )}
      </>
    );
  }

  const headers = [
    "",
    "Trailer",
    "Status",
    "Type",
    "Goods Type",
    "Location",
    "Last Move",
    "Booking",
    "Booking Time",
    "Sending Site",
    "Receiving Site",
    "Empty",
    ""
  ];

  const rows = trailers.map(t => [
    <TrailerUnavailableIcon trailer={t} />,
    `${t.haulier.shortCode} ${t.trailerNo}`,
    status(t),
    `${t.trailerType.name} ${t.isInternal ? "(internal)" : ""}`,
    (t.booking ? `${t.booking.loadType} ` : "") +
      ((t.booking && formatGoods(t.booking.goods || [])) || ""),
    (t.zone ? t.zone.name : "") + (t.dock ? ` - ${t.dock.number}` : ""),
    t.movedAt
      ? formatWithDefaultTz(t.movedAt, "DD/MM/YYYY HH:mm")
      : formatWithDefaultTz(t.insertedAt, "DD/MM/YYYY HH:mm"),
    <BookingLink trailer={t} />,
    t.booking
      ? formatWithDefaultTz(t.booking.slotStart, "DD/MM/YYYY HH:mm")
      : "",
    t.booking ? sendingSiteDescription(t.booking) : "",
    t.booking ? receivingSiteDescription(t.booking) : "",
    (t.booking && t.booking.direction === "outbound") || t.isEmpty === null
      ? "-"
      : t.isEmpty
      ? "Fully Emptied"
      : "Partially Emptied",
    config.FEATURE_LITE_VERSION ? (
      ""
    ) : (
      <Button
        variant="primary"
        size="tiny"
        title="Edit"
        onClick={() => handleEditTrailer(t)}
      />
    )
  ]);

  return <Table rows={rows} headers={headers}></Table>;
}

export { status };
