import React from "react";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";
import GlobalSiteSelect from "GlobalSiteSelect";
import DatePicker from "DatePicker";
import CustomControl from "form-controls/CustomControl";
import BaseCheckBox from "form-controls/BaseCheckBox";
import { selectDate } from "redux/app";
import InnerNavItem from "./InnerNavItem";

class InnerNavItems extends React.Component {
  render() {
    const bestMatch = this.props.innerNavItems.reduce((acc, { href }) => {
      if (this.props.match.url.startsWith(href) && href.length > acc.length) {
        return href;
      }

      return acc;
    }, "");

    const innerNavItems = this.props.innerNavItems.map((ni) => ({
      ...ni,
      selected: ni.href === bestMatch,
    }));

    const selectedNavItem = this.props.innerNavItems.find(
      (ni) => ni.href === bestMatch
    );

    const bestSecondaryMatch = selectedNavItem.secondaryNavItems
      ? selectedNavItem.secondaryNavItems.reduce((acc, { href }) => {
          if (
            this.props.match.url.startsWith(href) &&
            href.length > acc.length
          ) {
            return href;
          }

          return acc;
        }, "")
      : null;

    const secondaryNavItems = selectedNavItem.secondaryNavItems
      ? selectedNavItem.secondaryNavItems.map((ni) => ({
          ...ni,
          selected: ni.href === bestSecondaryMatch,
        }))
      : null;

    return (
      <>
        <nav className="inner-nav">
          <ul className="inner-nav__left inner-nav-list">
            {innerNavItems.map((ni, idx) => (
              <InnerNavItem {...ni} key={idx} />
            ))}
          </ul>
          <ul className="inner-nav__right inner-nav-list inner-nav-list--tight">
            {this.props.onSearchQueryChange && this.renderSearchInput()}
            {this.props.showDatePicker && !this.props.searchAllDates && (
              <li className="inner-nav-list__item">
                <CustomControl
                  theme="dark"
                  title={format(this.props.selectedDate, "dddd Do MMMM YYYY")}
                >
                  <DatePicker
                    selectedDate={this.props.selectedDate}
                    theme="dark"
                    onSelect={(date) => {
                      this.props.dispatch(selectDate(date));
                    }}
                  />
                </CustomControl>
              </li>
            )}
            <li className="inner-nav-list__item">
              {this.props.showDatePicker && this.props.showDateToggle && (
                <BaseCheckBox
                  field={{
                    value: this.props.searchAllDates,
                    onChange: (e) => {
                      this.props.onSearchAllDatesChange(e.target.checked);
                    },
                  }}
                  label="Search all dates"
                />
              )}
            </li>
            <li className="inner-nav-list__item">
              <GlobalSiteSelect />
            </li>
          </ul>
        </nav>
        {secondaryNavItems && (
          <nav className="secondary-nav">
            <ul className="secondary-nav__list">
              {secondaryNavItems.map((ni) => (
                <InnerNavItem {...ni} />
              ))}
            </ul>
          </nav>
        )}
      </>
    );
  }

  renderSearchInput() {
    return (
      <li className="inner-nav-list__item">
        <input
          className="search-input"
          placeholder={this.props.searchPlaceholder}
          value={this.props.searchQuery}
          onInput={(e) => this.props.onSearchQueryChange(e.target.value)}
        />
      </li>
    );
  }
}

export default withRouter(InnerNavItems);
