import React from "react";
import { chunk } from "lodash";
import {
  eachDay,
  isBefore,
  isSameDay,
  lastDayOfMonth,
  lastDayOfWeek,
  startOfMonth,
  startOfToday,
  startOfWeek
} from "date-fns";
import PropTypes from "prop-types";
import Week from "./Week";
import WeekLabels from "./WeekLabels";
import "./DayTable.css";

function buildTableRows(selectedDate) {
  const firstDay = startOfWeek(startOfMonth(selectedDate));
  const finalDay = lastDayOfWeek(lastDayOfMonth(selectedDate));

  const days = eachDay(firstDay, finalDay).map(date => ({
    day: date.getDate(),
    isCurrentMonth: date.getMonth() === selectedDate.getMonth(),
    isSelected: isSameDay(selectedDate, date),
    isToday: isSameDay(new Date(), date),
    isUnavailable: isBefore(date, startOfToday()),
    date
  }));

  return chunk(days, 7);
}

export default function DayTable(props) {
  const { selectedDate } = props;

  const rows = buildTableRows(selectedDate);

  return (
    <div className="day-table">
      <WeekLabels />
      {rows.map((row, index) => (
        <Week
          key={index}
          row={row}
          selectedDay={selectedDate.getDate()}
          onSelect={day => {
            props.onSelectDay(day.date);
          }}
        />
      ))}
    </div>
  );
}

DayTable.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired
};
