import React from "react";
import heatSensitive from "assets/heatSensitive.svg";
import expressLoad from "assets/expressLoad.svg";

export default function LoadIndicator({appointment}){
  const div = (<ReturnIndicatorDiv reference={parseForIndicators({appointment})}/>);
  return(div);
}

export function ReturnIndicatorDiv({reference}){
  switch(reference){
    case 1:
      return (
        <div style={{ color: "rgba(209, 73, 91, 1)" }}>
          <img src={heatSensitive} style={{ height: "25px" }} title="Heat Sensitive"/>
        </div>
      );
    case 2:
      return (
        <div style={{ color: "rgba(209, 73, 91, 1)" }}>
          <img src={expressLoad} style={{ height: "22px" }} title="Express Load"/>
        </div>
      );
    case 3:
      return(
        <div style={{ color: "rgba(209, 73, 91, 1)"}}>
          <img src={heatSensitive} style={{ height: "25px" }} title="Heat Sensitive"/>
          <img src={expressLoad} style={{ height: "22px" }} title="Express Load"/>
        </div>
      );

    default:
      return null;
  }
}

export function parseForIndicators({ appointment }) {
  if (!appointment) {
    return 0;
  }

  const isExpressLoad = appointment.data.load_type.toLowerCase().includes("express");
  const isHeatSensitive = appointment.data.heat_sensitive === "Y";

  if (isExpressLoad && isHeatSensitive) {
    return 3;
  }

  if (isExpressLoad) {
    return 2;
  }

  if (isHeatSensitive) {
    return 1;
  }

  return 0;
}
