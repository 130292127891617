export function formatGoods(goodsList) {
  if (!goodsList) {
    return "";
  }

  return goodsList
    .map(g => capitaliseFirstLetter(g.split(/(?=[A-Z])/).join(" ")))
    .join(", ");
}

function capitaliseFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function sendingSiteName(booking) {
  if (booking.sendingSite) {
    return booking.sendingSite.name;
  }

  if (booking.appointment) {
    return booking.appointment.data["sending_site_short_code"] || "";
  }

  return booking.origin || "";
}

function receivingSiteName(booking) {
  if (booking.receivingSite) {
    return booking.receivingSite.name;
  }

  if (booking.appointment) {
    return booking.appointment.data["receiving_site_short_code"] || "";
  }

  return booking.origin || "";
}

export function formatSite(site) {
  if (!site) {
    return "";
  }

  return site.parentSite ? site.parentSite.name + " - " + site.name : site.name;
}

export function sendingSiteDescription(booking) {
  return booking.sendingSite
    ? formatSite(booking.sendingSite)
    : booking.appointment
    ? booking.appointment.data.sending_site_description
    : booking.origin;
}

export function receivingSiteDescription(booking) {
  return booking.receivingSite
    ? formatSite(booking.receivingSite)
    : booking.appointment
    ? booking.appointment.data.receiving_site_description
    : booking.origin;
}

export function formatDestinations(booking) {
  const sendingName = sendingSiteName(booking);
  const receivingName = receivingSiteName(booking);

  return `${sendingName} → ${receivingName}`;
}

export function formatTrailer(booking) {
  const suffix =
    booking.appointment && booking.appointment.trailerNo
      ? ` - (${booking.appointment.trailerNo})`
      : "";

  return trailerDescription(booking) + suffix;
}

function trailerDescription(booking) {
  if (booking.trailer) {
    return `${booking.trailer.haulier.shortCode} - ${booking.trailer.trailerNo}`;
  } else if (booking.trailerOwner) {
    return booking.trailerOwner.shortCode;
  }
}

export function formatStatus({ direction, status, haulier, driverName }) {
  if (!status) {
    return "";
  }

  if (direction !== "internal" && haulier === null) {
    return "Not yet sold";
  }

  let formatted = capitaliseFirstLetter(status.replace(/_/g, " "));

  if (status === "new") {
    formatted = "Planned";
  }

  if (driverName) {
    return formatted + " (driver arrived)";
  }

  return formatted;
}
