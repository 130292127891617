import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addMessage } from "redux/app";
import { Field, Form, Formik } from "formik";
import { updateSite } from "./site-admin-queries";
import Can from "Can";
import Button from "Button";
import Loader from "Loader";
import SelectBox from "form-controls/SelectBox";
import "./DefaultSlotForm.css";

class DefaultSlotForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCopyFunctions: false,
      defaultSlotTimes: [],
      slotLoading: true,
      copyFrom: ""
    };
  }

  componentDidMount() {
    if (this.state.slotLoading) {
      this.setState({
        defaultSlotTimes: this.props.selectedSite.defaultSlotTimes,
        slotLoading: false
      });
    }
    document.body.addEventListener("click", event => {
      if (!event.target.parentNode.matches(".slot-copy")) {
        this.setState({ showCopyFunctions: false });
      }
    });
  }

  updateDefaultSlots(values) {
    const copyFrom = values.slots[values.copyFrom];
    const updatedSlots = values.slots.map((slots, idx) => {
      if (values.copyTo[idx]) {
        return copyFrom;
      } else {
        return slots;
      }
    });
    values.slots = updatedSlots;
    return values;
  }

  resetSlots(values) {
    values.slots = this.props.selectedSite.defaultSlotTimes;
    return values;
  }

  toggleCopy(id) {
    if (this.state.copyFrom === id) {
      this.setState({ showCopyFunctions: !this.state.showCopyFunctions });
    } else {
      this.setState({ copyFrom: id, showCopyFunctions: true });
    }
  }

  handleSubmit(values) {
    const result = {
      defaultSlots: values.slots.map(slot => slot.map(time => parseInt(time)))
    };

    updateSite(this.props.selectedSite.id, result).then(
      () => {
        this.props.dispatch(
          addMessage("success", "The default slots have been updated.")
        );
        this.props.updateSites();
      },
      () => {
        this.props.dispatch(
          addMessage("error", "Failed to save your changes.")
        );
      }
    );
  }

  copySlots(copyTo) {
    const defaultSlotTimes = this.state.defaultSlotTimes;
    defaultSlotTimes[copyTo] = defaultSlotTimes[this.state.copyFrom];
    this.setState({ defaultSlotTimes: defaultSlotTimes });
    this.toggleCopy(this.state.copyFrom);
    this.props.dispatch(
      addMessage(
        "success",
        "Time Slots Copied. Please remember to save your changes."
      )
    );
  }

  render() {
    if (this.state.slotLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const weekdays = [
      { value: 0, label: "Sunday" },
      { value: 1, label: "Monday" },
      { value: 2, label: "Tuesday" },
      { value: 3, label: "Wednesday" },
      { value: 4, label: "Thursday" },
      { value: 5, label: "Friday" },
      { value: 6, label: "Saturday" }
    ];
    const slots = [...Array(30).keys()];
    const numSlots = slots.reduce((acc, elem) => {
      acc[elem] = { value: parseInt(elem, 10), label: elem };
      return acc;
    }, []);
    const times = [...Array(24).keys()];

    const init = {
      slots: this.state.defaultSlotTimes
    };

    return (
      <div style={{ wordBreak: "keep-all" }}>
        <div className="default-slot-form-title">
          Manage the total concurrent bookings the site will allow per hour. You
          can override these limits on specific dates in the override screen.
        </div>
        <div className="default-slot-form-hint">Maximum Capacity per Hour</div>
        <Formik
          initialValues={init}
          enableReinitialize
          onSubmit={this.handleSubmit.bind(this)}
        >
          {() => (
            <Form className="default-slot-form">
              <table className="slot-config-table">
                <thead>
                  <tr>
                    <th>Time</th>
                    {weekdays.map(day => (
                      <th
                        key={day.value}
                        className="slot-config-copy"
                        onClick={() => this.toggleCopy(day.value)}
                      >
                        {day.label}{" "}
                        {this.state.showCopyFunctions &&
                          this.state.copyFrom === day.value && (
                            <span className="copy-menu">
                              <i className="material-icons copy-slot">
                                file_copy
                              </i>
                              <div className="slot-copy">
                                <strong>Copy To</strong>
                                {weekdays.map(({ value, label }, idx) => (
                                  <div
                                    className={
                                      idx === this.state.copyFrom
                                        ? "slot-copy-to-disabled"
                                        : "slot-copy-to"
                                    }
                                    onClick={() =>
                                      idx !== this.state.copyFrom
                                        ? this.copySlots(idx)
                                        : ""
                                    }
                                    value={value}
                                    id={idx}
                                  >
                                    {label}{" "}
                                  </div>
                                ))}
                              </div>
                            </span>
                          )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {times.map(key => (
                    <tr key={key}>
                      <td>{key < 10 ? "0" + key : key}:00</td>
                      {weekdays.map(day => (
                        <td key={`${key}.${day.value}`}>
                          <Field
                            name={"slots[" + day.value + "]" + key}
                            label=""
                            placeholder=""
                            options={numSlots}
                            component={SelectBox}
                            defaultValue="0"
                            noDefault={true}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="button-col">
                <Can when="settings.site.update">
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    title="Save"
                  />
                </Can>
                <Button
                  variant="secondary"
                  size="small"
                  title="Cancel"
                  onClick={() => this.props.returnHome()}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default connect()(withRouter(DefaultSlotForm));
