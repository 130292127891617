import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Formik, Form } from "formik";
import { format } from "date-fns";
import Button from "Button";
import Confirm from "Confirm";
import Loader from "Loader";
import AssignDockSafetyDeviceModal from "../site-admin-page/AssignDockSafetyDeviceModal";
import {
  availableDockSafetyDevicesForSite,
  assignSiteDockSafetyDevice,
  unassignSiteDockSafetyDevice
} from "./site-admin-queries";
import low from "assets/low.svg";
import medium from "assets/medium.svg";
import full from "assets/full.svg";

class DockDevices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showAssignDockSafetyDeviceModal: false,
      devices: []
    };
  }

  componentDidMount() {
    availableDockSafetyDevicesForSite(this.props.selectedSite.id).then(data => {
      this.setState({
        loading: false,
        devices: data.availableDockSafetyDevicesForSite
      });
    });
  }

  get initialValues() {
    return {
      isActive: true,
      siteId: this.props.selectedSite ? this.props.selectedSite.id : ""
    };
  }

  handleRemoveDevice(deviceId) {
    unassignSiteDockSafetyDevice(deviceId).then(() => {
      this.refreshDevices();
    });
  }

  handleAssignDevice(deviceId) {
    assignSiteDockSafetyDevice(deviceId, this.props.selectedSite.id).then(
      () => {
        this.refreshDevices();
      }
    );
  }

  refreshDevices() {
    availableDockSafetyDevicesForSite(this.props.selectedSite.id).then(data => {
      this.setState({ devices: data.availableDockSafetyDevicesForSite });
    });
  }

  toggleShowAssignDockSafetyDeviceModal() {
    this.setState({
      showAssignDockSafetyDeviceModal: !this.state
        .showAssignDockSafetyDeviceModal
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        {this.state.showAssignDockSafetyDeviceModal && (
          <AssignDockSafetyDeviceModal
            selectedSite={this.props.selectedSite}
            onClose={this.toggleShowAssignDockSafetyDeviceModal.bind(this)}
            onAssignDevice={this.handleAssignDevice.bind(this)}
          />
        )}
        <br />
        <Formik initialValues={this.initialValues} enableReinitialize>
          {() => (
            <Form className="dock-safety-device-table">
              <table className="zones-dock-table">
                <thead>
                  <tr className="zones-dock-table">
                    <th className="zones-dock-table">Device ID</th>
                    <th className="zones-dock-table">Last update sent</th>
                    <th className="zones-dock-table">Status</th>
                    <th className="zones-dock-table">Battery Level</th>
                    <th className="zones-dock-table">Errors</th>
                    <th className="zones-dock-table">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.devices.map((dockSafetyDevice, index) => (
                    <tr key={index}>
                      <td>{dockSafetyDevice.deviceId}</td>
                      <td>
                        {format(
                          dockSafetyDevice.lastMessageReceivedAt,
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </td>
                      <td>
                        {
                          <TrafficLight
                            status={dockSafetyDevice.trafficLightStatus}
                          />
                        }
                      </td>
                      <td>
                        {<BatteryIcon level={dockSafetyDevice.currentPower} />}
                      </td>
                      <td
                        style={
                          dockSafetyDevice.errorState != ""
                            ? { color: "rgba(209, 73, 91, 1)" }
                            : {}
                        }
                      >
                        {dockSafetyDevice.errorState != ""
                          ? dockSafetyDevice.errorState
                          : "-"}
                      </td>
                      <td>
                        <Confirm
                          title={
                            "The safety device will be removed from Site " +
                            this.props.selectedSite.name +
                            " and returned to available devices for all sites."
                          }
                        >
                          {confirm => (
                            <Button
                              size="tiny"
                              variant="cancel"
                              title="Unassign Device"
                              onClick={confirm(() =>
                                this.handleRemoveDevice(
                                  dockSafetyDevice.deviceId
                                )
                              )}
                            />
                          )}
                        </Confirm>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Form>
          )}
        </Formik>
        <br />
        <Button
          size="tiny"
          variant="primary"
          title="Assign New Device"
          style={{ width: "30%" }}
          onClick={() => this.toggleShowAssignDockSafetyDeviceModal()}
        />
      </>
    );
  }
}

function TrafficLight({ status }) {
  let color = "rgba(209, 73, 91, 1)";
  if (status == "green") {
    color = "rgba(143, 201, 58, 1)";
  }
  return (
    <div style={{ background: color }}>
      {status == "green" ? "GREEN" : "RED"}
    </div>
  );
}

function BatteryIcon({ level }) {
  if (level > 60) {
    return (
      <div style={{ color: "rgba(143, 201, 58, 1)" }}>
        <img src={full} style={{ height: "23px" }} /> {level}%
      </div>
    );
  } else if (level > 15 && level <= 60) {
    return (
      <div style={{ color: "rgba(254, 153, 32, 1)" }}>
        <img src={medium} style={{ height: "23px" }} /> {level}%
      </div>
    );
  } else
    return (
      <div style={{ color: "rgba(209, 73, 91, 1)" }}>
        <img src={low} style={{ height: "23px" }} /> {level}%
      </div>
    );
}

function mapStateToProps({ sites }) {
  return { sites };
}

export default connect(mapStateToProps)(withRouter(DockDevices));
