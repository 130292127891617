import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SelectSitePanel from "SelectSitePanel";
import Loader from "Loader";
import ListView from "./traffic-schedule-page/ListView";
import BookingPreviewModal from "BookingPreviewModal";
import "./traffic-schedule-page/TrafficSchedulePage.css";
import {
  getSiteBookings,
  updateBookingPriority
} from "./traffic-schedule-page/traffic-schedule-queries";
import TrafficPageTemplate from "./TrafficPageTemplate";
import { selectSite } from "redux/app";
import Content from "../../../components/Content";

class TrafficSchedulePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      orderedBookings: [],
      bookingsLoading: true,
      showingBookingPreviewModal: false,
      selectedBookingId: null,
      siteId: ""
    };
  }

  componentDidMount() {
    if (parseInt(this.props.match.params.siteId) !== this.props.selectedSite) {
      if (this.props.selectedSite === null && this.props.match.params.siteId) {
        this.props.dispatch(selectSite(this.props.match.params.siteId));
      } else if (this.props.selectedSite !== null) {
        this.updateRoute(this.props.selectedSite);
        this.fetchSiteBookings();
      } else {
        this.updateRoute();
        this.fetchSiteBookings();
      }
    } else {
      this.fetchSiteBookings();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedSite !== this.props.selectedSite ||
      prevProps.selectedDate !== this.props.selectedDate
    ) {
      if (this.props.selectedSite !== null) {
        this.fetchSiteBookings();
      } else if (this.props.match.params.siteId !== undefined) {
        this.props.dispatch(selectSite(this.props.match.params.siteId));
      }
    }
  }

  updateRoute(id) {
    if (id === undefined || id === "") {
      this.props.history.push({ pathname: "/traffic" });
    } else {
      this.props.history.push({
        pathname: `/traffic/schedule/site/${id}`
      });
    }
  }

  getBookingInfo(id, bookings) {
    return bookings.find(b => parseInt(b.id) === parseInt(id));
  }

  fetchSiteBookings() {
    if (this.props.selectedSite) {
      getSiteBookings(this.props.selectedSite).then(data => {
        let bookings = this.filterStagedBookings(data.bookings);
        const orderedBookings = [];
        if (
          data.priorityBookings &&
          data.priorityBookings.booking_priority.length > 0
        ) {
          data.priorityBookings.booking_priority.forEach(id => {
            const bookingInfo = this.getBookingInfo(id, bookings);
            if (bookingInfo) {
              orderedBookings.push(bookingInfo);
            }
            bookings = bookings.filter(b => parseInt(b.id) !== parseInt(id));
          });
        }
        this.setState({
          bookings: bookings,
          orderedBookings: orderedBookings,
          bookingsLoading: false,
          siteId: this.props.selectedSite
        });
      });
    }
  }

  filterStagedBookings(bookings) {
    return bookings.filter(
      booking =>
        booking.status !== "staged" &&
        booking.status !== "staged_arrived" &&
        booking.status !== "ready_for_driver"
    );
  }

  updateSchedule(orderedBookings) {
    updateBookingPriority(
      this.props.selectedSite,
      orderedBookings.map(b => parseInt(b.id))
    ).then(data => {
      this.setState({
        bookings: data.bookings,
        orderedBookings: orderedBookings
      });
    });
  }

  showBookingPreviewModal(id) {
    this.setState({ showingBookingPreviewModal: true, selectedBookingId: id });
  }

  closeBookingPreviewModal() {
    this.setState({ showingBookingPreviewModal: false });
  }

  render() {
    if (this.state.bookingsLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    return (
      <TrafficPageTemplate>
        {this.state.showingBookingPreviewModal && (
          <BookingPreviewModal
            onClose={this.closeBookingPreviewModal.bind(this)}
            bookingId={this.state.selectedBookingId}
          />
        )}
        {!this.props.selectedSite && <SelectSitePanel />}
        {this.props.selectedSite && (
          <Content>
            <div className="dash-row">
              <div className="content-block">
                <ListView
                  {...this.state}
                  updateSchedule={this.updateSchedule.bind(this)}
                  showBookingPreviewModal={this.showBookingPreviewModal.bind(
                    this
                  )}
                />
              </div>
            </div>
          </Content>
        )}
      </TrafficPageTemplate>
    );
  }
}

function mapStateToProps({ app: { selectedDate, selectedSite }, auth }) {
  return { selectedDate, selectedSite, auth };
}

export default withRouter(connect(mapStateToProps)(TrafficSchedulePage));
