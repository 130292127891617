import { fetch } from "graphql-helper";

export function bookingsBySiteId(siteId) {
  return fetch(`query {
      outbound: bookings (siteId: ${siteId}, direction: "outbound", filterOldBookings: true) {
        id
        journeyReference
        numberOfPallets
        origin
        direction
        slotStart
        slotEnd
        status
        driverName
        poNumber
        requiresTranship
        isRebooking
        loadType
        appointment {
          id
          data
          loadNo
        }
        haulier {
          id
          name
          shortCode
        }
        trailerOwner {
          id
          name
          shortCode
        }
        trailerType {
          id
          baseType
          type
          name
        }
        trailer {
          id
          trailerNo
          movedAt
          haulier {
            shortCode
          }
          zone {
            name
          }
          dock {
            number
          }
          movements {
            id
          }
        }
        allocations {
          id
        }
      }
      internal: bookings (siteId: ${siteId}, direction: "internal", filterOldBookings: true) {
        id
        journeyReference
        numberOfPallets
        origin
        direction
        slotStart
        slotEnd
        goods
        status
        driverName
        requiresTranship
        isRebooking
        appointment {
          id
          loadNo
        }
        haulier {
          id
          name
          shortCode
        }
        trailerOwner {
          id
          name
          shortCode
        }
        sendingSite {
          id
          name
        }
        receivingSite {
          id
          name
        }
        trailerType {
          id
          baseType
          type
          name
        }
        trailer {
          id
          trailerNo
          movedAt
          haulier {
            shortCode
          }
          zone {
            name
          }
          dock {
            number
          }
          movements {
            id
          }
        }
        allocations {
          id
        }
      }
      inbound: bookings (siteId: ${siteId}, direction: "inbound", filterOldBookings: true) {
        id
        journeyReference
        numberOfPallets
        origin
        direction
        slotStart
        slotEnd
        status
        driverName
        requiresTranship
        isRebooking
        loadType
        appointment {
          id
          data
          loadNo
        }
        haulier {
          id
          name
          shortCode
        }
        trailerOwner {
          id
          name
          shortCode
        }
        trailerType {
          id
          baseType
          type
          name
        }
        trailer {
          id
          trailerNo
          movedAt
          haulier {
            shortCode
          }
          zone {
            name
          }
          dock {
            number
          }
        }
        allocations {
          id
        }
      }
      site (id: ${siteId}) {
        id
        name
      }
    }`);
}

export function trailersByHaulierAndType(
  haulierId,
  trailerType,
  includeInternal
) {
  return fetch(`query {
      trailers(bookingTrailerType: "${trailerType}", includeInternal: ${includeInternal}${
    haulierId ? `, haulier: ${haulierId}` : ""
  }, withoutBookings:true) {
        id
        movedAt
        insertedAt
        isInternal
        isUnavailable
        unavailabilityReason
        unavailabilityNotes
        returnDate
        haulier {
          name
          shortCode
        }
        trailerType {
          name
        }
        zone {
          name
          type
          site {
            id
          }
        }
        dock {
          number
        }
        trailerNo
      }
    }`);
}

export function assignTrailerToBooking(bookingId, trailerId) {
  return fetch(`
    mutation UpdateBooking {
      updateBooking(id:${bookingId}, changeset: {trailerId:${trailerId}}) {
        id
      }
    }`);
}

export function unassignTrailerFromBooking(bookingId) {
  return fetch(`
    mutation UpdateBooking {
      updateBooking(id:${bookingId}, changeset: {trailerId: null}) {
        id
      }
    }`);
}
