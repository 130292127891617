import React from "react";

export default function RebookingIcon({ booking }) {
  if (booking.requiresTranship) {
    return (
      <i
        className="material-icons"
        style={{
          fontSize: "19px",
          verticalAlign: "text-bottom"
        }}
        title="Requires tranship"
      >
        swap_horiz
      </i>
    );
  }

  if (!booking.isRebooking) {
    return "";
  }

  return (
    <i
      className="material-icons"
      style={{
        fontSize: "19px",
        verticalAlign: "text-bottom"
      }}
      title="This journey is a Delivery Failure/Refusals"
    >
      event_note
    </i>
  );
}
