import { isFuture, distanceInWordsToNow } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";

export function formatRelativeDate(date) {
  if (isFuture(date)) {
    return `in ${distanceInWordsToNow(date)}`;
  }

  return `${distanceInWordsToNow(date)} ago`;
}

export function formatLocalDate(date) {
  return formatWithDefaultTz(date, "DD/MM/YYYY HH:mm");
}

export function isDate(value) {
  return /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/.test(value);
}

export function formatWithDefaultTz(date, format) {
  return formatToTimeZone(date, format, { timeZone: "Europe/London" });
}
