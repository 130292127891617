import axios from "axios";
const rootURL = "/api/invites";

export function createInvite(details) {
  return axios.post(`${rootURL}`, { invite: { ...details } });
}

export function getInvite(id) {
  axios.get(`${rootURL}/${id}`).then(result => {
    return result.data.data;
  });
}

export function fetchAllInvites() {
  return axios.get(rootURL).then(result => {
    return result.data.data;
  });
}

export function resendInvite(id) {
  return axios.put(`${rootURL}/${id}`).then(result => {
    return result.data.data;
  });
}
export function deleteInvite(id) {
  return axios.delete(`${rootURL}/${id}`).then(result => {
    return result.data;
  });
}
