import { fetch } from "graphql-helper";

// get rid of skip if trailer is null and replace with driver on site

export function bookingsOnSite() {
  return fetch(`query {
      bookings(driverOnSite: true, recentlyUpdated: true) {
        id
        vehicleReg
        journeyReference
        driverName
        driverLanguage
        status
        direction
        trailer {
          id
          zone {
            name
          }
          dock {
            number
          }
        }
      }
    }`);
}

export function flightBoardSubscription() {
  return `
    subscription {
      bookings: flightboard(driverOnSite: true, recentlyUpdated: true) {
        id
        vehicleReg
        journeyReference
        driverName
        driverLanguage
        status
        direction
        trailer {
          id
          zone {
            name
          }
          dock {
            number
          }
        }
      }
    }
  `;
}
