import React from "react";
import Modal from "Modal";
import Button from "Button";

export default function ViewCommentModal({ trailer, comment, onClose }) {
  return (
    <Modal>
      <div className="modal">
        <div className="modal__content">
          <div className="modal__body">
            <p
              style={{
                paddingBottom: "8px"
              }}
            >
              Comments for{" "}
              <b>
                {trailer.trailerNo} / {trailer.zone.name}
                {trailer.dock ? ` / Dock ${trailer.dock.number}` : ""}
              </b>
            </p>
            <p
              style={{
                borderRadius: "4px",
                background: "#eee",
                padding: "15px 35px",
                maxWidth: "500px"
              }}
            >
              {comment}
            </p>

            <div className="modal__buttons">
              <Button
                variant="secondary"
                size="small"
                title="Close"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
