import React from "react";
import { Socket as PhoenixSocket } from "phoenix";
import * as AbsintheSocket from "@absinthe/socket";

export default class LiveDataView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      loading: true
    };

    const host =
      process.env.NODE_ENV === "development" // eslint-disable-line
        ? "ws://localhost:4000/socket"
        : "/socket";

    this.absintheSocket = AbsintheSocket.create(new PhoenixSocket(host, {}));

    this.notifier = AbsintheSocket.send(this.absintheSocket, {
      operation: props.subscription,
      variables: {}
    });
  }

  componentDidMount() {
    this.observer = AbsintheSocket.observe(this.absintheSocket, this.notifier, {
      onError: this.onError.bind(this),
      onResult: this.onResult.bind(this)
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscription !== this.props.subscription) {
      AbsintheSocket.cancel(this.absintheSocket, this.notifier);

      this.notifier = AbsintheSocket.send(this.absintheSocket, {
        operation: this.props.subscription,
        variables: {}
      });

      this.observer = AbsintheSocket.observe(
        this.absintheSocket,
        this.notifier,
        {
          onError: this.onError.bind(this),
          onResult: this.onResult.bind(this)
        }
      );
    }
  }

  componentWillUnmount() {
    AbsintheSocket.cancel(this.absintheSocket, this.notifier);
  }

  onError() {
    AbsintheSocket.cancel(this.absintheSocket, this.notifier);
  }

  onResult({ data }) {
    if (this.props.onResult) {
      this.props.onResult(data, this.state.data);
    }

    this.setState({ data });
  }

  render() {
    return this.props.children(this.state);
  }
}
