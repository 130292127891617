import { fetch } from "graphql-helper";
const siteFields = `
        id
        journeyReference
        numberOfPallets
        loadType
        origin
        direction
        slotStart
        slotEnd
        status
        insertedAt
        driverName
        appointment {
          id
          data
          updatedAt
          messageDatetime
          yardName
          yms
          direction
          haulier
          trailerNo
          trailerType
          loadNo
          messageId
        }
        sendingSite {
          id
          name
          parentSite {
            id
            name
          }
        }
        receivingSite {
          id
          name
          parentSite {
            id
            name
          }
        }
        reasonDeleted
        updatedAt
        vehicleReg
        poNumber
        notes
        allocations {
          id
          order
          zone {
            id
            name
            type
            site {
              id
              name
            }
          }
          dock {
            id
            number
          }
        }
        haulier {
          id
          name
          shortCode
        }
        trailerOwner {
          id
          name
          shortCode
        }
        trailerType {
          id
          baseType
          type
          name
        }
        trailer {
          id
          trailerNo
          haulier {
            id
            name
            shortCode
          }
          zone {
            id
            name
            type
          }
          dock {
            id
            number
          }
        }
`;
export function getZones(siteId) {
  return fetch(`query {
      zones (site: ${siteId}) {
        id
        name
        type
        docks {
          id
          number
          isActive
        }
      }
    }`);
}

export function getSites() {
  return fetch(`
    query {
      sites {
        id
        name
        parentSite{
          id
          name
        }
      }
    }
  `);
}

export function updateBookingPriority(siteId, bookingList) {
  return fetch(`mutation UpdateBookingPriority {
        bookings: updatePriorityBookings(siteId: ${siteId}, bookingPriority: [${bookingList}]) {
          ${siteFields}
       }
    }`);
}

export function getSiteBookings(siteId) {
  return fetch(`query {
      priorityBookings(siteId: ${siteId}) {
        booking_priority
      }
      
      bookings(siteId: ${siteId}, filterOldBookings: true) {
        ${siteFields}
      }
    }`);
}
