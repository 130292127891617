import axios from "axios";

export function listTrailers() {
  return axios.get("/api/trailers").then(res => {
    return res.data.data;
  });
}

export function getTrailersBySite(siteId) {
  return axios
    .get("/api/trailers", {
      params: {
        siteId: siteId
      }
    })
    .then(res => {
      return res.data.data;
    });
}

export function createTrailer(trailer) {
  return axios
    .post("/api/trailers", { trailer })
    .then(result => result.data.data);
}

export function updateTrailer(trailerId, trailer) {
  return axios.put(`/api/trailers/${trailerId}`, { trailer });
}

export function deleteTrailer(trailerId) {
  return axios.delete(`/api/trailers/${trailerId}`);
}
