import React from "react";
import { connect } from "react-redux";
import { addMessage } from "redux/app";
import { withRouter } from "react-router";
import { Formik, Field, Form } from "formik";
import { format, getISODay } from "date-fns";
import SelectBox from "form-controls/SelectBox";
import Button from "Button";
import Can from "Can";
import Loader from "Loader";
import DatePicker from "DatePicker";
import TextInput from "form-controls/TextInput";
import "./SlotConfigForm.css";
import { getSiteSlots, updateSiteSlots } from "./site-admin-queries";

class SlotConfigForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slotOverrides: [],
      isLoading: true,
      slotLoading: true,
      pickDate: false,
      selectedDate: "",
      selectedDay: ""
    };
  }

  componentWillMount() {
    const { selectedDate } = this.props;
    this.getSlotConfig(selectedDate);

    document.body.addEventListener("click", event => {
      this.hideDatePicker(event);
    });
  }

  componentDidUpdate(prevProps) {
    const { selectedDate, selectedSite } = this.props;
    if (
      prevProps.selectedDate !== selectedDate ||
      prevProps.selectedSite !== selectedSite
    ) {
      this.getSlotConfig(selectedDate);
    }

    if (this.props.site !== undefined && prevProps.site !== this.props.site) {
      this.getSlotConfig(selectedDate);
    }
  }

  getSlotConfig(date) {
    getSiteSlots(this.props.selectedSite.id, date).then(data => {
      const day = getISODay(format(date, "YYYY-MM-DDTHH:mm:ss+00"));
      const defaultSlots = [...this.props.selectedSite.defaultSlotTimes[day]];

      this.setState({
        slotOverrides:
          data.siteSlots === null ? defaultSlots : data.siteSlots.slotTimes,
        isLoading: false,
        selectedDate: date,
        selectedDay: day,
        pickDate: false,
        slotLoading: false
      });
    });
  }

  hideDatePicker(event) {
    if (
      this.state.pickDate &&
      !event.target.parentNode.matches(".month-picker") &&
      !event.target.parentNode.matches(".datepicker__header") &&
      !event.target.parentNode.matches(".day-table__row") &&
      !event.target.parentNode.matches(".datepicker__day")
    ) {
      this.setState({ pickDate: false });
    }
  }

  clearOverride(slot) {
    const revertedSlotTime = this.props.selectedSite.defaultSlotTimes[
      this.state.selectedDay
    ][slot];
    const overrides = this.state.slotOverrides;
    overrides[slot] = revertedSlotTime;
    this.setState({ slotOverrides: overrides });
  }

  render() {
    if (this.state.isLoading || this.state.slotLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const times = [[], [], [], [], [], []];
    for (let i = 0; i < 24; i++) {
      const index = i % 6;
      times[index].push(i);
    }

    const initialValues = {
      slots:
        this.state.slotOverrides.length > 0
          ? this.state.slotOverrides
          : this.props.selectedSite.defaultSlotTimes[this.state.selectedDay],
      date: format(this.state.selectedDate, "Do MMMM YYYY")
    };

    const slots = [...Array(30).keys()];

    const numSlots = slots.reduce((acc, elem) => {
      acc[elem] = { value: elem, label: elem };
      return acc;
    }, []);

    return (
      <div className="slot-config-main">
        <div className="slot-config-form-title">
          Override the total concurrent bookings per hour on a particular day.
          Select a day from the calendar and set any slots which will be
          different from the current default.
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={values => {
            updateSiteSlots(
              this.props.selectedSite.id,
              this.state.selectedDate,
              values.slots
            ).then(
              data => {
                this.setState({
                  slotOverrides: data.updateSiteSlots.slotTimes
                });
                this.props.dispatch(
                  addMessage(
                    "success",
                    "Time slot overrides have been updated."
                  )
                );
              },
              () => {
                this.props.dispatch(
                  addMessage("error", "Failed to save your changes.")
                );
              }
            );
          }}
        >
          {({ setFieldValue }) => (
            <Form className="slot-config-form">
              <div className="slot-date-select">
                <Field
                  name="date"
                  label="Select a date"
                  component={TextInput}
                  onClick={() => this.setState({ pickDate: true })}
                />
              </div>
              <div className="slot-clear-button">
                <Button
                  variant="secondary"
                  size="small"
                  title="Closed/Set to 0"
                  onClick={() => {
                    setFieldValue("slots", Array(24).fill(0));
                  }}
                />
              </div>
              <div className="slot-config-form-hint">
                Select a date in the future to override
              </div>
              {this.state.pickDate && (
                <div className="date-picker-modal">
                  <DatePicker
                    minimized={true}
                    selectedDate={this.state.selectedDate}
                    onSelect={date => {
                      this.getSlotConfig(date);
                    }}
                  />
                </div>
              )}
              <table className="slot-config-table">
                <thead>
                  <tr>
                    <th>Dawn</th>
                    <th>Morning</th>
                    <th>Afternoon</th>
                    <th>Evening</th>
                  </tr>
                </thead>
                <tbody>
                  {times.map(col => (
                    <tr key={col}>
                      {col.map(item => (
                        <td
                          key={`${col}.${item}`}
                          className={
                            this.state.slotOverrides[item] !== undefined &&
                            this.state.slotOverrides[item] !==
                              this.props.selectedSite.defaultSlotTimes[
                                this.state.selectedDay
                              ][item]
                              ? "slot-override"
                              : "time-slot"
                          }
                          onClick={() =>
                            this.state.slotOverrides[item] !== undefined &&
                            this.state.slotOverrides[item] !==
                              this.props.selectedSite.defaultSlotTimes[
                                this.state.selectedDay
                              ][item]
                              ? this.clearOverride(item)
                              : ""
                          }
                        >
                          <strong>{item < 10 ? "0" + item : item}:00</strong>
                          <Field
                            name={`slots[${item}]`}
                            label=""
                            placeholder=""
                            options={numSlots}
                            component={SelectBox}
                            defaultValue="0"
                            noDefault={true}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="button-col">
                <Can when="settings.site.update">
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    title="Save"
                  />
                </Can>
                <Button
                  variant="secondary"
                  size="small"
                  title="Cancel"
                  onClick={() => this.props.returnHome()}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default connect()(withRouter(SlotConfigForm));
