import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isBefore, isAfter } from "date-fns";
import {
  sendingSiteDescription,
  receivingSiteDescription,
  formatTrailer
} from "booking-helper";
import Can from "Can";
import Loader from "Loader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./TrafficSchedulePage.css";
import dragIcon from "assets/drag-icon.svg";
import { formatWithDefaultTz } from "date-helper";

class ListView extends Component {
  constructor(props) {
    super(props);
    this.isItOverdue = this.isItOverdue.bind(this);
    this.state = {
      items: this.props.bookings,
      ordered: this.props.orderedBookings
    };
  }

  /**
   * Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    unordered: "items",
    ordered: "ordered"
  };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items =
        source.droppableId === "unordered"
          ? this.orderBySlotTime(this.getList(source.droppableId))
          : this.reorderItems(
              this.getList(source.droppableId),
              source.index,
              destination.index
            );

      let state = { items };

      if (source.droppableId === "ordered") {
        this.props.updateSchedule(items);
        state = { ordered: items };
      }

      this.setState(state);
    } else {
      const result = this.moveItems(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      if (destination.droppableId === "unordered") {
        result.unordered = this.orderBySlotTime(result.unordered);
      }
      if (
        source.droppableId === "ordered" ||
        destination.droppableId === "ordered"
      ) {
        this.props.updateSchedule(result.ordered);
      }

      this.setState({
        items: result.unordered,
        ordered: result.ordered
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.siteId !== this.props.siteId) {
      this.setState({
        items: this.props.bookings,
        ordered: this.props.orderedBookings
      });
    }
  }

  orderBySlotTime(list) {
    const result = Array.from(list);
    return result.sort((a, b) => (a.slotStart > b.slotStart ? 1 : -1));
  }

  reorderItems(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  moveItems(source, destination, droppableSource, droppableDestination) {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  }

  showBookingPreviewModal(booking) {
    this.props.showBookingPreviewModal(booking.id);
  }

  isItOverdue(slotStart, slotEnd, updated_at, status) {
    const now = new Date();
    return (
      (isBefore(updated_at, slotEnd) && isAfter(updated_at, slotStart)) ||
      isBefore(now, slotStart) ||
      status != null
    );
  }

  getAllocation(booking, index) {
    if (!booking.allocations[index]) {
      return "-";
    }

    const allocation = booking.allocations.find(
      al => parseInt(al.order) === index
    );

    if (!allocation) {
      return "-";
    }

    let string = "";
    string = allocation.zone ? allocation.zone.name : string;
    string += allocation.dock ? ` - ${allocation.dock.number}` : "";
    return string;
  }

  get canPrioritise() {
    return this.props.permissions.includes("traffic.schedule.prioritise");
  }

  render() {
    if (this.props.bookingsLoading || this.props.loading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }
    return (
      <div className="list-view">
        <h2>Operations Schedule</h2>
        <h5>Priority List: priority is set by order of items.</h5>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="ordered">
            {provided => (
              <table ref={provided.innerRef} className="dnd-table">
                <thead className="dnd-header">
                  <tr>
                    <Can when="traffic.schedule.prioritise">
                      <th className="dnd-handle" />
                    </Can>
                    <th>Start</th>
                    <th>Trailer</th>
                    <th>Load Type</th>
                    <th>Pallets</th>
                    <th>Reference</th>
                    <th>Direction</th>
                    <th>Sending Site</th>
                    <th>Receiving Site</th>
                    <th>Reg</th>
                    <th>Status</th>
                    <th>Primary</th>
                    <th>Secondary</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.ordered.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={!this.canPrioritise}
                    >
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`dnd-row ${
                            snapshot.isDragging ? "dnd-row--dragging" : ""
                          }`}
                          style={provided.draggableProps.style}
                        >
                          <Can when="traffic.schedule.prioritise">
                            <td className="dnd-handle">
                              <img src={dragIcon} style={{ height: "13px" }} />
                            </td>
                          </Can>
                          <td>
                            {formatWithDefaultTz(item.slotStart, "DD-MM-YYYY")}{" "}
                            {formatWithDefaultTz(item.slotStart, "HH:mm:ss")}
                          </td>
                          {this.isItOverdue(
                            item.slotStart,
                            item.slotEnd,
                            item.updatedAt,
                            item.status
                          ) ? (
                            <td>{formatTrailer(item)}</td>
                          ) : (
                            <td>OVERDUE</td>
                          )}
                          <td>{item.loadType}</td>
                          <td>{item.numberOfPallets}</td>
                          <td
                            className="booking-list-link"
                            onClick={() => this.showBookingPreviewModal(item)}
                          >
                            {item.journeyReference}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {item.direction}
                          </td>
                          <td>{sendingSiteDescription(item)}</td>
                          <td>{receivingSiteDescription(item)}</td>
                          <td>{item.vehicleReg}</td>
                          <td style={{ textTransform: "capitalize" }}>
                            {item.status.replace(/_/g, " ")}
                          </td>
                          <td>{this.getAllocation(item, 0)}</td>
                          <td>{this.getAllocation(item, 1)}</td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                </tbody>
                {provided.placeholder}
              </table>
            )}
          </Droppable>
          <h5>
            General List: items in this list will always be re-arranged in order
            of slot start time.
          </h5>
          <Droppable droppableId="unordered">
            {provided => (
              <table ref={provided.innerRef} className="dnd-table">
                <thead className="dnd-header">
                  <tr>
                    <Can when="traffic.schedule.prioritise">
                      <th className="dnd-handle" />
                    </Can>
                    <th>Start</th>
                    <th>Trailer</th>
                    <th>Load Type</th>
                    <th>Pallets</th>
                    <th>Reference</th>
                    <th>Direction</th>
                    <th>Sending Site</th>
                    <th>Receiving Site</th>
                    <th>Reg</th>
                    <th>Status</th>
                    <th>Primary</th>
                    <th>Secondary</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={!this.canPrioritise}
                    >
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`dnd-row ${
                            snapshot.isDragging ? "dnd-row--dragging" : ""
                          }`}
                          style={provided.draggableProps.style}
                        >
                          <Can when="traffic.schedule.prioritise">
                            <td className="dnd-handle">
                              <img src={dragIcon} style={{ height: "13px" }} />
                            </td>
                          </Can>
                          <td>
                            {formatWithDefaultTz(item.slotStart, "DD-MM-YYYY")}{" "}
                            {formatWithDefaultTz(item.slotStart, "HH:mm:ss")}
                          </td>
                          {this.isItOverdue(
                            item.slotStart,
                            item.slotEnd,
                            item.updatedAt,
                            item.status
                          ) ? (
                            <td>
                              {item.haulier ? item.haulier.shortCode : ""}
                              {item.trailer
                                ? " - " + item.trailer.trailerNo
                                : ""}
                            </td>
                          ) : (
                            <td>OVERDUE</td>
                          )}
                          <td>{item.loadType}</td>
                          <td>{item.numberOfPallets}</td>
                          <td
                            className="booking-list-link"
                            onClick={() => this.showBookingPreviewModal(item)}
                          >
                            {item.journeyReference}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {item.direction}
                          </td>
                          <td>{sendingSiteDescription(item)}</td>
                          <td>{receivingSiteDescription(item)}</td>
                          <td>{item.vehicleReg}</td>
                          <td style={{ textTransform: "capitalize" }}>
                            {item.status.replace(/_/g, " ")}
                          </td>
                          <td>{this.getAllocation(item, 0)}</td>
                          <td>{this.getAllocation(item, 1)}</td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                </tbody>
                {provided.placeholder}
              </table>
            )}
          </Droppable>
        </DragDropContext>

        {!this.props.bookings.length && (
          <p className="booking-list--empty">
            No bookings for the selected day.
          </p>
        )}
      </div>
    );
  }
}

function mapStateToProps({ auth: { permissions } }) {
  return { permissions };
}

export default connect(mapStateToProps)(withRouter(ListView));
