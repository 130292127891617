import React from "react";
import SplashPage from "./SplashPage";
import { withRouter } from "react-router";
import RecoverAccountForm from "./recover-account-page/RecoverAccountForm";

function RecoverAccountPage(props) {
  const { token } = props.match.params;

  return (
    <SplashPage>
      <p className="splash-page__heading">Reset</p>
      <p className="thin">Almost, there!</p>
      <p className="small-type center">
        Reset your password below to recover your account.
      </p>
      <RecoverAccountForm token={token} />
    </SplashPage>
  );
}

export default withRouter(RecoverAccountPage);
