import React from "react";
import Button from "./Button";
import "./Notice.css";

export default function Notice(props) {
  return (
    <div>
      <div className="notice-overlay" />
      <div className="notice">
        <div className="notice__message">{props.message}</div>
        <div className="button-col">
          <Button
            variant="primary"
            size="xsmall"
            title="Continue"
            onClick={props.onDismiss}
          />
        </div>
      </div>
    </div>
  );
}
