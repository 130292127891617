import React, { Component } from "react";
import UserRolesForm from "./user-admin-page/UserRolesForm";

class UserAdminPage extends Component {
  render() {
    return (
      <div className="site-config">
        <UserRolesForm {...this.props} />
      </div>
    );
  }
}

export default UserAdminPage;
