import React from "react";
import { Formik, Form } from "formik";
import Button from "Button";
import Confirm from "Confirm";

export default function EmptyTrailerForm({
  onReloadTrailer,
  onCompleteBooking,
  isInternalTrailer
}) {
  return (
    <>
      <p>
        Click the button below to complete
        <br /> the booking and leave the trailer
        <br />
        on the dock for another load.
      </p>
      {isInternalTrailer && (
        <Formik onSubmit={onReloadTrailer} initialValues={{}}>
          {({ isSubmitting }) => (
            <Form>
              <div className="button-col">
                <Button
                  type="submit"
                  variant="primary"
                  size="small"
                  title="Reload trailer"
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
      <Formik onSubmit={onCompleteBooking} initialValues={{}}>
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            <div className="button-col">
              <Confirm title="Are you sure you want to complete booking and keep trailer?">
                {confirm => (
                  <Button
                    variant="primary"
                    size="small"
                    title="Complete booking and keep trailer"
                    disabled={isSubmitting}
                    onClick={confirm(handleSubmit)}
                  />
                )}
              </Confirm>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
