import React from "react";
import { formatLocalDate } from "date-helper";
import {
  sendingSiteDescription,
  receivingSiteDescription
} from "booking-helper";
import RebookingIcon from "RebookingIcon";
import LoadIndicator from "LoadIndicators";
import TrailerUnavailableIcon from "TrailerUnavailableIcon";

export default function ParkingTrailersTable({
  trailers,
  movements,
  onSelectBooking
}) {
  function findValidMovement(trailerId) {
    return (
      movements &&
      movements.find(m => parseInt(m.trailer.id) === parseInt(trailerId))
    );
  }

  function moveTo(t) {
    const validMovement = findValidMovement(t.id);
    if (validMovement) {
      return (
        validMovement.location.zone.name +
        (validMovement.location.dock
          ? ` - ${validMovement.location.dock.number}`
          : "")
      );
    } else {
      return "";
    }
  }

  return (
    <table className="zone-table" style={{ whiteSpace: "nowrap" }}>
      <thead>
        <tr>
          <th />
          <th>Trailer</th>
          <th>Pallets</th>
          <th />
          <th>Journey Ref</th>
          <th>PO/ASN</th>
          <th>Direction</th>
          <th>Last Moved</th>
          <th>Sending Site</th>
          <th>Receiving Site</th>
          <th>Pending Movement</th>
        </tr>
      </thead>
      <tbody>
        {trailers.map(t => (
          <tr key={t.id}>
            <td>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <TrailerUnavailableIcon trailer={t} />
                {t.booking && t.booking.appointment && (
                  <LoadIndicator appointment={t.booking.appointment} />
                )}
              </div>
            </td>
            <td>
              {t.haulier.shortCode} - {t.trailerNo} ({t.trailerType.type})
            </td>
            <td>{t.booking && t.booking.numberOfPallets}</td>
            <td>
              {t.booking && (
                <div
                  className="link"
                  onClick={() => onSelectBooking(t.booking.id)}
                >
                  <RebookingIcon booking={t.booking} />
                </div>
              )}
            </td>
            <td>
              {t.booking && (
                <div
                  className="link"
                  onClick={() => onSelectBooking(t.booking.id)}
                >
                  {t.booking.journeyReference}
                </div>
              )}
            </td>
            <td>{t.booking && t.booking.poNumber}</td>
            <td style={{ textTransform: "capitalize" }}>
              {t.booking && t.booking.direction}
            </td>
            <td>
              {t.movedAt
                ? formatLocalDate(t.movedAt)
                : formatLocalDate(t.insertedAt)}
            </td>
            <td>{t.booking && sendingSiteDescription(t.booking)}</td>
            <td>{t.booking && receivingSiteDescription(t.booking)}</td>
            <td>{moveTo(t)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
