import React, { Component } from "react";
import classNames from "classnames";
import "./CustomControl.css";

const elements = [];

document.body.addEventListener("click", e => {
  elements.forEach(([element, callback]) => {
    if (!isChild(element.current, e.target)) {
      callback();
    }
  });
});

function isChild(element, target) {
  if (target === null) {
    return false;
  } else if (target === element) {
    return true;
  } else {
    return isChild(element, target.parentNode);
  }
}

class CustomControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false
    };
    this.myRef = React.createRef();
    elements.push([this.myRef, this.closeList.bind(this)]);
  }

  closeList() {
    this.setState({
      listOpen: false
    });
  }

  toggleList() {
    this.setState({
      listOpen: !this.state.listOpen
    });
  }

  render() {
    const { listOpen } = this.state;

    return (
      <div className="form-control">
        {this.props.label}
        <div
          ref={this.myRef}
          className={classNames(
            `custom-control--${this.props.theme || "light"}`,
            {
              "custom-control": !listOpen,
              "custom-control-open": listOpen
            }
          )}
        >
          <div
            className="custom-control-header"
            onClick={() => this.toggleList()}
          >
            <div className="custom-control-header-title">
              {this.props.title}
            </div>
            <span className="material-icons">arrow_drop_down</span>
          </div>
          <div className="custom-control-list">
            {listOpen && this.props.children}
          </div>
          {this.props.error && (
            <div className="form-field-error">{this.props.error}</div>
          )}
        </div>
      </div>
    );
  }
}

export default CustomControl;
