import React from "react";
import AppTemplate from "templates/AppTemplate";
import config from "../../../config";

export default function TrafficPageTemplate(props) {
  let innerNavItems;

  if (config.FEATURE_LITE_VERSION) {
    innerNavItems = [
      {
        title: "Trailers",
        href: "/traffic/trailers",
        permission: "traffic.trailers.view",
        secondaryNavItems: [
          {
            title: "Live Trailers",
            href: "/traffic/trailers",
            permission: "traffic.trailers.view"
          }
        ]
      }
    ];
  } else {
    innerNavItems = [
      {
        title: "Dashboard",
        href: "/traffic",
        permission: "traffic.dashboard.view"
      },
      {
        title: "Trailers",
        href: "/traffic/trailers",
        permission: "traffic.trailers.view",
        secondaryNavItems: [
          {
            title: "Live Trailers",
            href: "/traffic/trailers",
            permission: "traffic.trailers.view"
          },
          {
            title: "Servicing",
            href: "/traffic/trailers/servicing",
            permission: "traffic.servicing.view"
          },
          {
            title: "Yard Audits",
            href: "/traffic/trailers/yard-audits",
            permission: "traffic.yard-audits.view"
          }
        ]
      },
      {
        title: "Movements",
        href: "/traffic/movements",
        permission: "traffic.movements.view"
      },
      {
        title: "Pre-Allocate",
        href: "/traffic/bookings",
        permission: "traffic.preallocate.view"
      },
      {
        title: "Schedule",
        href: "/traffic/schedule",
        permission: "traffic.schedule.view"
      }
    ];
  }

  return (
    <AppTemplate
      filters={props.filters}
      innerNavItems={innerNavItems}
      showDatePicker
    >
      {props.children}
    </AppTemplate>
  );
}
