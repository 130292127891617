import React, { Component } from "react";
import "./RoundButton.css";

export default class HoverButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentHover: false,
      childHover: false,
      hideChildren: false,
      elapsed: 0
    };
  }
  handleMouseIn() {
    this.setState({ parentHover: true, hideChildren: false, elapsed: 0 });
  }

  handleMouseOut() {
    this.timeout();
  }

  childMouseIn() {
    this.setState({
      parentHover: true,
      hideChildren: false,
      elapsed: 0,
      childHover: true
    });
  }

  childMouseOut() {
    this.setState({ hideChildren: true, elapsed: 0 });
  }

  timeout() {
    if (
      this.state.hideChildren ||
      (this.state.elapsed >= 3 && !this.state.childHover)
    ) {
      this.setState({ parentHover: false });
    } else {
      setTimeout(() => {
        this.setState({ elapsed: this.state.elapsed + 1 });
        this.timeout();
      }, 1000);
    }
  }

  render() {
    const className = `round-btn-${this.props.variant}`;

    return (
      <div
        className={className}
        style={this.props.style}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        onMouseEnter={() => {
          this.handleMouseIn();
        }}
        onMouseLeave={() => {
          this.handleMouseOut();
        }}
      >
        {this.state.parentHover && (
          <div
            className="hoverMenu"
            onMouseEnter={() => {
              this.childMouseIn();
            }}
            onMouseLeave={() => {
              this.childMouseOut();
            }}
          >
            {this.props.children}
          </div>
        )}

        <i className="material-icons">add</i>
      </div>
    );
  }
}
