import React from "react";
import { connect } from "react-redux";
import { dismissMessage } from "redux/app";
import FadingAlert from "./FadingAlert";
import "./Toast.css";

function Toast(props) {
  return (
    <div className="toast-container">
      {props.messages.map(({ type, message, id }) => (
        <FadingAlert
          key={id}
          type={type}
          message={message}
          onDismiss={() => props.dispatch(dismissMessage(id))}
        />
      ))}
    </div>
  );
}

function mapStateToProps({ app: { messages } }) {
  return { messages };
}

export default connect(mapStateToProps)(Toast);
