import React from "react";
import "./LandingPanel.css";

export default function LandingPanel(props) {
  return (
    <div className="landing-center">
      <h1>{props.title}</h1>
      <h3>{props.text}</h3>
      {props.children}
    </div>
  );
}
