import React from "react";
import "./RoundButton.css";

export default function FloatingButton(props) {
  const className = `round-btn-${props.variant}`;

  return (
    <div
      title={props.title || ""}
      className={className}
      style={props.style}
      onClick={props.onClick}
      disabled={props.disabled}
      style={{
        height: `${props.height || 48}px`,
        width: `${props.width || 48}px`,
        right: `${props.right || -24}px`,
        top: `${props.top || -24}px`,
        backgroundColor: `${props.bgColor || "green"}`
      }}
    >
      {props.children || <i className="material-icons">add</i>}
    </div>
  );
}
