import React from "react";
import SelectSitePanel from "SelectSitePanel";
import TrafficPageTemplate from "./TrafficPageTemplate";
import { getMovements } from "./traffic-movements-page/trafficMovementsQueries";
import TrafficMovementsOverview from "./traffic-movements-page/TrafficMovementsOverview";
import {
  cancelMovement,
  completeMovement
} from "warehouse/pages/move-trailer-page/move-trailer-queries";
import Confirm from "Confirm";

export default class TrafficMovementsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assignedMovements: [],
      pendingMovements: [],
      loading: true
    };

    this.fetchMovements = this.fetchMovements.bind(this);
  }

  componentDidMount() {
    this.fetchMovements();
  }

  fetchMovements() {
    return getMovements().then(({ assignedMovements, pendingMovements }) => {
      this.setState({ assignedMovements, pendingMovements, loading: false });
    });
  }

  cancelMovement(id) {
    const movements = this.state.pendingMovements;
    this.setState({
      pendingMovements: updateMovementStatus(movements, id, "cancelling")
    });

    cancelMovement(id).then(this.fetchMovements);
  }

  completeMovement(id) {
    const movements = this.state.assignedMovements;
    this.setState({
      assignedMovements: updateMovementStatus(movements, id, "cancelling")
    });

    completeMovement(id).then(this.fetchMovements);
  }

  render() {
    return (
      <TrafficPageTemplate>
        {this.state.loading && <SelectSitePanel />}
        {!this.state.loading && (
          <Confirm title="Are you sure you want to remove this movement?">
            {confirm => (
              <TrafficMovementsOverview
                {...this.state}
                handleCancel={confirm(this.cancelMovement.bind(this))}
                handleComplete={confirm(this.completeMovement.bind(this))}
              />
            )}
          </Confirm>
        )}
      </TrafficPageTemplate>
    );
  }
}

function updateMovementStatus(movements, id, newstatus) {
  return movements.map(m => {
    if (m.id == id) {
      return { ...m, status: newstatus };
    }

    return m;
  });
}
