import React, { Component } from "react";
import { withRouter } from "react-router";
import { isBefore, isAfter } from "date-fns";
import { urlFromBooking } from "route-helper";
import { Link } from "react-router-dom";
import {
  formatTrailer,
  sendingSiteDescription,
  receivingSiteDescription
} from "booking-helper";
import "./WarehouseListView.css";
import { formatWithDefaultTz } from "date-helper";

class ListView extends Component {
  constructor(props) {
    super(props);
    this.isItOverdue = this.isItOverdue.bind(this);
    this.state = {
      items: [],
      ordered: []
    };
  }

  componentDidMount() {
    this.setState({
      items: this.orderBySlotTime(this.props.bookings),
      ordered: this.props.orderedBookings
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookings !== this.props.bookings) {
      this.setState({
        items: this.orderBySlotTime(this.props.bookings),
        ordered: this.props.orderedBookings
      });
    }
  }

  orderBySlotTime(list) {
    const result = Array.from(list);
    return result.sort((a, b) => (a.slotStart > b.slotStart ? 1 : -1));
  }

  isItOverdue(slotStart, slotEnd, updated_at, status) {
    const now = new Date();
    return (
      (isBefore(updated_at, slotEnd) && isAfter(updated_at, slotStart)) ||
      isBefore(now, slotStart) ||
      status != null
    );
  }

  getLocation(item) {
    const zone =
      item.trailer && item.trailer.zone ? item.trailer.zone.name : "";
    const dock =
      item.trailer && item.trailer.dock ? ` - ${item.trailer.dock.number}` : "";

    return zone + dock;
  }

  render() {
    return (
      <div>
        <h2>Operations Schedule</h2>
        {this.props.bookings.length === 0 && <h4>No bookings to display</h4>}
        {this.props.bookings.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Start</th>
                <th>Trailer</th>
                <th>Load Type</th>
                <th>Pallets</th>
                <th>Direction</th>
                <th>Journey Reference</th>
                <th>Sending Site</th>
                <th>Receiving Site</th>
                <th>Status</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {this.state.ordered.map(item => (
                <tr key={item.id} className="priority-booking">
                  <td>
                    {formatWithDefaultTz(item.slotStart, "DD-MM-YYYY")}{" "}
                    {formatWithDefaultTz(item.slotStart, "HH:mm")}
                  </td>
                  {this.isItOverdue(
                    item.slotStart,
                    item.slotEnd,
                    item.updatedAt,
                    item.status
                  ) ? (
                    <td>{formatTrailer(item)}</td>
                  ) : (
                    <td>OVERDUE</td>
                  )}
                  <td>{item.loadType}</td>
                  <td>{item.numberOfPallets}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {item.direction}
                  </td>
                  <td>
                    <Link to={urlFromBooking(item)}>
                      {item.journeyReference}
                    </Link>
                  </td>
                  <td>{sendingSiteDescription(item)}</td>
                  <td>{receivingSiteDescription(item)}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {item.status.replace(/_/g, " ")}
                  </td>
                  <td>{this.getLocation(item)}</td>
                </tr>
              ))}
              {this.state.ordered.length > 0 && (
                <tr>
                  <td colSpan="12">
                    <hr />
                  </td>
                </tr>
              )}

              {this.state.items.map(item => (
                <tr key={item.id}>
                  <td>
                    {formatWithDefaultTz(item.slotStart, "DD-MM-YYYY")}{" "}
                    {formatWithDefaultTz(item.slotStart, "HH:mm")}
                  </td>
                  {this.isItOverdue(
                    item.slotStart,
                    item.slotEnd,
                    item.updatedAt,
                    item.status
                  ) ? (
                    <td>
                      {item.haulier ? item.haulier.shortCode : ""}
                      {item.trailer ? " - " + item.trailer.trailerNo : ""}
                    </td>
                  ) : (
                    <td>OVERDUE</td>
                  )}
                  <td>{item.loadType}</td>
                  <td>{item.numberOfPallets}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {item.direction}
                  </td>
                  <td>
                    <Link to={urlFromBooking(item)}>
                      {item.journeyReference}
                    </Link>
                  </td>
                  <td>{sendingSiteDescription(item)}</td>
                  <td>{receivingSiteDescription(item)}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {item.status.replace(/_/g, " ")}
                  </td>
                  <td>{this.getLocation(item)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
export default withRouter(ListView);
