import React from "react";
import { addMessage } from "redux/app";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Formik, Form, Field } from "formik";
import { createTrailer, updateTrailer, deleteTrailer } from "api/trailers";
import TextInput from "form-controls/TextInput";
import Can from "Can";
import SelectBox from "form-controls/SelectBox";
import TextBox from "form-controls/TextBox";
import DateInput from "form-controls/DateInput";
import Alert from "Alert";
import Button from "Button";
import Confirm from "Confirm";
import * as Yup from "yup";
import { formatAsOptions } from "siteList";
import Loader from "Loader";
import { getTrailerOptionData } from "./trailer-admin-queries";
import Switch from "form-controls/Switch";
import { sortTrailerTypeOptions } from "../../../../utilities/trailer-utils";
import { sortHaulierOptions } from "../../../../utilities/haulier-utils";
import config from "../../../../config";

const UNAVAILABILITY_SERVICE = "service";
const UNAVAILABILITY_MOT = "mot";
const UNAVAILABILITY_VOR = "vor";
const UNAVAILABILITY_TRACKER_ISSUES = "tracker_issues";
const UNAVAILABILITY_RETURNS_REBOOKS = "returns_rebooks";
const UNAVAILABILITY_UNAVAILABLE = "unavailable";

const schema = Yup.object().shape({
  trailerNo: Yup.string()
    .uppercase()
    .max(16)
    .required("Please enter the trailer number"),
  haulierId: Yup.string().required("Please select a haulier"),
  trailerTypeId: Yup.string().required("Please select a trailer type"),
  siteId: Yup.string().required("Please select a site"),
  zoneId: Yup.string().required("Please select a zone"),
  unavailabilityContact: Yup.string().when("isUnavailable", {
    is: true,
    then: Yup.string().required("Please enter a contact"),
    otherwise: Yup.string()
  })
});

class TrailerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hauliers: [],
      sites: [],
      trailerTypes: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    getTrailerOptionData().then(({ sites, hauliers, trailerTypes }) => {
      this.setState({ sites, hauliers, trailerTypes, loading: false });
    });
  }

  get siteOptions() {
    return formatAsOptions(this.state.sites);
  }

  zoneOptions(siteId) {
    const site = this.state.sites.find(s => s.id === siteId);

    if (!site) {
      return [];
    }

    return site.zones.map(z => ({
      value: z.id,
      label: z.name,
      isDisabled: !z.isActive
    }));
  }

  dockOptions(siteId, zoneId) {
    const site = this.state.sites.find(s => s.id === siteId);

    if (!site) {
      return [];
    }

    const zone = site.zones.find(z => z.id === zoneId);

    if (!zone) {
      return [];
    }
    const { docks } = zone;

    docks.sort((a, b) => (a.number > b.number ? 1 : -1));

    return docks.map(d => ({
      value: d.id,
      label: d.number,
      isDisabled: !d.isActive
    }));
  }

  get trailerTypeOptions() {
    return this.state.trailerTypes.map(type => {
      return { value: type.id, label: type.name };
    });
  }

  get haulierOptions() {
    return this.state.hauliers.map(h => ({
      value: h.id,
      label: h.name
    }));
  }

  get title() {
    if (this.props.editing) {
      return "Edit Trailer";
    } else {
      return "Add Trailer";
    }
  }

  get subTitle() {
    if (this.props.editing) {
      return "Update an existing trailer";
    } else {
      return "Add a new trailer";
    }
  }

  get selectedTrailer() {
    if (!this.props.selectedTrailer) {
      return {};
    }
    return [...Object.entries(this.props.selectedTrailer)].reduce(
      (acc, [k, v]) => {
        if (v !== null) {
          acc[k] = v;
        }

        return acc;
      },
      {}
    );
  }

  get initialValues() {
    if (this.props.selectedTrailer) {
      const { zone, dock } = this.props.selectedTrailer;
      return {
        haulierId: this.props.selectedTrailer.haulier.id,
        trailerTypeId: this.props.selectedTrailer.trailerType.id,
        siteId: zone ? zone.site.id : "",
        zoneId: zone ? zone.id : "",
        dockId: dock ? dock.id : "",
        trailerNo: "",
        isUnavailable: false,
        unavailabilityContact: "",
        unavailabilityReason: "",
        unavailabilityNotes: "",
        returnDate: "",
        ...this.selectedTrailer
      };
    } else {
      return {
        trailerNo: "",
        haulierId: "",
        trailerTypeId: "",
        siteId: "",
        zoneId: "",
        dockId: "",
        isInternal: false
      };
    }
  }

  isDockSelectorDisabled(siteId, zoneId) {
    const site = this.state.sites.find(s => s.id === siteId);

    if (!site) {
      return true;
    }

    const zone = site.zones.find(z => z.id === zoneId);

    return !zone || ["parking", "virtual"].includes(zone.type);
  }

  handleSubmit(values, { setSubmitting }) {
    if (this.props.editing) {
      const { id } = this.props.selectedTrailer;
      updateTrailer(id, values).then(
        () => {
          this.props.dispatch(
            addMessage("success", "Trailer updated successfully")
          );
          setSubmitting(false);
          this.props.updateTrailers();
        },
        error => {
          if (error.response) {
            Object.values(error.response.data.errors).forEach(message => {
              this.props.dispatch(addMessage("error", message));
            });
          }
          setSubmitting(false);
        }
      );
    } else {
      createTrailer(values).then(
        () => {
          this.props.dispatch(
            addMessage("success", "Trailer created successfully")
          );
          setSubmitting(false);
          this.props.updateTrailers();
          this.props.back();
        },
        () => {
          setSubmitting(false);
        }
      );
    }
  }

  deleteTrailer() {
    const { id } = this.props.selectedTrailer;
    deleteTrailer(id)
      .then(() => this.props.updateTrailers())
      .then(() => {
        this.props.back();
      })
      .catch(error => {
        const errorMessage = Object.values(error.response.data.errors).join(
          " "
        );
        this.props.dispatch(addMessage("error", errorMessage));
      });
  }

  canSubmit({ siteId, zoneId, dockId }) {
    const site = this.state.sites.find(s => s.id === siteId);

    if (!site) {
      return false;
    }

    const zone = site.zones.find(z => z.id === zoneId);

    if (!zone) {
      return false;
    }

    if (zone.type === "numbered_parking") {
      return !!dockId;
    }

    return true;
  }

  canTakeOffUnavailability() {
    const { permissions } = this.props;
    const { isUnavailable, unavailabilityReason } = this.selectedTrailer;

    if (!isUnavailable) {
      return true;
    }

    switch (unavailabilityReason) {
      case "service":
        return permissions.includes(
          "settings.trailers.takeOffUnavailability.service"
        );
      case "mot":
        return permissions.includes(
          "settings.trailers.takeOffUnavailability.mot"
        );
      case "vor":
        return permissions.includes(
          "settings.trailers.takeOffUnavailability.vor"
        );
      case "tracker_issues":
        return permissions.includes(
          "settings.trailers.takeOffUnavailability.trackerIssues"
        );
      case "returns_rebooks":
        return permissions.includes(
          "settings.trailers.takeOffUnavailability.returnsRebooks"
        );
      case "unavailable":
        return permissions.includes(
          "settings.trailers.takeOffUnavailability.unavailable"
        );
      default:
        return true;
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const { permissions } = this.props;

    return (
      <div className="admin-details">
        <div className="admin-details-main">
          {this.props.editing && !this.selectedTrailer.zone && (
            <Alert
              type="error"
              message="This trailer is currently flagged as off-site"
            />
          )}
          <Formik
            enableReinitialize
            initialValues={this.initialValues}
            validationSchema={schema}
            onSubmit={this.handleSubmit.bind(this)}
          >
            {({ setFieldValue, errors, touched, values }) => (
              <Form className="admin-form">
                <div style={{ minWidth: "328px" }}>
                  <Field
                    name="trailerNo"
                    label="Trailer Number"
                    component={TextInput}
                    error={touched.trailerNo && errors.trailerNo}
                  />
                  <Field
                    name="haulierId"
                    label="Haulier (Trailer Owner)"
                    component={SelectBox}
                    options={sortHaulierOptions(this.haulierOptions)}
                    error={touched.haulierId && errors.haulierId}
                  />
                  <Field
                    name="trailerTypeId"
                    label="Trailer Type"
                    placeholder="Select Trailer Type"
                    options={sortTrailerTypeOptions(this.trailerTypeOptions)}
                    component={SelectBox}
                    error={touched.trailerTypeId && errors.trailerTypeId}
                  />
                  <Field
                    name="siteId"
                    label="Site"
                    options={this.siteOptions}
                    placeholder="Select Site"
                    component={SelectBox}
                    error={touched.siteId && errors.siteId}
                  />
                  <Field
                    name="zoneId"
                    label="Zone"
                    component={SelectBox}
                    options={this.zoneOptions(values.siteId)}
                    disabled={this.zoneOptions(values.siteId).length === 0}
                    error={touched.zoneId && errors.zoneId}
                  />
                  <Field
                    name="dockId"
                    label="Dock"
                    options={this.dockOptions(values.siteId, values.zoneId)}
                    placeholder="Select Dock"
                    component={SelectBox}
                    disabled={this.isDockSelectorDisabled(
                      values.siteId,
                      values.zoneId
                    )}
                    error={touched.dockId && errors.dockId}
                  />
                  {values.isTrailerUsedByOutboundBooking == true && (
                    <div style={{ margin: 20, marginBottom: 0 }}>
                      <Alert
                        type={"warning"}
                        message={
                          "Warning! This trailer is currently in use with an Outbound booking"
                        }
                      />
                    </div>
                  )}
                  <Field
                    name={`isUnavailable`}
                    label="Flag as Unavailable"
                    component={Switch}
                    disabled={!this.canTakeOffUnavailability()}
                  />
                  {values.isUnavailable && (
                    <>
                      {!this.canTakeOffUnavailability() && (
                        <div style={{ margin: 20, marginBottom: 0 }}>
                          <Alert
                            type={"warning"}
                            message={`You cannot change this trailer's unavailability details as you do not have permission to do so. Please contact workshop or your administrator.`}
                          />
                        </div>
                      )}
                      <Field
                        name="unavailabilityReason"
                        label="Reason for Unavailability"
                        component={SelectBox}
                        disabled={!this.canTakeOffUnavailability()}
                        options={[
                          {
                            value: UNAVAILABILITY_SERVICE,
                            label: "Service",
                            isDisabled: !permissions.includes(
                              "settings.trailers.setUnavailabilityReason.service"
                            )
                          },
                          {
                            value: UNAVAILABILITY_MOT,
                            label: "MOT",
                            isDisabled: !permissions.includes(
                              "settings.trailers.setUnavailabilityReason.mot"
                            )
                          },
                          {
                            value: UNAVAILABILITY_VOR,
                            label: "Vehicle off Road",
                            isDisabled: !permissions.includes(
                              "settings.trailers.setUnavailabilityReason.vor"
                            )
                          },
                          {
                            value: UNAVAILABILITY_TRACKER_ISSUES,
                            label: "Tracker Issues",
                            isDisabled: !permissions.includes(
                              "settings.trailers.setUnavailabilityReason.trackerIssues"
                            )
                          },
                          ...(config.FEATURE_RETURNS_REBOOKS_UNAVAILABILITY_REASON
                            ? [
                                {
                                  value: UNAVAILABILITY_RETURNS_REBOOKS,
                                  label: "Returns/Rebooks",
                                  isDisabled: !permissions.includes(
                                    "settings.trailers.setUnavailabilityReason.returnsRebooks"
                                  )
                                }
                              ]
                            : []),
                          {
                            value: UNAVAILABILITY_UNAVAILABLE,
                            label: "Unavailable",
                            isDisabled: !permissions.includes(
                              "settings.trailers.setUnavailabilityReason.unavailable"
                            )
                          }
                        ]}
                        error={
                          touched.unavailabilityReason &&
                          errors.unavailabilityReason
                        }
                      />
                      <Field
                        name="unavailabilityContact"
                        label="Contact"
                        component={TextInput}
                        placeholder="Who to contact for further information"
                        disabled={!this.canTakeOffUnavailability()}
                        error={
                          touched.unavailabilityContact &&
                          errors.unavailabilityContact
                        }
                      />
                      {(values.unavailabilityReason == UNAVAILABILITY_VOR ||
                        values.unavailabilityReason ==
                          UNAVAILABILITY_RETURNS_REBOOKS) && (
                        <Field
                          name="unavailabilityNotes"
                          label="Unavailability Notes"
                          placeholder="Additional notes about vehicle being off road"
                          disabled={!this.canTakeOffUnavailability()}
                          component={TextBox}
                        />
                      )}
                      {[UNAVAILABILITY_SERVICE, UNAVAILABILITY_MOT].includes(
                        values.unavailabilityReason
                      ) && (
                        <Field
                          name="returnDate"
                          label="Return by Date"
                          component={DateInput}
                          disabled={!this.canTakeOffUnavailability()}
                          onChange={date => {
                            setFieldValue("returnDate", date);
                          }}
                        />
                      )}
                    </>
                  )}
                  <div className="button-col">
                    {this.props.editing && (
                      <Can when="settings.trailers.delete">
                        <Confirm title="Are you sure you want to delete the trailer?">
                          {confirm => (
                            <Button
                              variant="white"
                              size="small"
                              title="Delete"
                              onClick={confirm(this.deleteTrailer.bind(this))}
                            />
                          )}
                        </Confirm>
                      </Can>
                    )}
                    <Can
                      when={
                        this.props.editing
                          ? "settings.trailers.update"
                          : "settings.trailers.create"
                      }
                    >
                      <Button
                        type="submit"
                        variant="primary"
                        size="small"
                        title="Save changes"
                        disabled={
                          Object.keys(errors).length || !this.canSubmit(values)
                        }
                      />
                    </Can>
                    <Button
                      variant="secondary"
                      size="small"
                      title="Cancel"
                      onClick={() => this.props.back()}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  permissions: state.auth.permissions
});

export default connect(mapStateToProps)(withRouter(TrailerForm));
