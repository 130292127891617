import React from "react";
import PropTypes from "prop-types";
import MonthPicker from "./MonthPicker";
import "./Header.css";

export default function Header(props) {
  return (
    <div className="datepicker__header">
      <MonthPicker
        selectedDate={props.selectedDate}
        onSelectMonth={props.onSelectMonth}
      />
      <i className="material-icons md-18" onClick={props.selectPreviousMonth}>
        chevron_left
      </i>
      <span onClick={props.onSelectToday}>Today</span>
      <i className="material-icons md-18" onClick={props.selectNextMonth}>
        chevron_right
      </i>
    </div>
  );
}

Header.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired
};
