import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import classNames from "classnames";
import AppNav from "AppNav";
import Can from "Can";
import "Sidebar.css";
import "./SettingsLandingPage.css";
import config from "../../../config";

function SettingsLandingPage(props) {
  const appClassName = classNames("app", {
    "app--offset": props.showAppSwitcher
  });

  const sidebarClassName = classNames("sidebar", "sidebar--no-filter", {
    "sidebar--offset": props.showAppSwitcher,
    "sidebar--min-height": props.showAppSwitcher && !props.showFilters
  });

  return (
    <div className={appClassName}>
      <div className={sidebarClassName}>
        <AppNav />
      </div>
      <div className="main-body">
        <div className="settings-links">
          <h1 className="settings-title">Settings</h1>

          <ul className="setting-tile-list">
            <Can when="settings.site.view">
              <li className="setting-tile">
                <Link to="/settings/site-admin">
                  <i className="material-icons">store</i> Sites
                </Link>
              </li>
            </Can>
            <Can when="settings.users.view">
              <li className="setting-tile">
                <Link to="/settings/user-admin">
                  <i className="material-icons">perm_identity</i> Users
                </Link>
              </li>
            </Can>
            <Can when="settings.zones.view">
              <li className="setting-tile">
                <Link to="/settings/zones">
                  <i className="material-icons">view_module</i>Zones
                </Link>
              </li>
            </Can>
            <Can when="settings.trailers.view">
              <li className="setting-tile">
                <Link to="/settings/trailers">
                  <i className="material-icons">local_shipping</i> Trailers
                </Link>
              </li>
            </Can>
            <Can when="settings.trailers.view">
              <li className="setting-tile">
                <Link to="/settings/trailer-types">
                  <i className="material-icons">category</i> Trailer types
                </Link>
              </li>
            </Can>
            <Can when="settings.hauliers.view">
              <li className="setting-tile">
                <Link to="/settings/hauliers">
                  <i className="material-icons">contacts</i> Hauliers
                </Link>
              </li>
            </Can>
            <Can when="settings.devices.view">
              <li className="setting-tile">
                <Link to="/settings/atlas">
                  <i className="material-icons">error_outline</i> Atlas
                </Link>
              </li>
            </Can>
            {config.FEATURE_TRANSPOREON_CSV && (
              <Can when="settings.csvimport">
                <li className="setting-tile">
                  <Link to="/settings/bookings-from-csv">
                    <i className="material-icons">cloud_download</i>Bookings
                    from CSV
                  </Link>
                </li>
              </Can>
            )}
            <Can when="settings.logout">
              <li className="setting-tile">
                <Link to="/auth/update-password">
                  <i className="material-icons">security</i>Update password
                </Link>
              </li>
            </Can>
            <Can when="settings.logout">
              <li className="setting-tile">
                <Link to="/auth/logout">
                  <i className="material-icons">exit_to_app</i>Log out
                </Link>
              </li>
            </Can>
          </ul>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({
  app: { selectedDate, showAppSwitcher, showFilters },
  auth
}) {
  return { selectedDate, showAppSwitcher, showFilters, auth };
}

export default withRouter(connect(mapStateToProps)(SettingsLandingPage));
