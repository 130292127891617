export default [
  {
    name: "goods[ingredients]",
    label: "Ingredients"
  },
  {
    name: "goods[packaging]",
    label: "Packaging"
  },
  {
    name: "goods[moulds]",
    label: "Moulds"
  },
  {
    name: "goods[finishedGoods]",
    label: "Finished Goods"
  },
  {
    name: "goods[semiFinishedGoods]",
    label: "Semi Finished Goods"
  },
  {
    name: "goods[crumb]",
    label: "Crumb"
  },
  { name: "goods[lumpyCrumb]", label: "Lumpy Crumb" },
  {
    name: "goods[waste]",
    label: "Waste"
  },
  {
    name: "goods[stillages]",
    label: "Stillages"
  },
  {
    name: "goods[returns]",
    label: "Returns"
  },
  {
    name: "goods[chep]",
    label: "Chep pallets"
  },
  {
    name: "goods[LPR]",
    label: "LPR pallets"
  },
  {
    name: "goods[euros]",
    label: "Euros pallets"
  },
  {
    name: "goods[dehire]",
    label: "Dehire pallets"
  },
  {
    name: "goods[other]",
    label: "Other"
  }
];

export const options = [
  {
    value: "ingredients",
    label: "Ingredients"
  },
  {
    value: "packaging",
    label: "Packaging"
  },
  {
    value: "moulds",
    label: "Moulds"
  },
  {
    value: "finishedGoods",
    label: "Finished Goods"
  },
  {
    value: "semiFinishedGoods",
    label: "Semi Finished Goods"
  },
  {
    value: "crumb",
    label: "Crumb"
  },
  {
    value: "lumpyCrumb",
    label: "Lumpy Crumb"
  },
  {
    value: "waste",
    label: "Waste"
  },
  {
    value: "stillages",
    label: "Stillages"
  },
  {
    value: "returns",
    label: "Returns"
  },
  {
    value: "chep",
    label: "Chep pallets"
  },
  {
    value: "LPR",
    label: "LPR pallets"
  },
  {
    value: "euros",
    label: "Euros pallets"
  },
  {
    value: "dehire",
    label: "Dehire pallets"
  },
  {
    value: "other",
    label: "Other"
  }
];
