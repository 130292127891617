import React from "react";
import Button from "Button";
import UnavailabilityIcon from "TrailerUnavailableIcon";
import { ReturnIndicatorDiv, parseForIndicators } from "LoadIndicators";
import { Link } from "react-router-dom";
import { formatLocalDate } from "date-helper";
import { formatDestinations, formatGoods } from "booking-helper";
import config from "../../../../config";
import { distanceInWords } from "date-fns";

export default function RequestTrailerTable({ bookings, createMovement }) {
  bookings = bookings.filter(b => b.trailer !== null);
  if (bookings.length === 0) {
    return (
      <p>
        There are no trailers with linked outbound references. Please use the{" "}
        <Link to="/warehouse/bookings">bookings tab</Link> to allocate a
        trailer.
      </p>
    );
  }

  bookings = organiseBookings({ bookings });

  return (
    <table>
      <thead>
        <tr>
          <th />
          <th>Trailer Owner/No</th>
          <th>Journey Ref</th>
          <th>Direction</th>
          <th>From/To</th>
          <th>Time since arr.</th>
          <th>Load Type</th>
          <th>Goods</th>
          <th>Pallets</th>
          <th>Booking Time</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {bookings.map(b => (
          <tr
            key={b.journeyReference}
            style={{
              backgroundColor:
                b.appointment && b.appointment.indicatorFlag
                  ? "cornsilk"
                  : "white"
            }}
          >
            <td>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <UnavailabilityIcon trailer={b.trailer} />
                {b.appointment && b.appointment.indicatorFlag && (
                  <ReturnIndicatorDiv reference={b.appointment.indicatorFlag} />
                )}
              </div>
            </td>
            <td>
              {b.trailer && b.trailer.haulier
                ? b.trailer.haulier.shortCode
                : ""}{" "}
              - {b.trailer && b.trailer.trailerNo}
            </td>
            <td>{b.journeyReference}</td>
            <td style={{ textTransform: "capitalize" }}>{b.direction}</td>
            <td>{formatDestinations(b)}</td>
            <td>
              {b.checkInCompleteAt
                ? distanceInWords(new Date(), b.checkInCompleteAt, {
                    includeSeconds: true
                  })
                : "-"}
            </td>
            <td>{b.loadType}</td>
            <td>{formatGoods(b.goods)}</td>
            <td>{b.numberOfPallets}</td>
            <td>{formatLocalDate(b.slotStart)}</td>
            <td>
              <Button
                onClick={() => createMovement(b.trailer.id)}
                size="tiny"
                variant="primary"
                title={config.FEATURE_LITE_VERSION ? "Move" : "Request"}
                disabled={b.trailer && b.trailer.movements.length > 0}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function organiseBookings({ bookings }) {
  //eslint-disable-next-line
  let priorityBookings = [];
  //eslint-disable-next-line
  let otherBookings = [];

  for (const b of bookings) {
    if (b.appointment) {
      b.appointment.indicatorFlag = parseForIndicators(b);
      if (b.appointment.indicatorFlag) {
        priorityBookings.push(b);
        continue;
      }
    }
    otherBookings.push(b);
  }
  bookings = priorityBookings.concat(otherBookings);
  return bookings;
}
