import { fetch, toArgList } from "graphql-helper";

export function getSites() {
  return fetch(`
    query {
      sites {
        id
        name
        parentSite{
          id
          name
        }
      }
    }
  `);
}

export function getLoadTypes(siteId) {
  return fetch(`
    query {
      loadTypes(siteId: ${siteId}) {
        name
      }
    }
  `);
}

export function getSite(id) {
  return fetch(`
    query {
      site: site(id: ${id}) {
        id
        name
        hauliers {
          id
          name
          shortCode
          manuallyCreated
        }
      }
      trailerTypes: trailerTypes {
        id
        name
        baseType
        type
      }
      hauliers: hauliers {
        id
        name
        shortCode
        manuallyCreated
      }
      sites: sites {
        id
        name
        parentSite {
          id
          name
        }
      }
    }
  `);
}

export function bookingFromJourneyReference(journeyReference) {
  return fetch(`
    query {
      bookings(journeyReference: "${journeyReference}", showCompleted: true) {
            id
            journeyReference
            numberOfPallets
            origin
            direction
            loadType
            slotStart
            slotEnd
            status
            insertedAt
            driverName
            goods
            checkInCompleteAt
            departedAt
            appointment {
              id
              data
            }
            sendingSite {
              id
              name
            }
            receivingSite {
              id
              name
            }
            reasonDeleted
            updatedAt
            vehicleReg
            poNumber
            notes
            haulier {
              id
              name
            }
            trailerOwner {
              id
              name
            }
            trailerType {
              id
              baseType
              type
              name
            }
            trailer {
              id
              trailerNo
              zone {
                id
                name
                type
              }
              dock {
                id
                number
              }
            }
          }
      }
  `);
}

export function getSiteBookingsOverview(siteId, date) {
  return fetch(`
    query {
      bookings(siteId: ${siteId}, date: "${date}", showCompleted: true) {
            id
            journeyReference
            direction
            slotStart
            slotEnd
            status
            insertedAt
            haulier {
              name
            }
          }
      }
  `);
}

export function getBooking(id) {
  return fetch(`
    query {
      booking(id: ${id}) {
            id
            journeyReference
            numberOfPallets
            origin
            direction
            slotStart
            slotEnd
            status
            insertedAt
            driverName
            vehicleReg
            checkInCompleteAt
            departedAt
            poNumber
            requiresEmptyTrailer
            notes
            goods
            appointment {
              id
              data
            }
            sendingSite {
              id
              name
            }
            receivingSite {
              id
              name
            }
            trailerType {
              id
              name
              type
              baseType
            }
            haulier {
              id
              name
              shortCode
            }
            trailerOwner {
              id
              name
              shortCode
            }
            trailer {
              id
              trailerNo
              zone {
                id
                name
                type
              }
              dock {
                id
                number
              }
            }
          }
      }
  `);
}

export function getSiteBookings(siteId, date) {
  return fetch(`
    query {
      booking(siteId: ${siteId}, date: "${date}", showCompleted: true) {
            id
            journeyReference
            numberOfPallets
            origin
            direction
            slotStart
            slotEnd
            status
            insertedAt
            driverName
            vehicleReg
            checkInCompleteAt
            departedAt
            poNumber
            requiresEmptyTrailer
            notes
            goods
            appointment {
              id
              data
            }
            sendingSite {
              id
              name
            }
            receivingSite {
              id
              name
            }
            trailerType {
              id
              name
              type
              baseType
            }
            haulier {
              id
              name
              shortCode
            }
            trailerOwner {
              id
              name
              shortCode
            }
            trailer {
              id
              trailerNo
              zone {
                id
                name
                type
              }
              dock {
                id
                number
              }
            }
          }
      }
  `);
}

export function trailerTypesAndHauliers() {
  return fetch(`
    query {
      trailerTypes: trailerTypes {
        id
        name
        baseType
        type
      }
      hauliers {
        id
        name
        shortCode
      }
      sites: restrictedSites {
        id
        name
        parentSite {
          id
          name
        }
      }
    }
  `);
}

export function listSlots(
  date,
  siteId,
  isPalletised,
  numberOfPallets,
  direction
) {
  return fetch(`
    query {
      slots(
        date:"${date}",
        siteId:${siteId},
        isPalletised:${isPalletised},
        numberOfPallets: ${numberOfPallets},
        direction: "${direction}"
      ) {
        availableSlots
      }
    }
  `);
}

export function createBooking(values) {
  return fetch(`
    mutation CreateBooking {
      booking: createBooking(booking:{${toArgList(values)}}) {
        id
        direction
        receivingSite {
          id
          name
        }
        sendingSite {
          id
          name
        }
        journeyReference
      }
    }
  `);
}

export function updateBooking(id, values) {
  return fetch(`
    mutation UpdateBooking {
      booking: updateBooking(id: ${id},
        changeset:{${toArgList(values)}}) {
        id
        receivingSite{
          id
        }
        sendingSite{
          id
        }
        journeyReference
      }
    }
  `);
}

export function deleteBooking(id, reasonDeleted) {
  return fetch(`
    mutation DeleteBooking {
      booking: deleteBooking(id: ${id}, reasonDeleted: "${reasonDeleted}") {
        id
      }
    }
  `);
}
