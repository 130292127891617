import { getSites } from "../queries/sites";

const REQUEST = "sites/REQUEST";
const FAILURE = "sites/FAILURE";
const SUCCESS = "sites/SUCCESS";

export default function reducer(
  state = {
    items: [],
    loading: false,
    failed: false
  },
  action = {}
) {
  switch (action.type) {
    case REQUEST:
      return {
        items: [],
        loading: true,
        failed: false
      };
    case FAILURE:
      return {
        items: [],
        loading: false,
        failed: true
      };
    case SUCCESS:
      return {
        items: action.items,
        loading: false,
        failed: false
      };
    default:
      return state;
  }
}

function request() {
  return { type: REQUEST };
}

function failure(error) {
  return { type: FAILURE, error };
}

function success(items) {
  return { type: SUCCESS, items };
}

export function fetchAllSites() {
  return dispatch => {
    dispatch(request());
    getSites().then(
      ({ sites }) => {
        dispatch(success(sites));
      },
      () => {
        dispatch(failure());
      }
    );
  };
}
