import React, { useState, useEffect } from "react";
import { fetch, toArgList } from "graphql-helper";
import {
  sendingSiteDescription,
  receivingSiteDescription,
  formatGoods
} from "booking-helper";
import { withRouter } from "react-router";
import Modal from "Modal";
import Button from "Button";
import Loader from "Loader";
import { urlFromBooking } from "route-helper";
import { formatLocalDate } from "date-helper";
import { dockStatus } from "trailerStatuses";
import { formatStatus } from "booking-helper";
import "./BookingPreviewModal.css";

function BookingPreviewModal({ bookingId, onClose, history }) {
  const [booking, setBooking] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigateToBooking = () => {
    const pathname = urlFromBooking(booking);
    history.push({ pathname });
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`
      query {
        booking(${toArgList({ id: bookingId })}) {
          id
          journeyReference
          direction
          slotStart
          poNumber
          origin
          haulier {
            shortCode
            name
          }
          trailerOwner {
            shortCode
            name
          }
          trailerType {
            name
          }
          receivingSite {
            id
            name
          }
          sendingSite {
            id
            name
          }
          goods
          appointment { data }
          requiresEmptyTrailer
          status
          trailer { status }
          driverName
          vehicleReg
          notes
        }
      }
    `).then(({ booking }) => {
      setBooking(booking);
      setIsLoading(false);
    });
  }, [bookingId]);

  return (
    <Modal>
      <div className="modal">
        <div className="modal__content">
          <div className="modal__header">
            <span className="modal__title">
              Booking Details {!isLoading && booking.journeyReference}
            </span>
            <i className="material-icons modal__close" onClick={onClose}>
              close
            </i>
          </div>
          <div className="modal__body">
            {isLoading && <Loader />}

            {!isLoading && (
              <div className="modal__description">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginRight: "1rem" }}>
                    <div className="data-row">
                      <span className="data-row__heading">Reference </span>
                      <span>{booking.journeyReference}</span>
                    </div>
                    <div className="data-row">
                      <span className="data-row__heading">Tag </span>
                      <span>
                        {booking.appointment &&
                          booking.appointment.data["journey_tag"]}
                      </span>
                    </div>
                    <div className="data-row">
                      <span className="data-row__heading">Slot Start</span>
                      <span>{formatLocalDate(booking.slotStart)}</span>
                    </div>
                    <div className="data-row">
                      <span className="data-row__heading">Direction </span>
                      <span style={{ textTransform: "capitalize" }}>
                        {booking.direction}
                      </span>
                    </div>
                    <div className="data-row">
                      <span className="data-row__heading">Sending Site</span>
                      <span>{sendingSiteDescription(booking)}</span>
                    </div>
                    <div className="data-row">
                      <span className="data-row__heading">Receiving Site</span>
                      <span>{receivingSiteDescription(booking)}</span>
                    </div>
                    <div className="data-row">
                      <span className="data-row__heading">Palletised </span>
                      <span>{booking.palletised ? "Yes" : "No"}</span>
                    </div>
                    {booking.palletised && (
                      <div className="data-row">
                        <span className="data-row__heading">
                          Number of Pallets
                        </span>
                        <span>{booking.numberOfPallets}</span>
                      </div>
                    )}
                    {booking.goods && (
                      <div className="data-row">
                        <span className="data-row__heading">Goods</span>
                        <span>{formatGoods(booking.goods)}</span>
                      </div>
                    )}
                    <div className="data-row">
                      <span className="data-row__heading">PO Number </span>
                      <span>{booking.poNumber}</span>
                    </div>
                    {booking.direction !== "internal" && (
                      <>
                        <div className="data-row">
                          <span className="data-row__heading">Origin </span>
                          <span>{booking.origin}</span>
                        </div>
                        <div className="data-row">
                          <span className="data-row__heading">Haulier </span>
                          <span>{booking.haulier && booking.haulier.name}</span>
                        </div>
                        <div className="data-row">
                          <span className="data-row__heading">
                            Trailer Owner{" "}
                          </span>
                          <span>
                            {booking.trailerOwner && booking.trailerOwner.name}
                          </span>
                        </div>
                      </>
                    )}
                    <div className="data-row">
                      <span className="data-row__heading">Trailer Type </span>
                      <span>
                        {booking.trailerType && booking.trailerType.name}
                      </span>
                    </div>
                    <div className="data-row">
                      <span className="data-row__heading">Requires Empty</span>
                      <span>{booking.requiresEmptyTrailer ? "Yes" : "No"}</span>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="data-row">
                      <span className="data-row__heading">Status</span>
                      <span>
                        {dockStatus(
                          booking.trailer ? booking.trailer.status : "",
                          booking.status
                        ) || formatStatus(booking)}
                      </span>
                    </div>
                    {booking.direction !== "internal" && (
                      <>
                        <div className="data-row">
                          <span className="data-row__heading">
                            Driver Name{" "}
                          </span>
                          <span>{booking.driverName}</span>
                        </div>
                        <div className="data-row">
                          <span className="data-row__heading">
                            Vehicle Reg{" "}
                          </span>
                          <span>{booking.vehicleReg}</span>
                        </div>
                      </>
                    )}
                    {(!booking.notes || booking.notes === "") && (
                      <div className="data-row">
                        <p>No notes added to this booking</p>
                      </div>
                    )}
                    {booking.notes && booking.notes !== "" && (
                      <div className="note-box">
                        <div className="note-box__header">
                          <i className="material-icons">info</i>Notes
                        </div>
                        <p className="note-box__body">{booking.notes}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="modal__buttons">
              <Button
                variant="primary"
                size="tiny"
                title="Go To Booking"
                onClick={navigateToBooking}
              />
              <Button
                variant="secondary"
                size="tiny"
                title="Close"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default withRouter(BookingPreviewModal);
