import React from "react";
import { connect } from "react-redux";
import CheckBox from "form-controls/CheckBox";
import { Formik, Field, Form } from "formik";
import Button from "Button";
import { addMessage } from "redux/app";
import { updateBookingOverweightStatus } from "./trailerQueries";

class OverweightFlagForm extends React.Component {
  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          isOverweight: this.props.booking.isOverweight
        }}
        onSubmit={values => {
          updateBookingOverweightStatus(
            this.props.booking.id,
            values.isOverweight
          )
            .then(() => {
              this.props.dispatch(
                addMessage("success", "Successfully updated the trailer status")
              );
              return this.props.afterUpdate();
            })
            .catch(errors => {
              if (errors instanceof Error) {
                this.props.dispatch(
                  addMessage("error", "An unknown error occurred")
                );
              } else {
                errors.forEach(({ message }) =>
                  this.props.dispatch(addMessage("error", message))
                );
              }
            });
        }}
      >
        {() => (
          <Form>
            <Field
              name="isOverweight"
              label="Is trailer overweight?"
              component={CheckBox}
            />
            <div className="button-col">
              <Button
                type="submit"
                variant="primary"
                size="small"
                title="Update overweight status"
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect()(OverweightFlagForm);
