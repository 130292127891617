import React from "react";
import { format } from "date-fns";
import { parseFromTimeZone } from "date-fns-timezone";

export default function TrailerUnavailableIcon({ trailer }) {
  if (!trailer || !trailer.unavailabilityReason) {
    return "";
  }

  function iconType(reason) {
    switch (reason) {
      case "service":
      case "mot":
        return "build";
      case "vor":
        return "error";
      case "tracker_issues":
        return "my_location";

      default:
        return "warning";
    }
  }

  function iconTitle(reason) {
    switch (reason) {
      case "service":
        return "Service";
      case "mot":
        return "MOT";
      case "vor":
        return "Vehicle Off Road";
      case "tracker_issues":
        return "Tracker Issues";
      case "returns_rebooks":
        return "Returns/Rebooks";

      default:
        return "Unavailable";
    }
  }

  function iconNote(trailer) {
    if (trailer.unavailabilityNotes) {
      return `${iconTitle(trailer.unavailabilityReason)} - ${
        trailer.unavailabilityNotes
      }`;
    }

    if (trailer.returnDate) {
      const returnDate = format(
        parseFromTimeZone(trailer.returnDate, {
          timeZone: "Etc/UTC"
        }),
        "DD/MM/YYYY"
      );
      return `${iconTitle(trailer.unavailabilityReason)} - ${returnDate}`;
    }

    return iconTitle(trailer.unavailabilityReason);
  }

  return (
    <i
      className="material-icons"
      style={{
        fontSize: "19px",
        verticalAlign: "text-bottom"
      }}
      title={iconNote(trailer)}
    >
      {iconType(trailer.unavailabilityReason)}
    </i>
  );
}
