import React from "react";
import SelectBox from "form-controls/SelectBox";
import { Formik, Field, Form } from "formik";
import Button from "Button";
import Loader from "Loader";
import { allTrailers, transferBooking } from "./trailerQueries";

export default class TranshipBookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trailers: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    allTrailers().then(({ trailers }) => {
      this.setState({ trailers, loading: false });
    });
  }

  get trailerOptions() {
    return this.state.trailers.map(t => ({
      value: t.id,
      label: `${t.haulier.shortCode} ${t.trailerNo} ${
        t.isInternal ? "(internal)" : ""
      }`,
      isDisabled:
        t.booking !== null ||
        (this.props.direction === "outbound" && t.isInternal)
    }));
  }

  get initialValues() {
    return {
      trailerId: null
    };
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <Formik
        enableReinitialize
        initialValues={this.initialValues}
        onSubmit={values => {
          transferBooking(this.props.bookingId, values.trailerId).then(() => {
            return this.props.afterUpdate();
          });
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Field
              name="trailerId"
              label="Transfer load"
              component={SelectBox}
              options={this.trailerOptions}
              placeholder="Select a trailer"
            />
            <div className="button-col">
              <Button
                type="submit"
                variant="primary"
                size="small"
                title="Transfer booking"
                disabled={
                  Object.keys(touched).length < 1 || Object.keys(errors).length
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
