import React from "react";

export default function WeekLabels() {
  const dayLabels = ["S", "M", "T", "W", "T", "F", "S"];

  return (
    <div className="day-table__row">
      {dayLabels.map((label, index) => (
        <span key={index} className="day-table__heading">
          {label}
        </span>
      ))}
    </div>
  );
}
