const languages = [
  { code: "en", name: "English", flag: "GB" },
  { code: "de", name: "German", flag: "DE" },
  { code: "fr", name: "French", flag: "FR" },
  { code: "it", name: "Italian", flag: "IT" },
  { code: "es", name: "Spanish", flag: "ES" },
  { code: "tr", name: "Turkish", flag: "TR" },
  { code: "pl", name: "Polish", flag: "PL" },
  { code: "ru", name: "Russian", flag: "RU" },
  { code: "nl", name: "Dutch", flag: "NL" },
  { code: "ro", name: "Romanian", flag: "RO" },
  { code: "hu", name: "Hungarian", flag: "HU" },
  { code: "cz", name: "Czech", flag: "CZ" },
  { code: "bg", name: "Bulgarian", flag: "BG" },
  { code: "sk", name: "Slovak", flag: "SK" },
  { code: "lt", name: "Lithuanian", flag: "LT" },
  { code: "sl", name: "Slovenian", flag: "SL" },
  // { code: "nl", name: "Flemish", flag: "NL"},
  { code: "sr", name: "Serbian", flag: "SR" }
];

export function languageOptionValues() {
  return languages.map(({ code, name }) => ({ value: code, label: name }));
}

export function getLanguageFlagCode(languageCode) {
  const language = languages.find(item => item.code === languageCode);
  return language ? language.flag : "";
}

const bookingDisplayStatuses = {
  en: {
    arrived: "Waiting",
    trailer_attached: "Waiting",
    inbound_in_progress: "Unloading",
    outbound_in_progress: "Loading",
    ready_for_driver: "Ready"
  },
  de: {
    arrived: "Warten",
    trailer_attached: "Warten",
    inbound_in_progress: "Entladung",
    outbound_in_progress: "Wird geladen",
    ready_for_driver: "Bereit"
  },
  fr: {
    arrived: "Attendre",
    trailer_attached: "Attendre",
    inbound_in_progress: "Déchargement",
    outbound_in_progress: "Chargement",
    ready_for_driver: "Prêt"
  },
  it: {
    arrived: "In attesa",
    trailer_attached: "In attesa",
    inbound_in_progress: "Scarico",
    outbound_in_progress: "Caricamento in corso",
    ready_for_driver: "Pronto"
  },
  es: {
    arrived: "Esperando",
    trailer_attached: "Esperando",
    inbound_in_progress: "Descarga",
    outbound_in_progress: "Cargando",
    ready_for_driver: "Listo"
  },
  tr: {
    arrived: "Bekleme",
    trailer_attached: "Bekleme",
    inbound_in_progress: "Boşaltma",
    outbound_in_progress: "Yükleniyor",
    ready_for_driver: "hazır"
  },
  pl: {
    arrived: "Czekanie",
    trailer_attached: "Czekanie",
    inbound_in_progress: "Rozładunek",
    outbound_in_progress: "Ładowanie",
    ready_for_driver: "Gotowy"
  },
  ru: {
    arrived: "Ожидание",
    trailer_attached: "Ожидание",
    inbound_in_progress: "разгрузочный",
    outbound_in_progress: "загрузка",
    ready_for_driver: "готов"
  },
  nl: {
    arrived: "Aan het wachten",
    trailer_attached: "Aan het wachten",
    inbound_in_progress: "Lossen",
    outbound_in_progress: "Bezig met laden",
    ready_for_driver: "Klaar"
  },
  ro: {
    arrived: "Aşteptare",
    trailer_attached: "Aşteptare",
    inbound_in_progress: "Descărcare",
    outbound_in_progress: "Se încarcă",
    ready_for_driver: "Gata"
  },
  hu: {
    arrived: "Várakozás",
    trailer_attached: "Várakozás",
    inbound_in_progress: "Kirakodás",
    outbound_in_progress: "Betöltés",
    ready_for_driver: "Kész"
  },
  cz: {
    arrived: "Čekání",
    trailer_attached: "Čekání",
    inbound_in_progress: "Vykládka",
    outbound_in_progress: "načítání",
    ready_for_driver: "Připraveno"
  },
  bg: {
    arrived: "Очакване",
    trailer_attached: "Очакване",
    inbound_in_progress: "Разтоварване",
    outbound_in_progress: "Зареждане",
    ready_for_driver: "Готов"
  },
  sk: {
    arrived: "čakania",
    trailer_attached: "čakania",
    inbound_in_progress: "vykládka",
    outbound_in_progress: "Načítava",
    ready_for_driver: "pripravený"
  },
  lt: {
    arrived: "Laukia",
    trailer_attached: "Laukia",
    inbound_in_progress: "Iškrovimas",
    outbound_in_progress: "Pakrovimas",
    ready_for_driver: "Parengta"
  },
  sl: {
    arrived: "Čakanje",
    trailer_attached: "Čakanje",
    inbound_in_progress: "Raztovarjanje",
    outbound_in_progress: "nalaganje",
    ready_for_driver: "Pripravljen"
  },
  sr: {
    arrived: "Чекање",
    trailer_attached: "Чекање",
    inbound_in_progress: "Истовар",
    outbound_in_progress: "Учитавање",
    ready_for_driver: "Спреман"
  }
};

export function getBookingDisplayStatus(driverLanguage, status) {
  if (driverLanguage) {
    return bookingDisplayStatuses[driverLanguage][status];
  } else {
    return bookingDisplayStatuses["en"][status];
  }
}
