export function formatAsOptions(sites) {
  const parentSites = sites.filter(site => site.parentSite === null);

  const childSites = sites.filter(site => site.parentSite !== null);

  const pSites = parentSites.map(item => ({
    value: item.id,
    label: item.name
  }));

  const cSites = childSites.map(item => ({
    value: item.id,
    label: (item.parentSite ? item.parentSite.name + " - " : "") + item.name
  }));

  const siteList = [...pSites, ...cSites].sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );

  return siteList;
}
