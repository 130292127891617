import React from "react";
import Can from "Can";
import { updateTrailerStatus } from "../warehouseQueries";
import { dockStatus } from "trailerStatuses";
import { bookingStatuses } from "bookingStatuses";
import { timeOnDock } from "trailer-helper";
import { isPending } from "movement-helper";
import classnames from "classnames";
import DockAction from "./DockAction";
import RebookingIcon from "RebookingIcon";
import TrailerUnavailableIcon from "TrailerUnavailableIcon";
import "./DockTable.css";
import noSignal from "assets/noSignal.svg";
import low from "assets/low.svg";
import config from "../../../../config";
import LoadIndicator from "LoadIndicators";

function PreviousActionButton(trailer) {
  const { id, status, booking } = trailer;

  const previousStatus = status => {
    switch (status) {
      case "action_in_progress":
        return "awaiting_action";
      case "action_complete":
        return "action_in_progress";
      case "ready_to_move":
        return "action_complete";
      default:
        return "";
    }
  };

  const isPrevActionDisabled =
    ["awaiting_action"].includes(status) ||
    (booking && booking.status === bookingStatuses.readyForDriver);

  return (
    <i
      className={`material-icons prev-action${
        isPrevActionDisabled ? "-disabled" : ""
      }`}
      onClick={() => {
        if (!isPrevActionDisabled) {
          updateTrailerStatus(id, previousStatus(status));
        }
      }}
    >
      keyboard_arrow_left
    </i>
  );
}

function NextActionButton(trailer) {
  const { id, status, booking } = trailer;

  const nextStatus = status => {
    switch (status) {
      case "awaiting_action":
        return "action_in_progress";
      case "action_in_progress":
        return "action_complete";
      default:
        return "";
    }
  };
  const isNextActionDisabled =
    ["action_complete", "ready_to_move"].includes(status) ||
    (booking && booking.status === bookingStatuses.readyForDriver);

  return (
    <i
      className={`material-icons next-action${
        isNextActionDisabled ? "-disabled" : ""
      }`}
      onClick={() => {
        if (!isNextActionDisabled) {
          updateTrailerStatus(id, nextStatus(status));
        }
      }}
    >
      keyboard_arrow_right
    </i>
  );
}

export default function NumberedDockTableRow({
  dock,
  trailers,
  onRequestTrailer,
  onMoveTrailer,
  onCancelMovement,
  onSelectBooking,
  movement: incomingMovement,
  isSelected,
  releaseTrailer,
  zone
}) {
  const [trailer] = trailers;
  const isManual = trailer && trailer.booking && !trailer.booking.appointment;
  const classname = classnames("table-row", {
    "table-row--selected": isSelected,
    "table-row--disabled": !dock.isActive,
    manual: isManual
  });

  const emptyRow = {
    trailer: null,
    numberOfPallets: "",
    journeyReference: "",
    poNumber: "",
    direction: "",
    title: "",
    timeOnDock: "",
    previousAction: "",
    nextAction: "",
    status: ""
  };

  function trailerRowData(rowData, trailer, movement) {
    const { booking } = trailer;

    return {
      ...rowData,
      ...booking,
      title: `${trailer.haulier.shortCode} - ${trailer.trailerNo} (${trailer.trailerType.type})`,
      trailer,
      timeOnDock: timeOnDock(trailer),
      previousAction: PreviousActionButton(trailer),
      nextAction: NextActionButton(trailer),
      status: dockStatus(trailer.status, booking && booking.status),
      incomingMovement: movement
    };
  }

  function movementRowData(rowData, movement) {
    const isArrivingOnTheDock = movement.location.zone.id === zone.id;
    return {
      ...rowData,
      status: `${movement.status.replace("_", " ")} ${
        isArrivingOnTheDock ? "" : "⇢"
      }`,
      previousAction: "",
      nextAction: ""
    };
  }

  function rowData() {
    if (trailers.length > 0) {
      const [trailer] = trailers;
      const outgoingMovement = trailer.movements
        ? trailer.movements.find(isPending)
        : false;

      if (outgoingMovement) {
        if (
          outgoingMovement.location.dock &&
          outgoingMovement.location.dock.id === trailer.dock.id
        ) {
          return [
            movementRowData(trailerRowData(emptyRow, trailer), outgoingMovement)
          ];
        }

        if (isPending(incomingMovement)) {
          return [
            movementRowData(
              trailerRowData(emptyRow, trailer, incomingMovement),
              outgoingMovement
            ),
            movementRowData(
              trailerRowData(
                emptyRow,
                incomingMovement.trailer,
                incomingMovement
              ),
              incomingMovement
            )
          ];
        }

        return [
          movementRowData(trailerRowData(emptyRow, trailer), outgoingMovement),
          emptyRow
        ];
      }

      return [trailerRowData(emptyRow, trailer)];
    } else if (isPending(incomingMovement)) {
      return [
        movementRowData(
          trailerRowData(emptyRow, incomingMovement.trailer, incomingMovement),
          incomingMovement
        )
      ];
    } else {
      return [emptyRow];
    }
  }

  const data = rowData();
  return data.map((d, index) => (
    <tr className={classname} key={index}>
      <td>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <TrailerUnavailableIcon trailer={d.trailer} />
          {d.appointment && <LoadIndicator appointment={d.appointment} />}
        </div>
      </td>
      {index === 0 && <td rowSpan={data.length}>{dock.number}</td>}
      {config.FEATURE_DOCK_SAFETY_ENABLED && index === 0 && (
        <td rowSpan={data.length} style={{ textAlign: "center" }}>
          {dock.dockSafetyDevice ? (
            <DeviceIcon dockSafetyDevice={dock.dockSafetyDevice} />
          ) : (
            "-"
          )}
        </td>
      )}
      <td>{d.title}</td>
      <td>{d.numberOfPallets}</td>
      <td>
        {d.journeyReference && (
          <div className="link" onClick={() => onSelectBooking(d.id)}>
            <RebookingIcon booking={d} />
          </div>
        )}
      </td>
      <td>
        {d.journeyReference && (
          <div className="link" onClick={() => onSelectBooking(d.id)}>
            {d.journeyReference}
          </div>
        )}
      </td>
      <td>{d.appointment ? d.appointment.loadNo : d.poNumber}</td>
      <td style={{ textTransform: "capitalize" }}>{d.direction}</td>
      <td>{d.timeOnDock}</td>
      <td>
        <Can when="warehouse.zones.updateStatus">{d.previousAction}</Can>
      </td>
      <td>
        <div className="status" style={{ textTransform: "capitalize" }}>
          {d.status}
        </div>
      </td>
      <td>
        <Can when="warehouse.zones.updateStatus">{d.nextAction}</Can>
      </td>
      <td>
        <DockAction
          trailer={d.trailer}
          incomingMovement={d.incomingMovement}
          dock={dock}
          zone={zone}
          onCancelMovement={onCancelMovement}
          onMoveTrailer={onMoveTrailer}
          onRequestTrailer={onRequestTrailer}
          releaseTrailer={releaseTrailer}
        />
      </td>
    </tr>
  ));
}

function DeviceIcon({ dockSafetyDevice }) {
  if (dockSafetyDevice.errorState == "Connection Lost") {
    return (
      <div style={{ color: "rgba(209, 73, 91, 1)" }}>
        <img src={noSignal} style={{ height: "23px" }} />
      </div>
    );
  } else if (dockSafetyDevice.trafficLightStatus == "green") {
    return (
      <div
        style={{
          color: "rgba(143, 201, 58, 1)",
          display: "flex",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <span className="material-icons">radio_button_checked</span>
        <BatteryIcon level={dockSafetyDevice.currentPower} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          color: "rgba(209, 73, 91, 1)",
          display: "flex",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <span className="material-icons">radio_button_checked</span>
        <BatteryIcon level={dockSafetyDevice.currentPower} />
      </div>
    );
  }
}

function BatteryIcon({ level }) {
  if (level <= 15) {
    return (
      <div style={{ color: "rgba(209, 73, 91, 1)" }}>
        <img src={low} style={{ height: "23px" }} />
      </div>
    );
  }
  return null;
}
