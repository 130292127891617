import React, { useState } from "react";
import { formatLocalDate, isDate } from "date-helper";
import Button from "Button";

const ASC = 1;
const DESC = -1;

export default function Table({
  headers,
  rows,
  noDataMessage = "No data to display.",
  sortable = true,
  pagination = null,
  onNextPage = () => {},
  onPrevPage = () => {},
}) {
  const [[sortColumn, sortDirection], setSort] = useState([null, null]);

  const toggleSort = column => {
    if (headers[column] === "") {
      // It's unlikely that a column without a header will be sortable
      return;
    }

    if (column === sortColumn) {
      if (sortDirection === ASC) {
        setSort([column, DESC]);
      } else {
        setSort([null, null]);
      }
    } else {
      setSort([column, ASC]);
    }
  };

  if (rows.length === 0) {
    return <p>{noDataMessage}</p>;
  }

  const sortedRows = [...rows].map(row =>
    row.map(cell => (Array.isArray(cell) ? cell : [cell]))
  );

  const extractSortableValue = ([value]) => {
    if (value !== null && typeof value === "object" && value.props) {
      value = value.props.children;
    }
    return value;
  };

  if (sortColumn !== null) {
    sortedRows.sort((a, b) => {
      const aValue = extractSortableValue(a[sortColumn]);
      const bValue = extractSortableValue(b[sortColumn]);

      if (aValue === null) {
        if (bValue === null) {
          return 0;
        } else {
          return sortDirection * -1;
        }
      } else if (bValue === null) {
        return sortDirection;
      }

      if (aValue < bValue) {
        return sortDirection * -1;
      }

      if (aValue > bValue) {
        return sortDirection;
      }

      return 0;
    });
  }

  const sortIndicator = idx => {
    if (idx !== sortColumn) {
      return "";
    }

    return sortDirection === ASC ? "▲" : "▼";
  };

  return (
    <table>
      <thead>
        <tr>
          {headers.map((name, idx) => (
            <th
              key={idx}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (sortable) {
                  toggleSort(idx);
                }
              }}
            >
              {name}
              {sortIndicator(idx)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedRows.map((row, idx) => (
          <tr key={idx}>
            {row.map(([value, props], idx) => {
              return (
                <td key={idx} {...props}>
                  {isDate(value) ? formatLocalDate(value) : value}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
      {pagination && (
        <tfoot>
          <tr>
            <td colSpan={headers.length}>
              <span style={{ display: "flex", alignItems: "center" }}>
                Showing {pagination.pageSize * (pagination.pageNumber - 1) + 1}{" "}
                to{" "}
                {Math.min(
                  pagination.totalEntries,
                  pagination.pageSize * pagination.pageNumber
                )}{" "}
                of {pagination.totalEntries} results
                {pagination.pageNumber > 1 && (
                  <Button
                    style={{ flex: 1, flexGrow: 0, marginLeft: "auto" }}
                    title="Prev"
                    variant="white"
                    size="tiny"
                    onClick={onPrevPage}
                  />
                )}
                {pagination.pageNumber < pagination.totalPages && (
                  <Button
                    style={{
                      flex: 1,
                      flexGrow: 0,
                      marginLeft: pagination.pageNumber === 1 ? "auto" : "1rem",
                    }}
                    title="Next"
                    variant="white"
                    size="tiny"
                    onClick={onNextPage}
                  />
                )}
              </span>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
}
