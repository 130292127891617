import React from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import PrivateRoute from "PrivateRoute";
import { clearAuth, clearRedirect } from "redux/auth";
import RegisterPage from "apps/bookings/pages/RegisterPage";
import RegisterConfirmationPage from "apps/bookings/pages/RegisterConfirmationPage";
import InvalidTokenPage from "bookings/pages/InvalidTokenPage";
import InvalidRecoverTokenPage from "bookings/pages/InvalidRecoverTokenPage";
import LoginPage from "bookings/pages/LoginPage";
import ForgotPasswordPage from "bookings/pages/ForgotPasswordPage";
import UpdatePasswordPage from "bookings/pages/UpdatePasswordPage";
import RecoverAccountPage from "bookings/pages/RecoverAccountPage";
import NotFoundPage from "./NotFoundPage";
import BookingsRoot from "bookings/BookingsRoot";
import SettingsRoot from "settings/SettingsRoot";
import TrafficRoot from "traffic/TrafficRoot";
import FlightboardRoot from "flightboard/FlightboardRoot";
import WarehouseRoot from "warehouse/WarehouseRoot";
import { logout } from "api/auth";
import "./Root.css";

import { useEffect } from "react";
import { getPermissions } from "api/auth";
import { updateAuth } from "redux/auth";
import { withRouter } from "react-router";

function SsoHandler({ location, dispatch }) {
  const defaultPathFromPermission = (p) => `/${p.replace(".access", "")}`;

  useEffect(() => {
    getPermissions().then((res) => {
      const defaultPath = defaultPathFromPermission(
        res.data.permissions.filter((p) => p.endsWith(".access"))[0]
      );

      const pushTo =
        location.state !== undefined && location.state.from.pathname !== "/"
          ? location.state.from.pathname + location.state.from.search
          : defaultPath;

      dispatch(
        updateAuth(
          res.data.token,
          true,
          res.data.permissions,
          res.data.allowedSites,
          pushTo
        )
      );
    }, []);
  });

  return "Logging you in, please wait...";
}

const SsoHandlerPage = connect()(withRouter(SsoHandler));

class AppsRouter extends React.Component {
  componentDidUpdate(prevProps) {
    const { auth } = this.props;
    if (auth.token !== prevProps.auth.token) {
      this.props.storeAppData(this.props.auth);
    }
  }

  render() {
    const state = { ...history.state };
    delete state.state;
    history.replaceState(state, state);

    const redirect =
      this.props.auth.pushTo !== "" ? this.props.auth.pushTo : null;

    if (redirect) {
      this.props.dispatch(clearRedirect());
    }

    return (
      <Router>
        {redirect && <Redirect to={this.props.auth.pushTo} />}
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to="/auth/login" />;
            }}
          />
          <Route
            exact
            path="/auth/login"
            render={() => {
              return <LoginPage {...this.props} />;
            }}
          />
          <Route path="/auth/login/forgot" component={ForgotPasswordPage} />
          <Route
            path="/auth/login/invalid"
            component={InvalidRecoverTokenPage}
          />
          <Route
            path="/auth/login/recover/:token"
            component={RecoverAccountPage}
          />
          <Route
            path="/auth/logout"
            render={() => {
              logout();
              this.props.dispatch(clearRedirect());
              this.props.dispatch(clearAuth());

              return <Redirect to="/auth/login" />;
            }}
          />
          <Route exact path="/auth/sso/handle" component={SsoHandlerPage} />
          <Route path="/auth/update-password" component={UpdatePasswordPage} />
          <Route path="/auth/register/invalid" component={InvalidTokenPage} />
          <Route
            path="/auth/register/success"
            component={RegisterConfirmationPage}
          />
          <Route path="/auth/register/:inviteToken" component={RegisterPage} />
          <PrivateRoute path="/bookings" component={BookingsRoot} />
          <PrivateRoute path="/settings" component={SettingsRoot} />
          <PrivateRoute path="/traffic" component={TrafficRoot} />
          <PrivateRoute path="/flightboard" component={FlightboardRoot} />
          <PrivateRoute path="/warehouse" component={WarehouseRoot} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps({ auth, app }) {
  return { auth, app };
}

export default connect(mapStateToProps)(AppsRouter);
