export function isPending(movement) {
  const pendingStatuses = [
    "pending",
    "blocked",
    "assigned",
    "picked_up",
    "tipping"
  ];
  return movement && pendingStatuses.includes(movement.status);
}
