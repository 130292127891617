import axios from "axios";

const rootURL = "/api/zones";

export function listZones() {
  return axios.get(rootURL).then(result => result.data.data);
}

export function getZonesBySite(siteId) {
  return axios
    .get("/api/zones", {
      params: {
        siteId: siteId
      }
    })
    .then(res => {
      return res.data.data;
    });
}

export function getZone(id) {
  return axios.get(`${rootURL}/${id}`).then(res => {
    return res.data.data;
  });
}

export function updateZone(id, zone) {
  return axios
    .put(`${rootURL}/${id}`, {
      zone
    })
    .then(result => {
      return result.data;
    });
}

export function createZone(zone) {
  return axios
    .post(rootURL, {
      zone
    })
    .then(result => {
      return result.data;
    });
}

export function deleteZone(id) {
  return axios.delete(`${rootURL}/${id}`).then(result => {
    return result.data;
  });
}
