import { fetch, toArgList } from "graphql-helper";

export function getSiteBookings(siteId, date, includeInternal, searchQuery) {
  const args = {
    siteId,
    date,
    includeInternal,
    searchQuery,
    filterOldBookings: !date,
    showOngoing: true
  };

  return fetch(`query {
        bookings(${toArgList(args)}) {
              id
              journeyReference
              numberOfPallets
              origin
              direction
              slotStart
              slotEnd
              status
              insertedAt
              driverName
              loadType
              isRebooking
              requiresTranship
              requiresEmptyTrailer
              permittedTypes {
                id
                type
              }
              appointment {
                id
                data
                updatedAt
                messageDatetime
                yardName
                yms
                direction
                loadType
                haulier
                trailerNo
                trailerType
                loadNo
                messageId
              }
              mission {
                operationStatus
                lastMessageReceived
              }
              sendingSite {
                id
                name
                parentSite {
                  id
                  name
                }
              }
              receivingSite {
                id
                name
                parentSite {
                  id
                  name
                }
              }
              reasonDeleted
              updatedAt
              vehicleReg
              poNumber
              notes
              allocations {
                id
              }
              haulier {
                id
                name
                shortCode
              }
              trailerOwner {
                id
                name
                shortCode
              }
              trailerType {
                id
                baseType
                type
                name
              }
              trailer {
                id
                trailerNo
                isUnavailable
                haulier {
                  id
                  name
                  shortCode
                }
                zone {
                  id
                  name
                  type
                }
                dock {
                  id
                  number
                }
              }
              weighbridgeTransactions {
                vehicleWeight
                startTime
                error
              }
            }
        }`);
}

export function trailerTypesAndHauliers() {
  return fetch(`query {
      trailerTypes(allowCheckIn: true) {
        id
        name
        baseType
        type
        isRigid
      }
      hauliers {
        id
        name
        shortCode
      }
    }`);
}

export function updateBookingStatus(id, status) {
  return fetch(`
    mutation UpdateBooking {
      booking: updateBooking(id: ${id},
        changeset:{
          status: "${status}"
        }) {
        id
        status
      }
    }
  `);
}

export function checkOut(bookingId) {
  return fetch(`
    mutation CheckOut {
      booking: checkOut(bookingId: ${bookingId}) {
        id
        status
      }
    }
  `);
}

export function checkOutExportLoad(
  bookingId,
  photo,
  vetCheckRequired,
  vetCheckPerformed
) {
  return fetch(`
    mutation checkOutExportLoad {
      booking: checkOutExportLoad(bookingsId: ${bookingId}, photo: "${photo}", vetCheckRequired: ${vetCheckRequired}, vetCheckPerformed: ${vetCheckPerformed}) {
        id
        status
      }
    }
  `);
}

export function confirmStagedLoad(id) {
  return fetch(`
    mutation UpdateBooking {
      booking: updateBooking(id: ${id},
        changeset:{
          status: "ready_for_driver"
          direction: "outbound"
        }) {
        id
        status
        direction
      }
    }
  `);
}

export function driverOffSite(id, direction) {
  const args = { driverName: null, vehicleReg: null };

  if (direction === "outbound") {
    args.checkInCompleteAt = null;
  }

  return fetch(`
    mutation UpdateBooking {
      booking: updateBooking(id: ${id},
        changeset:{
          ${toArgList(args, true)}
        }) {
        id
        receivingSite{
          id
        }
        sendingSite{
          id
        }
        journeyReference
      }
    }
  `);
}

export function checkIn(args) {
  return fetch(`
    mutation {
      checkIn(${toArgList(args)}) {
        id
        trailer {
          zone {
            name
          }
          dock {
            number
          }
        }
      }
    }
  `);
}

export function searchBookings(searchQuery, direction = null) {
  const args = {
    searchQuery,
    direction
  };

  return fetch(`
    query {
      bookings(${toArgList(args)}) {
        id
        journeyReference
        status
        direction
        slotStart
      }
    }
`);
}

export function getDailyPalletInfo(siteId, date) {
  const args = {
    siteId,
    date
  };

  return fetch(`
    query {
      dailyPalletInfo(${toArgList(args)}) {
        dailyPalletLimit
        palletsForDate
      }
    }
`);
}
