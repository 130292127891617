import React from "react";
import { connect } from "react-redux";
import { addMessage } from "redux/app";
import { withRouter } from "react-router";
import { fetch } from "graphql-helper";
import { fetchAllAccounts, updateUserAccount } from "api/accounts";
import Loader from "Loader";
import AdminPanel from "AdminPanel";
import AdminMenuItem from "AdminMenuItem";
import LandingPanel from "LandingPanel";
import InviteForm from "./InviteForm";
import InviteUserForm from "./InviteUserForm";
import UserDetailsForm from "./UserDetailsForm";
import UserPermissionsForm from "./UserPermissionsForm";
import UserSitesForm from "./UserSitesForm";
import "./UserRolesForm.css";
import Can from "Can";

const haulierQuery = `
query {
  hauliers {
    id
    name
  }
}
`;

class UserRolesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      hauliers: [],
      userLoading: true,
      selectedUser: "",
      location: "",
      newUser: false,
      showInvites: false
    };
  }

  componentDidMount() {
    if (this.state.userLoading) {
      this.loadAccounts();
    }

    fetch(haulierQuery).then(({ hauliers }) => {
      this.setState({ hauliers });
    });
  }

  loadAccounts() {
    this.setState({ ...this.state, userLoading: true });
    fetchAllAccounts().then(accounts => {
      this.setState({
        accounts: accounts.filter(
          a => !a.isDeviceAccount && a.email !== "atlas@mercurius-yard.co.uk"
        ),
        userLoading: false
      });
    });
  }

  saveUserRoles(roles, id) {
    const users = this.state.accounts;
    users[id]["roles"] = roles;
    updateUserAccount(users[id]).then(account => {
      users[id] = account;
      this.setState({ accounts: users });
      this.props.dispatch(addMessage("success", "User role(s) updated."));
    });
  }

  changeStatus(id) {
    const users = this.state.accounts;
    users[id]["isActive"] = !users[id]["isActive"];
    updateUserAccount(users[id]).then(account => {
      users[id] = account;
      this.setState({ accounts: users });
      this.props.dispatch(addMessage("success", "User status updated."));
    });
  }

  showUser(id) {
    this.setState({
      selectedUser: id,
      newUser: false,
      showInvites: false,
      location: ""
    });
  }

  addNewUser() {
    this.setState({ newUser: true, showInvites: false, selectedUser: "" });
  }

  selectTab(location) {
    this.setState({ location: location });
  }

  showInvites() {
    this.setState({ showInvites: true, newUser: false, selectedUser: "" });
  }

  updateUserAccounts(id, user) {
    const accounts = this.state.accounts.map(account =>
      account.id === id ? user : account
    );
    this.setState({ accounts: accounts });
  }

  returnHome(opts = {}) {
    this.setState({ newUser: false, selectedUser: "" });

    if (opts.refreshAccounts) {
      this.loadAccounts();
    }
  }

  get selectedUser() {
    return this.state.accounts.find(
      user => user.id === this.state.selectedUser
    );
  }

  render() {
    if (this.state.userLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const menuItems = [
      { name: "User Details", permission: "settings.users.view" },
      {
        name: "Roles & Permissions",
        permission: "settings.users.view.roles"
      },
      { name: "Site Access", permission: "settings.users.view.siteAccess" }
    ];

    const location =
      this.state.location !== "" ? this.state.location : menuItems[0]["name"];
    return (
      <AdminPanel
        title="USERS"
        items={this.state.accounts}
        footerText="Pending Invitations"
        footerLink={this.showInvites.bind(this)}
        showItem={this.showUser.bind(this)}
        addNew={this.addNewUser.bind(this)}
        addPermission="settings.users.invite"
        selectedId={this.state.selectedUser}
      >
        {this.state.selectedUser === "" &&
          !this.state.newUser &&
          !this.state.showInvites && <LandingPanel text="Select a User" />}
        {this.state.newUser && (
          <div className="admin-details">
            <div className="admin-details-title">New User</div>
            <div className="admin-details-location">Send Invitation</div>
            <div className="admin-details-main">
              <InviteUserForm
                showInvites={this.showInvites.bind(this)}
                cancel={this.returnHome.bind(this)}
              />
            </div>
          </div>
        )}
        {this.state.showInvites && (
          <div className="admin-details">
            <div className="admin-details-title">New User</div>
            <div className="admin-details-location">Pending Invitations</div>
            <div className="admin-details-main">
              <InviteForm />
            </div>
          </div>
        )}
        {this.state.selectedUser !== "" && (
          <div className="admin-details">
            <div className="admin-details-title">{this.selectedUser.name}</div>
            <div className="admin-details-location">{location}</div>

            <div className="admin-details-main">
              <div className="admin-details-menu">
                {menuItems.map((item, idx) => (
                  <Can key={idx} when={item["permission"]}>
                    <AdminMenuItem
                      key={idx}
                      id={idx}
                      title={item["name"]}
                      select={this.selectTab.bind(this)}
                      selected={location}
                    />
                  </Can>
                ))}
              </div>
              {location === "User Details" && (
                <div className="form-columns">
                  <UserDetailsForm
                    user={this.selectedUser}
                    selectedUser={this.state.selectedUser}
                    updateUserAccounts={this.updateUserAccounts.bind(this)}
                    cancel={this.returnHome.bind(this)}
                  />
                </div>
              )}
              {location === "Roles & Permissions" && (
                <UserPermissionsForm
                  user={this.selectedUser}
                  hauliers={this.state.hauliers}
                  selectedUser={this.state.selectedUser}
                  updateUserAccounts={this.updateUserAccounts.bind(this)}
                  cancel={this.returnHome.bind(this)}
                />
              )}
              {location === "Site Access" && (
                <UserSitesForm
                  user={this.selectedUser}
                  selectedUser={this.selectedUser}
                  updateUserAccounts={this.updateUserAccounts.bind(this)}
                  cancel={this.returnHome.bind(this)}
                />
              )}
            </div>
          </div>
        )}
      </AdminPanel>
    );
  }
}

export default connect()(withRouter(UserRolesForm));
