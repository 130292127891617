import { fetch } from "graphql-helper";
import { format } from "date-fns";

export function siteData(siteId, date) {
  return fetch(`query {
      site: site(id: ${siteId}) {
        zones {
          id
          name
          capacity
          type
          trailers {
            id
            trailerNo
            haulier {
              id
            }
            booking {
              id
              direction
            }
          }
        }
        id
        name
        children {
          id
          name
          zones {
            id
            name
            capacity
            type
            trailers {
              id
              trailerNo
            }
          }
        }
      }
      assignedMovements: movements (status: ["assigned", "picked_up"]) {
          ...movementFields
          device {
            id
            deviceId
          }
        }

      standLevel: standLevel(siteId: ${siteId}) {
        id
        site{
          id
          name
        }
        haulier{
          id
          name
          shortCode
          standLevel
        }
        standLevel
      }
      trailers(site: ${siteId}) {
        id
        trailerNo
        isUnavailable
        trailerType {
          id
          name
        }
        haulier {
          id
          name
          shortCode
        }
        booking {
          id
          direction
        }
      }
      trafficAgg (siteId: ${siteId}, slot_start_date: "${format(
    date,
    "YYYY-MM-DD"
  )}") {
        inboundBookingCount
        outboundBookingCount
        internalBookingCount
        emptyBookings {
          haulier {
            id
            shortCode
            name
          }
          inboundCount
          outboundCount
        }
      }
    }
    
     fragment movementFields on Movement {
        id
        trailer {
          id
          haulier {
            shortCode
            name
          }
          trailerNo
          trailerType {
            type
            baseType
          }
          zone {
            name
          }
          dock {
            number
          }
        }
        location {
          zone {
            name
          }
          dock {
            number
          }
        }
        status
        insertedAt
      }
    `);
}
