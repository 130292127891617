import React, { Component } from "react";
import {
  parse,
  getHours,
  getMinutes,
  differenceInMinutes,
  isBefore,
  isAfter
} from "date-fns";
import classnames from "classnames";

export default class Booking extends Component {
  constructor(props) {
    super(props);
    this.bookingRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.selectedItem === this.props.item.journeyReference) {
      this.bookingRef.current.scrollIntoView();
    }
  }

  getClass(selected, slotStart, slotEnd, updated_at, status, direction) {
    const now = new Date();
    return classnames("booking-info", {
      "booking-info--selected": selected,
      "booking-info--overdue-gravity-1":
        isAfter(updated_at, slotEnd) &&
        differenceInMinutes(updated_at, slotEnd) < 15 &&
        status === null,
      "booking-info--overdue-gravity-2":
        isAfter(updated_at, slotEnd) &&
        differenceInMinutes(updated_at, slotEnd) < 30 &&
        status == null,
      "booking-info--overdue-gravity-3":
        (isBefore(updated_at, slotStart) && isAfter(now, slotEnd)) ||
        (isAfter(updated_at, slotEnd) &&
          differenceInMinutes(updated_at, slotEnd) >= 30 &&
          status == null),
      "internal-booking": direction === "internal"
    });
  }

  render() {
    const start = this.props.item.viewStart;
    const end = this.props.item.viewEnd;
    const hours = getHours(parse(start)) * 80;
    const mins = getMinutes(parse(start)) * (80 / 60);
    const top = hours + mins;
    const length = differenceInMinutes(parse(end), parse(start)) / 30;
    const height = length * 40;
    const selected =
      this.props.item.journeyReference === this.props.selectedItem;

    const header = (() => {
      const { item } = this.props;
      const parts = [item.journeyReference];

      if (item.haulier) {
        parts.push(item.haulier.name);
      } else if (!item.direction === "internal") {
        parts.push("PROPOSED");
      }

      if (item.direction === "internal") {
        parts.push("INTERNAL");
      }

      return parts.join(" - ");
    })();

    return (
      <div
        ref={this.bookingRef}
        data-reference={this.props.item.journeyReference}
        className="booked"
        style={{
          position: "absolute",
          top: `${top}px`,
          height: `${height}px`
        }}
        onClick={this.props.onClick}
      >
        <div
          className={this.getClass(
            selected,
            parse(this.props.item.slotStart),
            parse(this.props.item.slotEnd),
            parse(this.props.item.updatedAt),
            this.props.item.status,
            this.props.item.direction
          )}
        >
          {(isBefore(this.props.item.updatedAt, this.props.item.slotEnd) &&
            isAfter(this.props.item.updatedAt, this.props.item.slotStart)) ||
          isBefore(new Date(), this.props.item.slotStart) ||
          this.props.item.status != null ? (
            <span className="text-header">{header}</span>
          ) : (
            <span className="text-header">
              {this.props.item.journeyReference} - OVERDUE
            </span>
          )}
          <p>{this.props.item.driverName || ""}</p>
        </div>
      </div>
    );
  }
}

export function deselectAllBookings() {
  [...document.querySelectorAll(".booking-info--selected")].forEach(element =>
    element.classList.remove("booking-info--selected")
  );
}

export function selectBooking(journeyReference) {
  deselectAllBookings();
  [
    ...document.querySelectorAll(
      `.booked[data-reference="${journeyReference}"] .booking-info`
    )
  ].forEach(bookingElement => {
    bookingElement.classList.add("booking-info--selected");
  });
}
