import React from "react";
import { format } from "date-fns";
import CustomControl from "./CustomControl";
import DatePicker from "DatePicker";
import "./DateInput.css";

export default function DateInput(props) {
  if (props.disabled) {
    const value = format(new Date(props.field.value), "ddd Do MMMM YYYY");
    return (
      <div className="form-control">
        {props.label}
        <div className="date-input">
          <input
            {...props.field}
            className="date-input__input"
            disabled
            value={value}
          />
          <div className="date-input__icon material-icons">today</div>
        </div>
      </div>
    );
  }

  const { value } = props.field;

  const selectedDate = value ? new Date(value) : new Date();

  return (
    <CustomControl
      title={value ? format(value, "DD-MM-YYYY") : "Please select date"}
      label={props.label}
    >
      <DatePicker
        selectedDate={selectedDate}
        onSelect={date => {
          props.onChange(date);
        }}
      />
    </CustomControl>
  );
}
