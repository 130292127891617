import { fetch } from "graphql-helper";

export function getAppointments() {
  return fetch(`
    query {
      appointments {
        id
        nestleData
        deleted_at
        data
        insertedAt
        updatedAt
        journeyReference
        status
        messageDatetime
        direction
        messageType
        yardName
        yms
        booking {
          id
          journey_reference
        }
        errors
        file
      }
    }
  `);
}
