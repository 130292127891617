import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetch, toArgList } from "graphql-helper";
import Table from "Table";
import Button from "Button";
import HoverButton from "HoverButton";
import Content from "Content";
import Confirm from "Confirm";
import RightPanel from "RightPanel";
import UnavailabilityIcon from "TrailerUnavailableIcon";
import TrafficPageTemplate from "./TrafficPageTemplate";
import config from "../../../config";

const trailersRequiredOffRoadQuery = siteId => `
  query {
    trailers: trailersRequiredOffRoad(${toArgList({ siteId })}) {
      id
      haulier {
        id
        shortCode
      }
      trailerNo
      unavailabilityContact
      unavailabilityReason
      unavailabilityNotes
      returnDate
      validAfter
    }
    hauliers {
      id
      name
    }
  }
`;

const addTrailersMutation = args => `
  mutation {
    addTrailerRequiredOffRoad(${toArgList(args)}) {
      id
    }
  }
`;

const removeTrailerMutation = args => `
  mutation {
    removeTrailerRequiredOffRoad(${toArgList(args)}) {
      id
    }
  }
`;

const UNAVAILABILITY_SERVICE = "service";
const UNAVAILABILITY_MOT = "mot";
const UNAVAILABILITY_VOR = "vor";
const UNAVAILABILITY_TRACKER_ISSUES = "tracker_issues";
const UNAVAILABILITY_RETURNS_REBOOKS = "returns_rebooks";

const unavailabilityReasons = {
  vor: "Vehicle Off Road",
  service: "Service",
  mot: "MOT",
  tracker_issues: "Tracker Issues",
  returns_rebooks: "Returns/Rebooks"
};

const unavailabilityOptions = [
  {
    value: UNAVAILABILITY_SERVICE,
    label: "Service"
  },
  {
    value: UNAVAILABILITY_MOT,
    label: "MOT"
  },
  {
    value: UNAVAILABILITY_VOR,
    label: "Vehicle off Road"
  },
  {
    value: UNAVAILABILITY_TRACKER_ISSUES,
    label: "Tracker Issues"
  },
  ...(config.FEATURE_RETURNS_REBOOKS_UNAVAILABILITY_REASON
    ? [
        {
          value: UNAVAILABILITY_RETURNS_REBOOKS,
          label: "Returns/Rebooks"
        }
      ]
    : [])
];

import { Formik, Form, Field } from "formik";
import DateInput from "form-controls/DateInput";
import TextBox from "form-controls/TextBox";
import TextInput from "form-controls/TextInput";
import SelectBox from "form-controls/SelectBox";
import * as Yup from "yup";
import { sortHaulierOptions } from "../../../utilities/haulier-utils";

const schema = Yup.object().shape({
  trailerNo: Yup.string()
    .uppercase()
    .max(16)
    .required("Please enter the trailer number"),
  haulierId: Yup.string().required("Please select a haulier"),
  unavailabilityReason: Yup.string().required("Please select a reason"),
  unavailabilityContact: Yup.string().required("Please enter a contact"),
  returnDate: Yup.string().when("unavailabilityReason", (reason, schema) =>
    [UNAVAILABILITY_MOT, UNAVAILABILITY_SERVICE].includes(reason)
      ? schema.required("Please select a return date")
      : schema
  )
});

function AddTrailerForm(props) {
  const haulierOptions = props.hauliers.map(h => ({
    label: h.name,
    value: h.id
  }));

  const initialValues = {
    trailerNo: "",
    haulierId: "",
    unavailabilityReason: "",
    unavailabilityContact: "",
    unavailabilityNotes: "",
    returnDate: ""
  };

  return (
    <Formik
      onSubmit={(values, actions) => {
        props.onAdd(values).then(() => {
          actions.resetForm(initialValues);
          actions.setSubmitting(false);
        });
      }}
      validationSchema={schema}
      initialValues={initialValues}
    >
      {({ touched, values, errors, setFieldValue }) => (
        <Form>
          <Field
            name="trailerNo"
            label="Trailer Number"
            component={TextInput}
            error={touched.trailerNo && errors.trailerNo}
          />

          <Field
            name="haulierId"
            label="Haulier (Trailer Owner)"
            component={SelectBox}
            options={sortHaulierOptions(haulierOptions)}
            error={touched.haulierId && errors.haulierId}
          />

          <Field
            name="unavailabilityReason"
            label="Reason for Unavailability"
            component={SelectBox}
            options={unavailabilityOptions}
            error={touched.unavailabilityReason && errors.unavailabilityReason}
          />

          <Field
            name="unavailabilityContact"
            label="Unavailability Contact"
            component={TextInput}
            error={
              touched.unavailabilityContact && errors.unavailabilityContact
            }
          />

          {(values.unavailabilityReason == UNAVAILABILITY_VOR ||
            values.unavailabilityReason == UNAVAILABILITY_RETURNS_REBOOKS) && (
            <Field
              name="unavailabilityNotes"
              label="Unavailability Notes"
              placeholder="Additional notes about vehicle being off road"
              component={TextBox}
            />
          )}
          {[UNAVAILABILITY_SERVICE, UNAVAILABILITY_MOT].includes(
            values.unavailabilityReason
          ) && (
            <Field
              name="returnDate"
              label="Return by Date"
              component={DateInput}
              onChange={date => {
                setFieldValue("returnDate", date);
              }}
              error={touched.returnDate && errors.returnDate}
            />
          )}
          <Button
            variant="primary"
            size="small"
            title="Submit"
            type="submit"
            disabled={Object.keys(errors).length}
          />
        </Form>
      )}
    </Formik>
  );
}

function PageBody(props) {
  const [showingAddTrailer, setShowingAddTrailer] = useState(false);

  const handleShowAdd = () => {
    setShowingAddTrailer(true);
  };

  const handleCloseAdd = () => {
    setShowingAddTrailer(false);
  };

  const headers = [
    "",
    "Type",
    "Owner",
    "Trailer No",
    "Return By",
    "Contact",
    "Notes",
    ""
  ];

  const rows = props.trailers.map(t => [
    <UnavailabilityIcon trailer={t} />,
    unavailabilityReasons[t.unavailabilityReason],
    t.haulier.shortCode,
    t.trailerNo,
    t.returnDate,
    t.unavailabilityContact,
    t.unavailabilityNotes,
    [
      <Confirm title="Are you sure?">
        {confirm => (
          <Button
            variant="primary"
            size="tiny"
            icon="delete"
            title="Remove"
            onClick={confirm(() => props.onRemove(t.id))}
          />
        )}
      </Confirm>,
      { style: { width: "120px" } }
    ]
  ]);

  return (
    <Content>
      <div className="dash-row">
        <div className="content-block traffic-servicing">
          <h2>Service on arrival</h2>
          <p>
            The trailers listed below will be flagged as unavailable once they
            arrive on site.
          </p>
          <Table headers={headers} rows={rows} />
          <RightPanel
            title="Add trailer"
            isExpanded={showingAddTrailer}
            onClose={handleCloseAdd}
          >
            <AddTrailerForm
              hauliers={props.hauliers}
              onAdd={values => props.onAdd(values).then(handleCloseAdd)}
            />
          </RightPanel>
          <HoverButton
            variant="hover"
            title="Add trailer for service"
            onClick={handleShowAdd}
          />
        </div>
      </div>
    </Content>
  );
}

function TrafficServicingPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [trailers, setTrailers] = useState([]);
  const [hauliers, setHauliers] = useState([]);

  const loadTrailers = () => {
    fetch(trailersRequiredOffRoadQuery(props.parentSite.id)).then(
      ({ trailers, hauliers }) => {
        setIsLoading(false);
        setTrailers(trailers);
        setHauliers(hauliers);
      }
    );
  };

  useEffect(() => {
    if (props.parentSite) {
      loadTrailers();
    } else {
      props.history.push({ pathname: "/traffic/trailers/servicing" });
    }
  }, [props.parentSite]);

  const handleRemove = id => {
    fetch(removeTrailerMutation({ id })).then(loadTrailers);
  };

  const handleAdd = args => {
    return fetch(
      addTrailersMutation({ ...args, siteId: props.parentSite.id })
    ).then(loadTrailers);
  };

  return (
    <TrafficPageTemplate>
      {!isLoading && (
        <PageBody
          trailers={trailers}
          hauliers={hauliers}
          onRemove={handleRemove}
          onAdd={handleAdd}
        />
      )}
    </TrafficPageTemplate>
  );
}

function mapStateToProps({ app: { selectedSite }, sites }) {
  const site = sites.items.find(s => s.id == selectedSite);

  let parentSite = site;
  if (site && site.parentSite) {
    parentSite = site.parentSite;
  }

  return { parentSite };
}

export default withRouter(connect(mapStateToProps)(TrafficServicingPage));
