import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import AppTemplate from "templates/AppTemplate";
import Content from "Content";
import Button from "Button";
import {
  getAssignedMovement,
  updateMovementStatus
} from "./device-simulator-page/deviceQueries";
import { addMessage } from "redux/app";
import RegisterDeviceForm from "./device-simulator-page/RegisterDeviceForm";
import { fetch, toArgList } from "graphql-helper";

class DeviceSimulatorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceId: "",
      deviceRegistered: false,
      assignedMovement: null
    };
  }

  get trailerName() {
    const { trailer } = this.state.assignedMovement;

    return trailer.haulier.shortCode + " " + trailer.trailerNo;
  }

  get fromLocation() {
    const { trailer } = this.state.assignedMovement;

    return trailer.zone.name + (trailer.dock ? " " + trailer.dock.number : "");
  }

  get toLocation() {
    const { location } = this.state.assignedMovement;

    return (
      location.zone.name + (location.dock ? " " + location.dock.number : "")
    );
  }

  get nextStatus() {
    switch (this.state.assignedMovement.status) {
      case "assigned":
        return this.toTip ? "tipping" : "picked_up";
      case "tipping":
        return "picked_up";
      case "picked_up":
        return "completed";
      default:
        return this.state.assignedMovement.status;
    }
  }

  get toTip() {
    return (
      this.state.assignedMovement &&
      this.state.assignedMovement.tipLocation.zone &&
      this.state.assignedMovement.tipLocation.zone.id !== null
    );
  }

  pickUpTrailer() {
    updateMovementStatus(this.state.assignedMovement.id, this.nextStatus)
      .then(async () => {
        const { assignedMovement } = await getAssignedMovement(
          this.state.deviceId
        );
        this.setState({ assignedMovement });
      })
      .catch(errors => {
        if (errors instanceof Error) {
          this.props.dispatch(addMessage("error", "An unknown error occurred"));
        }
        errors.forEach(({ message }) =>
          this.props.dispatch(addMessage("error", message))
        );
      });
  }

  blockMovement() {
    const reason = prompt("Reason for blocking:");

    if (reason === null) {
      return;
    }

    const args = {
      id: this.state.assignedMovement.id,
      reason
    };

    fetch(`
      mutation BlockMovement {
        blockMovement(${toArgList(args)}) {
          id
        }
      }
    `)
      .then(() => {
        this.setState({ assignedMovement: null });
      })
      .catch(errors => {
        if (errors instanceof Error) {
          this.props.dispatch(addMessage("error", "An unknown error occurred"));
        }
        errors.forEach(({ message }) =>
          this.props.dispatch(addMessage("error", message))
        );
      });
  }

  render() {
    return (
      <AppTemplate>
        <Content>
          <h3>Device Simulator</h3>
          {!this.state.deviceRegistered && (
            <RegisterDeviceForm
              onRegister={deviceId => {
                this.setState({ deviceId, deviceRegistered: true });
              }}
            />
          )}
          {this.state.deviceRegistered && (
            <p>Device ID: {this.state.deviceId}</p>
          )}
          {this.state.deviceRegistered && !this.state.assignedMovement && (
            <>
              <p>On a break</p>
              <Formik
                initialValues={{}}
                onSubmit={(values, { setSubmitting }) => {
                  getAssignedMovement(this.state.deviceId)
                    .then(({ assignedMovement }) => {
                      this.setState({ assignedMovement });
                    })
                    .catch(errors => {
                      if (errors instanceof Error) {
                        this.props.dispatch(
                          addMessage("error", "An unknown error occurred")
                        );
                      }
                      errors.forEach(({ message }) =>
                        this.props.dispatch(addMessage("error", message))
                      );
                      setSubmitting(false);
                    });
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="button-col">
                      <Button
                        type="submit"
                        variant="primary"
                        size="small"
                        title="Next Movement"
                        disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
          {this.state.assignedMovement && (
            <>
              <p>{this.trailerName}</p>
              <p>From: {this.fromLocation}</p>
              <p>To: {this.toLocation}</p>
              <Formik
                initialValues={{}}
                onSubmit={() => {
                  updateMovementStatus(
                    this.state.assignedMovement.id,
                    "completed"
                  )
                    .then(() => {
                      this.setState({ assignedMovement: null });
                    })
                    .catch(errors => {
                      if (errors instanceof Error) {
                        this.props.dispatch(
                          addMessage("error", "An unknown error occurred")
                        );
                      }
                      errors.forEach(({ message }) =>
                        this.props.dispatch(addMessage("error", message))
                      );
                    });
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="button-col">
                      <Button
                        variant="primary"
                        size="small"
                        title="Pick Up Trailer"
                        onClick={() => {
                          this.pickUpTrailer();
                        }}
                        disabled={
                          isSubmitting ||
                          this.state.assignedMovement.status === "picked_up"
                        }
                      />
                    </div>
                    {this.toTip && (
                      <div className="button-col">
                        <Button
                          variant="primary"
                          size="small"
                          title="Finished Tipping"
                          onClick={() => {
                            this.pickUpTrailer();
                          }}
                          disabled={
                            isSubmitting ||
                            this.state.assignedMovement.status !== "tipping"
                          }
                        />
                      </div>
                    )}
                    <div className="button-col">
                      <Button
                        type="submit"
                        variant="primary"
                        size="small"
                        title="Complete movement"
                        disabled={
                          isSubmitting ||
                          this.state.assignedMovement.status !== "picked_up"
                        }
                      />
                    </div>
                    <div className="button-col">
                      <Button
                        variant="red"
                        size="small"
                        title="Block movement"
                        onClick={() => {
                          this.blockMovement();
                        }}
                        disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Content>
      </AppTemplate>
    );
  }
}

export default connect()(DeviceSimulatorPage);
