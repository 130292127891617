import React from "react";
import { connect } from "react-redux";
import { addMessage } from "redux/app";
import { withRouter } from "react-router";
import * as Yup from "yup";
import Alert from "Alert";
import TextInput from "form-controls/TextInput";
import TextBox from "form-controls/TextBox";
import SelectBox from "form-controls/SelectBox";
import TimePicker from "form-controls/TimePicker";
import DateInput from "form-controls/DateInput";
import CheckBox from "form-controls/CheckBox";
import Button from "Button";
import Notice from "Notice";
import { format } from "date-fns";
import { Formik, Field, Form } from "formik";
import {
  trailerTypesAndHauliers,
  createBooking,
  listSlots,
  deleteBooking,
  updateBooking
} from "./calendar-view-queries";
import { formatAsOptions } from "siteList";
import trailerGoods from "trailer-goods";
import Can from "Can";
import "./BookingForm.css";
import Loader from "Loader";
import { formatWithDefaultTz } from "date-helper";
import { sortTrailerTypeOptions } from "../../../../utilities/trailer-utils";

const bookingValidationSchema = Yup.object().shape({
  time: Yup.string().required("Please choose a timeslot"),
  receivingSiteId: Yup.string().required("Please choose a site"),
  trailerTypeId: Yup.string().required("Please choose trailer type"),
  goods: Yup.mixed().test(
    "goods",
    "Please select at least one goods type",
    value => Object.entries(value).length > 0
  )
});

const bookingTerms = "Your booking has been created.";

class InternalBookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      timeslots: [],
      slotLoading: true,
      booking: [],
      showMessage: false,
      trailerTypes: [],
      sites: [],
      loading: true
    };
  }

  componentDidMount() {
    const { numberOfPallets } = this.initialValues;

    this.getTimeSlots(numberOfPallets);

    trailerTypesAndHauliers().then(data => {
      const trailerTypes = data.trailerTypes.map(type => {
        return { value: type.id, label: type.name };
      });
      this.setState({
        trailerTypes,
        sites: data.sites,
        loading: false
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.resetSlots();
    }

    if (prevState.deleting && this.state.deleting) {
      this.setState({ deleting: false });
    }

    if (
      this.props.selectedBooking !== undefined &&
      this.props.selectedBooking !== prevProps.selectedBooking
    ) {
      this.getTimeSlots(this.props.selectedBooking.numberOfPallets);
    } else if (this.props.selectedBooking !== prevProps.selectedBooking) {
      this.resetSlots();
    }
  }

  get deletionReasons() {
    return [
      { value: "cancelled", label: "Order cancelled" },
      { value: "production", label: "Production change" },
      { value: "rebooked", label: "Load rebooked" }
    ];
  }

  get initialValues() {
    const date = this.props.selectedBooking
      ? this.props.selectedBooking.slotStart
      : this.props.selectedDate;

    const time = this.props.selectedBooking
      ? new Date(this.props.selectedBooking.slotStart).toUTCString()
      : "";

    let goods = this.props.selectedBooking
      ? this.props.selectedBooking.goods
      : [];

    if (goods === null) {
      goods = [];
    }
    goods = goods.reduce(
      (acc, goods) => ({
        ...acc,
        [goods]: true
      }),
      {}
    );
    return {
      date: format(date, "YYYY-MM-DD"),
      time,
      journeyReference: "",
      numberOfPallets: 0,
      sendingSiteId: this.props.selectedBooking
        ? parseInt(this.props.selectedBooking.sendingSite.id)
        : parseInt(this.props.match.params.siteId),
      receivingSiteId: this.props.selectedBooking
        ? parseInt(this.props.selectedBooking.receivingSite.id)
        : "",
      trailerTypeId: this.props.selectedBooking
        ? this.props.selectedBooking.trailerType.id
        : "",
      notes: "",
      reasonDeleted: "",
      vehicleReg: "",
      poNumber: "",
      ...this.props.selectedBooking,
      goods: goods
    };
  }

  valuesForSubmission(values) {
    return {
      slotStart: new Date(values.time).toISOString(),
      journeyReference: values.journeyReference || "",
      trailerTypeId: parseInt(values.trailerTypeId),
      notes: values.notes || "",
      palletised: values.palletised || true,
      numberOfPallets: parseInt(values.numberOfPallets),
      receivingSiteId: parseInt(values.receivingSiteId),
      sendingSiteId: parseInt(values.sendingSiteId),
      goods: Object.entries(values.goods)
        .filter(([, v]) => v)
        .map(([k]) => k),
      direction: "internal",
      date: values.date
    };
  }

  handleSubmit(values, { setSubmitting }) {
    setSubmitting(false);
    if (this.state.deleting) {
      deleteBooking(this.props.selectedBooking.id, values.reasonDeleted)
        .then(() => {
          this.props.onDeleteBooking();
        })
        .catch(errors => {
          setSubmitting(false);
          this.handleErrors(errors, values.palletised, values.numberOfPallets);
        });
    } else if (this.props.editing) {
      updateBooking(
        this.props.selectedBooking.id,
        this.valuesForSubmission(values)
      )
        .then(() => {
          setSubmitting(false);
          this.props.dispatch(
            addMessage("success", "The booking has been updated.")
          );
          this.props.onUpdateBooking();
        })
        .catch(errors => {
          setSubmitting(false);
          this.handleErrors(errors, values.palletised, values.numberOfPallets);
        });
    } else {
      createBooking(this.valuesForSubmission(values))
        .then(data => {
          this.setState({ booking: data.booking, showMessage: true });
          this.props.onCreateBooking();
        })
        .catch(errors => {
          setSubmitting(false);
          this.handleErrors(errors, values.palletised, values.numberOfPallets);
        });
    }
  }

  showNewBooking() {
    this.setState({ showMessage: false });
    this.props.history.push({
      pathname: `/bookings/site/${this.state.booking.sendingSite.id}/booking/${this.state.booking.journeyReference}`
    });
  }

  handleErrors(errors) {
    const slotUnavailable = errors.filter(
      error => error.message.substr(0, 16) === "slot_unavailable"
    );
    const trailerInUse = errors.filter(
      error => error.message.substr(0, 10) === "trailer_id"
    );

    if (errors && slotUnavailable.length > 0) {
      this.props.dispatch(
        addMessage(
          "error",
          "The chosen time slot is no longer available, \n or the booking is longer than available timeslot"
        )
      );
      this.getTimeSlots();
    } else if (errors && trailerInUse.length > 0) {
      this.props.dispatch(
        addMessage("error", "This trailer is already in use.")
      );
    } else {
      this.props.dispatch(
        addMessage(
          "error",
          this.props.editing
            ? "Failed to save your changes"
            : "Failed to create the booking"
        )
      );
    }
  }

  getTimeSlots(numberOfPallets) {
    if (Number.isInteger(numberOfPallets)) {
      listSlots(
        format(this.props.selectedDate, "YYYY-MM-DD"),
        this.props.selectedSite,
        true,
        numberOfPallets,
        "internal"
      ).then(data => {
        this.setState({
          timeslots: data.slots.availableSlots.map(ts => ({
            value: new Date(ts.slot_time).toUTCString(),
            label: formatWithDefaultTz(new Date(ts.slot_time), "HH:mm"),
            isAvailable: ts.is_available
          })),
          slotLoading: false
        });
      });
    }
  }

  get siteOptions() {
    return formatAsOptions(this.state.sites);
  }

  resetSlots() {
    this.setState({ timeslots: [], slotLoading: true });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    return (
      <div>
        {this.state.showMessage && (
          <Notice
            onDismiss={this.showNewBooking.bind(this)}
            message={bookingTerms}
          />
        )}
        <Formik
          validationSchema={bookingValidationSchema}
          enableReinitialize
          initialValues={this.initialValues}
          initialErrors
          onSubmit={this.handleSubmit.bind(this)}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Field
                name="date"
                label="Date"
                component={DateInput}
                placeholder="Select the date"
                error={touched.date && errors.date}
                disabled
              />

              <Field
                name="sendingSiteId"
                label="From"
                placeholder="Select site"
                options={this.siteOptions}
                component={SelectBox}
                disabled
                error={touched.sendingSiteId && errors.sendingSiteId}
              />
              <Field
                name="receivingSiteId"
                label="To"
                placeholder="Select site"
                options={this.siteOptions}
                component={SelectBox}
                error={touched.receivingSiteId && errors.receivingSiteId}
              />
              <Field
                name="time"
                label="Time"
                component={TimePicker}
                placeholder="Choose a timeslot"
                options={this.state.timeslots}
                disabled={this.props.editing || this.state.slotLoading}
                error={touched.time && errors.time}
              />
              <Field
                name="numberOfPallets"
                label="Number of Pallets"
                component={TextInput}
              />
              {this.props.editing && (
                <Field
                  name="journeyReference"
                  label="Journey Reference"
                  component={TextInput}
                  disabled={true}
                />
              )}
              <Field
                name="trailerTypeId"
                label="Trailer Type"
                placeholder="Select Trailer Type"
                options={sortTrailerTypeOptions(this.state.trailerTypes)}
                component={SelectBox}
                error={touched.trailerTypeId && errors.trailerTypeId}
              />
              <div>Goods (Select all that apply)</div>
              {trailerGoods.map((x, idx) => (
                <Field
                  key={idx}
                  name={x.name}
                  label={x.label}
                  component={CheckBox}
                />
              ))}
              {errors.goods && (
                <div className="form-field-error">{errors.goods}</div>
              )}
              <Field
                name="notes"
                label="Notes"
                placeholder="Any additional notes should be added here."
                component={TextBox}
              />

              {this.props.editing && (
                <div>
                  {this.state.deleting && (
                    <div>
                      <div className="cancel-booking">
                        <Field
                          name="reasonDeleted"
                          label="Reason for deletion"
                          placeholder="Select Reason"
                          options={this.deletionReasons}
                          component={SelectBox}
                        />
                      </div>
                      <div className="button-row">
                        <Button
                          variant="secondary"
                          size="small"
                          title="Close"
                          onClick={() => {
                            this.setState({
                              deleting: false
                            });
                          }}
                          disabled={isSubmitting}
                        />
                        <Button
                          type="submit"
                          variant="primary"
                          size="small"
                          title="Confirm Delete"
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  )}
                  {!this.state.deleting && (
                    <div>
                      <div className="button-row">
                        <Can when="bookings.calendar.delete">
                          <Button
                            variant="secondary"
                            size="small"
                            title="Delete"
                            onClick={() => {
                              this.setState({
                                deleting: true
                              });
                            }}
                            disabled={isSubmitting}
                          />
                        </Can>
                        <Can when="bookings.calendar.update">
                          <Button
                            type="submit"
                            variant="primary"
                            size="small"
                            title="Save"
                            disabled={isSubmitting}
                          />
                        </Can>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {Object.keys(errors).length > 0 && (
                <div style={{ marginBottom: "1rem" }}>
                  <Alert
                    type="error"
                    message="The form contains errors. Please review."
                  />
                </div>
              )}

              {!this.props.editing && (
                <Can when="bookings.calendar.create">
                  <div className="button-col">
                    <Button
                      type="submit"
                      variant="primary"
                      size="small"
                      title="Add booking"
                      disabled={isSubmitting}
                    />
                  </div>
                </Can>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(connect()(InternalBookingForm));
