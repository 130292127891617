import React, { useMemo } from "react";
import BookingLayout from "./BookingLayout";
import { getTime, addDays } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import { parse, differenceInMinutes } from "date-fns";
import Can from "Can";
import RoundButton from "RoundButton";
import HoverButton from "HoverButton";
import "./CalendarView.css";
import internal from "assets/internalBooking.png";
import external from "assets/externalBooking.png";
import config from "../../../../config";

function calculateBookingLayout(bookings) {
  const sortedBookings = []
    .concat(bookings)
    .sort(
      (a, b) =>
        differenceInMinutes(parse(a.viewEnd), parse(a.viewStart)) >
        differenceInMinutes(parse(b.viewEnd), parse(b.viewStart))
    );

  const bookingLayout = [];

  function cheapAreRangesOverlapping(a1, a2, b1, b2) {
    return a1 < b2 && b1 < a2;
  }

  function isOverlapping(bookingList, booking) {
    const overlap =
      bookingList.length === 0
        ? false
        : bookingList.some(function(item) {
            return cheapAreRangesOverlapping(
              booking.slotStart,
              booking.slotEnd,
              item.slotStart,
              item.slotEnd
            );
          });

    return overlap;
  }

  let columnIndex = 0;
  while (sortedBookings.length !== 0) {
    bookingLayout[columnIndex] = [];
    for (let i = sortedBookings.length; i--; ) {
      const overlap = isOverlapping(
        bookingLayout[columnIndex],
        sortedBookings[i]
      );

      if (!overlap) {
        const item = sortedBookings.splice(i, 1);
        bookingLayout[columnIndex].push(item[0]);
      }
    }

    ++columnIndex;
  }

  return bookingLayout;
}

function CalendarView(props) {
  const siteTimeZone = "Europe/London";
  const formatTimeZone = (date, timeZone) =>
    formatToTimeZone(date, "YYYY-MM-DD HH:mm:SS [GMT]Z", {
      timeZone
    });
  const getViewStart = (slotStart, selectedDate) => {
    if (getTime(slotStart) < getTime(selectedDate)) {
      return formatTimeZone(selectedDate, siteTimeZone);
    }
    return formatTimeZone(slotStart, siteTimeZone);
  };

  const getViewEnd = (slotEnd, selectedDate) => {
    if (getTime(slotEnd) > getTime(selectedDate) + 86400000) {
      return formatTimeZone(addDays(selectedDate, 1), siteTimeZone);
    }
    return formatTimeZone(slotEnd, siteTimeZone);
  };

  const days = [...Array(24).keys()];

  const bookings = useMemo(
    () =>
      props.bookings.map(b => ({
        ...b,
        selected: b.id === props.selectedBookingId,
        slotStart: formatTimeZone(b.slotStart, siteTimeZone),
        slotEnd: formatTimeZone(b.slotEnd, siteTimeZone),
        viewStart: getViewStart(b.slotStart, props.selectedDate),
        viewEnd: getViewEnd(b.slotEnd, props.selectedDate)
      })),
    [props.bookings]
  );

  const bookingLayout = calculateBookingLayout(bookings);

  const currentBookings = {
    bookingLayout,
    selectedDate: props.selectedDate
  };

  return (
    <div onScroll={props.onScroll} className="booking-calendar">
      <div className="time-slots">
        {days.map((item, key) => (
          <React.Fragment key={key}>
            <div className="slot">
              <div className="timeslot-info">
                <span className="text-header">
                  {key < 10 ? "0" + key : key}:00
                </span>
              </div>
            </div>
            <div className="slot" />
          </React.Fragment>
        ))}
      </div>
      <div className="booking-slots">
        <BookingLayout
          data={currentBookings}
          onSelect={props.onSelectBooking}
          journeyReference={props.journeyReference}
        />
      </div>
      <Can when="bookings.calendar.create">
        {!config.FEATURE_NO_INTERNAL_BOOKINGS ? (
          <HoverButton variant="hover" title="Add new booking">
            <Can when="bookings.canSeeInternal">
              <RoundButton
                title="Internal Booking"
                variant="absolute"
                height="32"
                width="32"
                right="0"
                top="-72"
                onClick={props.onShowCreateInternal}
              >
                <img style={{ width: 32, height: 32 }} src={internal} />
              </RoundButton>
            </Can>
            <RoundButton
              title="External Booking"
              variant="absolute"
              height="32"
              width="32"
              right="0"
              top="-32"
              onClick={props.onShowCreateExternal}
            >
              <img style={{ width: 32, height: 32 }} src={external} />
            </RoundButton>
          </HoverButton>
        ) : (
          <HoverButton
            variant="hover"
            title="Add new booking"
            onClick={props.onShowCreateExternal}
          ></HoverButton>
        )}
      </Can>
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.bookings === nextProps.bookings &&
    prevProps.selectedDate === nextProps.selectedDate
  );
}

export default React.memo(CalendarView, areEqual);
