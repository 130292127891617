import React from "react";
import "./Switch.css";

export default function Switch(props) {
  const checked = props.field.value || false;
  const disabled = props.disabled || false;

  return (
    <div className="form-control">
      <label className="switch-label" data-disabled={disabled}>
        <span className="switch" data-disabled={disabled}>
          <input
            {...props.field}
            type="checkbox"
            checked={checked}
            disabled={disabled}
          />
          <span className="slider" />
        </span>
        <span className="switch-label">{props.label}</span>
      </label>
    </div>
  );
}
