import React from "react";
import WarehousePageTemplate from "./WarehousePageTemplate";
import Content from "Content";
import {
  bookingsBySiteId,
  trailersByHaulierAndType,
  assignTrailerToBooking,
  unassignTrailerFromBooking
} from "./assign-trailer-page/assign-trailer-queries";
import { connect } from "react-redux";
import BookingPreviewModal from "BookingPreviewModal";
import Can from "Can";
import Loader from "Loader";
import AssignTrailerTable from "./assign-trailer-page/AssignTrailerTable";
import RightPanel from "RightPanel";
import SelectSitePanel from "SelectSitePanel";
import TrailerUnavailableIcon from "TrailerUnavailableIcon";
import Button from "Button";
import { withErrorHandler } from "graphql-helper";
import { selectSite } from "redux/app";
import { formatLocalDate } from "date-helper";
import "./assign-trailer-page/AssignTrailer.css";

class WarehouseBookings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBooking: null,
      site: null,
      inbound: [],
      outbound: [],
      internal: [],
      trailers: {},
      loading: true,
      showingBookingPreviewModal: false,
      selectedBookingId: null
    };
  }

  componentDidMount() {
    if (parseInt(this.props.match.params.siteId) !== this.props.selectedSite) {
      if (this.props.selectedSite === null) {
        this.props.dispatch(selectSite(this.props.match.params.siteId));
      } else {
        this.updateRoute(this.props.selectedSite);
        this.loadBookings();
      }
    } else {
      this.loadBookings();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedSite !== this.props.selectedSite &&
      this.props.selectedSite !== null
    ) {
      this.setState({ showPanel: false });
      this.loadBookings();
      this.updateRoute(this.props.selectedSite);
    }
  }

  updateRoute(id) {
    if (id === null) {
      this.props.history.push({ pathname: "/warehouse/bookings" });
    } else {
      this.props.history.push({
        pathname: `/warehouse/bookings/site/${id}`
      });
    }
  }

  loadBookings() {
    bookingsBySiteId(this.props.selectedSite).then(
      ({ site, inbound, outbound, internal }) => {
        this.setState({
          site,
          inbound: this.filterStagedBookings(inbound),
          outbound: this.filterStagedBookings(outbound),
          internal,
          loading: false
        });
      }
    );
  }

  filterStagedBookings(bookings) {
    return bookings.filter(
      booking =>
        booking.isRebooking ||
        (booking.status !== "staged" &&
          booking.status !== "staged_arrived" &&
          booking.status !== "ready_for_driver")
    );
  }

  get bookings() {
    return this.state.site.bookings || [];
  }

  get selectedBooking() {
    if (this.state.selectedBooking === null) {
      return null;
    }

    return (
      this.state.outbound.find(b => b.id === this.state.selectedBooking) ||
      this.state.internal.find(b => b.id === this.state.selectedBooking)
    );
  }

  isOutboundBooking(bookingId) {
    return !!this.state.outbound.find(b => b.id === bookingId);
  }

  getTrailersFromBooking(bookingId, haulierId, trailerType, includeInternal) {
    trailersByHaulierAndType(haulierId, trailerType, includeInternal).then(
      trailers => {
        this.setState({
          selectedBooking: bookingId,
          trailers: trailers.trailers.filter(
            trailer =>
              (trailer.zone.type == "parking" ||
                trailer.zone.site.id == this.props.selectedSite) &&
              !trailer.isUnavailable
          ),
          showPanel: true
        });
      }
    );
  }

  assignTrailer(trailerId) {
    assignTrailerToBooking(this.state.selectedBooking, trailerId).then(() => {
      this.loadBookings();
    });
    this.setState({ showPanel: false, selectedBooking: null });
  }

  unassignTrailer(id) {
    this.props.handleErrors(
      unassignTrailerFromBooking(id).then(() => {
        this.loadBookings();
      }),
      "Trailer unassigned"
    );
    this.setState({ showPanel: false, selectedBooking: null });
  }

  closePanel() {
    this.setState({ showPanel: false, selectedBooking: null });
  }

  showBookingPreviewModal(selectedBookingId) {
    this.setState({ showingBookingPreviewModal: true, selectedBookingId });
  }

  closeBookingPreviewModal() {
    this.setState({
      showingBookingPreviewModal: false,
      selectedBookingId: null
    });
  }

  render() {
    if (this.state.loading && this.props.selectedSite !== null) {
      return <Loader />;
    }

    return (
      <WarehousePageTemplate>
        {this.state.showingBookingPreviewModal && (
          <BookingPreviewModal
            bookingId={this.state.selectedBookingId}
            onClose={this.closeBookingPreviewModal.bind(this)}
          />
        )}
        {this.state.site && (
          <Content>
            <div className="dash-row">
              <div className="content-block">
                <h2>{this.state.site.name}</h2>
                <AssignTrailerTable
                  site={this.state.site}
                  inbound={this.state.inbound}
                  outbound={this.state.outbound}
                  internal={this.state.internal}
                  getTrailersFromBooking={this.getTrailersFromBooking.bind(
                    this
                  )}
                  unassignTrailer={this.unassignTrailer.bind(this)}
                  selectedBooking={this.state.selectedBooking}
                  showBookingPreviewModal={this.showBookingPreviewModal.bind(
                    this
                  )}
                />
              </div>
            </div>
          </Content>
        )}
        {!this.state.site && <SelectSitePanel />}
        <RightPanel
          title="Assign trailer"
          isExpanded={this.state.showPanel}
          theme="light"
          onClose={this.closePanel.bind(this)}
        >
          {this.state.showPanel && (
            <>
              <h2>{this.selectedBooking.journeyReference}</h2>
              <div>
                Trailer Owner:{" "}
                {this.selectedBooking.trailerOwner
                  ? this.selectedBooking.trailerOwner.name
                  : ""}
              </div>
              <div>Trailer Type: {this.selectedBooking.trailerType.name}</div>
              <h3>Available trailers</h3>
              {this.state.trailers.length === 0 && (
                <p style={{ maxWidth: "30vw" }}>
                  There are no trailers currently on site which meet the
                  criteria.
                </p>
              )}
              {this.state.trailers.length > 0 && (
                <>
                  <p style={{ maxWidth: "30vw" }}>
                    The trailers below match the requirements of the outbound
                    booking. Selecting a trailer here will reserve it for use
                    with the selected outbound booking.
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>Trailer Owner/No</th>
                        <th>Type</th>
                        <th>Location</th>
                        <th>Last Move</th>
                        <Can when="warehouse.bookings.assignTrailer">
                          <th />
                        </Can>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.trailers.map(trailer => (
                        <tr
                          key={trailer.id}
                          className={
                            trailer.isInternal
                              ? "internal-trailer"
                              : this.selectedBooking.direction === "internal"
                              ? "external-trailer"
                              : ""
                          }
                        >
                          <td>
                            <TrailerUnavailableIcon trailer={trailer} />
                          </td>
                          <td>
                            {trailer.haulier.shortCode} - {trailer.trailerNo}
                          </td>
                          <td>{trailer.trailerType.name}</td>
                          <td>
                            {trailer.zone.name}
                            {trailer.dock ? ` - ${trailer.dock.number}` : ""}
                          </td>
                          <td>
                            {trailer.movedAt
                              ? formatLocalDate(trailer.movedAt)
                              : formatLocalDate(trailer.insertedAt)}
                          </td>
                          <Can when="warehouse.bookings.assignTrailer">
                            <td>
                              <Button
                                onClick={() => {
                                  this.assignTrailer(trailer.id);
                                }}
                                size="tiny"
                                variant="primary"
                                title="Select"
                              />
                            </td>
                          </Can>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}
        </RightPanel>
      </WarehousePageTemplate>
    );
  }
}

function mapStateToProps({ app: { selectedSite } }) {
  return { selectedSite };
}

export default withErrorHandler(connect(mapStateToProps)(WarehouseBookings));
