import React from "react";
import { Link } from "react-router-dom";
import Can from "Can";

export default function InnerNavItem({
  href,
  selected,
  title,
  permission = null
}) {
  const li = (
    <li
      key={href}
      className={`inner-nav-list__item ${
        selected ? "inner-nav-list__item--selected" : ""
      }`}
    >
      <Link to={href}>{title}</Link>
      <span className="selected-lozenge" />
    </li>
  );

  if (permission != null) {
    return <Can when={permission}>{li}</Can>;
  }

  return li;
}
