import React from "react";
import PropTypes from "prop-types";
import Day from "./Day";

export default function Week(props) {
  return (
    <div className="day-table__row">
      {props.row.map((day, index) => (
        <Day key={index} day={day} onClick={() => props.onSelect(day)} />
      ))}
    </div>
  );
}

Week.propTypes = {
  row: PropTypes.arrayOf(PropTypes.object).isRequired
};
