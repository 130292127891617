import React from "react";
import { connect } from "react-redux";
import AppNav from "AppNav";
import InnerNavItems from "./app-template/InnerNavItems";
import "./AppTemplate.css";
import LandingPanel from "LandingPanel";

class AppTemplate extends React.Component {
  render() {
    let mainBody = this.props.children;

    if (
      this.props.selectedSite &&
      !this.props.allowedSites.includes(this.props.selectedSite)
    ) {
      mainBody = (
        <LandingPanel text="We're sorry, you don't have permission to view this page.">
          Please check the site that you are trying to reach, and try again. If
          you still require access to this page, please contact a system
          administrator.
        </LandingPanel>
      );
    }

    return (
      <div className={"app"}>
        <AppNav />
        <div className="app-wrapper">
          {this.props.innerNavItems && (
            <InnerNavItems
              innerNavItems={this.props.innerNavItems}
              selectedDate={this.props.selectedDate}
              dispatch={this.props.dispatch}
              showDatePicker={this.props.showDatePicker}
              showDateToggle={this.props.showDateToggle}
              searchAllDates={this.props.searchAllDates}
              searchPlaceholder={this.props.searchPlaceholder}
              searchQuery={this.props.searchQuery}
              onSearchQueryChange={this.props.onSearchQueryChange}
              onSearchAllDatesChange={this.props.onSearchAllDatesChange}
            />
          )}
          <div className="nav-wrapper">
            <div className="main-body">{mainBody}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  app: { showAppSwitcher, showFilters, selectedDate, selectedSite },
  auth: { permissions, allowedSites }
}) {
  return {
    showAppSwitcher,
    showFilters,
    selectedDate,
    selectedSite,
    permissions,
    allowedSites
  };
}

export default connect(mapStateToProps)(AppTemplate);
