import React from "react";
import { connect } from "react-redux";
import { addMessage } from "redux/app";
import { withRouter } from "react-router";
import { createSite, getSites } from "./site-admin-queries";
import { Formik, Form, Field } from "formik";
import Button from "Button";
import Can from "Can";
import Loader from "Loader";
import TextInput from "form-controls/TextInput";
import SelectBox from "form-controls/SelectBox";
import Switch from "form-controls/Switch";
import * as Yup from "yup";
import "./SiteAdminForm.css";
import "./SiteEditForm.css";

const registerSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, "")
    .required("Please enter a site name"),
  bookingInterval: Yup.string().required("Please select a booking frequency"),
  shortCode: Yup.string().required(
    "Please enter a short code (Atlas code, or 3 characters)"
  )
});

class SiteCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteList: [],
      siteListLoading: true
    };
  }

  componentWillMount() {
    getSites().then(data => {
      this.setState({
        siteList: data.sites.filter(site => !site.parentSite),
        siteListLoading: false
      });
    });
  }

  get siteList() {
    return this.state.siteList.map(item => ({
      value: item.id,
      label: item.name
    }));
  }

  initialValues = {
    slots: Array(7).fill(Array(24).fill(0))
  };

  render() {
    if (this.state.siteListLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const bookingInterval = [
      { value: 300, label: "5 minutes" },
      { value: 600, label: "10 minutes" },
      { value: 900, label: "15 minutes" },
      { value: 1800, label: "30 minutes" },
      { value: 2700, label: "45 minutes" },
      { value: 3600, label: "60 minutes" }
    ];

    return (
      <Formik
        validationSchema={registerSchema}
        initialValues={this.initialValues}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          const result = {
            name: values.name,
            shortCode: values.shortCode || null,
            parentSiteId: values.parentSiteId || null,
            bookingInterval: parseInt(values.bookingInterval) || null,
            archivedAt: values.archivedAt ? true : false,
            defaultSlots: Array(7).fill(Array(24).fill(0))
          };
          createSite(result).then(
            () => {
              setSubmitting(false);
              this.props.dispatch(
                addMessage("success", "The site has been created.")
              );
              this.props.updateSites();
            },
            error => {
              error;
              setSubmitting(false);
              addMessage("error", "There was an error creating your site.");
            }
          );
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div>
              <Field
                name="name"
                label="Site Name"
                component={TextInput}
                error={touched.name && errors.name}
                autoFocus={true}
              />
              <Field
                name="shortCode"
                label="Site Short Code (Atlas Code)"
                component={TextInput}
                error={touched.shortCode && errors.shortCode}
                autoFocus={true}
              />
              <Field
                name="parentSiteId"
                label="Parent Site"
                placeholder="Select Parent Site"
                options={this.siteList}
                component={SelectBox}
              />
              <Field name="archivedAt" label="Active" component={Switch} />
              <Field
                label="Booking Frequency"
                name="bookingInterval"
                placeholder="Select frequency"
                options={bookingInterval}
                component={SelectBox}
              />
              {errors.bookingInterval && (
                <div className="form-field-error">{errors.bookingInterval}</div>
              )}
              <div className="button-col">
                <Can when="settings.site.create">
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    title="Save"
                    disabled={
                      Object.keys(touched).length < 1 ||
                      Object.keys(errors).length ||
                      isSubmitting
                    }
                  />
                </Can>
                <Button
                  variant="secondary"
                  size="small"
                  title="Cancel"
                  onClick={() => this.props.returnHome()}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect()(withRouter(SiteCreateForm));
