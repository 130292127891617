import { useSelector } from "react-redux";

export default when => {
  const permissions = useSelector(state => state.auth.permissions);

  if (!permissions) {
    return false;
  }

  return permissions.includes(when);
};
