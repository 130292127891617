import React from "react";
import "./PasswordInput.css";

class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPassword: false };
  }
  render() {
    return (
      <div className="form-control">
        {this.props.label}
        <div className="password-input-container">
          <input
            className="password-input"
            {...this.props.field}
            type={this.state.showPassword ? "text" : "password"}
            autoComplete="off"
          />
          <i
            className="password-input__icon material-icons"
            onClick={() => {
              this.setState({ showPassword: !this.state.showPassword });
              this.forceUpdate();
            }}
          >
            {this.state.showPassword ? "visibility" : "visibility_off"}
          </i>
        </div>
      </div>
    );
  }
}
export default PasswordInput;
