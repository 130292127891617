import React, { Component } from "react";
import classNames from "classnames";
import "./MultiSelect.css";

const multiSelects = [];

document.body.addEventListener("click", e => {
  multiSelects.forEach(([element, callback]) => {
    if (!isChild(element.current, e.target)) {
      callback();
    }
  });
});

function isChild(element, target) {
  if (target === null) {
    return false;
  } else if (target === element) {
    return true;
  } else {
    return isChild(element, target.parentNode);
  }
}

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false
    };
    this.myRef = React.createRef();
    multiSelects.push([this.myRef, this.closeList.bind(this)]);
  }

  closeList() {
    this.setState({
      listOpen: false
    });
  }

  toggleList() {
    this.setState({
      listOpen: !this.state.listOpen
    });
  }

  toggleItem(item, propsId) {
    let selected = this.props.field.value;

    if (this.selectedValues.includes(item.value)) {
      selected = selected.filter(
        selectedItem => selectedItem.value !== item.value
      );
    } else {
      selected.push(item);
    }

    if (this.props.update) {
      this.props.update(selected, propsId);
    }
    if (this.props.onBlur) {
      this.props.onBlur(selected);
    }
  }

  createTitle(items) {
    return items.join(", ") !== ""
      ? items.join(", ")
      : this.props.placeholder || "Please Select";
  }

  get selectedValues() {
    return this.props.field.value.map(({ value }) => value);
  }

  render() {
    const { options } = this.props;
    const { listOpen } = this.state;

    const selectedValues = this.selectedValues;

    const selectedItems = options.filter(item =>
      selectedValues.includes(item.value)
    );

    const title = this.createTitle(selectedItems.map(({ label }) => label));

    return (
      <div className="form-control">
        {this.props.label && this.props.label}
        <div
          ref={this.myRef}
          className={classNames({
            "multi-select": !listOpen,
            "multi-select-open": listOpen
          })}
        >
          <div
            className="multi-select-header"
            onClick={() => this.toggleList()}
          >
            <div className="multi-select-header-title">{title}</div>
          </div>
          {listOpen && (
            <ul
              className="multi-select-list"
              onClick={e => e.stopPropagation()}
            >
              {options.map(item => (
                <li
                  id={item.value}
                  className={classNames({
                    "selected-item": selectedValues.includes(item.value),
                    "list-item": !selectedValues.includes(item.value)
                  })}
                  key={item.label}
                  onClick={() => this.toggleItem(item, this.props.id)}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          )}
        </div>
        {this.props.error && (
          <div className="form-field-error">{this.props.error}</div>
        )}
      </div>
    );
  }
}

export default MultiSelect;
