import { sortDropdownOptions } from "./dropdown-sorting";

export function sortTrailerTypeOptions(options) {
  const promotedOptions = [
    "Regular Box Trailer",
    "Box Trailer - Regular",
    "Regular Curtain Sider",
    "Curtain Sider - Regular",
    "Extra Long Trailer"
  ];

  return sortDropdownOptions(options, promotedOptions);
}
