import React from "react";
import { withErrorHandler } from "graphql-helper";
import RequestTrailerTable from "./warehouse-zones/RequestTrailerTable";
import Can from "Can";
import CheckBox from "form-controls/CheckBox";
import {
  bookingsWithTrailers,
  createMovement,
  updateTrailerLocation
} from "./warehouseQueries";
import config from "../../../config";

class RequestTrailerPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bookings: [],
      parentBookings: [],
      loading: false,
      requiresShunterMovement: config.FEATURE_LITE_VERSION ? false : true
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    bookingsWithTrailers(this.props.selectedSite).then(data => {
      this.setState({
        bookings: this.filterInternalBookings(data.bookings),
        parentBookings: data.parentBookings,
        loading: false
      });
    });
  }

  createMovement(trailerId) {
    if (this.state.requiresShunterMovement) {
      this.props.handleErrors(
        createMovement(
          trailerId,
          this.props.zone.id,
          this.props.selectedDockId || null
        ).then(() => this.props.onClose())
      );
    } else {
      this.props.handleErrors(
        updateTrailerLocation(
          trailerId,
          parseInt(this.props.zone.id),
          typeof this.props.selectedDockId === "undefined"
            ? null
            : parseInt(this.props.selectedDockId)
        ).then(() => this.props.onClose())
      );
    }
  }

  filterInternalBookings(bookings) {
    return bookings.filter(booking => {
      if (
        ["arrived", "inbound_in_progress"].includes(booking.status) &&
        booking.receivingSite &&
        this.props.selectedSite !== parseInt(booking.receivingSite.id)
      ) {
        return false;
      }

      if (
        ["trailer_attached", "outbound_in_progress"].includes(booking.status) &&
        booking.sendingSite &&
        this.props.selectedSite !== parseInt(booking.sendingSite.id)
      ) {
        return false;
      }

      return true;
    });
  }

  render() {
    if (this.state.loading) {
      return <p>Loading, please wait!</p>;
    }

    return (
      <>
        {this.props.allowNonShunterMovement && !config.FEATURE_LITE_VERSION && (
          <Can when="warehouse.zones.updateTrailerLocation">
            <CheckBox
              field={{
                value: this.state.requiresShunterMovement,
                onChange: ({ target }) => {
                  this.setState({
                    requiresShunterMovement: target.value !== "true"
                  });
                }
              }}
              label="Require a shunter movement"
            />
          </Can>
        )}
        <RequestTrailerTable
          bookings={this.state.bookings}
          createMovement={this.createMovement.bind(this)}
        />
        {this.state.parentBookings.length > 0 && (
          <>
            <h3>Parent Site Trailers</h3>
            <RequestTrailerTable
              bookings={this.state.parentBookings}
              createMovement={this.createMovement.bind(this)}
            />
          </>
        )}
      </>
    );
  }
}

export default withErrorHandler(RequestTrailerPanel);
