import { fetch } from "graphql-helper";
const bookingFields = `
    id
    journeyReference
    numberOfPallets
    origin
    direction
    loadType
    slotStart
    slotEnd
    isRebooking
    status
    appointment {
      id
      data
      updatedAt
      messageDatetime
      yardName
      yms
      direction
      haulier
      trailerNo
      trailerType
      loadNo
      messageId
    }
    haulier {
      id
      name
      shortCode
    }
    trailerOwner {
      id
      name
      shortCode
    }
    sendingSite {
      id
      name
      parentSite {
        id
        name
      }
    }
    receivingSite {
      id
      name
      parentSite {
        id
        name
      }
    }
    trailerType {
      id
      baseType
      type
      name
    }
    trailer {
      id
      trailerNo
      haulier {
        id
        name
        shortCode
      }
      zone {
        id
        name
        type
      }
      dock {
        id
        number
      }
    }`;

export function bookingsBySiteId(siteId, date) {
  return fetch(`query {
      priorityBookings: priorityBookings(siteId: ${siteId}) {
        booking_priority
      }
      site: site(id: ${siteId}) {
        id
        name
        children {
          id
          name
          zones {
            id
            name
            capacity
            trailers {
              id
              trailerNo
            }
          }
        }

        zones {
          id
          capacity
          trailers {
            id
            trailerNo
          }
          name
        }
      }
      allBookings: bookings(date: "${date}") {
        ${bookingFields}
      }
      bookings(date: "${date}", siteId: ${siteId}) {
        ${bookingFields}
      }
    }`);
}
