import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SmallText from "../SmallText";
import "./Day.css";

export default function Day(props) {
  const className = classNames("datepicker__day", {
    "datepicker__day--selected": props.day.isSelected,
    "datepicker__day--light": !props.day.isCurrentMonth,
    "datepicker__day--today": props.day.isToday,
    "datepicker__day--line-through": props.day.isUnavailable
  });

  return (
    <div className={className} onClick={props.onClick}>
      <SmallText>{props.day.day}</SmallText>
    </div>
  );
}

Day.propTypes = {
  day: PropTypes.object
};
