import React from "react";
import AppTemplate from "templates/AppTemplate";
import Fullscreen from "react-full-screen";
import Button from "Button";
import "../FlightboardStyles.css";

export default class FlightboardPageTemplate extends React.Component {
  constructor() {
    super();

    this.state = {
      isFull: false
    };
  }

  goFull = () => {
    this.setState({ isFull: true });
  };

  render() {
    const body = () => {
      if (this.state.isFull) {
        return <div className="full-screen-body">{this.props.children}</div>;
      } else {
        return (
          <AppTemplate>
            <div className="landing-center">
              <Button
                onClick={this.goFull}
                variant="primary"
                title="Open full screen app"
              />
              <div className="full-screen-exit-hint">
                Tip: Press the "ESC" key to exit full screen mode
              </div>
            </div>
          </AppTemplate>
        );
      }
    };

    return (
      <Fullscreen
        enabled={this.state.isFull}
        onChange={isFull => this.setState({ isFull })}
      >
        {body()}
      </Fullscreen>
    );
  }
}
