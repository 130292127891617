import React, { useEffect, useState } from "react";
import AppTemplate from "templates/AppTemplate";
import Content from "Content";
import { formatLocalDate } from "date-helper";
import Papa from "papaparse";
import Button from "Button";
import {
  importTransporeonBookings,
  getSites
} from "./bookings-from-csv-page/csv-queries";
import config from "../../../config";

// import { addMessage } from "redux/app";

export default function BookingsFromCsvPage() {
  const [bookings, setBookings] = useState({});
  const [uploadResult, setUploadResult] = useState([]);
  const [fileInputRef, setFileInputRef] = useState();
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const { REACT_APP_CLIENT } = config;

  useEffect(() => {
    getSites()
      .then(data => {
        setSites(data.sites);
      })
      .catch(errors => {
        console.log("CSV upload error", errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [sites.length]);

  function getReceivingSiteShortCode() {
    switch (REACT_APP_CLIENT) {
      case "hamshall":
        return "HH2";
      case "xpo":
        return "EMG";
      default:
        return "EMXX";
    }
  }

  function getToSiteName() {
    const siteShortCode = getReceivingSiteShortCode();
    const site = sites.find(site => site.shortCode === siteShortCode);
    if (site) {
      return site.name;
    }
    return undefined;
  }

  function getLoadType() {
    switch (REACT_APP_CLIENT) {
      case "hamshall":
        return "Finished Goods";
      case "xpo":
        return "IMPORTS";
      default:
        return "IMPORTS";
    }
  }

  // function getFromSite(csvData) {
  //   const schedulingUnit = csvData["Scheduling unit"];
  //   const firstPart = schedulingUnit.split(" ")[0];
  //   switch (REACT_APP_CLIENT) {
  //     case "hamshall":
  //       return "IMPT";
  //     case "xpo":
  //       return "IMP";
  //     default:
  //       return "IMPT";
  //   }
  // }

  function getHaulierShortCode() {
    switch (REACT_APP_CLIENT) {
      case "hamshall":
        return "IMPT";
      case "xpo":
        return "IMP";
      default:
        return "IMP";
    }
  }

  function getTipOnArrival() {
    switch (REACT_APP_CLIENT) {
      case "hamshall":
        return undefined;
      case "xpo":
        return "Y";
      default:
        return "Y";
    }
  }

  function getNotes(csvData) {
    switch (REACT_APP_CLIENT) {
      case "hamshall":
        return csvData["Comment"];
      case "xpo":
        return `PO Number: ${csvData["PO number"]}. Delivery number: ${csvData["Delivery number"]}. ${csvData["Comment"]}`;
      default:
        return `PO Number: ${csvData["PO number"]}. Delivery number: ${csvData["Delivery number"]}. ${csvData["Comment"]}`;
    }
  }

  function getSendingSiteDescription(csvData) {
    const schedulingUnit = csvData["Scheduling unit"];
    const firstPart = schedulingUnit.split(" ")[0];
    if (!isNaN(Number.parseInt(firstPart))) {
      return schedulingUnit.substring(firstPart.length);
    } else {
      return schedulingUnit;
    }
  }

  const UploadButton = () => {
    return (
      <Button
        variant="secondary"
        size="small"
        title={isUploading ? "Processing..." : "Upload"}
        disabled={isUploading}
        onClick={() => {
          setIsUploading(true);
          importTransporeonBookings(bookings)
            .then(result => {
              setUploadResult(result.importTransporeonBookings);
              setBookings({});
              fileInputRef.value = "";
            })
            .catch(errors => {
              console.log("CSV upload error", errors);
            })
            .finally(() => {
              setIsUploading(false);
            });
        }}
      />
    );
  };

  const changeHandler = event => {
    setBookings({});
    setUploadResult([]);
    // console.log(event.target.files[0]);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        // console.log(results);
        setBookings(
          results.data.map(d => {
            d;
            return {
              slotStart: new Date(d["Booking time"]).toISOString(),
              driverName: `${d["Driver first name"]} ${d["Driver last name"]}`,
              vehicleReg: d["Licence plate No."],
              numberOfPallets: d["Pallets"] ? parseInt(d["Pallets"]) : 0,
              // palletised: d["Pallets"] ? true : false,
              notes: getNotes(d),
              // direction:
              //   d["Loading type"] == "Delivery" ? "inbound" : "outbound", // ?
              direction: "inbound",
              // siteId: "",
              receivingSiteShortCode: getReceivingSiteShortCode(),
              sendingSiteDescription: getSendingSiteDescription(d),
              // fromSiteShortCode: getFromSite(),
              journeyReference: d["Transport number"],
              // trailerTypeId: "",
              trailerType: d["Gate"] === "CURTAIN" ? "TAUT" : "BOX",
              loadType: getLoadType(),
              // haulierId: "",
              haulierShortCode: getHaulierShortCode(),
              // trailerOwnerId: "",
              trailerOwnerShortCode: "IMPT",
              loadNumber: d["Transport number"],
              receiptNumber: d["Delivery number"],
              heatSensitive: d["Temperature"] ? true : false,
              sealNo: d["Seal No."],
              tipOnArrival: getTipOnArrival(),
              poNumber: d["PO number"]

              // origin: "",
              // requiresEmptyTrailer: false,
              // reasonDeleted: "",
              // checkInDate: "",
              // checkOutDate: "",
              // journeyTag: "",
              // isRebooking: ` values.direction === "rebooking"`,
              // status: `isStaged ? "staged" : undefined`,
              // appointment: {
              //   vet_check_required: d["Additional information"].includes(
              //     "Vet Cert. Req"
              //   )
              //     ? "Y"
              //     : "N"
              // }
            };
          })
        );
      }
    });
  };

  if (isLoading) {
    return (
      <AppTemplate>
        <Content>
          <div className="dash-row">
            <div className="content-block" style={{ flex: 1 }}>
              Loading...
            </div>
          </div>
        </Content>
      </AppTemplate>
    );
  }

  if (!getToSiteName()) {
    return (
      <AppTemplate>
        <Content>
          <div className="dash-row">
            <div className="content-block" style={{ flex: 1, color: "red" }}>
              Error: no site found with short code {getReceivingSiteShortCode()}
            </div>
          </div>
        </Content>
      </AppTemplate>
    );
  }

  return (
    <AppTemplate>
      <Content>
        <div className="dash-row">
          <div className="content-block" style={{ flex: 1 }}>
            <h3>Import bookings from CSV</h3>
            <h4>CSV needs to have Transporeon booking export formatting</h4>
            <div style={{ paddingBottom: "2rem" }}>
              All bookings will be imported to <b>{getToSiteName()}</b> site
              (short code {getReceivingSiteShortCode()})
            </div>
            {/* File Uploader */}
            <input
              type="file"
              name="file"
              accept=".csv"
              ref={ref => setFileInputRef(ref)}
              onChange={changeHandler}
            />
            {bookings.length > 0 && (
              <div style={{ paddingTop: "2rem" }}>
                <UploadButton />
                <h4>
                  Preview (this is how Mercurius will interpret your CSV):
                </h4>
                <table>
                  <thead>
                    <tr>
                      <th>Slot start</th>
                      <th>Journey Ref</th>
                      <th>From Site</th>
                      <th>To Site</th>
                      <th>Trailer Type</th>
                      <th>Load Type</th>
                      <th>Load Number</th>
                      <th>Haulier</th>
                      <th>Trailer Owner</th>
                      <th>Driver name</th>
                      <th>Vehicle Reg</th>
                      {/* <th>Palletised?</th> */}
                      <th>Pallets</th>
                      <th>Direction</th>
                      <th>Receipt number</th>
                      <th>Seal No.</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.map((b, i) => (
                      <tr key={i}>
                        <td>{formatLocalDate(b.slotStart)}</td>
                        <td>{b.journeyReference}</td>
                        {/* <td>{b.fromSiteShortCode}</td> */}
                        <td>{b.sendingSiteDescription}</td>
                        <td>{b.receivingSiteShortCode}</td>
                        <td>{b.trailerType}</td>
                        <td>{b.loadType}</td>
                        <td>{b.loadNumber}</td>
                        <td>{b.haulierShortCode}</td>
                        <td>{b.trailerOwnerShortCode}</td>
                        <td>{b.driverName}</td>
                        <td>{b.vehicleReg}</td>
                        {/* <td>{b.palletised ? "Yes" : "No"}</td> */}
                        <td>{b.numberOfPallets}</td>
                        <td>{b.direction}</td>
                        <td>{b.receiptNumber}</td>
                        <td>{b.sealNo}</td>
                        <td>{b.notes}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <UploadButton />
              </div>
            )}
            <div style={{ paddingTop: 20 }}>
              {uploadResult.map(result => {
                if (!result.errors) {
                  return (
                    <div
                      style={{ color: "green" }}
                      key={result.journey_reference}
                    >
                      {result.journey_reference} - success
                    </div>
                  );
                }
                const errors =
                  typeof result.errors === "string"
                    ? [result.errors]
                    : result.errors;
                return (
                  <div
                    style={{ color: "red", paddingTop: 10 }}
                    key={result.journey_reference}
                  >
                    {result.journey_reference}
                    <div>
                      {errors.map((error, i) => (
                        <div key={i}> - {error}</div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Content>
    </AppTemplate>
  );
}
