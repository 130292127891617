import { fetch } from "graphql-helper";

const trailerFragment = `
  fragment trailerFields on Trailer {
    id
    insertedAt
    movedAt
    trailerNo
    status
    isUnavailable
    unavailabilityReason
    unavailabilityNotes
    returnDate
    trailerType {
      id
      name
      type
    }
    haulier {
      id
      shortCode
    }
    booking {
      status
      sendingSite {
        id
        name
        parentSite {
          name
        }
      }
      receivingSite {
        id
        name
        parentSite {
          name
        }
      }
      id
      journeyReference
      numberOfPallets
      poNumber
      isRebooking
      requiresTranship
      appointment {
        id
        data
        direction
        loadType
        haulier
        trailerNo
        loadNo
      }
      direction
    }
    zone {
      id
      name
    }
    dock {
      id
      number
    }
  }
`;

const zoneFields = `
  id
  name
  type
  capacity
  isActive
  movements {
    id
    status
    location {
      zone {
        id
        name
      }
      dock {
        id
        number
      }
    }
    tipLocation {
      zone {
        id
        name
      }
      dock {
        id
        number
      }
    }
    trailer {
       ...trailerFields
    }
  }
  docks {
    id
    number
    isActive
    isUnmanned
    dockSafetyDevice {
      deviceId
      isActive
      errorState
      messageData
      lastMessageReceivedAt
      currentPower
    trafficLightStatus
  }
  }
  trailers {
    ...trailerFields
    movements {
      id
      status
      location {
        zone {
          id
          name
        }
        dock {
          id
          number
        }
      }
      tipLocation {
        zone {
          id
          name
        }
        dock {
          id
          number
        }
      }
    }
  }
`;

export function zoneSubscription(zoneId) {
  return `
    subscription {
      zone(id: ${zoneId}) {
        ${zoneFields}
      }
    }
    ${trailerFragment}
  `;
}

export function getZones(siteId) {
  return fetch(`
    query {
      zones(site: ${siteId}) {
        ${zoneFields}
      }
    }
    ${trailerFragment}
  `);
}

export function releaseTrailer(trailerId, returnToSender) {
  return fetch(`
    mutation ReleaseTrailer {
      releaseTrailer(id:${trailerId}, returnToSender: ${returnToSender ? "true" : "false"
    }) {
        id
      }
    }`);
}
