import React from "react";
import { withRouter } from "react-router";
import DefaultSlotForm from "./DefaultSlotForm";
import Loader from "Loader";
import AdminPanel from "AdminPanel";
import AdminMenuItem from "AdminMenuItem";
import LandingPanel from "LandingPanel";
import Can from "Can";
import Cannot from "Cannot";
import { getSites } from "./site-admin-queries";
import { connect } from "react-redux";
import SiteCreateForm from "./SiteCreateForm";
import SiteEditForm from "./SiteEditForm";
import SiteMinimalEditForm from "./SiteMinimalEditForm";
import SiteHaulierForm from "./SiteHaulierForm";
import SlotConfigForm from "./SlotConfigForm";
import DefaultParkingForm from "./DefaultParkingForm";
import LoadTypesForm from "./LoadTypeForm";
import "Sidebar.css"; // TODO: Does this really want to be loading this??
import "../user-admin-page/UserRolesForm.css";
import SiteDevicesTab from "./SiteDevicesTab";
import GatehouseConfigForm from "./GatehouseConfigForm";
import DockDevices from "./DockDevices";
import config from "../../../../config";

class SiteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteList: [],
      siteLoading: true,
      selectedSite: "",
      location: "",
      newSite: false
    };
  }

  componentWillMount() {
    getSites().then(data => {
      this.setState({ siteList: data.sites, siteLoading: false });
    });
  }

  showSite(id) {
    this.setState({
      selectedSite: id,
      newSite: false,
      location: ""
    });
  }

  addNewSite() {
    this.setState({ newSite: true, selectedSite: "" });
  }

  selectTab(location) {
    this.setState({ location: location });
  }

  updateSites() {
    getSites().then(data => {
      this.setState({ siteList: data.sites, siteLoading: false });
    });
  }

  returnHome() {
    this.setState({ newSite: false, selectedSite: "" });
  }

  get selectedSite() {
    const selectedSite = this.state.siteList.find(
      site => site.id === this.state.selectedSite
    );

    return selectedSite ? selectedSite : null;
  }

  get selectedSiteId() {
    const selectedSite = this.state.siteList.find(
      site => site.id === this.state.selectedSite
    );

    return selectedSite ? selectedSite.id : null;
  }

  render() {
    if (this.state.siteLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const menuItems = [
      { name: "Site Details", permission: "settings.site.view" },
      {
        name: "Booking Capacity",
        permission: "settings.site.bookingCapacity.view"
      },
      {
        name: "Booking Overrides",
        permission: "settings.site.bookingOverrides.view"
      },
      { name: "Load Types", permission: "settings.site.loadTypes.view" },
      {
        name: "Default Parking",
        permission: "settings.site.parkingRules.view"
      },
      {
        name: "Site Hauliers",
        permission: "settings.site.siteHauliers.view"
      },
      { name: "Devices", permission: "settings.site.devices.view" },
      { name: "Gatehouse", permission: "settings.site.gatehouse.view" }
    ];

    if (config.FEATURE_DOCK_SAFETY_ENABLED) {
      menuItems.push({
        name: "Dock Safety Devices",
        permission: "settings.site.view"
      });
    }

    const location =
      this.state.location !== "" ? this.state.location : menuItems[0]["name"];
    return (
      <AdminPanel
        title="SITES"
        items={this.state.siteList}
        showItem={this.showSite.bind(this)}
        addNew={this.addNewSite.bind(this)}
        addPermission="settings.site.create"
        selectedId={this.selectedSiteId}
      >
        {this.state.selectedSite === "" && !this.state.newSite && (
          <LandingPanel text="Select a Site" />
        )}
        {this.state.newSite && (
          <div className="admin-details">
            <div className="admin-details-title">New Site</div>
            <div className="admin-details-location">Add New Site</div>
            <div className="admin-details-main">
              <div className="form-columns">
                <SiteCreateForm
                  selected={this.state.selectedSite}
                  selectedSite={this.selectedSite}
                  updateSites={this.updateSites.bind(this)}
                  returnHome={this.returnHome.bind(this)}
                />
              </div>
            </div>
          </div>
        )}

        {this.state.selectedSite !== "" && (
          <div className="admin-details">
            <div className="admin-details-title">Settings</div>
            <div className="admin-details-location">
              {this.selectedSite.name} / {location}
            </div>

            <div className="admin-details-main">
              <div className="admin-details-menu">
                {menuItems.map((item, idx) => (
                  <Can key={idx} when={item["permission"]}>
                    <AdminMenuItem
                      id={idx}
                      title={item["name"]}
                      select={this.selectTab.bind(this)}
                      selected={location}
                    />
                  </Can>
                ))}
              </div>
              {location === "Site Details" && (
                <>
                  <Can when="settings.site.updateRestricted">
                    <div className="form-columns">
                      <SiteEditForm
                        selected={this.state.selectedSite}
                        selectedSite={this.selectedSite}
                        updateSites={this.updateSites.bind(this)}
                        returnHome={this.returnHome.bind(this)}
                      />
                    </div>
                  </Can>
                  <Cannot when="settings.site.updateRestricted">
                    <div className="form-columns">
                      <SiteMinimalEditForm
                        selected={this.state.selectedSite}
                        selectedSite={this.selectedSite}
                        updateSites={this.updateSites.bind(this)}
                        returnHome={this.returnHome.bind(this)}
                      />
                    </div>
                  </Cannot>
                </>
              )}
              {location === "Booking Capacity" && (
                <DefaultSlotForm
                  selected={this.state.selectedSite}
                  selectedSite={this.selectedSite}
                  updateSites={this.updateSites.bind(this)}
                  returnHome={this.returnHome.bind(this)}
                />
              )}
              {location === "Booking Overrides" && (
                <SlotConfigForm
                  selectedDate={this.props.selectedDate}
                  user={this.state.siteList[this.state.selectedSite]}
                  selectedSite={this.selectedSite}
                  sites={this.state.siteList}
                  updateSites={this.updateSites.bind(this)}
                  returnHome={this.returnHome.bind(this)}
                />
              )}
              {location === "Load Types" && (
                <LoadTypesForm selectedSite={this.state.selectedSite} />
              )}
              {location === "Default Parking" && (
                <Can when="settings.site.parkingRules.view">
                  <DefaultParkingForm
                    selectedSiteId={this.state.selectedSite}
                  />
                </Can>
              )}
              {location === "Site Hauliers" && (
                <SiteHaulierForm
                  selected={this.state.selectedSite}
                  selectedSite={this.selectedSite}
                  updateSites={this.updateSites.bind(this)}
                  returnHome={this.returnHome.bind(this)}
                />
              )}
              {location === "Devices" && (
                <SiteDevicesTab selectedSite={this.selectedSite} />
              )}
              {location === "Gatehouse" && (
                <GatehouseConfigForm
                  selectedSite={this.selectedSite}
                  updateSites={this.updateSites.bind(this)}
                  returnHome={this.returnHome.bind(this)}
                />
              )}
              {location === "Dock Safety Devices" && (
                <Can when="settings.site.update">
                  <DockDevices selectedSite={this.selectedSite} />
                </Can>
              )}
            </div>
          </div>
        )}
      </AdminPanel>
    );
  }
}
function mapStateToProps({ app: { selectedDate } }) {
  return { selectedDate };
}

export default withRouter(connect(mapStateToProps)(SiteForm));
