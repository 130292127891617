import React from "react";

export default function AssignedMovementsTable({ movements }) {
  if (movements.length === 0) {
    return <p>No movements currently assigned.</p>;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Trailer</th>
          <th>From</th>
          <th>To</th>
          <th>Device</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {movements.map(m => (
          <tr key={m.id}>
            <td>
              {m.trailer.haulier && `${m.trailer.haulier.shortCode} - `}
              {m.trailer.trailerNo}
            </td>
            <td>
              {m.trailer.zone && m.trailer.zone.name}
              {m.trailer.dock && ` - ${m.trailer.dock.number}`}
            </td>
            <td>
              {m.location.zone.name}
              {m.location.dock && ` - ${m.location.dock.number}`}
            </td>
            <td>{m.device && m.device.deviceId}</td>
            <td className="status">{m.status.replace(/_/g, " ")}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
