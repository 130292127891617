import React from "react";
import { connect } from "react-redux";
import { addMessage } from "redux/app";
import { withRouter } from "react-router";
import { distanceInWords, isBefore, addDays, addHours } from "date-fns";
import { fetchAllInvites, resendInvite, deleteInvite } from "api/invites";
import Loader from "Loader";
import "./InviteForm.css";

class InviteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invites: [],
      loading: true
    };
  }

  componentDidMount() {
    if (this.state.loading) {
      fetchAllInvites().then(invites => {
        this.setState({ invites: invites, loading: false });
      });
    }
  }

  resendInvite(id) {
    resendInvite(id).then(res => {
      const idx = this.state.invites.findIndex(invite => invite.id === id);
      const invites = this.state.invites;
      invites[idx] = res;
      this.setState({ invites: invites });
      this.props.dispatch(addMessage("success", "Invite resent"));
    });
  }

  deleteInvite(id) {
    deleteInvite(id).then(() => {
      const invites = this.state.invites.filter(function(invite) {
        return invite.id !== id;
      });

      this.setState({ invites: invites });
      this.props.dispatch(addMessage("success", "Invite removed"));
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    if (this.state.invites.length === 0) {
      return (
        <div>
          <div className="invites-title">
            There are no pending invites. Use the + button on the sidebar to
            invite a new user.
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="invites-title">
          This is a list of invitations that have yet to be accepted.
        </div>
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Status</th>
              <th colSpan="3">Sent</th>
            </tr>
          </thead>
          <tbody>
            {this.state.invites.map(invite => (
              <tr>
                <td>
                  {invite.email} - {invite.token}
                </td>
                <td>
                  {isBefore(new Date(), addDays(invite.updatedAt, 1))
                    ? "Pending"
                    : "Expired"}
                </td>
                <td>
                  {distanceInWords(addHours(invite.updatedAt, 1), new Date())}
                </td>
                <td
                  className="resend"
                  onClick={() => this.resendInvite(invite.id)}
                >
                  Resend
                </td>
                <td
                  className="remove"
                  onClick={() => this.deleteInvite(invite.id)}
                >
                  Remove
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect()(withRouter(InviteForm));
