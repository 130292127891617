export function urlFromBooking(booking) {
  const siteId = getSiteId(booking);

  return `/bookings/site/${siteId}/booking/${booking.journeyReference}`;
}

function getSiteId(booking) {
  const receivingSiteId = booking.receivingSite
    ? booking.receivingSite.id
    : null;
  const sendingSiteId = booking.sendingSite ? booking.sendingSite.id : null;

  if (booking.direction === "inbound" && receivingSiteId) {
    return receivingSiteId;
  } else if (booking.direction === "outbound" && sendingSiteId) {
    return sendingSiteId;
  } else if (!receivingSiteId) {
    return sendingSiteId;
  } else {
    return receivingSiteId;
  }
}
