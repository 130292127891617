import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetch, toArgList } from "graphql-helper";
import { Socket as PhoenixSocket } from "phoenix";
import * as AbsintheSocket from "@absinthe/socket";
import { intersection, upperFirst } from "lodash";
import Can from "Can";
import Content from "Content";
import Button from "Button";
import TrafficPageTemplate from "./TrafficPageTemplate";
import { selectSite } from "redux/app";
import RightPanel from "RightPanel";
import MoveTrailerForm from "./traffic-live-page/MoveTrailerForm";
import TranshipBookingForm from "./traffic-live-page/TranshipBookingForm";
import EditTrailerStatusForm from "./traffic-live-page/EditTrailerStatusForm";
import OverweightFlagForm from "./traffic-live-page/OverweightFlagForm";
import BookingPreviewModal from "BookingPreviewModal";
import AddTrailerBookingModal from "./traffic-live-page/AddTrailerBookingModal";
import JourneyReferenceModal from "./traffic-live-page/JourneyReferenceModal";
import TrafficTrailersTable, {
  status as trailerStatus
} from "./traffic-live-page/TrafficTrailersTable";
import TrailerFilterForm from "./traffic-live-page/TrailerFilterForm";
import "./TrafficTrailersPage.css";
import Alert from "Alert";
import noSignal from "assets/noSignal.svg";
import low from "assets/low.svg";
import medium from "assets/medium.svg";
import full from "assets/full.svg";

const trailerFields = site => `
trailers (${toArgList({ site })}) {
  id
  isUnavailable
  unavailabilityReason
  unavailabilityNotes
  returnDate
  haulier {
    id
    name
    shortCode
  }
  trailerNo
  booking {
    appointment {
      data
    }
    id
    direction
    status
    goods
    slotStart
    origin
    loadType
    journeyReference
    direction
    receivingSite {
      id
      name
      parentSite {
        name
      }
    }
    sendingSite {
      id
      name
      parentSite {
        name
      }
    }
    requiresTranship
    isRebooking
  }
  status
  isInternal
  isEmpty
  trailerType {
    id
    name
  }
  zone {
    id
    name
  }
  dock {
   number
   dockSafetyDevice {
    trafficLightStatus
    errorState
    currentPower
   }
  }
  movedAt
  insertedAt
}
`;

function FilterBar({ appliedFilters, onShowFilters, onClearFilters }) {
  const filterName = k => upperFirst(k.replace(/([A-Z])/g, " $1"));
  return (
    <div className="table-filter-bar">
      {appliedFilters.length > 0 && (
        <div className="flex">
          {appliedFilters.map(([k, v]) => (
            <span key={k} className="capsule">
              <span className="capsule__title">{filterName(k)}:</span>
              {v.join(", ")}
            </span>
          ))}
          <span>
            <Button
              variant="white"
              size="tiny"
              title="Clear all"
              onClick={onClearFilters}
            />
          </span>
        </div>
      )}
      <Button
        variant="white"
        icon="filter_list"
        size="tiny"
        title="Filter"
        onClick={onShowFilters}
      />
    </div>
  );
}

class TrafficTrailersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPanel: false,
      showFilters: false,
      showLinkBookingModal: false,
      showOutboundReferenceModal: false,
      showBookingPreviewModal: false,
      selectedBookingId: null,
      outboundReference: "",
      selectedTrailerId: null,
      trailers: [],
      appliedFilters: []
    };
  }

  componentDidMount() {
    if (parseInt(this.props.match.params.siteId) !== this.props.selectedSite) {
      this.props.dispatch(selectSite(this.props.match.params.siteId));
    }

    this.loadTrailers(this.props.selectedSite);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedSite !== this.props.selectedSite) {
      this.updateRoute(this.props.selectedSite);

      this.loadTrailers(this.props.selectedSite);

      this.closePanel();
    }
  }

  updateRoute(id) {
    if (id === null) {
      this.props.history.push({ pathname: "/traffic/trailers" });
    } else {
      this.props.history.push({
        pathname: `/traffic/trailers/site/${id}`
      });
    }
  }

  loadTrailers(siteId) {
    if (this.notifier) {
      AbsintheSocket.cancel(this.absintheSocket, this.notifier);
    }

    if (siteId === null) {
      return;
    }

    fetch(`query { ${trailerFields(siteId)} }`)
      .then(({ trailers }) => this.setState({ trailers }))
      .then(() => {
        const host =
          process.env.NODE_ENV === "development" // eslint-disable-line
            ? "ws://localhost:4000/socket"
            : "/socket";
        this.absintheSocket = AbsintheSocket.create(
          new PhoenixSocket(host, {})
        );

        this.notifier = AbsintheSocket.send(this.absintheSocket, {
          operation: `subscription { ${trailerFields(siteId)} }`,
          variables: {}
        });

        this.observer = AbsintheSocket.observe(
          this.absintheSocket,
          this.notifier,
          {
            onError: this.onError.bind(this),
            onResult: this.onResult.bind(this)
          }
        );
      });
  }

  onError() {
    AbsintheSocket.cancel(this.absintheSocket, this.notifier);
  }

  onResult({ data: { trailers } }) {
    this.setState({ trailers });
  }

  handleLinkBooking(trailerId) {
    this.setState({
      selectedTrailerId: trailerId,
      showLinkBookingModal: true
    });
  }

  handleEditTrailer(trailer) {
    this.setState({
      selectedTrailerId: trailer,
      showPanel: true
    });
  }

  handleCreateOutbound({ journeyReference }) {
    this.closeLinkBookingModal();
    this.setState({
      showOutboundReferenceModal: true,
      outboundReference: journeyReference
    });
  }

  handlePreviewBooking(selectedBookingId) {
    this.setState({
      showBookingPreviewModal: true,
      selectedBookingId
    });
  }

  handleFilterSubmit(appliedFilters) {
    this.setState({ appliedFilters, showFilters: false });
  }

  handleFilterReset() {
    this.setState({ appliedFilters: [], showFilters: false });
  }

  handleClearAllFilters() {
    this.setState({ appliedFilters: [] });
  }

  closePanel() {
    this.setState({ showPanel: false, selectedTrailerId: null });
  }

  closeLinkBookingModal() {
    this.setState({ showLinkBookingModal: false, selectedTrailerId: null });
  }

  closeOutboundReferenceModal() {
    this.setState({ showOutboundReferenceModal: false, outboundReference: "" });
  }

  closeBookingPreviewModal() {
    this.setState({ showBookingPreviewModal: false, selectedBookingId: null });
  }

  get selectedTrailer() {
    return this.state.selectedTrailerId;
  }

  get filteredTrailers() {
    const defaultFilter = () => true;

    const filterFunctions = Object.entries(this.state.appliedFilters).map(
      ([k, v]) => {
        if (Array.isArray(v) && v.length === 0) {
          return defaultFilter;
        }

        switch (k) {
          case "status":
            return t => {
              const values = v.map(({ value }) => value);
              return values.includes(trailerStatus(t));
            };
          case "trailerOwner":
            return t => {
              const values = v.map(({ value }) => value);
              return values.includes(t.haulier.id);
            };
          case "trailerType":
            return t => {
              const values = v.map(({ value }) => value);
              return values.includes(t.trailerType.id);
            };
          case "location":
            return t => {
              const values = v.map(({ value }) => value);
              return values.includes(t.zone.id);
            };
          case "goods":
            return t => {
              if (!t.booking || !t.booking.goods) {
                return false;
              }
              const values = v.map(({ value }) => value);
              return intersection(values, t.booking.goods).length > 0;
            };
          case "sendingSite":
            return t => {
              if (!t.booking) return false;
              const values = v.map(({ value }) => value);
              if (t.booking.sendingSite) {
                return values.includes(t.booking.sendingSite.id);
              } else {
                return values.includes(t.booking.origin);
              }
            };
          case "receivingSite":
            return t => {
              if (!t.booking) return false;
              const values = v.map(({ value }) => value);
              if (t.booking.receivingSite) {
                return values.includes(t.booking.receivingSite.id);
              } else {
                return values.includes(t.booking.origin);
              }
            };
          case "unavailabilityReason":
            return t => {
              const values = v.map(({ value }) => value);
              return values.includes(t.unavailabilityReason);
            };
          case "internalOnly":
            if (v) {
              return t => t.isInternal;
            } else {
              return defaultFilter;
            }
          case "loadType":
            return t => {
              if (!t.booking) {
                return false;
              }
              const values = v.map(({ value }) => value);
              return values.includes(t.booking.loadType);
            };

          default:
            return defaultFilter;
        }
      }
    );

    return this.state.trailers.filter(t => filterFunctions.every(af => af(t)));
  }

  get appliedFilters() {
    return Object.entries(this.state.appliedFilters)
      .filter(([, v]) => (Array.isArray(v) && v.length > 0) || v === true)
      .map(([k, values]) => {
        if (Array.isArray(values)) {
          return [k, values.map(v => v.label)];
        } else if (typeof values === "boolean") {
          return [k, ["True"]];
        }

        return [k, values];
      });
  }

  render() {
    const isSelectedDockSafe =
      !this.selectedTrailer ||
      !this.selectedTrailer.dock ||
      !this.selectedTrailer.dock.dockSafetyDevice ||
      this.selectedTrailer.dock.dockSafetyDevice.trafficLightStatus ===
        "green" ||
      this.selectedTrailer.dock.dockSafetyDevice.errorState ===
        "Connection Lost";

    return (
      <TrafficPageTemplate>
        {this.state.showBookingPreviewModal && (
          <BookingPreviewModal
            bookingId={this.state.selectedBookingId}
            onClose={this.closeBookingPreviewModal.bind(this)}
          />
        )}
        {this.state.showOutboundReferenceModal && (
          <JourneyReferenceModal
            journeyReference={this.state.outboundReference}
            onClose={this.closeOutboundReferenceModal.bind(this)}
          />
        )}
        {this.state.showLinkBookingModal && (
          <AddTrailerBookingModal
            selectedTrailer={this.selectedTrailer}
            onCancel={this.closeLinkBookingModal.bind(this)}
            onCreateOutbound={this.handleCreateOutbound.bind(this)}
          />
        )}
        <Content>
          <div className="dash-row">
            <div className="content-block">
              <h2>Live trailers</h2>

              <FilterBar
                appliedFilters={this.appliedFilters}
                onClearFilters={this.handleClearAllFilters.bind(this)}
                onShowFilters={() => {
                  this.setState({ showFilters: true });
                }}
              />

              <TrafficTrailersTable
                trailers={this.filteredTrailers}
                handleEditTrailer={this.handleEditTrailer.bind(this)}
                onShowBooking={this.handlePreviewBooking.bind(this)}
                onLinkBooking={this.handleLinkBooking.bind(this)}
              />
            </div>
          </div>
        </Content>
        <RightPanel
          title="Filter Trailers"
          isExpanded={this.state.showFilters}
          onClose={() => {
            this.setState({ showFilters: false });
          }}
        >
          {this.state.showFilters && (
            <TrailerFilterForm
              appliedFilters={this.state.appliedFilters}
              trailers={this.state.trailers}
              onSubmit={this.handleFilterSubmit.bind(this)}
              onReset={this.handleFilterReset.bind(this)}
            />
          )}
        </RightPanel>
        <RightPanel
          title="Edit Trailer"
          isExpanded={this.state.showPanel}
          onClose={this.closePanel.bind(this)}
        >
          {this.selectedTrailer && !this.state.showLinkBookingModal && (
            <>
              {!isSelectedDockSafe && (
                <div
                  style={{
                    paddingBottom: 16,
                    maxWidth: 250,
                    wordWrap: "break-word"
                  }}
                >
                  <Alert
                    type={"warning"}
                    message={
                      'You will not be able to move this trailer or change the status to "Ready To Move" because dock safety device light is red'
                    }
                  />
                </div>
              )}
              {this.selectedTrailer.dock && (
                <>
                  {this.selectedTrailer.dock.dockSafetyDevice &&
                    this.selectedTrailer.dock.dockSafetyDevice
                      .trafficLightStatus === "red" &&
                    this.selectedTrailer.dock.dockSafetyDevice.errorState ===
                      "Connection Lost" && (
                      <div
                        style={{
                          paddingBottom: 16,
                          maxWidth: 250,
                          wordWrap: "break-word"
                        }}
                      >
                        <Alert
                          type={"error"}
                          message={
                            "The safety device has lost connection and the last signal we received was red. Are you sure you want to move this trailer?"
                          }
                        />
                      </div>
                    )}

                  <div className="horizontal-container">
                    <Alert type={"info"} message={"Safety Device Status:"} />
                    <DeviceIcon
                      dockSafetyDevice={
                        this.selectedTrailer.dock.dockSafetyDevice
                      }
                    />
                  </div>
                </>
              )}
              <Can when="traffic.trailers.editLocation">
                <MoveTrailerForm
                  selectedTrailer={this.selectedTrailer}
                  afterUpdate={this.closePanel.bind(this)}
                  selectedSite={this.props.selectedSite}
                  isSelectedDockSafe={isSelectedDockSafe}
                />
              </Can>
              {this.selectedTrailer.booking && (
                <Can when="traffic.trailers.transhipBooking">
                  <TranshipBookingForm
                    direction={this.selectedTrailer.booking.direction}
                    bookingId={this.selectedTrailer.booking.id}
                    afterUpdate={this.closePanel.bind(this)}
                  />
                </Can>
              )}
              <Can when="traffic.trailers.updateStatus">
                <EditTrailerStatusForm
                  selectedTrailer={this.selectedTrailer}
                  afterUpdate={this.closePanel.bind(this)}
                  isSelectedDockSafe={isSelectedDockSafe}
                />
              </Can>
              {this.selectedTrailer.booking && (
                <Can when="traffic.trailers.flagOverweight">
                  <OverweightFlagForm
                    booking={this.selectedTrailer.booking}
                    afterUpdate={this.closePanel.bind(this)}
                  />
                </Can>
              )}
            </>
          )}
        </RightPanel>
      </TrafficPageTemplate>
    );
  }
}

function mapStateToProps({ app: { selectedSite } }) {
  return { selectedSite };
}

function DeviceIcon({ dockSafetyDevice }) {
  if (dockSafetyDevice === null) {
    return (
      <div
        style={{
          color: "rgba(209, 73, 91, 1)",
          display: "flex",
          justifyContent: "center",
          marginTop: "auto"
        }}
      >
        <span class="material-icons">system_security_update_warning</span>
      </div>
    );
  }
  if (dockSafetyDevice.errorState == "Connection Lost") {
    return (
      <div style={{ color: "rgba(209, 73, 91, 1)" }}>
        <img src={noSignal} style={{ height: "23px" }} />
      </div>
    );
  } else if (dockSafetyDevice.trafficLightStatus == "green") {
    return (
      <div
        style={{
          color: "rgba(143, 201, 58, 1)",
          display: "flex",
          justifyContent: "center",
          marginTop: "auto"
        }}
      >
        <span className="material-icons">radio_button_checked</span>
        <BatteryIcon level={dockSafetyDevice.currentPower} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          color: "rgba(209, 73, 91, 1)",
          display: "flex",
          justifyContent: "center",
          marginTop: "auto"
        }}
      >
        <span className="material-icons">radio_button_checked</span>
        <BatteryIcon level={dockSafetyDevice.currentPower} />
      </div>
    );
  }
}
function BatteryIcon({ level }) {
  if (level > 60) {
    return (
      <div style={{ color: "rgba(143, 201, 58, 1)" }}>
        <img
          src={full}
          style={{
            height: "100%",
            "-ms-transform": "translateY(-25%)",
            transform: "translateY(-25%)"
          }}
        />
      </div>
    );
  } else if (level > 15 && level <= 60) {
    return (
      <div style={{ color: "rgba(254, 153, 32, 1)" }}>
        <img
          src={medium}
          style={{
            height: "100%",
            "-ms-transform": "translateY(-25%)",
            transform: "translateY(-25%)"
          }}
        />
      </div>
    );
  } else if (level > 0 && level <= 15) {
    return (
      <div style={{ color: "rgba(209, 73, 91, 1)" }}>
        <img
          src={low}
          style={{
            height: "100%",
            "-ms-transform": "translateY(-25%)",
            transform: "translateY(-25%)"
          }}
        />
      </div>
    );
  } else return null;
}

export default withRouter(connect(mapStateToProps)(TrafficTrailersPage));
