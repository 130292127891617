import React, { useState } from "react";
import TableView from "TableView";
import Button from "Button";
import { fetch, toArgList, withErrorHandler } from "graphql-helper";
import ViewMessageModal from "./ViewMessageModal";

function NotificationsTable({ siteId, handleErrors }) {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);

  function acknowledgeMessage(id) {
    return fetch(`
      mutation AcknowledgeMessage {
        acknowledgeMessage(${toArgList({ id })}) {
          id
        }
      }
    `);
  }

  function resumeMovement(id) {
    return fetch(`
      mutation ResumeMovement {
        resumeMovement(${toArgList({ id })}) {
          id
        }
      }
    `);
  }

  function cancelMovement(id) {
    return fetch(`
      mutation CancelMovement {
        deleteMovement(${toArgList({ id })}) {
          id
        }
      }
    `);
  }

  function handleDismiss(message) {
    handleErrors(acknowledgeMessage(message.id).then(() => handleClose()));
  }

  function handleResume() {
    handleErrors(
      resumeMovement(selectedMessage.movement.id).then(() => handleClose())
    );
  }

  function handleCancel() {
    handleErrors(
      cancelMovement(selectedMessage.movement.id).then(() => handleClose())
    );
  }

  function handleView(message) {
    setSelectedMessage(message);
    setShowViewModal(true);
  }

  function handleClose() {
    setSelectedMessage(null);
    setShowViewModal(false);
  }

  return (
    <>
      {showViewModal && selectedMessage && (
        <ViewMessageModal
          data={selectedMessage}
          onResume={handleResume}
          onCancel={handleCancel}
          onClose={handleClose}
        ></ViewMessageModal>
      )}
      <TableView
        root="movementMessages"
        args={{ siteId }}
        noDataMessage="Nothing to action."
        fields={[
          [
            "Location",
            ["movement.trailer.zone.name", "movement.trailer.dock.number"]
          ],
          [
            "Trailer",
            ["movement.trailer.haulier.shortCode", "movement.trailer.trailerNo"]
          ],
          ["Message", "message"],
          [
            "",
            ["id", "requiresAction", "movement.id"],
            (d, m) => {
              if (m.requiresAction) {
                return (
                  <Button
                    size="tiny"
                    variant="secondary"
                    title="View"
                    onClick={() => handleView(m)}
                  />
                );
              } else {
                return (
                  <Button
                    size="tiny"
                    variant="secondary"
                    title="Dismiss"
                    onClick={() => handleDismiss(m)}
                  />
                );
              }
            }
          ]
        ]}
      ></TableView>
    </>
  );
}
export default withErrorHandler(NotificationsTable);
