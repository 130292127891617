import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { addMessage } from "redux/app";

export function fetch(queryString, variables = {}) {
  return axios
    .post("/graphql", {
      query: queryString,
      variables
    })
    .then(({ data }) => {
      if (data.errors) {
        return Promise.reject(data.errors);
      }

      return data.data;
    });
}

export function toArgList(object, allowNulls = false) {
  return Object.entries(object)
    .filter(
      ([, v]) => v || v === 0 || v === false || (allowNulls && v === null)
    )
    .map(([k, v]) => `${k}: ${JSON.stringify(v)}`)
    .join(", ");
}

export function buildQuery(root, args, fields) {
  return `query {${buildQueryFromQueryTree(
    root,
    fieldsToQueryTree(fields),
    args
  )}}`;
}

export function buildSubscription(root, args, fields) {
  return `subscription {${buildQueryFromQueryTree(
    root,
    fieldsToQueryTree(fields),
    args
  )}}`;
}

function buildQueryFromQueryTree(name, tree, args) {
  const argsList = args ? `(${toArgList(args)})` : "";

  return `${name}${argsList} {${Object.entries(tree)
    .map(([k, v]) => {
      if (v === true) {
        return k;
      } else {
        return buildQueryFromQueryTree(k, v);
      }
    })
    .join(", ")}}`;
}

function fieldsToQueryTree(fields) {
  return fields.map(f => f.split(".")).reduce(pathsToTree, {});
}

function pathsToTree(acc, [f, ...rest]) {
  if (rest.length === 0) {
    acc[f] = true;
  } else {
    acc[f] = pathsToTree(acc[f] || {}, rest);
  }

  return acc;
}

export function withErrorHandler(WrappedComponent) {
  return connect()(
    class extends React.Component {
      handleError(error) {
        if (typeof error.message === "string") {
          this.props.dispatch(addMessage("error", error.message));
        } else if (error instanceof String) {
          this.props.dispatch(addMessage("error", error));
        } else if (error instanceof Array) {
          error.forEach(this.handleError.bind(this));
        } else {
          this.props.dispatch(
            addMessage("error", "An unknown error occurred. Please try again")
          );
        }
      }

      handleErrors(promise, successMessage) {
        if (successMessage) {
          promise.then(() => {
            this.props.dispatch(addMessage("success", successMessage));
          });
        }

        return promise.catch(this.handleError.bind(this));
      }

      render() {
        return (
          <WrappedComponent
            handleErrors={this.handleErrors.bind(this)}
            {...this.props}
          />
        );
      }
    }
  );
}
