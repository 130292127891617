import React from "react";
import Button from "Button";
import Modal from "Modal";
import "./ConfirmationModal.css";

export default function ConfirmationModal(props) {
  return (
    <Modal>
      <div className="modal">
        <div className="modal__content">
          <div className="modal__body">
            <div className="modal__title">{props.title}</div>
            <div className="modal__buttons">
              <Button
                variant="primary"
                size="small"
                title="Confirm"
                onClick={props.onConfirm}
              />
              <Button
                variant="secondary"
                size="small"
                title="Cancel"
                onClick={props.onCancel}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
