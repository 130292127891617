import React, { Component } from "react";
import AdminPanel from "AdminPanel";
import LandingPanel from "LandingPanel";
import Loader from "Loader";
import { getTrailers } from "./trailer-admin-page/trailer-admin-queries";
import TrailerForm from "./trailer-admin-page/TrailerForm";
import TrailerInternalForm from "./trailer-admin-page/TrailerInternalForm";
import TabControl from "form-controls/TabControl";
import TabContentItem from "form-controls/TabContentItem";

export default class TrailerAdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newSite: false,
      selectedTrailer: null,
      trailers: [],
      loading: true
    };
  }

  componentDidMount() {
    this.updateTrailers();
  }

  updateTrailers() {
    getTrailers().then(
      ({ trailers }) => {
        this.setState({ trailers, loading: false });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  addNewTrailer() {
    this.setState({ newSite: true, selectedTrailer: null });
  }

  showTrailer(id) {
    this.setState({ newSite: false, selectedTrailer: id });
  }

  back() {
    this.setState({ newSite: false, selectedTrailer: null });
  }

  get selectedTrailer() {
    return this.state.trailers.find(t => t.id === this.state.selectedTrailer);
  }

  get panelItems() {
    return this.state.trailers.map(t => ({
      id: t.id,
      name: t.trailerNo,
      text: t.trailerType ? t.trailerType.name : ""
    }));
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="site-config">
        <AdminPanel
          title="TRAILERS"
          items={this.panelItems}
          showItem={this.showTrailer.bind(this)}
          addNew={this.addNewTrailer.bind(this)}
          addPermission="settings.trailers.create"
          selectedId={this.state.selectedTrailer}
        >
          {this.state.selectedTrailer === null && !this.state.newSite && (
            <LandingPanel text="Select a trailer" />
          )}
          {this.state.selectedTrailer !== null && (
            <div className="admin-details">
              <TabControl title={this.selectedTrailer.number}>
                <TabContentItem title="Trailer Details">
                  <TrailerForm
                    back={this.back.bind(this)}
                    updateTrailers={this.updateTrailers.bind(this)}
                    selectedTrailer={this.selectedTrailer}
                    editing
                  />
                </TabContentItem>
                <TabContentItem title="Internal Trailer Options">
                  <TrailerInternalForm
                    back={this.back.bind(this)}
                    updateTrailers={this.updateTrailers.bind(this)}
                    selectedTrailer={this.selectedTrailer}
                    editing
                  />
                </TabContentItem>
              </TabControl>
            </div>
          )}

          {this.state.newSite && (
            <TrailerForm
              back={this.back.bind(this)}
              updateTrailers={this.updateTrailers.bind(this)}
              selectedTrailer={this.selectedTrailer}
            />
          )}
        </AdminPanel>
      </div>
    );
  }
}
