import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "PrivateRoute";
import TrafficAuditingPage from "./pages/TrafficAuditingPage";
import TrafficBookingsPage from "./pages/TrafficBookingsPage";
import TrafficDashboardPage from "./pages/TrafficDashboardPage";
import TrafficLandingPage from "./pages/TrafficLandingPage";
import TrafficMovementsPage from "./pages/TrafficMovementsPage";
import TrafficSchedulePage from "./pages/TrafficSchedulePage";
import TrafficServicingPage from "./pages/TrafficServicingPage";
import TrafficTrailersPage from "./pages/TrafficTrailersPage";
import NotFoundPage from "NotFoundPage";

export default function TrafficRoot() {
  return (
    <Switch>
      <PrivateRoute exact path="/traffic" component={TrafficDashboardPage} />
      <PrivateRoute
        exact
        path="/traffic/site/:siteId"
        component={TrafficDashboardPage}
      />
      <PrivateRoute
        exact
        path="/traffic/trailers"
        component={TrafficLandingPage}
      />
      <PrivateRoute
        path="/traffic/trailers/site/:siteId"
        component={TrafficTrailersPage}
      />
      <PrivateRoute
        path="/traffic/movements"
        component={TrafficMovementsPage}
      />
      <PrivateRoute
        exact
        path="/traffic/movements/site/:siteId"
        component={TrafficMovementsPage}
      />
      <PrivateRoute
        exact
        path="/traffic/bookings"
        component={TrafficBookingsPage}
      />
      <PrivateRoute
        exact
        path="/traffic/bookings/site/:siteId"
        component={TrafficBookingsPage}
      />
      <PrivateRoute
        exact
        path="/traffic/trailers/servicing"
        component={TrafficLandingPage}
      />
      <PrivateRoute
        exact
        path="/traffic/trailers/servicing/site/:siteId"
        component={TrafficServicingPage}
      />
      <PrivateRoute
        exact
        path="/traffic/trailers/yard-audits"
        component={TrafficLandingPage}
      />
      <PrivateRoute
        exact
        path="/traffic/trailers/yard-audits/site/:siteId"
        component={TrafficAuditingPage}
      />
      <PrivateRoute path="/traffic/schedule" component={TrafficSchedulePage} />
      <PrivateRoute
        path="/traffic/schedule/site/:siteId"
        component={TrafficSchedulePage}
      />

      <Route component={NotFoundPage} />
    </Switch>
  );
}
