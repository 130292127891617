import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Formik, Form, Field } from "formik";
import Button from "Button";
import TextInput from "form-controls/TextInput";
import * as Yup from "yup";
import "./HaulierCreateForm.css";
import { createHaulier } from "./hauliers-admin-queries";

const registerSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, "")
    .required("Please enter a haulier name"),
  shortCode: Yup.string()
    .min(4)
    .max(4, "")
    .required("Please enter a haulier code")
});

class HaulierCreateForm extends React.Component {
  get initialValues() {
    return {
      name: "",
      shortCode: ""
    };
  }

  render() {
    return (
      <Formik
        validationSchema={registerSchema}
        initialValues={this.initialValues}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          createHaulier(values).then(() => {
            this.props.returnHome();
            setSubmitting(false);
          });
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div>
              <Field
                name="name"
                label="Haulier Name"
                component={TextInput}
                error={touched.name && errors.name}
                autoFocus={true}
              />
              <Field
                name="shortCode"
                label="Haulier Short Code"
                component={TextInput}
                error={touched.shortCode && errors.shortCode}
              />

              <div className="button-col">
                <Button
                  type="submit"
                  variant="primary"
                  size="small"
                  title="Save"
                  disabled={
                    Object.keys(touched).length < 1 ||
                    Object.keys(errors).length ||
                    isSubmitting
                  }
                />
                <Button
                  variant="secondary"
                  size="small"
                  title="Cancel"
                  onClick={() => this.props.returnHome()}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect()(withRouter(HaulierCreateForm));
