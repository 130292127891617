import React, { Component } from "react";
import {
  hauliers,
  bookingReceipts
} from "./hauliers-admin-page/hauliers-admin-queries";
import LandingPanel from "LandingPanel";
import AdminPanel from "AdminPanel";
import Loader from "Loader";
import TabControl from "form-controls/TabControl";
import TabContentItem from "form-controls/TabContentItem";
import HaulierOverview from "./hauliers-admin-page/HaulierOverview";
import HaulierForm from "./hauliers-admin-page/HaulierForm";
import BookingReceiptForm from "./hauliers-admin-page/BookingReceiptForm";
import HaulierCreateForm from "./hauliers-admin-page/HaulierCreateForm";

class HaulierAdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hauliers: [],
      haulierLoading: true,
      selectedHaulierId: null,
      newHaulier: false,
      sites: [],
      receipts: []
    };
  }
  componentDidMount() {
    this.getHauliers();
  }
  showHaulier(id) {
    this.setState({
      selectedHaulierId: id,
      newHaulier: false
    });
    this.getBookingReceipts(id);
  }

  getBookingReceipts(id = undefined) {
    bookingReceipts(id ? id : this.state.selectedHaulierId).then(data => {
      if (data.bookingReceipts) {
        this.setState({ receipts: data.bookingReceipts });
      }
    });
  }

  getSelectedHaulier() {
    return this.state.hauliers.find(
      haulier => haulier.id === this.state.selectedHaulierId
    );
  }

  getHauliers() {
    hauliers().then(data => {
      this.setState({
        hauliers: data.hauliers,
        sites: data.sites,
        haulierLoading: false
      });
    });
  }

  returnHome() {
    this.setState({ newHaulier: false, selectedHaulierId: null });
    this.getHauliers();
  }

  addNewHaulier() {
    this.setState({ newHaulier: true, selectedHaulierId: null });
  }

  render() {
    if (this.state.haulierLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const selectedHaulier = this.getSelectedHaulier();
    return (
      <div className="site-config">
        <AdminPanel
          title="Hauliers"
          items={this.state.hauliers}
          showItem={this.showHaulier.bind(this)}
          selectedId={this.state.selectedHaulierId}
          addNew={this.addNewHaulier.bind(this)}
          addPermission="settings.hauliers.create"
        >
          {this.state.selectedHaulierId === null && !this.state.newHaulier && (
            <LandingPanel text="Select Haulier" />
          )}

          {this.state.newHaulier && (
            <div className="admin-details">
              <div className="admin-details-title">New Haulier</div>
              <div className="admin-details-location">Add New Haulier</div>
              <div className="admin-details-main">
                <div className="form-columns">
                  <HaulierCreateForm returnHome={this.returnHome.bind(this)} />
                  <div className="form-right-column">
                    This is for the haulier logo
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.selectedHaulierId !== null && (
            <div className="admin-details">
              <TabControl title={selectedHaulier.name}>
                <TabContentItem title="Overview">
                  <HaulierOverview
                    haulier={selectedHaulier}
                    receipts={this.state.receipts}
                  />
                </TabContentItem>
                <TabContentItem title="Details">
                  <HaulierForm
                    isEditMode
                    selectedHaulier={selectedHaulier}
                    sites={this.state.sites}
                    returnHome={this.returnHome.bind(this)}
                  />
                </TabContentItem>
                <TabContentItem title="Emailing">
                  <BookingReceiptForm
                    isEditMode
                    selectedHaulierId={this.state.selectedHaulierId}
                    returnHome={this.returnHome.bind(this)}
                    receipts={this.state.receipts}
                    getBookingReceipts={() => this.getBookingReceipts()}
                  />
                </TabContentItem>
              </TabControl>
            </div>
          )}
        </AdminPanel>
      </div>
    );
  }
}

export default HaulierAdminPage;
