import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Button from "Button";
import Loader from "Loader";
import TextInput from "form-controls/TextInput";
import Alert from "Alert";

function equalTo(ref, msg) {
  return this.test({
    name: "equalTo",
    exclusive: false,
    message: msg || "${path} must be the same as ${reference}",
    params: {
      reference: ref.path
    },
    test: function(value) {
      return value === this.resolve(ref);
    }
  });
}

Yup.addMethod(Yup.string, "equalTo", equalTo);

const registerSchema = Yup.object().shape({
  password: Yup.string()
    .min(16, "Your password must be at least 16 characters")
    .matches(
      /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{16,})/,
      "Your password must contain: at least one upper case character, at least on lower case character and at least one number."
    )
    .required("Please enter a password"),
  confirmPassword: Yup.string().equalTo(
    Yup.ref("password"),
    "Passwords do not match"
  )
});

function UpdatePasswordForm({ errorMessage, updatePassword }) {
  const initialValues = {
    password: "",
    confirmPassword: ""
  };

  const isSubmitDisabled = (values, errors) =>
    values.password.length === 0 ||
    values.password !== values.confirmPassword ||
    Object.keys(errors).length > 0;

  return (
    <Formik
      validationSchema={registerSchema}
      onSubmit={(values, { setSubmitting }) => {
        updatePassword(values).then(() => {
          setSubmitting(false);
        });
      }}
      initialValues={initialValues}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <div>
          {isSubmitting && <Loader />}
          {errorMessage && <Alert type="error" message={errorMessage}></Alert>}
          <Form>
            <Field
              name="password"
              label="Password"
              component={TextInput}
              inputType="password"
              error={touched.password && errors.password}
            />
            <Field
              name="confirmPassword"
              label="Confirm Password"
              component={TextInput}
              inputType="password"
              error={touched.confirmPassword && errors.confirmPassword}
            />
            <div className="center">
              <Button
                type="submit"
                variant="primary"
                size="small"
                title="Change Password"
                disabled={isSubmitDisabled(values, errors)}
              />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}

export default UpdatePasswordForm;
