import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addMessage } from "redux/app";
import { Formik, Form, Field } from "formik";
import SelectBox from "form-controls/SelectBox";
import { updateStandLevel, standLevels } from "./hauliers-admin-queries";
import "./HaulierCreateForm.css";

class HaulierForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      standLevels: []
    };
  }

  componentDidMount() {
    this.getStandLevels();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedHaulier !== this.props.selectedHaulier) {
      this.setState({ standLevels: [] });
      this.getStandLevels();
    }
  }

  getStandLevels() {
    standLevels(this.props.selectedHaulier.id).then(data => {
      const standLevels = [];
      if (data.standLevel) {
        this.props.sites.map(site => {
          standLevels[site.id] = this.getStandLevel(data.standLevel, site.id);
        });
      }
      this.setState({ standLevels: standLevels });
    });
  }

  getStandLevel(standLevels, siteId) {
    const sl = standLevels.filter(level => level.site.id === siteId);
    return sl[0] ? sl[0].standLevel : 0;
  }

  updateStandLevel(siteId, standLevel) {
    updateStandLevel(this.props.selectedHaulier.id, siteId, standLevel).then(
      () => {
        this.props.dispatch(
          addMessage("success", "Trailer stand level has been updated.")
        );
      }
    );
  }

  get initialValues() {
    return {
      standLevel: this.state.standLevels
    };
  }

  render() {
    const slots = [...Array(100).keys()];

    const numSlots = slots.reduce((acc, elem) => {
      acc[elem] = { value: elem, label: elem };
      return acc;
    }, []);

    return (
      <Formik initialValues={this.initialValues} enableReinitialize>
        {() => (
          <Form className="admin-form">
            <table className="stand-level-table">
              <thead>
                <tr>
                  <th>Site</th>
                  <th>Stand Level</th>
                </tr>
              </thead>
              <tbody>
                {this.props.sites.map(site => (
                  <tr key={site.id}>
                    <td className="site-label">{site.name}</td>
                    <td>
                      <Field
                        name={`standLevel[${site.id}]`}
                        label=""
                        placeholder=""
                        options={numSlots}
                        component={SelectBox}
                        defaultValue="0"
                        noDefault={true}
                        onChange={e =>
                          this.updateStandLevel(
                            e.target.name.match(/\d+/)[0],
                            e.target.value
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect()(withRouter(HaulierForm));
