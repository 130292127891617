import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";

import TextInput from "form-controls/TextInput";
import SelectBox from "form-controls/SelectBox";
import CheckBox from "form-controls/CheckBox";
import Button from "Button";

import trailerGoods from "trailer-goods";
import { formatAsOptions } from "siteList";

export default ({ onSubmit, sites }) => {
  const [siteOptions, setSiteOptions] = useState([]);

  useEffect(() => {
    setSiteOptions(formatAsOptions(sites));
  }, sites);

  return (
    <Formik onSubmit={onSubmit} initialValues={{}}>
      {({ isSubmitting }) => (
        <Form>
          <Field
            name="receivingSiteId"
            label="To"
            placeholder="Select site"
            options={siteOptions}
            component={SelectBox}
          />
          <Field
            name="numberOfPallets"
            label="Number of Pallets"
            component={TextInput}
          />
          <div>Goods (Select all that apply)</div>
          {trailerGoods.map(x => (
            <Field
              key={x.name}
              name={x.name}
              label={x.label}
              component={CheckBox}
            />
          ))}
          <div className="button-col">
            <Button
              type="submit"
              variant="primary"
              size="small"
              title="Submit"
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
