import React from "react";
import classnames from "classnames";
import NumericInput from "react-numeric-input";
import "./TextInput.css";

export default function TextInput({
  min,
  max,
  step,
  value,
  onChange,
  ...props
}) {
  const theme = props.theme || "light";
  const className = classnames("text-input", `text-input--${theme}`, {
    "text-input--has-errors": props.error
  });

  return (
    <div className="form-control">
      {props.label}
      <NumericInput
        {...props}
        className={className}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        disabled={props.disabled}
      />
      {props.error && <div className="form-field-error">{props.error}</div>}
    </div>
  );
}
