import React from "react";
import { withRouter } from "react-router";
import { Formik, Field, Form } from "formik";
import { sendPasswordResetEmail } from "api/accounts";
import TextInput from "form-controls/TextInput";
import Button from "components/Button";
import Loader from "components/Loader";
import "./ForgotPasswordForm.css";

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPasswordSent: false
    };
  }

  get initialValues() {
    return {
      email: ""
    };
  }

  render() {
    if (this.state.resetPasswordSent) {
      return (
        <p>
          Check your inbox. Your password reset instructions will be with you
          shortly.
        </p>
      );
    }

    return (
      <Formik
        initialValues={this.initialValues}
        onSubmit={({ email }) => {
          sendPasswordResetEmail(email).then(() => {
            this.setState({ resetPasswordSent: true });
          });
        }}
      >
        {({ errors, values, isSubmitting }) => (
          <div className="forgot-password-form">
            {isSubmitting && <Loader />}
            <Form>
              <Field
                name="email"
                label="Email"
                placeholder="Enter Email"
                component={TextInput}
              />
              <div className="forgot-password-form__buttons center">
                <Button
                  type="submit"
                  variant="primary"
                  title="Send"
                  disabled={!values.email || Object.keys(errors).length}
                />
                <Button
                  type="submit"
                  variant="white"
                  size="small"
                  title="Back to login"
                  onClick={() => this.props.history.push("/auth/login")}
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    );
  }
}

export default withRouter(ForgotPasswordForm);
