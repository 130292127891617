import React from "react";
import classnames from "classnames";
import "./Alert.css";

export default function Alert(props) {
  const iconType = (type => {
    switch (type) {
      case "success":
        return "check_circle";
      case "error":
        return "error";
      case "locked":
        return "lock";
      case "warning":
        return "warning";
    }
    return "";
  })(props.type);

  const className = classnames("alert", `alert--${props.type}`, {
    "alert--fade-out": props.fadingOut
  });

  return (
    <div className={className} ref={props.containerRef}>
      <i className="alert__icon material-icons">{iconType}</i>
      <span className="alert__message">{props.message}</span>
      {props.onDismiss && (
        <i
          className="alert__icon alert__icon--dismiss material-icons"
          onClick={props.onDismiss}
        >
          close
        </i>
      )}
    </div>
  );
}
