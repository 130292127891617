export function optionList(canCreateRebooking) {
  const options = [
    { value: "inbound", label: "Inbound" },
    { value: "outbound", label: "Outbound" },
    { value: "staged", label: "Staged" }
  ];

  return canCreateRebooking
    ? [...options, { value: "rebooking", label: "Delivery Failure/Refusal" }]
    : options;
}

export function fromOptionValue(value) {
  const option = optionList().find(option => option.value === value);

  return option ? option.label : "";
}
