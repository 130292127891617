import React from "react";
import { withRouter } from "react-router";
import RegisterForm from "./register-page/RegisterForm";
import SplashPage from "./SplashPage";
import "./RegisterPage.css";

function RegisterPage(props) {
  const { inviteToken } = props.match.params;

  return (
    <SplashPage>
      <p className="splash-page__heading">Register</p>
      <p className="thin">You've been invited!</p>
      <p className="small-type center">
        Please enter your details below to finish creating your account.
      </p>
      <RegisterForm inviteToken={inviteToken} />
    </SplashPage>
  );
}

export default withRouter(RegisterPage);
