import React from "react";
import BaseCheckBox from "./BaseCheckBox";
import "./CheckBox.css";

export default function CheckBox(props) {
  return (
    <div className="form-control">
      <BaseCheckBox {...props} />
    </div>
  );
}
