import React from "react";
import { Formik, Field, Form } from "formik";
import SelectBox from "form-controls/SelectBox";
import Loader from "Loader";
import { selectSite } from "redux/app";
import { fetchAllSites } from "redux/sites";
import { connect } from "react-redux";
import { formatAsOptions } from "siteList";

class GlobalSiteSelect extends React.Component {
  componentDidMount() {
    if (this.props.sites.length === 0) {
      this.props.dispatch(fetchAllSites());
    }
  }

  handleSiteChange({ target: { value } }) {
    this.props.dispatch(selectSite(value));
  }

  get siteOptions() {
    return formatAsOptions(this.props.sites);
  }

  render() {
    if (this.props.loading) {
      return <Loader />;
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{
          siteId:
            this.props.selectedSite !== null ? this.props.selectedSite : ""
        }}
        initialErrors
      >
        <Form>
          <Field
            name="siteId"
            options={this.siteOptions}
            placeholder="Select Site"
            component={SelectBox}
            onChange={this.handleSiteChange.bind(this)}
            theme={this.props.theme || "dark"}
          />
        </Form>
      </Formik>
    );
  }
}

function mapStateToProps({ app: { selectedSite }, sites: { items, loading } }) {
  return { selectedSite, sites: items, loading };
}

export default connect(mapStateToProps)(GlobalSiteSelect);
