import React from "react";
import LandingPanel from "LandingPanel";
import GlobalSiteSelect from "GlobalSiteSelect";

export default function SelectSitePanel() {
  return (
    <LandingPanel text="Please select a site to view this page">
      <p style={{ marginBottom: "1em" }}>
        Use the dropdown below or in the top-right of the page.
      </p>
      <GlobalSiteSelect theme="light" />
    </LandingPanel>
  );
}
