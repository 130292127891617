import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "PrivateRoute";
import NotFoundPage from "NotFoundPage";
import WarehouseZones from "./pages/WarehouseZones";
import WarehouseDashboardPage from "./pages/WarehouseDashboardPage";
import WarehouseBookings from "./pages/WarehouseBookings";
import WarehouseRebookings from "./pages/WarehouseRebookings";

export default function WarehouseRoot() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/warehouse"
        component={WarehouseDashboardPage}
      />
      <PrivateRoute
        exact
        path="/warehouse/site/:siteId"
        component={WarehouseDashboardPage}
      />
      <PrivateRoute exact path="/warehouse/zones" component={WarehouseZones} />
      <PrivateRoute
        exact
        path="/warehouse/zones/:zoneId"
        component={WarehouseZones}
      />
      <PrivateRoute
        exact
        path="/warehouse/zones/site/:siteId"
        component={WarehouseZones}
      />
      <PrivateRoute
        exact
        path="/warehouse/bookings/"
        component={WarehouseBookings}
      />
      <PrivateRoute
        path="/warehouse/bookings/site/:siteId"
        component={WarehouseBookings}
      />

      <PrivateRoute
        path="/warehouse/rebookings"
        component={WarehouseRebookings}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
