import { fetch } from "graphql-helper";

export function getTrailerTypes() {
  return fetch(`
    query {
      trailerTypes {
        id
        name
        type
        baseType
      }
    }
  `);
}

export function getTrailers() {
  return fetch(`
    query {
      trailers(includeOffsite: false) {
        id
        trailerNo
        haulier {
          id
        }
        zone {
          id
          site {
            id
          }
        }
        dock {
          id
        }
        trailerType {
          id
          name
        }
        isInternal
        isUnavailable
        unavailabilityContact
        unavailabilityReason
        unavailabilityNotes
        returnDate
        status
        movedAt
        insertedAt
        isTrailerUsedByOutboundBooking
      }
    }
  `);
}

export function getTrailerOptionData() {
  return fetch(`
    query {
      hauliers {
        id
        name
      }
      sites {
        id
        name
        zones {
          id
          name
          type
          isActive
          docks {
            id
            number
            isActive
          }
        }
      }
      trailerTypes {
        id
        name
      }
    }
  `);
}
