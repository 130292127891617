export const trailerStatuses = {
  awaitingAction: "awaiting_action",
  actionInProgress: "action_in_progress",
  actionComplete: "action_complete",
  readyToMove: "ready_to_move"
};

export function optionList() {
  return [
    { value: trailerStatuses.awaitingAction, label: "Awaiting Action" },
    { value: trailerStatuses.actionInProgress, label: "In Progress" },
    { value: trailerStatuses.actionComplete, label: "Loading Complete" },
    { value: trailerStatuses.readyToMove, label: "Ready To Move" }
  ];
}

export function fromOptionValue(value) {
  const option = optionList().find(option => option.value === value);

  return option ? option.label : "";
}

const loadingTrailerStatues = {
  [trailerStatuses.awaitingAction]: "Awaiting Loading",
  [trailerStatuses.actionInProgress]: "Loading in Progress",
  [trailerStatuses.actionComplete]: "Loading Complete",
  [trailerStatuses.readyToMove]: "Ready to Move"
};

const unloadingTrailerStatuses = {
  [trailerStatuses.awaitingAction]: "Awaiting Unloading",
  [trailerStatuses.actionInProgress]: "Unloading in Progress",
  [trailerStatuses.actionComplete]: "Unloading Complete",
  [trailerStatuses.readyToMove]: "Ready to Move"
};

export function dockStatus(trailerStatus, bookingStatus) {
  const loadingBookingStatuses = ["trailer_attached", "outbound_in_progress"];
  const unloadingBookingStatuses = ["arrived", "inbound_in_progress"];

  if (bookingStatus === "ready_for_driver") {
    return "Awaiting Driver";
  } else if (loadingBookingStatuses.includes(bookingStatus)) {
    return loadingTrailerStatues[trailerStatus] || "Unknown";
  } else if (unloadingBookingStatuses.includes(bookingStatus)) {
    return unloadingTrailerStatuses[trailerStatus] || "Unknown";
  }

  return fromOptionValue(trailerStatus);
}
