import React from "react";
import NumberedDockTableRow from "./NumberedDockTableRow";
import config from "../../../../config";

export default function NumberedDockTable(props) {
  function movementByDockId(dockId) {
    return (
      props.movements &&
      props.movements.find(
        m =>
          (m.location.dock && parseInt(m.location.dock.id) == dockId) ||
          (m.tipLocation.dock && parseInt(m.tipLocation.dock.id) == dockId)
      )
    );
  }

  function trailersByDockId(dockId) {
    return (
      props.zone.trailers &&
      props.zone.trailers.filter(t => t.dock && parseInt(t.dock.id) == dockId)
    );
  }

  return (
    <table className="zone-table" style={{ whiteSpace: "nowrap" }}>
      <thead>
        <tr>
          <th />
          <th>Dock no</th>
          {config.FEATURE_DOCK_SAFETY_ENABLED && (
            <th style={{ textAlign: "center" }}>Device</th>
          )}
          <th>Trailer</th>
          <th>Pallets</th>
          <th />
          <th>Journey Ref</th>
          <th>PO/ASN</th>
          <th>Direction</th>
          <th>Time on Dock</th>
          <th />
          <th>Trailer Status</th>
          <th />
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.docks.map(d => (
          <NumberedDockTableRow
            key={d.id}
            dock={d}
            zone={props.zone}
            onRequestTrailer={props.onRequestTrailer}
            onMoveTrailer={props.onMoveTrailer}
            releaseTrailer={props.releaseTrailer}
            onCancelMovement={props.onCancelMovement}
            onSelectBooking={props.onSelectBooking}
            movement={movementByDockId(d.id)}
            trailers={trailersByDockId(d.id)}
            isSelected={d.id === props.selectedDockId}
          />
        ))}
      </tbody>
    </table>
  );
}
