import React from "react";
import { Formik, Form } from "formik";
import Button from "Button";

export default function RequestDriverForm({ booking, onSubmit }) {
  const driverHasArrived =
    booking.driverName && booking.driverName != "" && booking.checkInCompleteAt;

  if (!driverHasArrived) {
    return (
      <p>
        The driver has not yet arrived.
        <br />
        Please move the trailer to a<br />
        parking zone to free up the dock.
      </p>
    );
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={{}}>
      {({ isSubmitting }) => (
        <Form>
          <p>
            Click the button below to allow
            <br />
            the driver to pick-up the trailer
          </p>
          <div className="button-col">
            <Button
              type="submit"
              variant="primary"
              size="small"
              title="Request driver"
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
