import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Slider from "react-slick";
import Loader from "Loader";
import FlightboardPageTemplate from "./FlightboardPageTemplate";
import FlightboardTable from "../components/FlightboardTable";
import { bookingsOnSite, flightBoardSubscription } from "../flightboardQueries";
import LiveDataView from "LiveDataView";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class FlightboardLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: null,
      loading: true
    };
  }

  componentDidMount() {
    bookingsOnSite().then(result => {
      const bookings = result.bookings.filter(
        booking =>
          booking.vehicleReg !== null && booking.direction !== "internal"
      );
      this.setState({ bookings: bookings, loading: false });
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const itemsPerPage = 8;
    const settings = {
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: false,
      speed: 1000
    };

    const pages = () => {
      const pages = [];
      for (let i = 0; i < this.state.bookings.length / itemsPerPage; i++) {
        pages.push(
          <div key={i}>
            <FlightboardTable
              bookings={this.state.bookings.slice(
                i * itemsPerPage,
                i * itemsPerPage + itemsPerPage
              )}
            />
          </div>
        );
      }

      return pages;
    };

    return (
      <LiveDataView
        subscription={flightBoardSubscription()}
        onResult={({ bookings }) => {
          const updatedBookings = bookings.filter(
            booking =>
              booking.vehicleReg !== null && booking.direction !== "internal"
          );
          this.setState({ bookings: updatedBookings });
        }}
      >
        {() => (
          <FlightboardPageTemplate>
            <div className="flightboard-container">
              <Slider {...settings}>{pages()}</Slider>
            </div>
          </FlightboardPageTemplate>
        )}
      </LiveDataView>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default withRouter(connect(mapStateToProps)(FlightboardLandingPage));
