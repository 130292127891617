import React from "react";
import BookingForm from "./BookingForm";
import InternalBookingForm from "./InternalBookingForm";
import RightPanel from "RightPanel";
import SelectBox from "form-controls/SelectBox";
import { Formik, Field, Form } from "formik";
import { formatLocalDate } from "date-helper";

function SelectBookingForm({ onSelectBooking, bookings, selectedBookingId }) {
  if (bookings.length < 2) {
    return null;
  }

  const bookingOptions = bookings.map((b) => ({
    value: b.id,
    label: `${formatLocalDate(b.slotStart)} - ${b.loadType} (${b.direction})`,
  }));

  return (
    <Formik initialValues={{ booking: selectedBookingId }}>
      {() => (
        <Form>
          <p
            className="small-type"
            style={{ width: "330px", marginBottom: "0.5rem" }}
          >
            There are multiple bookings with this reference. Use the dropdown
            below to choose the correct one.
          </p>
          <Field
            name="booking"
            label="Bookings"
            component={SelectBox}
            noDefault={true}
            options={bookingOptions}
            onChange={(e) => onSelectBooking(e.target.value)}
          />
        </Form>
      )}
    </Formik>
  );
}

function BookingRightPanel(props) {
  function getPanelTitle(path, isInternal) {
    const suffix = isInternal ? " - Internal" : "";
    switch (path) {
      case "/bookings/site/:siteId/createBooking":
      case "/bookings/site/:siteId/createInternalBooking":
        return `New Booking${suffix}`;
      case "/bookings/site/:siteId/booking/:journeyRef":
        return `Booking Information${suffix}`;
      default:
        return "";
    }
  }

  const { selectedBooking } = props;

  const expandedPaths = [
    "/bookings/site/:siteId/booking/:journeyRef",
    "/bookings/site/:siteId/createBooking",
    "/bookings/site/:siteId/createInternalBooking",
  ];

  const isExpanded =
    expandedPaths.includes(props.match.path) && !props.loadingBooking;
  const isInternal =
    props.match.path === "/bookings/site/:siteId/createInternalBooking" ||
    (selectedBooking && selectedBooking.direction === "internal");

  const title = getPanelTitle(props.match.path, isInternal);

  return (
    <RightPanel
      title={title}
      onClose={() => props.updateRoute(props.selectedSite)}
      isExpanded={isExpanded}
    >
      <SelectBookingForm
        onSelectBooking={props.onSelectBooking}
        bookings={props.selectedBookings}
        selectedBookingId={
          props.selectedBooking ? props.selectedBooking.id : null
        }
      />
      {isExpanded &&
        (isInternal ? (
          <InternalBookingForm
            key={props.journeyReference || ""}
            selectedBooking={selectedBooking}
            editing={!!props.journeyReference}
            selectedDate={props.selectedDate}
            selectedSite={props.match.params.siteId}
            onCreateBooking={props.onCreateBooking}
            updateBooking={props.updateBooking}
            updateRoute={props.updateRoute}
          />
        ) : (
          <BookingForm
            key={props.journeyReference || ""}
            selectedBooking={selectedBooking}
            editing={!!props.journeyReference}
            selectedDate={props.selectedDate}
            selectedSite={props.match.params.siteId}
            onCreateBooking={props.onCreateBooking}
            onUpdateBooking={props.onUpdateBooking}
            onDeleteBooking={props.onDeleteBooking}
            updateRoute={props.updateRoute}
          />
        ))}
    </RightPanel>
  );
}

export default BookingRightPanel;
