import React from "react";
import { Formik, Field, Form } from "formik";
import CheckBox from "form-controls/CheckBox";
import TextInput from "form-controls/TextInput";
import SelectBox from "form-controls/SelectBox";
import Button from "Button";
import Loader from "Loader";
import { formatRelativeDate } from "date-helper";
import {
  trailerTypesAndHauliers,
  checkIn,
  searchBookings
} from "./list-view-queries";
import { languageOptionValues } from "languages";
import { withErrorHandler } from "graphql-helper";
import { uniq, upperFirst, lowerCase } from "lodash";
import "./CheckInForm.css";
import config from "../../../../config";

const baseTrailerTypeNames = {
  TAUT: "Tautliner",
  CONT: "Container",
  STRD: "Standard"
};

class CheckInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseTrailerTypes: [],
      baseTrailerType: null,
      trailerTypes: [],
      loading: true,
      searchResults: [],
      secondaryBooking: null,
      hauliers: []
    };
  }

  componentDidMount() {
    trailerTypesAndHauliers().then(({ trailerTypes, hauliers }) => {
      const baseTrailerTypes = uniq(trailerTypes.map(x => x.baseType));
      this.setState({
        baseTrailerTypes,
        baseTrailerType: baseTrailerTypes[0],
        trailerTypes,
        hauliers,
        loading: false
      });
    });
  }

  trailerTypeFromMissionTrailerType(missionTrailerType) {
    const shortCode = this.trailerTypeShortCodeFromMissionTrailerType(
      missionTrailerType
    );
    return this.state.trailerTypes.find(tt => tt.type === shortCode);
  }

  trailerTypeShortCodeFromMissionTrailerType(missionTrailerType) {
    switch (missionTrailerType) {
      case 1:
        return "BOX";
      case 2:
        return "TAUT";
      case 3:
        return "CONT";
      case 4:
        return "VAN";
      case 5:
        return "";
      default:
        return "BOX";
    }
  }

  trailerTypesByBaseType(baseType) {
    if (baseType === "TANK") {
      return this.state.trailerTypes
        .filter(tt => tt.type === "TANK")
        .map(function(tt) {
          return tt;
        });
    }

    return this.state.trailerTypes
      .filter(tt => tt.baseType === baseType)
      .map(function(tt) {
        return tt;
      });
  }

  get trailerTypeOptions() {
    const permittedIds = this.props.booking.permittedTypes.map(({ id }) => id);
    if (this.state.baseTrailerType !== null) {
      const trailerTypesFilteredByBaseType = this.trailerTypesByBaseType(
        this.state.baseTrailerType
      );

      return trailerTypesFilteredByBaseType.map(tt => ({
        value: tt.id,
        label:
          tt.name + (permittedIds.includes(tt.id) ? "" : " (not requested)")
      }));
    }
    return this.state.trailerTypes.map(tt => ({
      value: tt.id,
      label: tt.name + (permittedIds.includes(tt.id) ? "" : " (not requested)")
    }));
  }

  get trailerBaseTypeOptions() {
    return [
      ...this.state.baseTrailerTypes
        .filter(baseType => baseType !== "OTHER")
        .map(baseType => ({
          value: baseType,
          label:
            baseTrailerTypeNames[baseType] || upperFirst(lowerCase(baseType))
        })),
      { value: "TANK", label: "Tanker" },
      { value: "OTHER", label: "Other" }
    ];
  }

  get haulierOptions() {
    return this.state.hauliers.map(haulier => ({
      value: haulier.id,
      label: haulier.name
    }));
  }

  get missionTrailer() {
    if (
      !this.props.booking.mission ||
      !this.props.booking.mission.lastMessageReceived
    ) {
      return {
        trailerNumber: "",
        trailerTypeId: "",
        trailerBaseType: ""
      };
    }

    const {
      trailer_number,
      trailer_type
    } = this.props.booking.mission.lastMessageReceived;

    const trailerType = this.trailerTypeFromMissionTrailerType(trailer_type);

    const trailerBaseType = trailerType
      ? trailerType.baseType
        ? this.state.trailerTypes.find(t => t.type === trailerType.baseType)
        : trailerType.id
      : "";

    return {
      trailerNumber: trailer_number,
      trailerTypeId: trailerType ? trailerType.id : "",
      trailerBaseType
    };
  }

  get initialValues() {
    return {
      driverName: this.props.booking.driverName || "",
      driverLanguage: this.props.booking.driverLanguage || "en",
      vehicleReg: this.props.booking.vehicleReg || "",
      arrivedWithTrailer: true,
      haulierId: this.props.booking.haulier.id,
      trailerOwnerId:
        this.props.booking.trailerOwner && this.props.booking.trailerOwner.id,
      ...this.missionTrailer
    };
  }

  isFormDisabled(values, errors) {
    if (Object.entries(errors).length > 0) {
      return true;
    }

    const requiredFields = [
      values.driverName,
      values.driverLanguage,
      values.vehicleReg,
      values.haulierId
    ];

    if (values.arrivedWithTrailer) {
      requiredFields.push(values.trailerTypeId);
    }

    if (
      values.arrivedWithTrailer &&
      !this.isRigidTrailerType(values.trailerTypeId)
    ) {
      requiredFields.push(values.trailerOwnerId);
      requiredFields.push(values.trailerNumber);
    }

    return !requiredFields.every(x => x !== "");
  }

  isTrailerTypeRequested(id) {
    if (!id) {
      return true;
    }

    return this.props.booking.permittedTypes.map(({ id }) => id).includes(id);
  }

  isRigidTrailerType(id) {
    const trailerType = this.state.trailerTypes.find(x => x.id === id);
    return trailerType && trailerType.isRigid;
  }

  handleSubmit(values, { setSubmitting }) {
    const { booking } = this.props;
    let bookingId = booking.id;
    let secondaryBookingId =
      this.state.secondaryBooking && this.state.secondaryBooking.id;

    // When we have two bookings the inbound booking should be checked in
    // with the trailer first.
    if (secondaryBookingId && booking.direction === "outbound") {
      [bookingId, secondaryBookingId] = [secondaryBookingId, bookingId];
    }

    let checkInArgs = {
      bookingId,
      secondaryBookingId,
      driverName: values.driverName,
      driverLanguage: values.driverLanguage,
      vehicleReg: values.vehicleReg,
      haulierId: values.haulierId,
      arrivedWithTrailer: values.arrivedWithTrailer
    };

    if (values.arrivedWithTrailer) {
      checkInArgs = {
        ...checkInArgs,
        trailerTypeId: values.trailerTypeId
      };
    }

    if (
      values.arrivedWithTrailer &&
      !this.isRigidTrailerType(values.trailerTypeId)
    ) {
      checkInArgs = {
        ...checkInArgs,
        trailerOwnerId: values.trailerOwnerId,
        trailerNo: values.trailerNumber
      };
    }

    this.props.handleErrors(
      checkIn(checkInArgs)
        .then(({ checkIn: { trailer } }) => {
          if (booking.direction === "inbound" || booking.status === "staged") {
            this.props.showDriverLocation(trailer);
          }
        })
        .then(() => {
          this.props.closePanel();
        }),
      "The driver has been checked in"
    );
    setSubmitting(false);
  }

  generateExpectedFieldString(appointmentField) {
    const { booking } = this.props;

    if (
      !booking ||
      !booking.appointment ||
      !booking.appointment.data ||
      !booking.appointment.data[appointmentField]
    )
      return "";

    return ` (expected: ${booking.appointment.data[appointmentField]})`;
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.baseTrailerTypes.length === 0) {
      return (
        <p>
          Please check the trailer type configuration. At least one trailer type
          must be allowed at check in.
        </p>
      );
    }

    return (
      <Formik
        enableReinitialize
        initialValues={this.initialValues}
        onSubmit={this.handleSubmit.bind(this)}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            {this.props.booking && (
              <>
                <p>Checking in for:</p>
                <p>
                  {this.props.booking.journeyReference} (
                  {this.props.booking.direction})
                </p>
                {this.state.secondaryBooking && (
                  <p>
                    {this.state.secondaryBooking.journeyReference} (
                    {this.state.secondaryBooking.direction})
                  </p>
                )}
                {!this.state.secondaryBooking && !config.FEATURE_LITE_VERSION && (
                  <>
                    <Field
                      name="bookingSearch"
                      label="Secondary booking"
                      component={TextInput}
                      onChange={({ target }) => {
                        const { value } = target;

                        const searchDirection =
                          this.props.booking.direction === "inbound"
                            ? "outbound"
                            : "inbound";

                        if (value === "") {
                          this.setState({ searchResults: [] });
                        } else {
                          searchBookings(
                            value,
                            searchDirection
                          ).then(({ bookings }) =>
                            this.setState({ searchResults: bookings })
                          );
                        }
                      }}
                      error={touched.driverName && errors.driverName}
                    />
                    {this.state.searchResults.length > 0 && (
                      <ul className="quick-search-list">
                        {this.state.searchResults.map(r => (
                          <li
                            className="quick-search-item"
                            onClick={() =>
                              this.setState({ secondaryBooking: r })
                            }
                          >
                            {r.journeyReference} - {r.direction} - {r.status} -{" "}
                            {formatRelativeDate(r.slotStart)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </>
            )}
            <p>Use the form below to manually check in a driver.</p>
            <Field
              name="driverName"
              label="Driver name"
              component={TextInput}
              error={touched.driverName && errors.driverName}
            />
            <Field
              name="driverLanguage"
              label="Language preference"
              options={languageOptionValues()}
              noDefault
              component={SelectBox}
              error={touched.driverLanguage && errors.driverLanguage}
            />
            <Field
              name="vehicleReg"
              label="Vehicle registration"
              component={TextInput}
              error={touched.vehicleReg && errors.vehicleReg}
            />
            <Field
              name="haulierId"
              label={`Haulier${this.generateExpectedFieldString("haulier")}`}
              options={this.haulierOptions}
              placeholder="Select the haulier"
              component={SelectBox}
              error={touched.haulierId && errors.haulierId}
            />
            <Field
              name="arrivedWithTrailer"
              label="Arrived with trailer"
              component={CheckBox}
              disabled={
                this.props.booking.direction !== "outbound" ||
                this.state.secondaryBooking
              }
            />
            {(this.props.booking.direction === "inbound" ||
              this.props.booking.status === "staged" ||
              values.arrivedWithTrailer) && (
              <>
                <Field
                  name="trailerBaseType"
                  label={`Trailer type${this.generateExpectedFieldString(
                    "trailer_type"
                  )}`}
                  options={this.trailerBaseTypeOptions}
                  placeholder="Please select..."
                  component={SelectBox}
                  error={touched.trailerTypeId && errors.trailerTypeId}
                  onChange={e => {
                    this.setState({ baseTrailerType: e.target.value });
                    const subTypes = this.trailerTypesByBaseType(
                      e.target.value
                    );
                    setFieldValue(
                      "trailerTypeId",
                      subTypes.length === 1 ? subTypes[0].id : ""
                    );
                  }}
                />

                <Field
                  name="trailerTypeId"
                  label="Trailer details"
                  options={this.trailerTypeOptions}
                  disabled={
                    !values.trailerBaseType ||
                    this.trailerTypeOptions.length === 1
                  }
                  placeholder="Please select..."
                  component={SelectBox}
                  error={touched.trailerTypeId && errors.trailerTypeId}
                  warning={
                    !this.isTrailerTypeRequested(values.trailerTypeId) &&
                    "This is not the type of trailer requested on the booking, are you sure you brought this kind of trailer onto site?"
                  }
                />

                <Field
                  name="trailerOwnerId"
                  label={`Trailer owner${this.generateExpectedFieldString(
                    "trailer_owner"
                  )}`}
                  options={this.haulierOptions}
                  placeholder="Select the trailer owner"
                  disabled={this.isRigidTrailerType(values.trailerTypeId)}
                  component={SelectBox}
                  error={touched.trailerOwnerId && errors.trailerOwnerId}
                />

                <Field
                  name="trailerNumber"
                  label={`Trailer number${this.generateExpectedFieldString(
                    "trailer_no"
                  )}`}
                  disabled={this.isRigidTrailerType(values.trailerTypeId)}
                  component={TextInput}
                  error={touched.trailerNumber && errors.trailerNumber}
                />
              </>
            )}
            <div className="button-col">
              <Button
                type="submit"
                variant="primary"
                size="small"
                title="Check in"
                disabled={this.isFormDisabled(values, errors)}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withErrorHandler(CheckInForm);
