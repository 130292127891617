import React from "react";
import { connect } from "react-redux";
import { addMessage } from "redux/app";
import { withRouter } from "react-router";
import { Formik, Form, Field } from "formik";
import { getSites } from "queries/sites";
import { getUserSites, updateUserSites } from "api/userSites";
import Button from "Button";
import Can from "Can";
import Loader from "Loader";
import Switch from "form-controls/Switch";
import "./UserDetailsForm.css";

class UserSitesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      loading: true,
      allowedSites: [],
      siteLoading: true
    };
  }

  componentDidMount() {
    getSites().then(({ sites }) => {
      this.setState({ sites, loading: false });
    });
    getUserSites(this.props.selectedUser.id).then(userSites => {
      this.setState({
        allowedSites: userSites.allowedSites,
        siteLoading: false
      });
    });
  }

  handleSubmit(values) {
    const allowedSites = Object.keys(values)
      .filter(key => values[key] === true)
      .map(x => parseInt(x, 10));

    if (allowedSites.length === 0) {
      this.props.dispatch(
        addMessage("error", "User must have at least one site.")
      );
    } else {
      updateUserSites(this.props.selectedUser.id, allowedSites).then(
        userSites => {
          this.setState({
            allowedSites: userSites.allowedSites
          });
          this.props.dispatch(addMessage("success", "User sites updated."));
        }
      );
    }
  }

  render() {
    if (this.state.user === "" || this.state.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }

    if (this.state.loading || this.state.siteLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const initialValues = this.state.allowedSites.reduce(
      (acc, site) => ({
        ...acc,
        [site]: true
      }),
      {}
    );

    const { permissions } = this.props;
    const canUpdate = permissions.includes("settings.users.update.siteAccess");

    return (
      <div className="user-details-form">
        <div className="user-details-title">
          Manage what sites this user can access.
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={this.handleSubmit.bind(this)}
        >
          <Form>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Site</th>
                    <th>Access</th>
                    <th>Parent Site</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sites.map((site, idx) => (
                    <tr key={idx}>
                      <td>{site.name}</td>
                      <td>
                        <Field
                          id={idx}
                          name={site.id}
                          component={Switch}
                          disabled={!canUpdate}
                        />
                      </td>
                      <td>{site.parentSite ? site.parentSite.name : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="button-col">
              <Can when="settings.users.update.siteAccess">
                <Button
                  type="submit"
                  variant="primary"
                  size="small"
                  title="Save changes"
                />
              </Can>
              <Button
                variant="secondary"
                size="small"
                title="Back"
                onClick={() => this.props.cancel()}
              />
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(UserSitesForm));

function mapStateToProps({ auth: { permissions } }) {
  return { permissions };
}
