import React from "react";
import { Formik, Field, Form } from "formik";
import { withRouter } from "react-router";
import * as Yup from "yup";
import { resetPassword } from "api/accounts";
import TextInput from "form-controls/TextInput";
import Button from "Button";
import Loader from "Loader";
import "./RecoverAccountForm.css";

const recoverSchema = Yup.object().shape({
  password: Yup.string()
    .min(16, "Your password must be at least 16 characters")
    .matches(
      /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{16,})/,
      "Your password must contain: at least one upper case character, at least on lower case character and at least one number."
    )
    .required("Please enter a password")
});

class RecoverAccountForm extends React.Component {
  render() {
    const { token, history } = this.props;

    return (
      <Formik
        validationSchema={recoverSchema}
        onSubmit={(values, { setSubmitting }) => {
          resetPassword(token, values.password)
            .then(() => history.push("/auth/login"))
            .catch(() => {
              history.push("/auth/login/invalid");
            });

          setSubmitting(false);
        }}
        initialValues={{ password: "" }}
      >
        {({ errors, isSubmitting }) => (
          <div className="recover-account-form">
            {isSubmitting && <Loader />}
            <Form>
              <Field
                name="password"
                label="Password"
                component={TextInput}
                inputType="password"
                error={errors.password}
              />
              <div className="recover-account-form__buttons center">
                <Button
                  type="submit"
                  variant="primary"
                  title="Send"
                  disabled={Object.keys(errors).length}
                />
                <Button
                  type="submit"
                  variant="white"
                  size="small"
                  title="Back to login"
                  onClick={() => this.props.history.push("/auth/login")}
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    );
  }
}

export default withRouter(RecoverAccountForm);
