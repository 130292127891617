import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addMessage } from "redux/app";
import { deleteZone } from "api/zones";
import { zones, zone } from "./zones-page/zones-queries";
import Loader from "Loader";
import AdminPanel from "AdminPanel";
import LandingPanel from "LandingPanel";
import TabControl from "form-controls/TabControl";
import TabContentItem from "form-controls/TabContentItem";
import ZoneForm from "./zones-page/ZoneForm";
import DockDevices from "./zones-page/DockDevices";
import ZoneOverview from "./zones-page/ZoneOverview";
import "./zones-page/ZoneForm.css";
import "Sidebar.css"; // TODO (shouldn't be here?)

class ZonesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: [],
      zoneLoading: true,
      selectedZoneId: null,
      newZone: false
    };
  }

  componentDidMount() {
    if (this.state.zoneLoading) {
      zones().then(data => {
        this.setState({ zones: data.zones, zoneLoading: false });
      });
    }
  }

  get selectedZone() {
    return this.state.zones.find(zone => zone.id === this.state.selectedZoneId);
  }

  showZone(id) {
    this.setState({
      selectedZoneId: id,
      newZone: false
    });
  }

  addNewZone() {
    this.setState({ newZone: true, selectedZoneId: null });
  }

  updateZone(updatedZone) {
    const zones = this.state.zones.map(zone =>
      zone.id === updatedZone.id ? updatedZone : zone
    );
    this.setState({ zones });
  }

  createZone(newZone) {
    let zones = this.state.zones;
    zones.push(newZone);
    zones = zones.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
    this.setState({ zones });
  }

  refreshSelectedZone() {
    if (this.selectedZone) {
      zone(this.selectedZone.id).then(data => {
        this.updateZone(data.zone);
      });
    }
  }

  deleteZone() {
    const selectedZone = this.selectedZone;
    deleteZone(selectedZone.id).then(
      () => {
        this.returnHome();
        const zones = this.state.zones.filter(
          zone => selectedZone.id !== zone.id
        );
        this.setState({ zones });
        this.props.dispatch(
          addMessage("success", `'${selectedZone.name}' deleted`)
        );
      },
      () => {
        this.props.dispatch(addMessage("error", "Zone could not be deleted"));
      }
    );
  }

  returnHome() {
    this.setState({ newZone: false, selectedZoneId: null });
  }

  get adminPanelItems() {
    return this.state.zones.map(z => ({
      id: z.id,
      name: z.name,
      text: `Capacity: ${z.capacity}`
    }));
  }

  render() {
    if (this.state.zoneLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    return (
      <div className="site-config">
        <AdminPanel
          title="ZONES"
          items={this.adminPanelItems}
          showItem={this.showZone.bind(this)}
          addNew={this.addNewZone.bind(this)}
          addPermission="settings.zones.create"
          selectedId={this.state.selectedZoneId}
        >
          {this.state.selectedZoneId === null && !this.state.newZone && (
            <LandingPanel text="Select a Zone" />
          )}

          {(this.state.selectedZoneId || this.state.newZone) && (
            <div className="admin-details">
              {this.state.newZone && (
                <>
                  <div className="admin-details-title">New Zone</div>
                  <div className="admin-details-main">
                    <ZoneForm
                      handleResponse={this.createZone.bind(this)}
                      returnHome={this.returnHome.bind(this)}
                    />
                  </div>
                </>
              )}

              {this.state.selectedZoneId !== null && (
                <TabControl title={this.selectedZone.name}>
                  <TabContentItem title="Overview">
                    <ZoneOverview zone={this.selectedZone} />
                  </TabContentItem>
                  <TabContentItem title="Details">
                    <ZoneForm
                      isEditMode
                      selectedZone={this.selectedZone}
                      handleResponse={this.updateZone.bind(this)}
                      deleteZone={this.deleteZone.bind(this)}
                      returnHome={this.returnHome.bind(this)}
                    />
                  </TabContentItem>
                  <TabContentItem title="Dock Safety Devices">
                    {this.selectedZone.docks.length > 0 ? (
                      <DockDevices
                        isEditMode
                        selectedZone={this.selectedZone}
                        refreshZone={this.refreshSelectedZone.bind(this)}
                      />
                    ) : (
                      <div style={{ padding: "24px" }}>
                        No docks to display.
                      </div>
                    )}
                  </TabContentItem>
                </TabControl>
              )}
            </div>
          )}
        </AdminPanel>
      </div>
    );
  }
}

export default connect()(withRouter(ZonesPage));
