import { fetch } from "graphql-helper";

export function movementsSubscription(status) {
  return `
    subscription {
      movements (status: [${status.map(s => `"${s}"`).join(", ")}]) {
        id
        trailer {
          id
          haulier {
            shortCode
            name
          }
          trailerNo
          trailerType {
            type
            baseType
          }
          zone {
            name
          }
          dock {
            number
          }
        }
        location {
          zone {
            name
          }
          dock {
            number
          }
        }
        status
        insertedAt
        device {
          id
          deviceId
        }
      }
    }`;
}

export function getMovements() {
  return fetch(`
      query {
        assignedMovements: movements (status: ["assigned", "picked_up"]) {
          ...movementFields
          device {
            id
            deviceId
          }
        }
        pendingMovements: movements (status: "pending") {
          ...movementFields
        }
      }
      fragment movementFields on Movement {
        id
        trailer {
          id
          haulier {
            shortCode
            name
          }
          trailerNo
          trailerType {
            type
            baseType
          }
          zone {
            name
          }
          dock {
            number
          }
        }
        location {
          zone {
            name
          }
          dock {
            number
          }
        }
        status
        insertedAt
      }`);
}

export function prioritiseMovement(id, priority) {
  return fetch(`
    mutation {
      prioritiseMovement(id: ${id}, priority: ${priority + 1}) {
        id
      }
    }
  `);
}
