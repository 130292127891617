import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "PrivateRoute";
import FlightboardLandingPage from "./pages/FlightboardLandingPage";
import NotFoundPage from "NotFoundPage";

export default function FlightboardRoot() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/flightboard"
        component={FlightboardLandingPage}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
