import React from "react";
import { withRouter } from "react-router";
import Button from "Button";
import SplashPage from "./SplashPage";
import "./RegisterPage.css";

function RegisterConfirmationPage({ history }) {
  return (
    <SplashPage>
      <p className="splash-page__heading">Success</p>
      <p className="thin">Your account has been created</p>
      <p className="small-type center">
        You can now log in with your email address and password.
      </p>
      <div className="center" style={{ marginTop: 32 }}>
        <Button
          variant="primary"
          title="Go to login"
          onClick={() => history.push("/auth/login")}
        />
      </div>
    </SplashPage>
  );
}

export default withRouter(RegisterConfirmationPage);
