import React from "react";
import { formatTrailer } from "booking-helper";
import { formatWithDefaultTz } from "date-helper";

export default function DashboardBookingTable({ bookings }) {
  function renderSite(booking) {
    const site = booking.sendingSite || booking.receivingSite;

    if (!site) {
      return "";
    }

    return site.parentSite
      ? `${site.parentSite.name} - ${site.name}`
      : site.name;
  }

  function getLocation(item) {
    const zone =
      item.trailer && item.trailer.zone ? item.trailer.zone.name : "";
    const dock =
      item.trailer && item.trailer.dock ? ` - ${item.trailer.dock.number}` : "";

    return zone + dock;
  }

  function getSealNumber(booking) {
    const placeholder = "-";

    if (!booking.appointment) {
      return placeholder;
    }

    return booking.appointment["data"]["seal_no"] || placeholder;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Start</th>
          <th>Trailer</th>
          <th>Load Type</th>
          <th>Pallets</th>
          <th>Seal No</th>
          <th>Journey</th>
          <th>Site</th>
          <th>Reg</th>
          <th>Status</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {bookings.map(b => {
          return (
            <tr key={b.id}>
              <td>{formatWithDefaultTz(b.slotStart, "HH:mm")}</td>
              <td>{formatTrailer(b)}</td>
              <td>{b.loadType}</td>
              <td>{b.numberOfPallets}</td>
              <td>{getSealNumber(b)}</td>
              <td>{b.journeyReference}</td>
              <td>{renderSite(b)}</td>
              <td>{b.vehicleReg}</td>
              <td style={{ textTransform: "capitalize" }}>
                {b.haulier === null ? "PROPOSED" : b.status.replace(/_/g, " ")}
              </td>
              <td>{getLocation(b)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
