import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "sanitize.css";
import configureStore from "./configureStore";
import "./index.css";
import Root from "./components/Root";

let appData = {};

try {
  appData = JSON.parse(window.localStorage.getItem("appData")) || appData;
} catch (e) {
  // Invalid JSON in app data
}

const defaults = {
  token: "",
  isAuthenticated: false,
  permissions: [],
  allowedSites: [],
  pushTo: ""
};

const store = configureStore({ auth: { defaults, ...appData } });

function storeAppData(data) {
  const cleanData = Object.fromEntries(
    Object.entries(data).filter(([k]) => k !== "pushTo")
  );
  window.localStorage.setItem("appData", JSON.stringify(cleanData));
}

ReactDOM.render(
  <Root store={store} storeAppData={storeAppData} />,
  document.getElementById("root")
);
