import React from "react";
import TrafficPageTemplate from "./TrafficPageTemplate";
import Content from "Content";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HorizontalBar from "./traffic-dashboard-page/HorizontalBar";
import VerticalBar from "./traffic-dashboard-page/VerticalBar";
import AssignedMovementsTable from "./traffic-dashboard-page/AssignedMovementsTable";
import VehiclesOffRoadTable from "./traffic-dashboard-page/VehiclesOffRoadTable";
import PieChart from "./traffic-dashboard-page/PieChart";
import Button from "Button";
import BookingPreviewModal from "BookingPreviewModal";
import { selectSite } from "redux/app";
import "./Dashboard.css";
import { siteData } from "./traffic-dashboard-page/traffic-dashboard-queries";
import SelectSitePanel from "SelectSitePanel";
// import { isWithinRange, endOfDay } from "date-fns";

import { useState } from "react";

function EmptyTrailerDash({ emptyBookings }) {
  const [selectedDirection, setSelectedDirection] = useState(null);

  const bookingsWithoutNgd = emptyBookings
    .filter(b => b.haulier.shortCode !== "NGD")
    .map(b => ({
      haulier: b.haulier,
      count: b[`${selectedDirection}Count`]
    }))
    .sort((a, b) => {
      if (a.count === b.count) {
        return 0;
      }

      return a.count > b.count ? -1 : 1;
    })
    .filter(b => b.count > 0);

  const handleSelectDirection = direction => {
    setSelectedDirection(direction);
  };

  const handleCloseTrailerBreakdown = () => {
    setSelectedDirection(null);
  };

  return (
    <div className="content-block" style={{ flex: 1, padding: "1rem" }}>
      {selectedDirection && (
        <EmptyTrailerBreakdown
          direction={selectedDirection}
          title={`Haulier ${selectedDirection}`}
          emptyBookings={bookingsWithoutNgd}
          onClose={handleCloseTrailerBreakdown}
        />
      )}
      {!selectedDirection && (
        <EmptyTrailerCounts
          emptyBookings={emptyBookings}
          onSelectDirection={handleSelectDirection}
        />
      )}
    </div>
  );
}

function EmptyTrailerCounts({ emptyBookings, onSelectDirection }) {
  const ngdEmptyTrailers = emptyBookings.find(
    b => b.haulier.shortCode === "NGD"
  ) || { inboundCount: 0, outboundCount: 0 };

  const overallCounts = emptyBookings.reduce(
    (acc, val) => ({
      inboundCount: acc.inboundCount + val.inboundCount,
      outboundCount: acc.outboundCount + val.outboundCount
    }),
    { inboundCount: 0, outboundCount: 0 }
  );

  return (
    <>
      <div className="horizontal-tile">
        <div className="horizontal-tile__desc">
          <span>NGD Inbound</span>
          <span>Empty Trailers</span>
        </div>
        <div className="horizontal-tile__count">
          {ngdEmptyTrailers.inboundCount}
        </div>
      </div>
      <div className="horizontal-tile">
        <div className="horizontal-tile__desc">
          <span>NGD Outbound</span>
          <span>Empty Trailers</span>
        </div>
        <div className="horizontal-tile__count">
          {ngdEmptyTrailers.outboundCount}
        </div>
      </div>
      <div
        className="horizontal-tile"
        onClick={() => onSelectDirection("inbound")}
      >
        <div className="horizontal-tile__desc">
          <span>Haulier Inbound</span>
          <span>Empty Trailers</span>
        </div>
        <div className="horizontal-tile__count horizontal-tile__count--small-margin">
          {overallCounts.inboundCount - ngdEmptyTrailers.inboundCount}
        </div>
        <div className="horizontal-tile__expand">
          <i className="material-icons">play_arrow</i>
        </div>
      </div>
      <div
        className="horizontal-tile"
        onClick={() => onSelectDirection("outbound")}
      >
        <div className="horizontal-tile__desc">
          <span>Haulier Outbound</span>
          <span>Empty Trailers</span>
        </div>
        <div className="horizontal-tile__count horizontal-tile__count--small-margin">
          {overallCounts.outboundCount - ngdEmptyTrailers.outboundCount}
        </div>
        <div className="horizontal-tile__expand">
          <i className="material-icons">play_arrow</i>
        </div>
      </div>
    </>
  );
}

function EmptyTrailerBreakdown({ title, emptyBookings, onClose }) {
  const count = emptyBookings.reduce((acc, b) => acc + b.count, 0);

  return (
    <>
      <div className="horizontal-tile">
        <div className="horizontal-tile__desc">
          <span>{title}</span>
          <span>Empty Trailers</span>
        </div>
        <div className="horizontal-tile__count horizontal-tile__count--small-margin">
          {count}
        </div>
        <div className="horizontal-tile__expand" onClick={onClose}>
          <i className="material-icons">close</i>
        </div>
      </div>
      <div>
        <table className="trailer-breakdown">
          <tbody>
            {emptyBookings.map(b => (
              <tr key={b.haulier.name}>
                <td>{b.haulier.name}</td>
                <td>{b.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

class TrafficDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      standLevels: [],
      sites: [],
      assignedMovements: [],
      trailers: [],
      selectedHaulierId: null,
      selectedBookingId: null,
      showingBookingPreviewModal: false,
      emptyBookings: [],
      trafficAgg: null
    };
  }
  componentDidMount() {
    if (parseInt(this.props.match.params.siteId) !== this.props.selectedSite) {
      if (this.props.selectedSite === null) {
        this.props.dispatch(selectSite(this.props.match.params.siteId));
      } else {
        this.updateRoute(this.props.selectedSite);
        this.getTrailerData(this.props.selectedSite);
      }
    } else {
      this.getTrailerData(this.props.selectedSite);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedSite !== this.props.selectedSite &&
      this.props.selectedSite !== null
    ) {
      this.getTrailerData(this.props.selectedSite);
      this.updateRoute(this.props.selectedSite);
    }
  }

  getTrailerData(siteId) {
    siteData(siteId, this.props.selectedDate).then(data => {
      data.standLevel.sort((a, b) => {
        if (a.haulier.name < b.haulier.name) {
          return -1;
        }

        return 1;
      });

      this.setState({
        sites: data.site.children
          ? [data.site].concat(data.site.children)
          : [data.site],
        standLevels: data.standLevel.filter(s => s.standLevel > 0),
        assignedMovements: data.assignedMovements,
        trailers: data.trailers,
        emptyBookings: data.trafficAgg.emptyBookings,
        trafficAgg: data.trafficAgg
      });
    });
  }

  getCurrentTrailerLevel(zoneType) {
    return this.state.sites.reduce(
      (acc, site) =>
        acc +
        site.zones.reduce(
          (acc, zone) =>
            acc +
            (zone.type === zoneType || zoneType === "all"
              ? zone.trailers.length
              : 0),
          0
        ),
      0
    );
  }

  getTrailerBreakdown(haulierId) {
    const allTrailers = this.state.trailers;

    const breakdown = allTrailers
      .filter(t => t.haulier.id == haulierId)
      .reduce(
        (acc, t) => {
          if (t.isUnavailable) {
            return { ...acc, vor: acc.vor + 1 };
          } else if (t.booking === null) {
            return { ...acc, empty: acc.empty + 1 };
          } else if (["inbound", "internal"].includes(t.booking.direction)) {
            return { ...acc, inbound: acc.inbound + 1 };
          } else if (t.booking.direction === "outbound") {
            return { ...acc, outbound: acc.outbound + 1 };
          }

          return acc;
        },
        { inbound: 0, outbound: 0, empty: 0, vor: 0 }
      );

    const orange = "rgba(254, 153, 32, 1)";
    const red = "rgba(209, 73, 91, 1)";
    const green = "rgba(143, 201, 58, 1)";
    const blue = "rgba(0, 145, 147, 1)";

    return [
      { label: "Empty", value: breakdown.empty, fillStyle: green },
      { label: "Loaded Inbound", value: breakdown.inbound, fillStyle: blue },
      {
        label: "Loaded Outbound",
        value: breakdown.outbound,
        fillStyle: orange
      },
      { label: "VOR Trailers", value: breakdown.vor, fillStyle: red }
    ];
  }

  getCapacity(zoneType) {
    return this.state.sites.reduce(
      (acc, site) =>
        acc +
        site.zones.reduce(
          (acc, zone) =>
            acc +
            (zone.type === zoneType || zoneType === "all" ? zone.capacity : 0),
          0
        ),
      0
    );
  }

  getHaulierName(haulierId) {
    const standLevel = this.state.standLevels.find(
      sl => sl.haulier.id == haulierId
    );

    if (standLevel === null) {
      return "";
    }

    return standLevel.haulier.name;
  }

  getHaulierTrailerCount(haulierId) {
    return this.state.trailers.filter(t => t.haulier.id === haulierId).length;
  }

  get getStandLevels() {
    const colors = [
      "#006493",
      "#C3B61E",
      "#FE9920",
      "#009193",
      "#AC80A0",
      "#BFDBF7",
      "#8FC93A",
      "#F4E04D",
      "#BB7E5D",
      "#D1495B"
    ];

    return [
      this.state.standLevels.map((sl, index) => {
        return {
          label: sl.haulier.name,
          value: sl.standLevel,
          color: colors[index]
        };
      }),
      this.state.standLevels.map((sl, index) => {
        return {
          label: sl.haulier.name,
          value: this.getHaulierTrailerCount(sl.haulier.id),
          color: colors[index]
        };
      })
    ];
  }

  get bookingCounts() {
    const { trafficAgg } = this.state;
    if (trafficAgg) {
      return {
        internal: trafficAgg.internalBookingCount,
        inbound: trafficAgg.inboundBookingCount,
        outbound: trafficAgg.outboundBookingCount
      };
    }
    return { internal: 0, inbound: 0, outbound: 0 };
  }

  get getZones() {
    const colors = [
      "#006493",
      "#C3B61E",
      "#FE9920",
      "#009193",
      "#AC80A0",
      "#BFDBF7",
      "#8FC93A",
      "#F4E04D",
      "#BB7E5D",
      "#D1495B"
    ];

    const max = [];
    const actual = [];

    this.state.sites.map(s => {
      s.zones.map((z, index) => {
        max.push({
          label: z.name,
          value: z.capacity,
          color: colors[index]
        });
        actual.push({
          label: z.name,
          value: z.trailers.length,
          color: colors[index]
        });
      });
    });

    return [max, actual];
  }

  updateRoute(id) {
    if (id === null) {
      this.props.history.push({ pathname: "/traffic" });
    } else {
      this.props.history.push({
        pathname: `/traffic/site/${id}`
      });
    }
  }

  getTileColor(level, capacity) {
    let color = "rgba(143, 201, 58, 1)";
    if (level > 0.89 * capacity) {
      color = "rgba(209, 73, 91, 1)";
    } else if (level > 0.6 * capacity && level < 0.75 * capacity) {
      color = "rgba(254, 153, 32, 1)";
    } else if (level > 0.74 * capacity && level < 0.9 * capacity) {
      color = "rgba(254, 153, 32, 1)";
    } else if (capacity === 0) {
      color = "rgba(255,255,255,1)";
    }
    return color;
  }

  showBookingPreviewModal(bookingId) {
    this.setState({
      selectedBookingId: bookingId,
      showingBookingPreviewModal: true
    });
  }

  closeBookingPreviewModal() {
    this.setState({
      selectedBookingId: null,
      showingBookingPreviewModal: false
    });
  }

  render() {
    const bookingCounts = this.bookingCounts;
    const parkingLevel = this.getCurrentTrailerLevel("parking");
    const parkingCapacity = this.getCapacity("parking");
    const loadingLevel = this.getCurrentTrailerLevel("loading");
    const loadingCapacity = this.getCapacity("loading");
    const standLevels = this.getStandLevels;
    const standTrailers =
      standLevels[1].length === 0
        ? "---"
        : standLevels[1].reduce((acc, sl) => acc + sl.value, 0);
    const parkingColor = this.getTileColor(parkingLevel, parkingCapacity);
    const loadingColor = this.getTileColor(loadingLevel, loadingCapacity);

    return (
      <TrafficPageTemplate>
        {!this.props.selectedSite && <SelectSitePanel />}

        {this.state.showingBookingPreviewModal && (
          <BookingPreviewModal
            bookingId={this.state.selectedBookingId}
            onClose={this.closeBookingPreviewModal.bind(this)}
          />
        )}

        {this.state.sites.length > 0 && (
          <Content>
            <div className="dash-row">
              <div style={{ flex: 1.04 }}>
                <div className="dash-row traffic-dash-row">
                  <div className="content-block" style={{ flex: "0.25 1 5%" }}>
                    <h1 className="notifiy-value">
                      {parkingCapacity > 0 ? parkingLevel : "---"}
                    </h1>
                    <span className="notify-text">In Parking</span>
                  </div>
                  <div
                    className="content-block"
                    style={{ flex: "0.25 1 5%", backgroundColor: parkingColor }}
                  >
                    <h1 className="notifiy-value">
                      {parkingCapacity > 0
                        ? parkingCapacity - parkingLevel
                        : "---"}
                    </h1>
                    <span className="notify-text">Empty Parking</span>
                  </div>
                  <div className="content-block" style={{ flex: "0.25 1 5%" }}>
                    <h1 className="notifiy-value">
                      {loadingCapacity > 0 ? loadingLevel : "---"}
                    </h1>
                    <span className="notify-text">On Dock</span>
                  </div>
                  <div
                    className="content-block"
                    style={{ flex: "0.25 1 5%", backgroundColor: loadingColor }}
                  >
                    <h1 className="notifiy-value">
                      {loadingCapacity > 0
                        ? loadingCapacity - loadingLevel
                        : "---"}
                    </h1>
                    <span className="notify-text">Empty Docks</span>
                  </div>
                </div>
                <div
                  className="dash-row traffic-dash-row"
                  style={{ marginBottom: "0px" }}
                >
                  <div className="content-block" style={{ flex: "0.25 1 5%" }}>
                    <h1 className="notifiy-value">{standTrailers}</h1>
                    <span className="notify-text">
                      Stand Trailer
                      {standTrailers !== 1 ? "s" : ""}
                    </span>
                  </div>
                  <div className="content-block" style={{ flex: "0.25 1 5%" }}>
                    <h1 className="notifiy-value">{bookingCounts.inbound}</h1>
                    <span className="notify-text">
                      Inbound Booking{bookingCounts.inbound !== 1 ? "s" : ""}
                    </span>
                  </div>
                  <div className="content-block" style={{ flex: "0.25 1 5%" }}>
                    <h1 className="notifiy-value">{bookingCounts.outbound}</h1>
                    <span className="notify-text">
                      Outbound Booking{bookingCounts.outbound !== 1 ? "s" : ""}
                    </span>
                  </div>
                  <div className="content-block" style={{ flex: "0.25 1 5%" }}>
                    <h1 className="notifiy-value">{bookingCounts.internal}</h1>
                    <span className="notify-text">
                      Internal Booking{bookingCounts.internal !== 1 ? "s" : ""}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="content-block"
                style={{ flex: 1, marginBottom: "8px" }}
              >
                <h3>Assigned movements</h3>

                <AssignedMovementsTable
                  movements={this.state.assignedMovements}
                />
              </div>
            </div>
            <div className="dash-row">
              <EmptyTrailerDash emptyBookings={this.state.emptyBookings} />
              <div className="content-block" style={{ flex: 1 }}>
                <h3>VOR'd Vehicles</h3>
                <VehiclesOffRoadTable
                  selectedSiteId={this.props.selectedSite}
                  onSelectBooking={bookingId =>
                    this.showBookingPreviewModal(bookingId)
                  }
                />
              </div>
            </div>
            <div className="dash-row">
              <div className="content-block" style={{ flex: 1 }}>
                <h3>Number of stand trailers</h3>
                {this.state.selectedHaulierId === null && (
                  <>
                    <p>
                      Click the bar to see the trailer breakdown for the haulier
                    </p>
                    <HorizontalBar
                      data={standLevels}
                      invertColours={true}
                      onClickBar={barIndex => {
                        const standLevel = this.state.standLevels[barIndex];
                        this.setState({
                          selectedHaulierId: standLevel.haulier.id
                        });
                      }}
                    />
                  </>
                )}
                {this.state.selectedHaulierId !== null && (
                  <div style={{ position: "relative" }}>
                    <Button
                      title="Back"
                      size="tiny"
                      variant="primary"
                      onClick={() => this.setState({ selectedHaulierId: null })}
                      style={{
                        position: "absolute",
                        top: 20,
                        right: 20,
                        width: "60px"
                      }}
                    />
                    <PieChart
                      title={`${this.getHaulierName(
                        this.state.selectedHaulierId
                      )} Trailer Breakdown`}
                      data={this.getTrailerBreakdown(
                        this.state.selectedHaulierId
                      )}
                    />
                  </div>
                )}
                <Link to="/traffic/trailers">
                  Go to trailer list
                  <i
                    className="material-icons"
                    style={{
                      fontSize: "21px",
                      verticalAlign: "middle"
                    }}
                  >
                    chevron_right
                  </i>
                </Link>
              </div>
              <div className="content-block" style={{ flex: 1 }}>
                <h3>Trailers per zone</h3>
                {this.getZones[0].length > 10 ? (
                  <HorizontalBar data={this.getZones} />
                ) : (
                  <VerticalBar data={this.getZones} />
                )}
              </div>
            </div>
          </Content>
        )}
      </TrafficPageTemplate>
    );
  }
}
function mapStateToProps({ app: { selectedSite, selectedDate } }) {
  return { selectedSite, selectedDate };
}

export default connect(mapStateToProps)(TrafficDashboardPage);
