import React, { useState, useEffect } from "react";
import Modal from "Modal";
import Button from "Button";
import Loader from "Loader";
import { Formik, Form, Field } from "formik";
import TextInput from "form-controls/TextInput";
import LinkTrailerForm from "./LinkTrailerForm";
import { fetch, toArgList, withErrorHandler } from "graphql-helper";

function AddTrailerBookingModal(props) {
  const handleCreateBooking = ({ journeyReference }) => {
    const args = {
      inboundJourneyReference: journeyReference.toUpperCase(),
      trailerId: props.selectedTrailer
    };

    props.handleErrors(
      fetch(`
          mutation {
            booking: createEmptyOutboundBooking(${toArgList(args)}) {
              journeyReference
            }
          }
        `).then(({ booking }) => {
        props.onCreateOutbound(booking);
      }),
      "Outbound booking successfully created"
    );
  };

  const [selectedTrailer, setSelectedTrailer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`
        query {
          trailer(id: ${props.selectedTrailer}) {
            id
            trailerNo
            isInternal
            haulier {
              shortCode
            }
          }
        }
    `).then(({ trailer }) => {
      setSelectedTrailer(trailer);
      setIsLoading(false);
    });
  }, [props.selectedTrailer]);

  return (
    <Modal>
      <div className="modal">
        <div className="modal__content">
          <div className="modal__header">
            <span className="modal__title">Add booking to trailer</span>
            <i className="material-icons modal__close" onClick={props.onCancel}>
              close
            </i>
          </div>
          {isLoading && <Loader />}

          {!isLoading && (
            <div className="modal__body">
              <p>
                {selectedTrailer.haulier && selectedTrailer.haulier.shortCode} -{" "}
                {selectedTrailer.trailerNo}
              </p>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    flex: "1",
                    marginRight: "1rem",
                    paddingRight: "1rem",
                    borderRight: "1px solid #ccc"
                  }}
                >
                  <h4>Choose an existing booking</h4>

                  <p>Select an existing booking from the list below:</p>

                  <LinkTrailerForm
                    isInternal={selectedTrailer.isInternal}
                    trailerId={selectedTrailer.id}
                    afterUpdate={props.onCancel}
                  />
                </div>

                {!selectedTrailer.isInternal && (
                  <div style={{ flex: "1" }}>
                    <h4>Create an outbound booking for a driver onsite</h4>

                    <p>
                      Enter the inbound journey reference below to create an
                      outbound booking for the empty trailer.
                    </p>

                    <Formik
                      initialValues={{ journeyReference: "" }}
                      onSubmit={handleCreateBooking}
                    >
                      {({ values }) => (
                        <Form>
                          <Field
                            name="journeyReference"
                            component={TextInput}
                          />
                          <div className="modal__buttons">
                            <Button
                              type="submit"
                              variant="primary"
                              size="small"
                              title="Create booking"
                              disabled={values.journeyReference === ""}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default withErrorHandler(AddTrailerBookingModal);
