import React from "react";
import { Switch, Route } from "react-router-dom";
import BookingLandingPage from "./pages/BookingLandingPage";
import CalendarViewPage from "./pages/CalendarViewPage";
import ListViewPage from "./pages/ListViewPage";
import PrivateRoute from "PrivateRoute";
import NotFoundPage from "NotFoundPage";

export default function BookingsRoot() {
  return (
    <Switch>
      <PrivateRoute exact path="/bookings" component={BookingLandingPage} />
      <PrivateRoute path="/bookings/daily-bookings" component={ListViewPage} />
      <PrivateRoute
        path="/bookings/site/:siteId/daily-bookings"
        component={ListViewPage}
      />
      <PrivateRoute
        path="/bookings/site/:siteId/bookings"
        component={CalendarViewPage}
      />
      <PrivateRoute
        path="/bookings/site/:siteId/booking/:journeyRef"
        component={CalendarViewPage}
      />
      <PrivateRoute
        path="/bookings/site/:siteId/createBooking"
        component={CalendarViewPage}
      />
      <PrivateRoute
        path="/bookings/site/:siteId/createInternalBooking"
        component={CalendarViewPage}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
