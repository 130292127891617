import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetch, toArgList } from "graphql-helper";
import Button from "Button";
import Content from "Content";
import RightPanel from "RightPanel";
import Table from "Table";
import TrafficPageTemplate from "./TrafficPageTemplate";
import ViewCommentModal from "./traffic-auditing-page/ViewCommentModal";

const auditReportsQuery = (siteId, page = 1, pageSize = 10) => `
  query {
    auditReports(${toArgList({ siteId, pageSize, page })}) {
      pageNumber
      pageSize
      totalPages
      totalEntries
      entries {
        id
        data
        zone {
          name
        }
        reviewedById
        insertedAt
        trailers {
          detailsAreCorrect
          comment
          trailer {
            id
            trailerNo
            haulier {
              shortCode
            }
            zone {
              id
              name
            }
            dock {
              id
              number
            }
          }
        }
      }
    }
  }
`;

const completeReviewMutation = reportId => `
  mutation {
    reviewYardAuditReport(${toArgList({ reportId })}) {
      id
    }
  }
`;

function TrailersTable({ trailers }) {
  const [selection, setSelection] = useState();

  const headers = ["Haulier", "Trailer No", "Location", "Found", "Comments"];

  const rows = trailers.map(({ detailsAreCorrect, trailer, comment }) => [
    trailer.haulier.shortCode,
    trailer.trailerNo,
    trailer.zone ? trailer.zone.name : "",
    detailsAreCorrect ? "Yes" : "No",
    comment ? (
      <Button
        variant="primary"
        size="tiny"
        title="View"
        onClick={() => {
          setSelection({ trailer, comment });
        }}
      />
    ) : (
      "-"
    )
  ]);

  return (
    <div>
      {selection && (
        <ViewCommentModal
          trailer={selection.trailer}
          comment={selection.comment}
          onClose={() => setSelection(undefined)}
        />
      )}
      <Table headers={headers} rows={rows}></Table>;
    </div>
  );
}

function UnexpectedTrailersTable({ trailers }) {
  const headers = ["Haulier", "Trailer No"];
  const rows = trailers.map(({ haulierName, trailerNo }) => [
    haulierName,
    trailerNo
  ]);

  return <Table headers={headers} rows={rows}></Table>;
}

function NotesTable({ notes }) {
  if (notes.length === 0) {
    return null;
  }

  const headers = ["Notes"];

  return <Table headers={headers} rows={notes.map(n => [n])} />;
}

function ReviewForm(props) {
  return (
    <>
      <h3>Trailers in Zone</h3>
      <TrailersTable trailers={props.selectedReport.trailers} />
      <h3>Unexpected Trailers</h3>
      <UnexpectedTrailersTable
        trailers={props.selectedReport.data.unexpectedTrailers}
      />
      {props.selectedReport.data.notes.length > 0 && <h3>Notes</h3>}
      <NotesTable notes={props.selectedReport.data.notes} />
      <Button
        variant="primary"
        title="Complete review"
        onClick={props.onCompleteReview}
      />
    </>
  );
}

function PageBody(props) {
  const [showingReviewPanel, setShowingReviewPanel] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const summarisedReports = props.reports.entries.map(r => ({
    ...r,
    numberOfTrailersNotInZone: r.data.trailers.reduce(
      (acc, { detailsAreCorrect }) => acc + (detailsAreCorrect ? 0 : 1),
      0
    ),
    additionalTrailersInZone: r.data.unexpectedTrailers.length,
    commentsOnTrailers: r.data.trailers.reduce(
      (acc, { comment }) => acc + (comment ? 1 : 0),
      0
    )
  }));

  const handleClosePanel = () => {
    setShowingReviewPanel(false);
  };

  const headers = [
    "Audited at",
    "Zone",
    "Trailers not found",
    "Additional trailers",
    "Comments on trailers",
    ""
  ];
  const rows = summarisedReports.map(r => [
    r.insertedAt,
    r.zone.name,
    r.numberOfTrailersNotInZone,
    r.additionalTrailersInZone,
    r.commentsOnTrailers,
    [
      !r.reviewedById && (
        <Button
          icon="rule"
          variant="primary"
          size="tiny"
          title="Review"
          onClick={() => {
            setSelectedReport(r);
            setShowingReviewPanel(true);
          }}
        />
      ),
      { style: { width: "120px" } }
    ]
  ]);

  return (
    <Content>
      <div className="dash-row">
        <div className="content-block traffic-auditing">
          <h2>Yard Audits</h2>
          <p>
            The results of recent yard audits are displayed below. Click the
            "Review" button to check the details of the audit. Further action
            may be required if the auditor has identified additional trailers in
            the zone.
          </p>
          <Table
            noDataMessage="No yard audit results to display"
            headers={headers}
            rows={rows}
            sortable={false}
            pagination={props.reports}
            onNextPage={props.onNextPage}
            onPrevPage={props.onPrevPage}
          />
          <RightPanel
            title="Review yard audit"
            isExpanded={showingReviewPanel}
            onClose={handleClosePanel}
          >
            {selectedReport && (
              <ReviewForm
                selectedReport={selectedReport}
                onCompleteReview={() =>
                  props
                    .onCompleteReview(selectedReport.id)
                    .then(handleClosePanel)
                }
              />
            )}
          </RightPanel>
        </div>
      </div>
    </Content>
  );
}

function TrafficAuditingPage(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [reports, setReports] = useState({ entries: [] });

  const loadReports = siteId => {
    fetch(auditReportsQuery(siteId, currentPage)).then(({ auditReports }) => {
      setReports(auditReports);
    });
  };

  const handleCompleteReview = reportId => {
    return fetch(completeReviewMutation(reportId)).then(() =>
      loadReports(props.selectedSite)
    );
  };

  useEffect(() => {
    if (props.selectedSite) {
      loadReports(props.selectedSite, currentPage);
    } else {
      props.history.push({ pathname: "/traffic/trailers/yard-audits" });
    }
  }, [props.selectedSite, currentPage]);

  return (
    <TrafficPageTemplate>
      <PageBody
        reports={reports}
        onNextPage={() => setCurrentPage(currentPage + 1)}
        onPrevPage={() => setCurrentPage(currentPage - 1)}
        onCompleteReview={handleCompleteReview}
      />
    </TrafficPageTemplate>
  );
}

function mapStateToProps({ app: { selectedSite } }) {
  return { selectedSite };
}

export default withRouter(connect(mapStateToProps)(TrafficAuditingPage));
