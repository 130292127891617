import React from "react";
import "./TextBox.css";

export default function TextBox(props) {
  return (
    <div className="form-control">
      {props.label}
      <textarea
        {...props.field}
        value={props.field.value || ""}
        className="text-box"
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
    </div>
  );
}
