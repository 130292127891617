import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addMessage } from "redux/app";
import SiteForm from "./site-admin-page/SiteForm";

class SiteAdminPage extends Component {
  componentDidMount() {
    if (this.props.location.state !== undefined) {
      this.props.dispatch(
        addMessage(
          this.props.location.state.messageStatus,
          this.props.location.state.messageText
        )
      );
    }
  }

  render() {
    return (
      <div className="site-config">
        <SiteForm />
      </div>
    );
  }
}

export default connect()(withRouter(SiteAdminPage));
