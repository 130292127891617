import React from "react";

export default class HorizontalBar extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.canvasRef = React.createRef();
    this.toolRef = React.createRef();
    this.state = {
      barStatus: [],
      canvasHeight: 300
    };
  }
  componentDidMount() {
    this.createGraph();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.createGraph();
    }
  }
  createGraph() {
    const data = this.props.data;
    const canvas = this.canvasRef.current;
    const containerDimensions = this.containerRef.current.getBoundingClientRect();
    const context = canvas.getContext("2d");
    const width = containerDimensions.width;
    let height = this.state.canvasHeight;
    canvas.width = width;
    canvas.height = height;

    const xLabelMargin = 30;
    const yLabelMargin = 60;
    let barHeight = (canvas.height - xLabelMargin) / data[0].length;

    if (barHeight < 25) {
      barHeight = 25;
      height = canvas.height = barHeight * data[0].length + xLabelMargin;
      this.setState({ canvasHeight: height });
    }

    const tools = this.toolRef.current;
    const tc = tools.getContext("2d");
    tools.width = width;
    tools.height = height;

    const maxX =
      data.flat().reduce((acc, datum) => Math.max(acc, datum.value), 0) +
      Math.floor(
        0.2 * data[0].reduce((acc, datum) => Math.max(acc, datum.value), 0)
      );
    const columnCount = 6;
    const columnWidth =
      Math.floor(maxX / columnCount) * ((canvas.width - yLabelMargin) / maxX);
    const margin = 3;
    const gridWidth = (canvas.width - yLabelMargin) / maxX;
    const barCount = data[0].length;
    this.setState({ noData: data[0].length > 0 ? false : true });

    if (barCount === 0) {
      context.fillStyle = "black";
      context.font = "30px Arial";
      context.fillText("No Data", width * 0.4, height * 0.4);
      this.setState({ barStatus: [], labels: [] });
    } else {
      const max = this.buildBars(data[0], {
        yLabelMargin,
        barHeight,
        margin,
        gridWidth
      });
      let actual = [];
      let series = 1;
      if (data[1]) {
        series = 2;
        actual = this.buildBars(data[1], {
          yLabelMargin,
          barHeight,
          margin,
          gridWidth
        });
      }

      this.setState({
        barStatus: [...Array(data.length * series).keys()].fill(0)
      });

      this.buildGrid(context, {
        xLabelMargin,
        yLabelMargin,
        width,
        height,
        columnWidth,
        columnCount,
        barCount,
        barHeight
      });

      this.addXlabel(context, maxX, yLabelMargin, width, height, xLabelMargin);

      const labels = [];

      max.forEach((bar, index) => {
        const red = "rgba(209, 73, 91, 1)";
        context.fillStyle = "rgba(255, 255, 255, 0.6)";
        context.fillRect(bar.x, bar.y, bar.w, bar.h);
        context.strokeStyle = data[1]
          ? this.props.invertColours
            ? red
            : `rgba(0, 145, 147, 1)`
          : bar.color;
        context.strokeRect(bar.x, bar.y + 1, bar.w, bar.h - 2);

        context.fillStyle = "black";
        context.font = "10px Arial";
        context.fillText(bar.label.substr(0, 3), 5, (index + 0.6) * barHeight);

        labels.push({
          label: bar.label,
          x: yLabelMargin + 10,
          y: (index + 0.6) * barHeight
        });
      });

      this.setState({ labels: labels });

      if (data[1]) {
        actual.forEach((bar, index) => {
          const capacity = bar.w / max[index].w;
          const barColour = this.barColour(capacity);

          if (this.props.invertColours) {
            if (bar.w < max[index].w) {
              context.strokeStyle = barColour;
              this.addHatch(context, bar, max[index]);
            }

            context.fillStyle = barColour;
            context.fillRect(bar.x, bar.y, bar.w, bar.h);
          } else if (bar.w < max[index].w) {
            context.fillStyle = barColour;
            context.fillRect(bar.x, bar.y, bar.w, bar.h);
          } else {
            context.fillStyle = barColour;
            context.fillRect(
              max[index].x + max[index].w,
              max[index].y,
              bar.w - max[index].w,
              bar.h
            );
            context.strokeStyle = barColour;
            context.beginPath();
            context.rect(bar.x, bar.y, bar.w, bar.h);
            context.stroke();
            context.closePath();
            this.addHatch(context, bar, max[index]);
          }
        });
      }

      tools.onmousemove = e => {
        let merged = [];
        if (data[1]) {
          for (let i = 0; i < max.length; i++) {
            merged.push(max[i]);
            merged.push(actual[i]);
          }
        } else {
          merged = max;
        }

        const ob = [...Array(merged.length).keys()].fill(0);
        const r = tools.getBoundingClientRect();
        const x = e.clientX - r.left;
        const y = e.clientY - r.top;

        for (let i = merged.length - 1, b; (b = merged[i]); i--) {
          if (this.overBar(x, y, b)) {
            ob[i] = 1;

            this.showLabel(
              context,
              this.state.labels.find(
                label => label.y > b.y && label.y < b.y + barHeight
              ),
              width,
              height
            );

            break;
          } else {
            ob[i] = 0;
          }
        }
        if (!this.state.noData) {
          this.updateToolTip(tc, ob, merged, width, height, series);
        }
      };

      if (this.props.onClickBar) {
        tools.onclick = e => {
          const r = tools.getBoundingClientRect();
          const x = e.clientX - r.left;
          const y = e.clientY - r.top;

          for (let i = 0; i < max.length; i++) {
            let bar = max[i];
            if (data[1] && actual[i].w > bar.w) {
              bar = actual[i];
            }

            if (this.overBar(x, y, bar)) {
              this.props.onClickBar(i);
            }
          }
        };
      }
    }
  }

  barColour(capacity) {
    const orange = "rgba(254, 153, 32, 1)";
    const red = "rgba(209, 73, 91, 1)";
    const green = "rgba(143, 201, 58, 1)";

    if (this.props.invertColours) {
      if (capacity < 0.6) {
        return red;
      }

      if (capacity < 0.89) {
        return orange;
      }

      return green;
    }

    if (capacity > 0.6 && capacity < 0.75) {
      return orange;
    } else if (capacity > 0.74 && capacity < 0.9) {
      return orange;
    } else if (capacity > 0.89) {
      return red;
    }

    return green;
  }

  addHatch(context, bar, max) {
    const lines = max.w / 10;
    for (let i = 0; i < lines - 1; i++) {
      context.beginPath();
      context.moveTo(bar.x + i * 10, bar.y + 1);
      context.lineTo(bar.x + i * 10 + 10, bar.y + bar.h - 2);
      context.stroke();
      context.closePath();
    }
  }

  addXlabel(context, maxX, yLabelMargin, width, height, xLabelMargin) {
    for (let i = 1; i < maxX; i++) {
      if (i % 5 === 0) {
        context.fillStyle = "black";
        context.font = "10px Arial";
        context.fillText(
          i,
          yLabelMargin + i * ((width - yLabelMargin) / maxX) - (i < 10 ? 3 : 4),
          height - 0.6 * xLabelMargin
        );
      }
    }
  }

  buildBars(data, values) {
    return data.map((d, index) => {
      return {
        x: values.yLabelMargin,
        y: index * values.barHeight + values.margin,
        w: values.gridWidth * d.value,
        h: values.barHeight - 2 * values.margin,
        label: d.label,
        color: d.color,
        value: d.value
      };
    });
  }

  buildGrid(context, values) {
    context.strokeStyle = "black";
    context.beginPath();
    context.moveTo(values.yLabelMargin, 0);
    context.lineTo(
      values.yLabelMargin,
      values.height - values.xLabelMargin + 0.5
    );
    context.lineTo(values.width, values.height - values.xLabelMargin + 0.5);
    context.stroke();
    context.closePath();

    for (let i = 1; i <= values.columnCount; i++) {
      context.beginPath();
      context.strokeStyle = "#ccc";
      context.moveTo(values.yLabelMargin + i * 100, 0);
      context.lineTo(
        values.yLabelMargin + i * 100 + 0.5,
        values.height - values.xLabelMargin
      );
      context.stroke();
      context.closePath();
    }

    for (let i = 1; i < values.barCount; i++) {
      context.beginPath();
      context.strokeStyle = "#ccc";
      context.moveTo(values.yLabelMargin, i * values.barHeight + 0.5);
      context.lineTo(values.width, i * values.barHeight + 0.5);
      context.stroke();
      context.closePath();
    }
  }

  buildToolTip(context, x, y, w, h, r, color) {
    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    context.fillStyle = color;
    context.beginPath();
    context.moveTo(x + r, y);
    context.arcTo(x + w, y, x + w, y + h, r);
    context.arcTo(x + w, y + h, x, y + h, r);
    context.arcTo(x, y + h, x, y, r);
    context.arcTo(x, y, x + w, y, r);
    context.fill();
    context.closePath();
    return context;
  }

  hideLabel(context, width, height) {
    context.fillStyle = "white";
    context.fillRect(0, height - 15, width, 20);
  }

  overBar(x, y, b) {
    return x >= b.x && x <= b.x + b.w && y >= b.y && y <= b.y + b.h;
  }

  showLabel(context, label, width, height) {
    this.hideLabel(context, width, height);
    if (label) {
      context.fillStyle = "black";
      context.font = "bold 10px Arial";
      context.fillText(label.label, 5, height - 5);
    }
  }

  updateToolTip(context, ob, bars, width, height, seriesCount) {
    const currentOb = this.state.barStatus.findIndex(k => k === 1);
    const newOb = ob.findIndex(k => k === 1);
    if (newOb === -1 && currentOb !== newOb) {
      context.clearRect(0, 0, width, height);
      this.setState({ barStatus: ob });
    } else if (currentOb !== newOb) {
      let text = "";
      const color = "#eee";
      const fill = "black";
      if (newOb % 2 && seriesCount == 2) {
        text = `${bars[newOb].value} / ${bars[newOb - 1].value} Max`;
      } else {
        text = `Max: ${bars[newOb].value}`;
      }

      let backgroundOffset = 5;
      if (newOb % 2 && seriesCount == 2 && bars[newOb - 1].w > bars[newOb].w) {
        backgroundOffset = bars[newOb - 1].w - bars[newOb].w + 5;
      }

      context.clearRect(0, 0, width, height);

      let labelOffset = -5;

      if (bars[newOb].h >= 80) {
        labelOffset = bars[newOb].h / 2;
      } else if (bars[newOb].h < 80 && bars[newOb].h > 60) {
        labelOffset = 25;
      } else if (bars[newOb].h <= 60 && bars[newOb].h > 45) {
        labelOffset = 10;
      } else if (bars[newOb].h <= 45 && bars[newOb].h > 30) {
        labelOffset = 5;
      } else if (bars[newOb].h < 30 && bars[newOb].h > 25) {
        labelOffset = 0;
      } else if (bars[newOb].h <= 25 && bars[newOb].h > 20) {
        labelOffset = -2;
      }

      const canvas = this.canvasRef.current;
      const toolTipWidth = newOb % 2 && seriesCount == 2 ? 75 : 55;

      let toolTipX = bars[newOb].x + bars[newOb].w;

      if (toolTipX + backgroundOffset + toolTipWidth > canvas.width) {
        toolTipX = canvas.width - toolTipWidth - 2 * backgroundOffset;
      }

      this.buildToolTip(
        context,
        toolTipX + backgroundOffset,
        bars[newOb].y + labelOffset,
        toolTipWidth,
        25,
        25,
        color
      );
      context.fillStyle = fill;
      context.font = "10px Arial";

      let textOffset = 15;
      if (newOb % 2 && seriesCount == 2 && bars[newOb - 1].w > bars[newOb].w) {
        textOffset = bars[newOb - 1].w - bars[newOb].w + 15;
      }

      context.fillText(
        text,
        toolTipX + textOffset,
        bars[newOb].y + labelOffset + 15
      );
      this.setState({ barStatus: ob });
    }
  }

  render() {
    const height = { height: `${this.state.canvasHeight}px` };
    return (
      <div className="barGraph" style={height} ref={this.containerRef}>
        <canvas className="bars" ref={this.canvasRef} />
        <canvas className="tools" ref={this.toolRef} />
      </div>
    );
  }
}
