import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addMessage } from "redux/app";
import { Formik, Form, Field } from "formik";
import {
  getSites,
  getZones,
  updateAllocations,
} from "./traffic-bookings-queries";
import Button from "Button";
import RightPanel from "RightPanel";
import SelectBox from "form-controls/SelectBox";
import { formatAsOptions } from "siteList";

class PreAllocateRightPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sites: [],
      loading: true,
      primaryZones: [],
      pZoneLoading: true,
      secondaryZones: [],
      sZoneLoading: true,
      selectedBooking: "",
    };
  }

  componentDidMount() {
    getSites().then((data) => {
      this.setState({
        sites: data.sites,
        loading: false,
      });
    });
  }

  formatSubmitData(values, setFieldError) {
    const primary = this.props.selectedBooking.allocations.find(
      (al) => parseInt(al.order) === 0
    );
    const secondary = this.props.selectedBooking.allocations.find(
      (al) => parseInt(al.order) === 1
    );
    const primaryZoneType = !values.primaryZoneId
      ? ""
      : this.state.primaryZones.find((z) => z.id === values.primaryZoneId).type;
    const secondaryZoneType = !values.secondaryZoneId
      ? ""
      : this.state.secondaryZones.find((z) => z.id === values.secondaryZoneId)
          .type;

    const errors = this.handleErrors(
      values,
      setFieldError,
      primaryZoneType,
      secondaryZoneType
    );

    if (
      ["parking", "virtual"].includes(primaryZoneType) ||
      primaryZoneType === ""
    ) {
      values.primaryDockId = null;
    }

    if (
      ["parking", "virtual"].includes(secondaryZoneType) ||
      secondaryZoneType === ""
    ) {
      values.secondaryDockId = null;
    }

    values.primaryId =
      this.props.selectedBooking.allocations.length > 0
        ? parseInt(primary.id)
        : null;
    values.secondaryId =
      this.props.selectedBooking.allocations.length > 1
        ? parseInt(secondary.id)
        : null;

    return { values: values, errors: errors };
  }

  handleErrors(values, setFieldError, primaryZoneType, secondaryZoneType) {
    let errors = false;
    if (!values.primarySiteId && values.secondarySiteId) {
      setFieldError("primarySiteId", "Please remove the secondary allocation.");
      errors = true;
    }
    if (!values.primaryZoneId && values.primarySiteId) {
      setFieldError("primaryZoneId", "Please select a zone.");
      errors = true;
    }
    if (!values.primaryDockId && primaryZoneType === "loading") {
      setFieldError("primaryDockId", "Please select a dock.");
      errors = true;
    }
    if (!values.secondaryZoneId && values.secondarySiteId) {
      setFieldError("secondaryZoneId", "Please select a zone.");
      errors = true;
    }
    if (!values.secondaryDockId && secondaryZoneType === "loading") {
      setFieldError("secondaryDockId", "Please select a dock.");
      errors = true;
    }
    return errors;
  }

  handleSubmit(values, { setSubmitting, setFieldError }) {
    const submitValues = this.formatSubmitData(values, setFieldError);
    if (!submitValues.errors) {
      updateAllocations(
        submitValues.values,
        this.props.selectedBooking.id
      ).then((data) => {
        if (data.errors) {
          this.props.dispatch(
            addMessage("error", "There was an error saving your changes.")
          );
        } else {
          setSubmitting(false);
          this.props.dispatch(
            addMessage("success", "Allocations updated successfully.")
          );
          this.props.onClose();
        }
      });

      setSubmitting(false);
    }
  }

  updateZones(siteId, allocation) {
    getZones(siteId).then((data) => {
      if (allocation === "primary") {
        if (data && data.zones !== this.state.primaryZones) {
          this.setState({ primaryZones: data.zones, pZoneLoading: false });
        } else {
          this.setState({ primaryZones: [] });
        }
      } else {
        if (data && data.zones !== this.state.secondaryZones) {
          this.setState({ secondaryZones: data.zones, sZoneLoading: false });
        }
      }
    });
  }

  zoneOptions(siteId, allocation) {
    if (allocation === "primary") {
      return this.state.primaryZones.map((z) => ({
        value: z.id,
        label: z.name,
      }));
    } else {
      return this.state.secondaryZones.map((z) => ({
        value: z.id,
        label: z.name,
      }));
    }
  }

  selectedZone(zoneId, allocation) {
    if (allocation === "primary") {
      return this.state.primaryZones.find(
        (z) => parseInt(z.id) === parseInt(zoneId)
      );
    } else {
      return this.state.secondaryZones.find(
        (z) => parseInt(z.id) === parseInt(zoneId)
      );
    }
  }

  dockOptions(zoneId, allocation) {
    const selectedZone = this.selectedZone(zoneId, allocation);
    if (!selectedZone) {
      return [];
    }
    const { docks } = selectedZone;

    docks.sort((a, b) => (a.number > b.number ? 1 : -1));

    return docks.length > 0
      ? docks.map((d) => ({
          value: d.id,
          label: d.number,
          isDisabled: !(d.isActive && !d.isTaken),
        }))
      : [];
  }

  get initialValues() {
    const primary = this.props.selectedBooking.allocations.find(
      (al) => parseInt(al.order) === 0
    );
    const secondary = this.props.selectedBooking.allocations.find(
      (al) => parseInt(al.order) === 1
    );

    return {
      primarySiteId: primary ? primary.zone.site.id : "",
      primaryZoneId: primary ? primary.zone.id : "",
      primaryDockId: !primary ? "" : primary.dock ? primary.dock.id : "",
      secondarySiteId: secondary ? secondary.zone.site.id : "",
      secondaryZoneId: secondary ? secondary.zone.id : "",
      secondaryDockId: !secondary
        ? ""
        : secondary.dock
        ? secondary.dock.id
        : "",
    };
  }

  render() {
    if (!this.props.selectedBooking) {
      return null;
    } else if (this.props.selectedBooking !== this.state.selectedBooking) {
      this.setState({ selectedBooking: this.props.selectedBooking });
      return null;
    }

    const siteOptions = formatAsOptions(this.state.sites);

    if (
      this.state.selectedBooking.allocations.length > 0 &&
      this.state.pZoneLoading
    ) {
      const primary = this.props.selectedBooking.allocations.find(
        (al) => parseInt(al.order) === 0
      );
      this.updateZones(primary.zone.site.id, "primary");
    }
    if (
      this.state.selectedBooking.allocations.length > 1 &&
      this.state.sZoneLoading
    ) {
      const secondary = this.props.selectedBooking.allocations.find(
        (al) => parseInt(al.order) === 1
      );
      this.updateZones(secondary.zone.site.id, "secondary");
    }

    return (
      <RightPanel
        isExpanded={this.props.isExpanded}
        title="Edit trailer allocation"
        onClose={this.props.onClose}
      >
        {this.props.isExpanded && (
          <Formik
            onSubmit={this.handleSubmit.bind(this)}
            initialValues={this.initialValues}
          >
            {({ errors, values, setValues }) => (
              <>
                <p>
                  Use the fields below to set the primary and secondary
                  locations for the trailer when it arrives on site.
                </p>
                <Form>
                  <div>
                    <h3>Primary Allocation</h3>
                    <Field
                      name="primarySiteId"
                      label="Site"
                      options={siteOptions}
                      disabled={this.state.loading}
                      placeholder="Select Site/Clear Allocation"
                      onChange={(e) => {
                        this.updateZones(e.target.value, "primary");
                        setValues({
                          ...values,
                          primaryZoneId: "",
                          primaryDockId: "",
                          primarySiteId: e.target.value,
                        });
                      }}
                      component={SelectBox}
                      error={errors.primarySiteId}
                    />
                    <Field
                      name="primaryZoneId"
                      label="Zone"
                      component={SelectBox}
                      options={this.zoneOptions(
                        values.primarySiteId,
                        "primary"
                      )}
                      placeholder="Select Zone"
                      disabled={
                        this.zoneOptions(values.primarySiteId, "primary")
                          .length === 0
                      }
                      onChange={(e) => {
                        setValues({
                          ...values,
                          primaryZoneId: e.target.value,
                          primaryDockId: "",
                        });
                      }}
                      error={errors.primaryZoneId}
                    />
                    <Field
                      name="primaryDockId"
                      label="Dock"
                      options={this.dockOptions(
                        values.primaryZoneId,
                        "primary"
                      )}
                      placeholder="Select Dock"
                      disabled={
                        this.dockOptions(values.primaryZoneId, "primary")
                          .length === 0
                      }
                      error={errors.primaryDockId}
                      component={SelectBox}
                    />
                  </div>
                  <div>
                    <h3>Secondary Allocation</h3>
                    <Field
                      name="secondarySiteId"
                      label="Site"
                      options={siteOptions}
                      placeholder="Select Site/Clear Allocation"
                      onChange={(e) => {
                        this.updateZones(e.target.value, "secondary");
                        setValues({
                          ...values,
                          secondaryZoneId: "",
                          secondaryDockId: "",
                          secondarySiteId: e.target.value,
                        });
                      }}
                      component={SelectBox}
                      error={errors.secondarySiteId}
                    />
                    <Field
                      name="secondaryZoneId"
                      label="Zone"
                      component={SelectBox}
                      options={this.zoneOptions(
                        values.secondarySiteId,
                        "secondary"
                      )}
                      placeholder="Select Zone"
                      disabled={
                        this.zoneOptions(values.secondarySiteId, "secondary")
                          .length === 0
                      }
                      onChange={(e) => {
                        setValues({
                          ...values,
                          secondaryZoneId: e.target.value,
                          secondaryDockId: "",
                        });
                      }}
                      error={errors.secondaryZoneId}
                    />
                    <Field
                      name="secondaryDockId"
                      label="Dock"
                      options={this.dockOptions(
                        values.secondaryZoneId,
                        "secondary"
                      )}
                      placeholder="Select Dock"
                      disabled={
                        this.dockOptions(values.secondaryZoneId, "secondary")
                          .length === 0
                      }
                      error={errors.secondaryDockId}
                      component={SelectBox}
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    title="Save changes"
                  />
                </Form>
              </>
            )}
          </Formik>
        )}
      </RightPanel>
    );
  }
}

export default connect()(withRouter(PreAllocateRightPanel));
