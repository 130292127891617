import { fetch, toArgList } from "graphql-helper";
import { format } from "date-fns";

export function getSites() {
  return fetch(`
    query {
      sites: sites(includeInactive: true) {
        id
        name
        parentSite{
          id
          name
        }
        siteConfig
        defaultSlotTimes
        archivedAt
        shortCode
      }
    }
  `);
}

export function getSiteSlots(siteId, date) {
  return fetch(`
    query {
      siteSlots(id: ${siteId}, date: "${date.toISOString()}") {
        id
        slot_date
        site {
          id
          name
          defaultSlotTimes
        }
        insertedAt
        updatedAt
        slotTimes
      }
    }
  `);
}

export function getSite(id) {
  return fetch(`
    query {
      site: site(id: ${id}) {
        id
        name
        shortCode
        archivedAt
        defaultSlotTimes
        siteConfig
        stackrId
        stackrDefaultDestinationId
        offDockZoneId
        unknownZoneId
        zones {
          id
          name
          type
          isActive
          docks {
            id
            number
            isActive
          }
        }
        devices {
          id
          name
          deviceId
          active
          applications {
            name
          }
        }
        hauliers {
          id
          name
          shortCode
        }
        parentSite{
          id
          name
          zones {
            id
            name
            isActive
          docks {
            id
            number
            isActive
          }
          }
        }
      }
      hauliers {
        id
        name
        shortCode
      }
      sites: sites {
        id
        name
        parentSite{
          id
          name
        }
      }
    }
  `);
}

export function createSite(site) {
  return fetch(`
    mutation CreateSite {
      createSite(
        name: "${site.name}"
        shortCode:  "${site.shortCode}"
        parentSiteId:  ${site.parentSiteId}
        bookingInterval:  ${site.bookingInterval}
        archivedAt:  ${site.archivedAt}
        defaultSlots: ${JSON.stringify(site.defaultSlots)}
      ) {
        id
      }
    }`);
}

export function updateSite(siteId, site) {
  const args = { id: siteId, ...site };
  return fetch(`
    mutation UpdateSite {
      updateSite(${toArgList(args)}) {
        id
      }
    }`);
}

export function updateSiteSlots(siteId, date, slots) {
  return fetch(`
    mutation UpdateSite {
      updateSiteSlots(
        id:${siteId}
        date: "${format(date, "YYYY-MM-DDTHH:mm:ssZ")}"
        slots:  [${slots}]
      ) {
        id
        slotTimes
      }
    }`);
}

export function updateSiteHauliers(site, hauliers) {
  return fetch(`
    mutation UpdateSiteHaulier {
      site: updateSiteHaulier(id: ${site}, hauliers:[${hauliers}]) {
        id
        name
        hauliers {
          id
          name
          shortCode
        }
        parentSite{
          id
          name
        }
      }
    }
  `);
}

export function availableDockSafetyDevicesForSite(siteId) {
  return fetch(`
  query {
    availableDockSafetyDevicesForSite(siteId: ${siteId}) {
      deviceId
      isActive
      errorState
      messageData
      lastMessageReceivedAt
      currentPower
    trafficLightStatus
  }}
  `);
}

export function newDockSafetyDevices() {
  return fetch(`
    query {
      newDockSafetyDevices {
        deviceId
        isActive
        errorState
        messageData
        lastMessageReceivedAt
        currentPower
      trafficLightStatus
    }}
    `);
}

export function assignSiteDockSafetyDevice(deviceId, siteId) {
  return fetch(`
    mutation 
    {assignSiteDockSafetyDevice(deviceId: "${deviceId}", siteId:"${siteId}") {
      deviceId
    }
  }
  `);
}

export function unassignSiteDockSafetyDevice(deviceId) {
  return fetch(`
    mutation 
    {unassignSiteDockSafetyDevice(device_id: "${deviceId}") {
      deviceId
    }
  }
  `);
}
