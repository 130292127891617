import React from "react";
import Modal from "Modal";
import Button from "Button";

export default function YesNoCancelModal({
  title,
  description,
  onYes,
  onNo,
  onCancel
}) {
  return (
    <Modal>
      <div className="modal">
        <div className="modal__content">
          <div className="modal__header">
            <span className="modal__title">{title}</span>
            <i className="material-icons modal__close" onClick={onCancel}>
              close
            </i>
          </div>

          <div className="modal__body">
            <div className="modal__description">{description}</div>
            <div className="modal__buttons">
              <Button
                variant="primary"
                size="small"
                title="Yes"
                onClick={onYes}
              />
              <Button
                variant="secondary"
                size="small"
                title="No"
                onClick={onNo}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
