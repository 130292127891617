import React from "react";
import { Link } from "react-router-dom";
import SplashPage from "./SplashPage";
import Button from "Button";
import "./RegisterPage.css";

export default function InvalidRecoverTokenPage() {
  return (
    <SplashPage>
      <p className="splash-page__heading">Expired</p>
      <p className="thin">Sorry, this link has expired.</p>
      <p className="small-type center">
        Reset links are only valid for 20 minutes.
      </p>
      <Link to="/auth/login">
        <Button
          type="submit"
          variant="white"
          size="small"
          title="Back to login"
        />
      </Link>
    </SplashPage>
  );
}
