import React from "react";
import classnames from "classnames";
import "./RightPanel.css";

export default function RightPanel({
  title,
  isExpanded,
  children,
  onClose,
  theme
}) {
  const className = classnames("right-panel", {
    "right-panel--expanded": isExpanded,
    [`right-panel--${theme}`]: theme
  });

  return (
    <section className={className}>
      <div className="right-panel__header">
        <span className="right-panel__title">{title}</span>
        <i className="material-icons right-panel__close" onClick={onClose}>
          close
        </i>
      </div>
      {children}
    </section>
  );
}
