import { fetch } from "graphql-helper";

export function importTransporeonBookings(bookings) {
  return fetch(
    `
    mutation ImportTransporeonBookings($transporeonBooking: [TransporeonBooking]) {
      importTransporeonBookings(transporeon_bookings: $transporeonBooking) {
        journey_reference
        errors
      }
    }`,
    { transporeonBooking: bookings }
  );
}

export function getSites() {
  return fetch(`
    query {
      sites {
        id
        name
        shortCode
      }
    }
  `);
}
