import React from "react";
import Content from "Content";
import RequestMovementForm from "./move-trailer-page/RequestMovementForm";
import EmptyTrailerForm from "./move-trailer-page/EmptyTrailerForm";
import RequestDriverForm from "./move-trailer-page/RequestDriverForm";
import ReloadTrailerForm from "./move-trailer-page/ReloadTrailerForm";
import {
  trailerMovementsById,
  requestMovement,
  cancelMovement
} from "./move-trailer-page/move-trailer-queries";
import {
  updateTrailerEmptyFlag,
  updateBookingPallets,
  updateBookingStatusByTrailer
} from "./warehouseQueries";
import { withErrorHandler } from "graphql-helper";
import { bookingStatuses } from "bookingStatuses";
import { createBooking } from "bookings/pages/calendar-view-page/calendar-view-queries";

class MoveTrailerPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trailer: [],
      sites: [],
      allSites: [],
      movements: [],
      loading: true,
      showReloadTrailerForm: false
    };
  }

  componentDidMount() {
    this.loadTrailerMovements();
  }

  cancelMovement(id) {
    cancelMovement(id).then(data => {
      const trailerMovements = this.state.movements;
      const ind = trailerMovements.findIndex(t => t.id === id);
      trailerMovements[ind].status = data.movement.status;
      this.setState({ movements: trailerMovements });
    });
  }

  handleEmptyTrailer(values, { setSubmitting }) {
    this.props.handleErrors(
      updateTrailerEmptyFlag(this.state.trailer.id, true)
        .then(() => {
          return updateBookingStatusByTrailer(
            this.state.trailer.id,
            bookingStatuses.completed
          );
        })
        .then(() => {
          setSubmitting(false);
          this.props.onClose();
        })
    );
  }

  reloadTrailer(values, { setSubmitting }) {
    const args = {
      direction: "internal",
      status: "trailer_attached",
      trailerId: parseInt(this.state.trailer.id),
      sendingSiteId: parseInt(this.props.selectedSite),
      receivingSiteId: parseInt(values.receivingSiteId),
      numberOfPallets: parseInt(values.numberOfPallets),
      goods: Object.entries(values.goods)
        .filter(([, v]) => v)
        .map(([k]) => k),
      trailerTypeId: parseInt(this.state.trailer.trailerType.id)
    };
    this.props.handleErrors(
      updateTrailerEmptyFlag(this.state.trailer.id, true)
        .then(() => {
          updateBookingStatusByTrailer(
            this.state.trailer.id,
            bookingStatuses.completed
          ).then(() => {
            return createBooking(args);
          });
        })
        .then(() => {
          setSubmitting(false);
          this.props.onClose();
        })
    );
  }

  handleReleaseToDriver(values, { setSubmitting }) {
    this.props.handleErrors(
      updateBookingStatusByTrailer(
        this.state.trailer.id,
        bookingStatuses.readyForDriver
      ).then(() => {
        setSubmitting(false);
        this.props.onClose();
      })
    );
  }

  handleSubmitMovement(values, { setSubmitting }) {
    this.props
      .handleErrors(
        requestMovement(
          this.state.trailer.id,
          values.zoneId,
          values.dockId === undefined ? null : values.dockId,
          values.hasIssuesWithTrailer,
          values.trailerIssueNotes
        ),
        "Movement requested"
      )
      .then(() => {
        setSubmitting(false);
        this.props.onClose();
      });

    if (typeof values.isEmpty !== "undefined") {
      this.props.handleErrors(
        updateTrailerEmptyFlag(this.state.trailer.id, values.isEmpty).then(
          () => {
            setSubmitting(false);
            this.props.onClose();
          }
        )
      );

      if (values.isEmpty === "false") {
        this.props.handleErrors(
          updateBookingPallets(
            this.state.trailer.booking.id,
            values.numberOfPallets
          )
        );
      }
    }
  }

  loadTrailerMovements() {
    return trailerMovementsById(this.props.trailerId).then(trailerInfo => {
      this.setState({
        loading: false,
        sites: trailerInfo.sites,
        allSites: trailerInfo.allSites,
        movements: trailerInfo.trailer.movements,
        trailer: trailerInfo.trailer
      });
    });
  }

  get hasPendingMovement() {
    return !!this.state.movements.find(m => m.status === "pending");
  }

  get trailerLocation() {
    const trailer = this.state.trailer;
    if (trailer.dock) {
      return `${trailer.zone.name} - ${trailer.dock.number}`;
    }

    return trailer.zone.name;
  }

  render() {
    if (this.state.loading) {
      return <p>Loading, please wait!</p>;
    }

    if (this.state.showReloadTrailerForm) {
      return (
        <Content>
          <h3>Reload trailer</h3>
          <ReloadTrailerForm
            sites={this.state.allSites}
            onSubmit={this.reloadTrailer.bind(this)}
          />
        </Content>
      );
    }

    const status = this.state.trailer.booking
      ? this.state.trailer.booking.status
      : "";

    const isInternalBooking = this.state.trailer.booking
      ? this.state.trailer.booking.direction === "internal"
      : false;

    const showRequestDriverForm =
      this.state.trailer.booking &&
      !this.hasPendingMovement &&
      this.state.trailer.booking.direction !== "internal";
    const showEmptyTrailerForm =
      !this.hasPendingMovement && status === "inbound_in_progress";

    return (
      <Content>
        {this.hasPendingMovement && (
          <p>
            This trailer has pending movements. You can request a new movement
            when the pending movement is complete or cancelled.
          </p>
        )}
        {showRequestDriverForm && (
          <>
            <h3>Request driver</h3>
            <RequestDriverForm
              booking={this.state.trailer.booking}
              onSubmit={this.handleReleaseToDriver.bind(this)}
            />
          </>
        )}
        {showEmptyTrailerForm && (
          <>
            <h3>Keep trailer on dock</h3>
            <EmptyTrailerForm
              onReloadTrailer={() =>
                this.setState({ showReloadTrailerForm: true })
              }
              onCompleteBooking={this.handleEmptyTrailer.bind(this)}
              isInternalTrailer={
                this.state.trailer.isInternal || isInternalBooking
              }
            />
          </>
        )}
        {!this.hasPendingMovement && (
          <>
            <h3>Request a movement</h3>
            <RequestMovementForm
              onSubmit={this.handleSubmitMovement.bind(this)}
              trailer={this.state.trailer}
              sites={this.state.sites}
            />
          </>
        )}
      </Content>
    );
  }
}

export default withErrorHandler(MoveTrailerPanel);
