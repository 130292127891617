import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Can from "Can";
import classNames from "classnames";
import { toggleAppSwitcher } from "../redux/app";
import appSuiteLogo from "../assets/mercurius-logo.png";
import bookingAppLogo from "./app-nav/booking.png";
import trafficAppLogo from "./app-nav/traffic.png";
import warehouseAppLogo from "./app-nav/warehouse.png";
import flightboardAppLogo from "./app-nav/flightboard.png";
import settingsLogo from "./app-nav/settings-logo.png";
import "./AppNav.css";
import config from "../config";

function AppNav(props) {
  const navBarClassName = classNames("navigation-bar", {
    "navigation-bar--open": props.showAppSwitcher
  });

  let appIcons;
  if (config.FEATURE_LITE_VERSION) {
    appIcons = [
      {
        title: "Booking app",
        logo: bookingAppLogo,
        href: "/bookings",
        className: "bookings",
        permission: "bookings.access"
      },
      {
        title: "Traffic app",
        logo: trafficAppLogo,
        href: "/traffic/trailers",
        className: "traffic",
        permission: "traffic.access"
      },
      {
        title: "Warehouse app",
        logo: warehouseAppLogo,
        href: "/warehouse/zones",
        className: "warehouse",
        permission: "warehouse.access"
      },
      {
        title: "Settings",
        logo: settingsLogo,
        href: "/settings",
        className: "settings",
        permission: "settings.access"
      }
    ];
  } else {
    appIcons = [
      {
        title: "Booking app",
        logo: bookingAppLogo,
        href: "/bookings",
        className: "bookings",
        permission: "bookings.access"
      },
      {
        title: "Traffic app",
        logo: trafficAppLogo,
        href: "/traffic",
        className: "traffic",
        permission: "traffic.access"
      },
      {
        title: "Warehouse app",
        logo: warehouseAppLogo,
        href: "/warehouse",
        className: "warehouse",
        permission: "warehouse.access"
      },
      {
        title: "Flightboard app",
        logo: flightboardAppLogo,
        href: "/flightboard",
        className: "flightboard",
        permission: "flightboard.access"
      },
      {
        title: "Settings",
        logo: settingsLogo,
        href: "/settings",
        className: "settings",
        permission: "settings.access"
      }
    ];
  }

  appIcons = appIcons.map(ai => ({
    ...ai,
    selected: props.location.pathname.startsWith(ai.href)
  }));

  return (
    <div className={navBarClassName}>
      <i
        className="material-icons navigation-bar__menu"
        onClick={() => props.dispatch(toggleAppSwitcher())}
      >
        menu
      </i>
      <span className="app-suite-logo">
        <img src={appSuiteLogo} alt="Mercurius logo" />
      </span>
      {appIcons.map((ai, idx) => (
        <Can key={idx} when={ai.permission}>
          <Link
            className={`app-icon app-icon--${ai.className} ${
              ai.selected ? "app-icon--selected" : ""
            }`}
            to={ai.href}
          >
            <img src={ai.logo} alt={ai.title} />
            <span className="app-icon__tooltip">{ai.title}</span>
          </Link>
        </Can>
      ))}
    </div>
  );
}

function mapStateToProps({ app: { showAppSwitcher } }) {
  return { showAppSwitcher };
}

export default withRouter(connect(mapStateToProps)(AppNav));
