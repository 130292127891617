import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Formik, Form, Field } from "formik";
import Button from "Button";
import Can from "Can";
import Loader from "Loader";
import TextInput from "form-controls/TextInput";
import SelectBox from "form-controls/SelectBox";
import Switch from "form-controls/Switch";
import "./SiteAdminForm.css";
import "./SiteEditForm.css";
import { withErrorHandler } from "graphql-helper";
import { getSite, updateSite } from "./site-admin-queries";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  dailyPalletLimit: Yup.number()
    .integer("Must be a round number")
    .min(0, "Must be 0, a positive number, or left empty")
    .typeError("Must be a number")
});
class SiteEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: [],
      siteLoading: true,
      siteList: [],
      siteListLoading: true,
      zones: []
    };
  }

  componentDidMount() {
    getSite(this.props.selectedSite.id).then(data => {
      const zones = data.site.parentSite
        ? data.site.parentSite.zones.concat(data.site.zones)
        : data.site.zones;

      this.setState({
        site: data.site,
        siteLoading: false,
        siteList: this.filterSiteList(data),
        siteListLoading: false,
        zones
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedSite.id !== this.props.selectedSite.id) {
      this.setState({ siteLoading: true, siteListLoading: true });
      getSite(this.props.selectedSite.id).then(data => {
        const zones = data.site.parentSite
          ? data.site.parentSite.zones.concat(data.site.zones)
          : data.site.zones;

        this.setState({
          site: data.site,
          siteLoading: false,
          siteList: this.filterSiteList(data),
          siteListLoading: false,
          zones
        });
      });
    }
  }

  filterSiteList(data) {
    return data.sites.filter(site => !site.parentSite);
  }

  get siteList() {
    return this.state.siteList.map(item => ({
      value: item.id,
      label: item.name
    }));
  }

  zoneOptions(type = null) {
    return this.state.zones
      .filter(z => type === null || z.type === type)
      .map(z => ({
        value: z.id,
        label: z.name,
        isDisabled: !z.isActive
      }));
  }

  dockOptions(zoneId) {
    const zone = this.state.zones.find(
      z => parseInt(z.id) === parseInt(zoneId)
    );
    if (!zone) {
      return [];
    }
    const { docks } = zone;

    docks.sort((a, b) => (a.number > b.number ? 1 : -1));

    return docks.map(d => ({
      value: d.id,
      label: d.number,
      isDisabled: !d.isActive
    }));
  }

  render() {
    if (this.state.siteLoading || this.state.siteListLoading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const parentSiteId = this.state.site.parentSite
      ? this.state.site.parentSite.id
      : "";

    const bookingInterval = [
      { value: 300, label: "5 minutes" },
      { value: 600, label: "10 minutes" },
      { value: 900, label: "15 minutes" },
      { value: 1800, label: "30 minutes" },
      { value: 2700, label: "45 minutes" },
      { value: 3600, label: "60 minutes" }
    ];

    const siteConfig = this.state.site.siteConfig;

    const archivedAt = this.state.site.archivedAt;

    const init = {
      name: this.state.site.name,
      shortCode: this.state.site.shortCode || "",
      parentSiteId: parentSiteId,
      bookingInterval: siteConfig.booking_interval,
      stackrId: this.state.site.stackrId,
      stackrDefaultDestinationId: this.state.site.stackrDefaultDestinationId,
      defaultZone: !siteConfig.default_parking
        ? ""
        : siteConfig.default_parking.zone_id || "",
      defaultDock: !siteConfig.default_parking
        ? ""
        : siteConfig.default_parking.dock_id || "",
      isActive: archivedAt ? null : true,
      isHaulierTimeRestricted: siteConfig.is_haulier_time_restricted,
      offDockZoneId: this.state.site.offDockZoneId,
      unknownZoneId: this.state.site.unknownZoneId,
      dailyPalletLimit: siteConfig.daily_pallet_limit
    };

    return (
      <div className="admin-main">
        <Formik
          initialValues={init}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            const result = {
              name: values.name,
              parentSiteId: values.parentSiteId || null,
              shortCode: values.shortCode,
              bookingInterval: parseInt(values.bookingInterval) || null,
              zoneId: parseInt(values.defaultZone) || null,
              dockId:
                this.dockOptions(values.defaultZone).length < 1
                  ? null
                  : values.defaultDock
                  ? parseInt(values.defaultDock)
                  : null,
              stackrId: values.stackrId,
              stackrDefaultDestinationId: values.stackrDefaultDestinationId,
              isActive: !!values.isActive,
              isHaulierTimeRestricted: !!values.isHaulierTimeRestricted,
              offDockZoneId: parseInt(values.offDockZoneId) || null,
              unknownZoneId: parseInt(values.unknownZoneId) || null,
              dailyPalletLimit: parseInt(values.dailyPalletLimit) || 0
            };

            this.props
              .handleErrors(
                updateSite(this.state.site.id, result).then(() => {
                  setSubmitting(false);
                  this.props.updateSites();
                }),
                "Site successfully updated."
              )
              .then(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting, values, errors, touched }) => (
            <Form className="site-details-form">
              <Field name="name" label="Site Name" component={TextInput} />
              <Field
                name="shortCode"
                label="Site Short Code (Atlas Code)"
                component={TextInput}
              />
              <Field name="stackrId" label="Stackr ID" component={TextInput} />
              <Field
                name="stackrDefaultDestinationId"
                label="Stackr Default Destination ID"
                component={TextInput}
              />
              <Field
                name="parentSiteId"
                label="Parent Site"
                placeholder="Select Parent Site"
                options={this.siteList}
                component={SelectBox}
              />
              <Field name="isActive" label="Active" component={Switch} />

              <Field
                label="Booking Frequency"
                name="bookingInterval"
                placeholder="Select frequency"
                options={bookingInterval}
                component={SelectBox}
              />
              <h3>Default Parking Location</h3>
              <Field
                label="Zone"
                name="defaultZone"
                placeholder="Select zone"
                options={this.zoneOptions()}
                component={SelectBox}
              />
              <Field
                label="Dock"
                name="defaultDock"
                placeholder="Select dock"
                options={this.dockOptions(values.defaultZone)}
                component={SelectBox}
                disabled={this.dockOptions(values.defaultZone).length < 1}
              />

              <h3>Virtual Zones</h3>
              <Field
                label="Off Dock Zone"
                name="offDockZoneId"
                placeholder="Select zone"
                options={this.zoneOptions("virtual")}
                component={SelectBox}
              />
              <Field
                label="Unknown Location Zone"
                name="unknownZoneId"
                placeholder="Select zone"
                options={this.zoneOptions("virtual")}
                component={SelectBox}
              />
              <Can when="settings.site.update.24hBookingBlock">
                <h3>Block hauliers creating bookings within 24h</h3>
                <Field
                  name="isHaulierTimeRestricted"
                  label="Block"
                  component={Switch}
                />
              </Can>
              <Can when="settings.site.update.dailyPalletLimit">
                <Field
                  name="dailyPalletLimit"
                  label="Daily Pallet Limit"
                  component={TextInput}
                  error={touched.dailyPalletLimit && errors.dailyPalletLimit}
                />
              </Can>
              <div className="button-col">
                <Can when="settings.site.update">
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    title="Save"
                    disabled={isSubmitting}
                  />
                </Can>
                <Button
                  variant="secondary"
                  size="small"
                  title="Cancel"
                  onClick={() => this.props.returnHome()}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withErrorHandler(connect()(withRouter(SiteEditForm)));
