import React from "react";
import { connect } from "react-redux";
import { addMessage } from "redux/app";
import { withRouter } from "react-router";
import { Formik, Form, Field } from "formik";
import { updateUserAccount } from "api/accounts";
import SelectBox from "form-controls/SelectBox";
import Button from "Button";
import Can from "Can";
import Loader from "components/Loader";
import Switch from "form-controls/Switch";
import "./UserDetailsForm.css";
import config from "../../../../config";

class UserPermissionsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user
    };
  }

  componentDidUpdate() {
    if (this.state.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }
  }

  handleSubmit(values) {
    const roles = Object.entries(values.role)
      .filter(([, v]) => v)
      .map(([k]) => k);

    const haulierId = roles.includes("Haulier") ? values.haulierId : null;

    const user = { ...this.state.user, roles, haulierId };

    updateUserAccount(user).then(account => {
      this.props.updateUserAccounts(this.props.selectedUser, account);
      this.props.dispatch(addMessage("success", "User details updated."));
    });
  }

  get haulierList() {
    return this.props.hauliers.map(({ id, name }) => ({
      label: name,
      value: id
    }));
  }

  render() {
    let roleOptions = [
      { value: "Security", label: "Security" },
      { value: "Hamshall_security", label: "Hamshall Security" },
      { value: "Shunter", label: "Shunter" },
      { value: "Haulier", label: "Haulier" },
      { value: "Traffic_office", label: "Traffic office" },
      { value: "Warehouse_manager", label: "Warehouse Manager" },
      { value: "Warehouse_operator", label: "Warehouse Operator" },
      { value: "Logistics_management", label: "Logistics Management" },
      { value: "Workshop", label: "Workshop" },
      { value: "Flightboard", label: "Flightboard" },
      { value: "Admin", label: "Admin" }
    ];

    if (config.REACT_APP_CLIENT !== "york") {
      roleOptions = [
        { value: "Digital_enabler", label: "Digital Enabler" }
      ].concat(roleOptions);
    }

    if (this.state.user === "") {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const initialValues = {
      haulierId: this.state.user.haulierId,
      role: this.state.user.roles.reduce(
        (acc, role) => ({ ...acc, [role]: true }),
        {}
      )
    };

    const { permissions } = this.props;
    const canUpdate = permissions.includes("settings.users.update.roles");

    return (
      <div className="user-details-form">
        <div className="user-details-title">
          Manage permissions and access this user has throughout Mercurius.
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={this.handleSubmit.bind(this)}
        >
          {({ values }) => (
            <Form>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Role</th>
                      <th>Access</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roleOptions.map((role, idx) => (
                      <tr key={idx}>
                        <td>{role.label}</td>
                        <td>
                          <Field
                            id={idx}
                            name={`role.${role.value}`}
                            component={Switch}
                            disabled={!canUpdate}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {values.role.Haulier && (
                <Field
                  name="haulierId"
                  label="Haulier"
                  component={SelectBox}
                  options={this.haulierList}
                  disabled={!canUpdate}
                />
              )}
              <div className="button-col">
                <Can when="settings.users.update.roles">
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    title="Save changes"
                  />
                </Can>
                <Button
                  variant="secondary"
                  size="small"
                  title="Back"
                  onClick={() => this.props.cancel()}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(UserPermissionsForm));

function mapStateToProps({ auth: { permissions } }) {
  return { permissions };
}
