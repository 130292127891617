import React from "react";
import Button from "Button";
import Table from "Table";
import RebookingIcon from "RebookingIcon";
import { isPast, subHours } from "date-fns";
import { formatStatus, formatGoods, formatTrailer } from "booking-helper";

export default function AssignTrailerTable({
  site,
  inbound,
  outbound,
  internal,
  getTrailersFromBooking,
  unassignTrailer,
  showBookingPreviewModal
}) {
  function renderSite(site) {
    if (!site) {
      return "";
    }

    return site.parentSite
      ? site.parentSite.name + " - " + site.name
      : site.name;
  }

  function location(trailer) {
    if (!trailer || !trailer.zone) {
      return "";
    }

    return `${trailer.zone.name}${
      trailer.dock ? " - " + trailer.dock.number : ""
    }`;
  }

  function slotStartColour(slotStart) {
    if (isPast(slotStart)) {
      return {
        color: "#de4121"
      };
    }

    const anHourBefore = subHours(slotStart, 1);

    if (isPast(anHourBefore)) {
      return {
        color: "#499fdc"
      };
    }

    return {};
  }

  return (
    <>
      <h3>Outbound bookings</h3>
      <Table
        headers={[
          "",
          "Journey Ref",
          "Tag",
          "Goods Type",
          "Trailer",
          "Pallets",
          "First drop",
          "Collection time",
          "Status",
          "Load on Arrival",
          ""
        ]}
        rows={outbound.map(b => [
          <RebookingIcon booking={b} />,
          <div className="link" onClick={() => showBookingPreviewModal(b.id)}>
            {b.journeyReference} {b.appointment ? "" : "(manual)"}
          </div>,
          b.appointment ? b.appointment.data["journey_tag"] : "",
          b.loadType,
          formatTrailer(b),
          b.numberOfPallets,
          b.appointment
            ? b.appointment.data["receiving_site_description"]
            : b.origin,
          [b.slotStart, { style: slotStartColour(b.slotStart) }],
          [formatStatus(b), { style: { textTransform: "capitalize" } }],
          b.appointment &&
          b.appointment.data &&
          b.appointment.data.load_on_arrival === "Y"
            ? "Yes"
            : "No",
          (b.trailer === null && b.trailerOwner !== null && (
            <Button
              onClick={() =>
                getTrailersFromBooking(
                  b.id,
                  b.trailerOwner.id,
                  b.trailerType.type,
                  false
                )
              }
              size="tiny"
              variant="primary"
              title="Assign Trailer"
            />
          )) || (
            <Button
              onClick={() => unassignTrailer(b.id)}
              size="tiny"
              variant="secondary"
              title="Unassign Trailer"
              disabled={
                (b.trailer &&
                  b.trailer.movements &&
                  b.trailer.movements.length > 0) ||
                b.status !== "trailer_attached"
              }
            />
          )
        ])}
      />
      <h3>Inbound bookings</h3>
      <Table
        headers={[
          "",
          "Journey Ref",
          "Trailer",
          "Pallets",
          "Origin",
          "Planned Arrival Time",
          "ETA",
          "Status",
          "Location on Site",
          "Heat Status",
          "Tip on Arrival"
        ]}
        rows={inbound.map(b => [
          [
            <RebookingIcon booking={b} />,
            { style: { width: "20px", paddingRight: "0" } }
          ],
          <div className="link" onClick={() => showBookingPreviewModal(b.id)}>
            {b.journeyReference} {b.appointment ? "" : "(manual)"}
          </div>,
          formatTrailer(b),
          b.numberOfPallets,
          b.appointment
            ? b.appointment.data.sending_site_description
            : b.origin,
          [b.slotStart, { style: slotStartColour(b.slotStart) }],
          b.appointment && b.appointment.data ? b.appointment.data.eta : "",
          [formatStatus(b), { style: { textTransform: "capitalize" } }],
          location(b.trailer),
          b.appointment &&
          b.appointment.data &&
          b.appointment.data.heat_sensitive === "Y"
            ? "Heat sensitive"
            : "",
          b.appointment &&
          b.appointment.data &&
          b.appointment.data.tip_on_arrival === "Y"
            ? "Yes"
            : "No"
        ])}
      />
      <h3>Internal bookings</h3>
      <Table
        headers={[
          "",
          "Journey Ref",
          "Trailer",
          "Pallets",
          "Sending Site",
          "Receiving Site",
          "Goods Type",
          "Planned Booking Time",
          "Status",
          ""
        ]}
        rows={internal.map(b => [
          [
            <RebookingIcon booking={b} />,
            { style: { width: "20px", paddingRight: "0" } }
          ],
          <div className="link" onClick={() => showBookingPreviewModal(b.id)}>
            {b.journeyReference} {b.appointment ? "" : "(manual)"}
          </div>,
          b.trailer ? "INT - " + b.trailer.trailerNo : "",
          b.numberOfPallets,
          renderSite(b.sendingSite),
          renderSite(b.receivingSite),
          formatGoods(b.goods),
          [b.slotStart, { style: slotStartColour(b.slotStart) }],
          [formatStatus(b), { style: { textTransform: "capitalize" } }],
          b.sendingSite.id === site.id ? (
            <>
              {b.trailer === null && (
                <Button
                  onClick={() =>
                    getTrailersFromBooking(b.id, null, b.trailerType.type, true)
                  }
                  size="tiny"
                  variant="primary"
                  title="Assign Trailer"
                />
              )}
              {b.trailer !== null && (
                <Button
                  onClick={() => unassignTrailer(b.id)}
                  size="tiny"
                  variant="secondary"
                  title="Unassign Trailer"
                  disabled={
                    (b.trailer &&
                      b.trailer.movements &&
                      b.trailer.movements.length > 0) ||
                    b.status !== "trailer_attached"
                  }
                />
              )}
            </>
          ) : (
            ""
          )
        ])}
      />
    </>
  );
}
