import React from "react";
import { connect } from "react-redux";
import { addMessage } from "redux/app";
import { withRouter } from "react-router";
import { Formik, Form, Field } from "formik";
import {
  updateUserAccount,
  unlockAccount,
  deleteUserAccount
} from "api/accounts";
import TextInput from "form-controls/TextInput";
import Switch from "form-controls/Switch";
import Alert from "Alert";
import Button from "Button";
import Can from "Can";
import "./UserDetailsForm.css";

class UserDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user
    };

    this.handleClickDelete = this.handleClickDelete.bind(this);
  }

  componentDidUpdate() {
    if (this.props.user !== this.state.user) {
      this.setState({ user: this.props.user });
    }
  }

  handleSubmit(values) {
    const user = { ...this.state.user, ...values };

    updateUserAccount(user).then(account => {
      this.props.updateUserAccounts(this.props.selectedUser, account);
      this.props.dispatch(addMessage("success", "User details updated."));
    });
  }

  handleUnlock() {
    unlockAccount(this.state.user.id).then(account => {
      this.props.updateUserAccounts(this.props.selectedUser, account);
      this.props.dispatch(addMessage("success", "User account unlocked"));
    });
  }

  handleClickDelete() {
    if (confirm("Are you sure you want to delete this user account?")) {
      deleteUserAccount(this.state.user.id)
        .then(() => this.props.cancel({ refreshAccounts: true }))
        .catch(() => {
          console.error("Failed to delete user account");
        });
    }
  }

  render() {
    const { permissions } = this.props;
    const canUpdate = permissions.includes("settings.users.update");

    return (
      <div className="user-details-form">
        {this.state.user.isLockedOut && (
          <div className="horizontal-container">
            <Alert
              type="locked"
              message="This account has been locked after too many failed login attempts."
            />
            <Button
              variant="white"
              title="Unlock"
              icon="lock_open"
              size="small"
              onClick={() => this.handleUnlock()}
            />
          </div>
        )}
        <Formik
          initialValues={this.state.user}
          enableReinitialize
          onSubmit={this.handleSubmit.bind(this)}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="flex-start">
                <Field
                  name="name"
                  label="Name"
                  component={TextInput}
                  error={touched.name && errors.name}
                  disabled={
                    !canUpdate ||
                    !permissions.includes("settings.users.update.details.name")
                  }
                />
                <Field
                  name="email"
                  label="Email"
                  component={TextInput}
                  error={touched.email && errors.email}
                  disabled={
                    !canUpdate ||
                    !permissions.includes("settings.users.update.details.email")
                  }
                />
                <Field
                  name="contactNumber"
                  label="Phone"
                  component={TextInput}
                  error={touched.contactNumber && errors.contactNumber}
                  disabled={
                    !canUpdate ||
                    !permissions.includes("settings.users.update.details.phone")
                  }
                />
                <Field
                  name="isActive"
                  label="Active"
                  component={Switch}
                  disabled={
                    !canUpdate ||
                    !permissions.includes(
                      "settings.users.update.details.isActive"
                    )
                  }
                />
              </div>
              <div className="button-col flex-start">
                <Can when="settings.users.update">
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    title="Save changes"
                  />
                </Can>
                <Button
                  variant="secondary"
                  size="small"
                  title="Back"
                  onClick={() => this.props.cancel()}
                />
              </div>
            </Form>
          )}
        </Formik>
        <Can when="settings.users.delete">
          <div className="danger-zone">
            <h2 className="caption">Danger Zone</h2>
            <p>
              If you no longer need this account you may delete it. Once deleted
              it will be removed from the list of accounts you can manage as an
              admin. If you just want to disable the account and might need to
              enable it again in the future consider deactivating the account
              above rather than deleting it.
            </p>
            <Button
              onClick={this.handleClickDelete}
              type="button"
              variant="red"
              size="small"
              title="Delete User Account"
              className="cta"
            />
          </div>
        </Can>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(UserDetailsForm));

function mapStateToProps({ auth: { permissions } }) {
  return { permissions };
}
